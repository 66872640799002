import React from 'react'
import moment from 'moment'
import useVictory from './helpers/useVictory'
import { formatBits, graphZoomDomain, timeTickFormat } from '../util/dataFormatters'
import MouseFollowTooltip from './MouseFollowTooltip'

const buildLabel = ({ peakBandwidthBits, allocateBandwidthBits, peakOverageBandwidthBits, windowEnd, createdAt }) => {
  return `Peak BW: ${formatBits(peakBandwidthBits)}\nAllocated BW: ${formatBits(allocateBandwidthBits)}\nOverage BW: ${formatBits(peakOverageBandwidthBits)}\n${moment(windowEnd).utc().format('llll')} (UTC)\nCreated at: ${moment.unix(createdAt).utc().format('llll')} (UTC)\nScroll to zoom.`
}

const ThroughputGraph = ({
  previous,
  resetWeek,
  next,
  startTime,
  endTime,
  data
}) => {
  const Victory = useVictory()

  return (<div className='border-bottom'>
    <div className='text-center'>
      <h4 className='text-muted h4 mt1'>Peak Bandwidth</h4>
    </div>
    { Victory &&
      <Victory.Chart
        height={230}
        width={450}
        scale={{x: 'time'}}
        domain={{x: [startTime.toDate(), endTime.toDate()]}}
        padding={{bottom: 20, top: 5, left: 37, right: 9}}
        theme={Victory.Theme.material}
        containerComponent={<Victory.ZoomContainer
          zoomDomain={graphZoomDomain(data, startTime, endTime, 'peakBandwidthBits')} />
        }
      >
        <Victory.Axis dependentAxis
          tickFormat={(t) => formatBits(t)}
          style={{tickLabels: {padding: 0, fontSize: 6}}}
        />
        <Victory.Axis
          scale={{x: 'time'}}
          style={{tickLabels: {padding: 0, fontSize: 6}}}
          tickFormat={timeTickFormat}
        />
        <Victory.Bar
          data={data.filter(item => item.peakBandwidthBits > item.allocateBandwidthBits)}
          style={{data: {fill: '#c43a31'}, labels: {fontSize: 8, fill: 'white', padding: 0}}}
          barWidth={1}
          x='windowEnd'
          y='peakBandwidthBits'
          y0='allocateBandwidthBits'
          labels={(d) => buildLabel(d.datum)}
          labelComponent={<MouseFollowTooltip orientation='bottom' cornerRadius={3} flyoutStyle={{fill: 'black', strokeWidth: 0}} />}
        />
        <Victory.Bar
          data={data}
          style={{data: {fill: '#F59100'}, labels: {fontSize: 8, fill: 'white', padding: 0}}}
          barWidth={1}
          x='windowEnd'
          y='allocateBandwidthBits'
          labels={(d) => buildLabel(d.datum)}
          labelComponent={<MouseFollowTooltip orientation='right' cornerRadius={3} flyoutStyle={{fill: 'black', strokeWidth: 0}} />}
        />
      </Victory.Chart>
    }
    <div className='text-center'>
      <ul className='pagination'>
        <li><a onClick={(e) => previous(e, 1, 'month')} className='prev' href='#'><i className='fa fa-angle-double-left' /></a></li>
        <li><a onClick={(e) => previous(e, 5, 'days')} className='prev' href='#'><i className='fa fa-angle-left' /></a></li>
        <li><a onClick={(e) => resetWeek(e)} href='#'>Reset</a></li>
        <li><a onClick={(e) => next(e, 5, 'days')} className='next' href='#'><i className='fa fa-angle-right' /></a></li>
        <li><a onClick={(e) => next(e, 1, 'month')} className='next' href='#'><i className='fa fa-angle-double-right' /></a></li>
      </ul>
    </div>
  </div>)
}

export default ThroughputGraph
