import React from 'react'
import moment from 'moment'
import useVictory from '../helpers/useVictory'
import { formatBytes, graphZoomDomain, timeTickFormat } from '../../util/dataFormatters'
import MouseFollowTooltip from '../MouseFollowTooltip'

const buildLabel = ({ usage, usagePerMin, overage, windowEnd, createdAt }) => {
  return `Total usage: ${formatBytes(parseInt(usage) + parseInt(overage))}\nNormal usage: ${formatBytes(usage)}\nOverage usage: ${formatBytes(overage)}\n${moment(windowEnd).utc().format('llll')} (UTC)\nCreated at: ${moment.unix(createdAt).utc().format('llll')} (UTC)\nScroll to zoom.`
}

export const UsageGraph = ({
  previous,
  resetWeek,
  next,
  startTime,
  endTime,
  data
}) => {
  const Victory = useVictory()

  return (
    <div className='border-bottom'>
      <div className='text-center'>
        <h4 className='text-muted h4 mt1'>Total Volume</h4>
      </div>
      {Victory &&
      <Victory.Chart
        height={230}
        width={450}
        scale={{x: 'time'}}
        domain={{x: [startTime.toDate(), endTime.toDate()]}}
        padding={{bottom: 20, top: 5, left: 43, right: 9}}
        theme={Victory.Theme.material}
        containerComponent={<Victory.ZoomContainer
          zoomDomain={graphZoomDomain(data, startTime, endTime, 'totalUsage')} />
        }
      >
        <Victory.Axis dependentAxis
          tickFormat={(t) => formatBytes(t)}
          style={{tickLabels: {padding: 0, fontSize: 6}}}
        />
        <Victory.Axis
          scale={{x: 'time'}}
          style={{tickLabels: {padding: 0, fontSize: 6}}}
          tickFormat={timeTickFormat}
        />
        <Victory.Bar
          data={data.filter(item => item.overage > 0)}
          style={{data: {fill: '#c43a31'}, labels: {fontSize: 8, fill: 'white', padding: 0}}}
          barWidth={3}
          x='windowEnd'
          y='totalUsage'
          y0='usage'
          labels={(d) => buildLabel(d.datum)}
          labelComponent={<MouseFollowTooltip orientation='bottom' cornerRadius={3} flyoutStyle={{fill: 'black', strokeWidth: 0}} />}
        />
        <Victory.Bar
          data={data}
          style={{data: {fill: '#F59100'}, labels: {fontSize: 8, fill: 'white', padding: 0}}}
          barWidth={3}
          x='windowEnd'
          y='usage'
          labels={(d) => buildLabel(d.datum)}
          labelComponent={<MouseFollowTooltip orientation='bottom' cornerRadius={3} flyoutStyle={{fill: 'black', strokeWidth: 0}} />}
        />
      </Victory.Chart>
      }
      <div className='text-center'>
        <ul className='pagination'>
          <li><a onClick={(e) => previous(e, 1, 'month')} className='prev' href='#'><i className='fa fa-angle-double-left' /></a></li>
          <li><a onClick={(e) => previous(e, 5, 'days')} className='prev' href='#'><i className='fa fa-angle-left' /></a></li>
          <li><a onClick={(e) => resetWeek(e)} href='#'>Reset</a></li>
          <li><a onClick={(e) => next(e, 5, 'days')} className='next' href='#'><i className='fa fa-angle-right' /></a></li>
          <li><a onClick={(e) => next(e, 1, 'month')} className='next' href='#'><i className='fa fa-angle-double-right' /></a></li>
        </ul>
      </div>
    </div>
  )
}
