import React from 'react'
import { FormInput } from './Form'

export const ManagedDevices = (props) =>
  <FormInput
    type='number'
    id='license_managed_devices'
    name='managed_devices'
    label='Managed Devices'
    {...props}
  />

export default ManagedDevices
