import React, { Fragment } from 'react'
import Select from 'react-select'
import WizardForm from '../WizardForm'
import WizardButtons from '../WizardButtons'
import RolesDataApi from '../../data/RolesDataApi'

class Roles extends WizardForm {
  state = {
    ...this.props.wizardState,
    nextEnabled: this.props.wizardState.selectedRoles.length > 0,
    roles: [],
    oemRoles: []
  }

  componentDidMount = () => {
    if (this.props.wizardState.trial) {
      this.skipForm()
    }
    this.loadRoles()
    this.loadOemRoles()
  }

  loadRoles = async () => {
    const roles = await new RolesDataApi().getInternalRoles()
    let elements = []
    roles.forEach(role => {
      elements.push({ label: role[0], value: role[1] })
    })
    this.setState({
      roles: elements
    })
  }

  loadOemRoles = async () => {
    const response = await fetch('/api/skus/oem_roles')
    if (response.ok) {
      const json = await response.json()
      let elements = []
      json.oem_roles.forEach(o => {
        elements.push({ label: o[0], value: o[1] })
      })
      this.setState({
        oemRoles: elements
      })
    }
  }

  onChange = (selectedOptions) => {
    this.setState({
      selectedRoles: selectedOptions,
      nextEnabled: selectedOptions.length > 0
    })
  }

  onOemChange = (selectedOptions) => {
    this.setState({
      selectedOemRoles: selectedOptions
    })
  }

  getWizardState = () => {
    return ({
      selectedOemRoles: this.state.selectedOemRoles,
      selectedRoles: this.state.selectedRoles
    })
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <label className='control-label'>Roles</label>
        <Select
          value={this.state.selectedRoles}
          onChange={this.onChange}
          options={this.state.roles}
          isMulti
        />
      </div>
      <div className='py1'>
        <label className='control-label'>OEM Roles</label>
        <Select
          value={this.state.selectedOemRoles}
          onChange={this.onOemChange}
          options={this.state.oemRoles}
          isMulti
        />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled={this.state.nextEnabled}
      />
    </Fragment>
  )
}

export default Roles
