import React, { Fragment } from 'react'
import Select from 'react-select'
import WizardForm from '../WizardForm'
import WizardButtons from '../WizardButtons'

class Versions extends WizardForm {
  state = {
    ...this.props.wizardState,
    nextEnabled: true,
    versions: [{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}]
  }

  componentDidMount = () => {
    if (this.props.wizardState.trial) {
      this.skipForm()
    }
    this.loadVersions()
  }

  loadVersions = () => {
    this.setState({
      selectedVersions: [this.state.versions[this.state.versions.length - 1]]
    })
  }

  onChange = (selectedOptions) => {
    this.setState({
      selectedVersions: selectedOptions,
      nextEnabled: selectedOptions.length > 0
    })
  }

  getWizardState = () => {
    return {
      selectedVersions: this.state.selectedVersions
    }
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <label className='control-label'>ELM Versions</label>
        <Select
          value={this.state.selectedVersions}
          onChange={this.onChange}
          options={this.state.versions}
          isMulti
        />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled={this.state.nextEnabled}
      />
    </Fragment>
  )
}

export default Versions
