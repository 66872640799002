import React from 'react'

const SummaryRow = ({
  label,
  value,
  ...props
}) => {
  return (
    <div className='row'>
      <div className='col-md-6 text-right'>
        <label className='control-label'>{label}</label>
      </div>
      <div className='col-md-6'>
        {value}
      </div>
    </div>
  )
}

export default SummaryRow
