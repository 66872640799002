import React, { Fragment } from 'react'
import moment from 'moment'
import useVictory from '../helpers/useVictory'

const TrialLicensesCreatedGraph = ({
  startTime,
  endTime,
  vthunders,
  webroots,
  agalaxys,
  capacityPools,
  instancePools,
  cylances,
  qosmos
}) => {
  const Victory = useVictory()

  return (<Fragment>
    <div className='text-center'>
      <h4 className='text-muted h4 mt1'>Trial Licenses Created</h4>
    </div>
    { Victory &&
      <Victory.Chart
        height={200}
        width={500}
        theme={Victory.Theme.material}
        domainPadding={20}>
        <Victory.Axis dependentAxis />
        <Victory.Axis
          scale={{ x: 'time' }}
          style={{ tickLabels: { padding: 2, fontSize: 9 } }}
          tickFormat={(t) => moment(t).utc().format('MMM D YYYY')} />
        <Victory.Stack>
          {!!vthunders.length &&
            <Victory.Bar
              data={vthunders}
              barWidth={2}
              style={{ labels: { fontSize: 10, fill: 'white', padding: 0 } }}
              labelComponent={<Victory.Tooltip flyoutStyle={{ fill: 'black', strokeWidth: 0 }} />} />}
          {!!webroots.length &&
            <Victory.Bar
              data={webroots}
              barWidth={2}
              style={{ labels: { fontSize: 10, fill: 'white', padding: 0 } }}
              labelComponent={<Victory.Tooltip flyoutStyle={{ fill: 'black', strokeWidth: 0 }} />} />}
          {!!agalaxys.length &&
            <Victory.Bar
              data={agalaxys}
              barWidth={2}
              style={{ labels: { fontSize: 10, fill: 'white', padding: 0 } }}
              labelComponent={<Victory.Tooltip flyoutStyle={{ fill: 'black', strokeWidth: 0 }} />} />}
          {!!capacityPools.length &&
            <Victory.Bar
              data={capacityPools}
              barWidth={2}
              style={{ labels: { fontSize: 10, fill: 'white', padding: 0 } }}
              labelComponent={<Victory.Tooltip flyoutStyle={{ fill: 'black', strokeWidth: 0 }} />} />}
          {!!instancePools.length &&
            <Victory.Bar
              data={instancePools}
              barWidth={2}
              style={{ labels: { fontSize: 10, fill: 'white', padding: 0 } }}
              labelComponent={<Victory.Tooltip flyoutStyle={{ fill: 'black', strokeWidth: 0 }} />} />}
          {!!cylances.length &&
            <Victory.Bar
              data={cylances}
              barWidth={2}
              style={{ labels: { fontSize: 10, fill: 'white', padding: 0 } }}
              labelComponent={<Victory.Tooltip flyoutStyle={{ fill: 'black', strokeWidth: 0 }} />} />}
          {!!qosmos.length &&
            <Victory.Bar
              data={qosmos}
              barWidth={2}
              style={{ labels: { fontSize: 10, fill: 'white', padding: 0 } }}
              labelComponent={<Victory.Tooltip flyoutStyle={{ fill: 'black', strokeWidth: 0 }} />} />}
        </Victory.Stack>
      </Victory.Chart>
    }
  </Fragment>)
}

export default TrialLicensesCreatedGraph
