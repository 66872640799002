import React, {useState, useEffect, Fragment} from 'react'
import Modal from 'react-bootstrap/Modal'
import times from 'lodash/times'
import orderBy from 'lodash/orderBy'
import Spinner from './Spinner'
import SendgridMessageInfo from './SendgridMessageInfo'
import SortableTableHeader from './SortableTableHeader'
import { formatDateTime } from '../helpers/moment'

/* eslint-disable complexity */
const CustomEmailRecipients = ({customEmailId, batchesCount}) => {
  const [recipients, setRecipients] = useState([])
  const [sortedRecipients, setSortedRecipients] = useState([])
  const [sortVal, setSortVal] = useState({})
  const [currentBatch, setCurrentBatch] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [sendgridErrors, setSendgridErrors] = useState()
  const [selectedMsgId, setSelectedMsgId] = useState()

  const scrollStyle = {
    position: 'absolute',
    top: '10px',
    bottom: '0',
    overflowY: 'auto',
    minHeight: '200px'
  }

  const renderStatus = (status) => {
    let bgColor = ''
    if (status === 'delivered') {
      bgColor = '#28a745'
    }
    if (status === 'not_delivered') {
      bgColor = '#E74C3C'
    }
    return <span className='badge' style={{backgroundColor: bgColor}}>{status}</span>
  }

  const handleBatch = (event, batchNum) => {
    event.preventDefault()
    setError()
    setCurrentBatch(batchNum)
    loadRecipients(batchNum)
  }

  const handleClose = () => {
    setSelectedMsgId()
    setShowModal(false)
  }

  const loadRecipients = async (batchNum) => {
    setLoading(true)
    setRecipients([])

    const response = await fetch(`/custom_emails/${customEmailId}/sendgrid_recipients?batch=${batchNum}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      if (jsonData.error) {
        setError(jsonData.error)
      } else if (jsonData.errors) {
        setSendgridErrors(jsonData.errors)
      } else if (jsonData.messages) {
        if (jsonData.messages.length > 0) {
          setRecipients(jsonData.messages)
        } else {
          setError('No data found, please allow for up to 15 minutes as the information is not real-time.')
        }
      }
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (loading && showModal) {
      loadRecipients(1)
    }
  }, [showModal])

  useEffect(() => {
    if (sortedRecipients.length > 0) {
      $('[data-toggle="tooltip"]').tooltip()
    }
  }, [sortedRecipients])

  useEffect(() => {
    if (recipients && recipients.length > 0) {
      const { key, direction } = sortVal
      if (key && direction) {
        setSortedRecipients(orderBy(recipients, key, direction))
      } else {
        setSortedRecipients(recipients)
      }
    } else {
      setSortedRecipients([])
    }
  }, [sortVal, recipients])

  useEffect(() => {
    let offsetLeft = 0
    if (selectedMsgId) {
      const scrollWidth = $('.modal-body')[0].scrollWidth
      const outerWidth = $('.modal-body').outerWidth()
      offsetLeft = scrollWidth - outerWidth
    }
    $('html, body, .modal-body').animate({scrollLeft: offsetLeft}, 100)
  }, [selectedMsgId])

  const handleMessage = (event, messageId) => {
    event.preventDefault()
    setSelectedMsgId(messageId)
  }

  const renderSummary = () => {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>From</th>
            <SortableTableHeader
              title='To'
              trackKey='to_email'
              onChange={setSortVal}
              sortKey={sortVal.key}
              sortDirection={sortVal.direction} />
            <SortableTableHeader
              title='Status'
              trackKey='status'
              onChange={setSortVal}
              sortKey={sortVal.key}
              sortDirection={sortVal.direction} />
            <SortableTableHeader
              title='Last Event Time'
              trackKey='last_event_time'
              onChange={setSortVal}
              sortKey={sortVal.key}
              sortDirection={sortVal.direction} />
            {!selectedMsgId && <th />}
          </tr>
        </thead>
        <tbody>
          {sortedRecipients.map((recipient, i) =>
            <tr key={i} className={`${selectedMsgId === recipient.msg_id ? 'alert-warning' : ''}`}>
              <td style={{maxWidth: '200px'}}>{recipient.from_email}</td>
              <td style={{maxWidth: '200px'}}>{recipient.to_email}</td>
              <td>{renderStatus(recipient.status)}</td>
              <td>{formatDateTime(recipient.last_event_time)}</td>
              {!selectedMsgId &&
                <td>
                  <a className='btn btn-default btn-round'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Details'
                    href='#'
                    onClick={(e) => handleMessage(e, recipient.msg_id)}>
                    <i className='fa fa-eye' />
                  </a>
                  <a className='btn btn-default btn-round'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Resend'
                    href={`/custom_emails/new?clone_email_id=${customEmailId}&sendTo=${encodeURIComponent(recipient.to_email)}`}>
                    <i className='fa fa-paper-plane' />
                  </a>
                </td>
              }
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  const renderSendgridErrors = () => {
    return (
      <div className='alert alert-danger'>
        <h4>Following Sendgrid errors returned</h4>
        <ul>
          {sendgridErrors.map((err, i) =>
            <li key={i}>{err.message}</li>
          )}
        </ul>
      </div>
    )
  }

  return (
    <Fragment>
      <button onClick={() => setShowModal(true)} className='btn btn-primary'>
        View Sendgrid Recipients
      </button>
      { showModal &&
        <Modal show onHide={handleClose} size='lg' animation={false} dialogClassName='adjust-header'>
          <Modal.Header closeButton>
            <Modal.Title>
              Sendgrid Recipients {recipients && recipients.length > 0 && <small> {recipients.length} entries in batch {currentBatch}</small>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{minHeight: '400px', overflowX: 'hidden'}}>
            <div className='row'>
              {
                batchesCount > 0 &&
                <div className='col-md-2' style={scrollStyle}>
                  <div className='list-group text-center'>
                    {
                      times(batchesCount, (ind) =>
                        <a href='#'
                          key={ind}
                          className={`list-group-item list-group-item-action ${currentBatch === (ind + 1) ? 'active' : ''}`}
                          onClick={(e) => handleBatch(e, ind + 1)}>
                          Batch {ind + 1}
                        </a>
                      )
                    }
                  </div>
                </div>
              }
              <div className={`${batchesCount > 0 ? 'col-md-10 col-md-offset-2' : 'col-md-12'}`} style={scrollStyle}>
                {sendgridErrors && sendgridErrors.length > 0 && renderSendgridErrors()}
                {loading && <div> Loading data from sendgrid <Spinner /></div> }
                {error && <div className='alert alert-danger'> {error} </div>}
                {sortedRecipients && sortedRecipients.length > 0 && renderSummary()}
              </div>
              <div
                className={`col-md-7 col-md-offset-12`}
                style={{...scrollStyle, backgroundColor: 'white', borderLeft: '2px solid #e5e5e5', top: '0px'}}>
                { selectedMsgId &&
                  <SendgridMessageInfo
                    messageId={selectedMsgId}
                    onClose={() => setSelectedMsgId()}
                    customEmailId={customEmailId} />
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </Fragment>
  )
}
/* eslint-enable complexity */

export default CustomEmailRecipients
