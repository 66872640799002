import React, { Component, Fragment } from 'react'
import { Checkbox } from '../components/Input'
import Label from '../Form/Label'

export class SkuRoles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      skuRoles: this.props.sku.roles
    }
  }

  componentDidMount () {
    this.handleState()
  }

  isChecked (role) {
    return this.state.skuRoles.indexOf(role) > -1
  }

  handleCheck (role) {
    if (this.isChecked(role)) {
      this.setState({ skuRoles: this.state.skuRoles.filter(item => item !== role) })
    } else {
      this.setState({ skuRoles: [...this.state.skuRoles, role] })
    }

    this.handleState()
  }

  handleState () {
    const { onChange, name } = this.props
    this.setState(
      { ...this.state.skuRoles },
      () => onChange({ name, value: this.state.skuRoles })
    )
  }

  render () {
    return (
      <Fragment>
        <Label>Available Roles</Label>
        {
          this.props.roles.map((role, i) =>
            <div key={i} className='row m1'>
              <Checkbox
                id={i}
                value={role}
                checked={this.isChecked(role)}
                onChange={(e) => this.handleCheck(role)}
              />
            </div>
          )
        }
      </Fragment>
    )
  }
}

export default SkuRoles
