import React, { Fragment } from 'react'
import Select from 'react-select'
import WizardForm from '../WizardForm'
import WizardButtons from '../WizardButtons'

class Entitlements extends WizardForm {
  state = {
    ...this.props.wizardState,
    entitlements: []
  }

  componentDidMount = () => {
    if (this.props.wizardState.trial) {
      this.skipForm()
    }
    this.loadEntitlements()
  }

  loadEntitlements = async () => {
    const response = await fetch('/api/skus/entitlements')
    if (response.ok) {
      const json = await response.json()
      let elements = []
      json.entitlements.forEach(e => {
        elements.push({ label: e[0], value: e[1] })
      })
      this.setState({
        entitlements: elements
      })
    }
  }

  onChange = (selectedOptions) => {
    this.setState({
      selectedEntitlements: selectedOptions,
      nextEnabled: selectedOptions.length > 0
    })
  }

  getWizardState = () => {
    return {
      selectedEntitlements: this.state.selectedEntitlements
    }
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <label className='control-label'>Entitlements</label>
        <Select
          value={this.state.selectedEntitlements}
          onChange={this.onChange}
          options={this.state.entitlements}
          isMulti
        />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled
      />
    </Fragment>
  )
}

export default Entitlements
