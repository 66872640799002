import React, {Fragment, useState} from 'react'
import qs from 'query-string'
import has from 'lodash/has'
import StepperLicenseForm from '../containers/StepperLicenseForm'
import LicenseForm from '../containers/LicenseForm'

const LegacyAndNewLicenseForm = (props) => {
  const locationParams = qs.parse(window.location.search)
  const legacy = has(locationParams, 'legacy_form')
  const [legacyForm, setLegacyForm] = useState(legacy)

  const handleClick = (e) => {
    e.preventDefault()
    setLegacyForm(!legacyForm)
  }

  return (
    <Fragment>
      <div class="text-right">
        <a href="#" onClick={handleClick} className='btn btn-link btn-sm'>Use {legacyForm ? 'new' : 'legacy'} form</a>
      </div>
      {legacyForm && <LicenseForm {...props}/>}
      {!legacyForm && <StepperLicenseForm {...props}/>}
    </Fragment>
  )
}

export default LegacyAndNewLicenseForm
