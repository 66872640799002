import React, {useState} from 'react'
import Form from 'react-bootstrap/Form'
import WithHelpText from './WithHelpText'

const SearchField = ({onSearch, initVal, infoKey}) => {
  const [searchVal, setSearchVal] = useState(initVal || '')

  const handleSubmit = (e) => {
    e.preventDefault()
    onSearch(searchVal)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label><WithHelpText infoKey={infoKey}>Search</WithHelpText></Form.Label>
        <div className='row'>
          <div className='col-md-10'>
            <Form.Control
              as='input'
              onChange={(e) => setSearchVal(e.target.value)}
              value={searchVal} />
          </div>
          <div className='col-md-2'>
            <button className='btn btn-primary' onClick={() => onSearch(searchVal)}>
              Search
            </button>
          </div>
        </div>
      </Form.Group>
    </Form>
  )
}

export default SearchField
