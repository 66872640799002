import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from '../components/Button'

function SubscriberFile({rownumber, file, licenseid, onDelete}) {
  return (
    <Row className={rownumber%2 ? 'subscriber-row subscriber-row-odd' : 'subscriber-row subscriber-row-even'}>
      <Col lg={2} className='subscriber-col'>{file.filename}</Col>
      <Col lg={2} className='subscriber-col'>{file.year}</Col>
      <Col lg={1} className='subscriber-col'>{file.quarter}</Col>
      <Col lg={2} className='subscriber-col'>{file.size}</Col>
      <Col lg={3} className='subscriber-col'>{new Date(file.created_at).toLocaleString()}</Col>
      <Col lg={2}>
        <Button variant='danger'
          classNames={['btn-sm', 'pull-right', 'pt2', 'pb2']}
          onClick={() => onDelete(file.id)}
        >
          <i className='fa fa-trash' />
        </Button>
        <Button variant='primary'
          classNames={['btn-sm', 'pull-right', 'pt2', 'pb2']}
          href={`/subscriber_audit_files/${file.id}/download?licenseid=${licenseid}`}
          target='_blank'
        >
          <i className='fa fa-download' />
        </Button>
      </Col>
    </Row>
  )
}

export default SubscriberFile
