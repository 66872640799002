import React from 'react'

export const NoOrganizationMessage = (props) => {
  return(
    <div className='row'>
      <div className='col-12'>
        <h3>Organization required</h3>
        <p className='text-muted'>Your account does not belong to an organization.  Either:</p>
      </div>
    </div>
  )
}

export default NoOrganizationMessage
