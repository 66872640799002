import React, { useEffect, useState } from 'react'
import Spinner from '../Spinner'
import LogResponse from './LogResponse'
import moment from 'moment'
import 'isomorphic-fetch'

const ThreatstopLogs = ({ licenseId }) => {
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentLogId, setCurrentLogId] = useState()
  const [error, setError] = useState()

  const loadLogs = async () => {
    setLoading(true)

    const response = await fetch(`/licenses/${licenseId}/threatstop_logs.json`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setLogs(jsonData.data)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  const trClass = (log) => {
    if (currentLogId === log.id) {
      return 'bg-warning'
    } else if (log.status_code === 200 || log.status_code === 201) {
      return 'bg-success'
    } else {
      return 'bg-danger'
    }
  }

  const formatDateTime = (dateTimeString) => moment(dateTimeString).format('lll')

  const setLogForResponse = (event, id) => {
    event.preventDefault()
    setCurrentLogId(id)
  }

  useEffect(() => {
    loadLogs()
  }, [])

  const renderLogs = () => {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Description</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {logs.map((log, i) =>
            <tr key={i} className={trClass(log)}>
              <td>
                <div>{log.description}</div>
                <div><small>status code: <b>{log.status_code}</b></small></div>
                <div><small>Created At: <b>{formatDateTime(log.created_at)}</b></small></div>
              </td>
              <td><small><a href='#' className='badge btn-primary' onClick={(e) => {
                setLogForResponse(e, log.id) 
              }}>View Reponse</a></small></td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      <LogResponse logId={currentLogId} onClose={setCurrentLogId} />
      {loading && <Spinner />}
      {error && <div className='alert alert-danger'> {error} </div>}
      {logs.length === 0 && !loading && !error && <div className='alert alert-danger'> No Logs available </div>}
      {logs.length > 0 && renderLogs()}
    </div>
  )
}

export default ThreatstopLogs
