import React, { Component } from 'react'
import { wizard } from '../Wizard'
import Bandwidth from './Bandwidth'
import Entitlements from './Entitlements'
import Roles from './Roles'
import FamilyAndProduct from './FamilyAndProduct'
import Description from './Description'
import Counts from './Counts'
import Trial from './Trial'
import Webroot from './Webroot'
import Versions from './Versions'
import Summary from './Summary'
import Success from './Success'

const Wizard = wizard(FamilyAndProduct,
  Webroot,
  Trial,
  Entitlements,
  Roles,
  Bandwidth,
  Counts,
  Versions,
  Description,
  Summary,
  Success
)

class CreateSkuWizard extends Component {
  state = {
    wizardState: {
      selectedFamily: '',
      selectedFamilyText: '',
      selectedProduct: '',
      selectedProductText: '',
      selectedPlatform: '',
      selectedPlatformText: '',
      selectedWebroot: '',
      selectedBandwidth: 1,
      unlimitedBandwidth: false,
      bandwidthUnit: 'Mbps',
      displayBandwidth: 1,
      selectedEntitlements: [],
      selectedRoles: [],
      selectedOemRoles: [],
      cpuCount: 0,
      maxAdc: 0,
      maxDevices: 0,
      unlimitedDevices: false,
      maxTps: 0,
      trial: false,
      selectedVersions: [],
      selectedTrialUnit: '',
      selectedTrialIncrement: '',
      wizardTitle: 'Create New SKU',
      isFlexpool: false
    }
  }

  render () {
    return (
      <div className='col-md-offset-2 col-md-8'>
        <Wizard wizardState={this.state.wizardState} />
      </div>
    )
  }
}

export default CreateSkuWizard
