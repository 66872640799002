import React, { PureComponent } from 'react'
import FilterWithClear from '../FilterWithClear'
import debounce from 'lodash/debounce'

class LicenseFilter extends PureComponent {
  static defaultProps = {license_filter: '', spinnerOn: false}

  fetchSearch = filter => {
    const url = `/api/licenses/search.json?${this.params()}&query=${encodeURIComponent(filter)}`
    return fetch(
      url, {
        credentials: 'include',
        method: 'GET',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        }
      })
  }
  componentDidUpdate (prevProps) {
    if (prevProps.sortDirection !== this.props.sortDirection && $('#license_filter_id').val().length > 0) {
      this.updateLicenses($('#license_filter_id').val())
    }
  }

  params = () => `organization_id=${this.props.organizationId || ''}&user_id=${this.props.userId || ''}&order_id=${this.props.orderId || ''}&myLicenses=${this.props.myLicenses || 'false'}&sort_direction=${this.props.sortDirection || 'desc'}`

  licenseSummary = (jsonData) => (
    [...new Set(jsonData.map((i) => (
      [i['attributes']['token'], i['attributes']['display_name'], i['attributes']['sku_description']]
    )).flat())]
  )

  updateLicensesList = async filter => {
    this.props.onFilterChange({ spinnerOn: true })
    try {
      const response = await this.fetchSearch(filter)
      const json = await response.json()
      this.props.onFilterChange({
        license_filter: filter,
        filtered_licenses: json.data,
        spinnerOn: false,
        dropdownItems: this.licenseSummary(json.data)
      })
    } catch (error) {
      this.props.onFilterChange({
        license_filter: filter,
        filtered_licenses: [],
        spinnerOn: false,
        dropdownItems: []
      })
    }
  }

  updateLicenses = debounce(this.updateLicensesList, 300)

  handleFilterChange = values => {
    this.updateLicenses(values.license_filter)
  }

  handleClearInput = values => {
    this.props.onFilterChange({
      license_filter: '',
      filtered_licenses: [],
      dropdownItems: []
    })
  }

  render () {
    return (
      <FilterWithClear
        input={{
          id: 'license_filter_id',
          name: 'license_filter_name',
          placeholder: 'Search Licenses',
          required: true
        }}
        submitField='license_filter'
        className='form-control'
        items={this.props.items}
        onChange={this.handleFilterChange}
        handleClearInput={this.handleClearInput}
      />
    )
  }
}

export default LicenseFilter
