import React from 'react'
import Box from '../components/Box'
import Label from '../components/Label'
import IdpDomainList from './IdpDomainList'
import IdpReadOnlyFields from './IdpReadOnlyFields'

export const IdpShowForm = ({ idpConfiguration, idpDomains }) => {
  return(
    <>
      <div className='row'>
        <div className='col-md-12'>
          <h3>{idpConfiguration.name}</h3>
        </div>
      </div>
      <div className='row py1'>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Adminstrator Email</Label><br />
          <Box tag='span'>{idpConfiguration.administrator_email}</Box>
        </div>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Email Claim Identifier</Label><br />
          <Box tag='span'>{idpConfiguration.email_claim_identifier}</Box>
        </div>
      </div>
      <div className='row py1'>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Name Identifier Format</Label><br />
          <Box tag='span'>{idpConfiguration.name_identifier_format}</Box>
        </div>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Authn Context</Label><br />
          <Box tag='span'>{idpConfiguration.authn_context}</Box>
        </div>
      </div>
      <IdpReadOnlyFields idpConfiguration={idpConfiguration} />
      <div className='row py1'>
        <div className='col-md-12'>
          <Label classNames={['text-muted']}>{`Domain${idpDomains.length?'s':''}`}</Label><br />
          <IdpDomainList idpDomains={idpDomains} />
        </div>
      </div>
    </>
  )
}

export default IdpShowForm
