import React, { PureComponent } from 'react'

class Table extends PureComponent {
  render () {
    return (
      <div className='col-md-12'>
        <table className='table table-slats table-layout-fixed'>
          <thead>
            <tr>
              {this.props.headers.map((header, i) =>
                <th key={'header_' + i}>{header}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.props.children}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table
