import React from 'react'

import {
  FormGroup,
  FormSection,
  FormText
} from './Form'
import { Checkbox } from './Input'

export const DiskEncryption = props =>
  <FormSection>
    <FormGroup>
      <Checkbox
        id='license_disk_encryption'
        name='disk_encryption'
        label='Grant Disk Encryption'
        hint='Note: This is a non-revertible and is not bound by the license term.'
        {...props}
      />
    </FormGroup>
  </FormSection>

export default DiskEncryption
