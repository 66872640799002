import React from 'react'
import moment from 'moment'

export const DATE_FORMAT = 'DD/MM/YYYY'

export const getMoment = (start, ...props) => moment(start).utc().add(...props)
export const getMomentFmt = (start, fmt) => moment(start, fmt)
export const getMomentStr = (start, ...props) => getMoment(start, ...props).format(DATE_FORMAT)

export const dayMoment = (start, days) => {
  const prefix = days === 1 ? 'Day' : 'Days'

  return getMomentStr(start, days.toString(), prefix)
}

export const yearMoment = (start, years) => {
  const prefix = years === 1 ? 'Year' : 'Years'

  return getMomentStr(start, years.toString(), prefix)
}

export const formatDateTime = (dateTimeString) => {
  if (dateTimeString) {
    return moment(dateTimeString).format('lll')
  } else {
    return 'N/A'
  }
}
