import isNumber from 'lodash/isNumber'
import React from 'react'

const mbpsValue = (value, unit) => {
  switch (unit) {
    case 'Tbps':
      return value * 1000000
    case 'Gbps':
      return value * 1000
    default:
      return value * 1
  }
}

const formattedBandwidth = value => {
  if (value > 999999) {
    return { unit: 'Tbps', value: value / 1000000 }
  } else if (value > 999) {
    return { unit: 'Gbps', value: value / 1000 }
  } else if (value > 0) {
    return { unit: 'Mbps', value: value }
  } else {
    return { unit: 'Mbps', value: null }
  }
}

const getLabel = (value, unit, chassisQty) => {
  if (isNumber(chassisQty) && value) {
    const totalBw = mbpsValue(parseInt(value) * chassisQty, unit)
    const formattedBw = formattedBandwidth(totalBw)
    return <span>{formattedBw.value} {formattedBw.unit} <small>({value} {unit} * {chassisQty} Chassis)</small></span>
  } else {
    return `${value}  ${unit}`
  }
}

export default getLabel