import React, {Fragment, useState, useEffect} from 'react'

const HarmonyJson = ({schema, onChange, initialVal, ...props}) => {
  const [formData, setFormData] = useState(initialVal)
  const [loading, setLoading] = useState(false)
  const [jsResources, setJsResources] = useState()

  const uiSchema = {
    "ui:order": ["metadata", "device-management", 'user', 'subscribers', 'services'],
    metadata: {
      'compatible-release': {
        "ui:title": "compatible release",
        "max-version": {"ui:title": "max version"},
        "min-version": {"ui:title": "min version"},
        "ui:order": ['min-version', 'max-version', '*']
      }
    },
    'device-management': {
      "ui:title": "device management",
      syslog: {
        'retention-hours': { "ui:title": "retention hours"}
      },
      backup: {
        manual: {
          "ui:order": ['isAllowed', 'unlimited', 'number', '*'],
          'retention-days': { "ui:title": "retention days"}
        },
        periodic: {
          'retention-days': { "ui:title": "retention days"}
        }
      },
      metrics: {
        'roll-up': { "ui:title": "roll up"},
        'retention-days': { "ui:title": "retention days"}
      },
      'session-log': {
        "ui:title": "session log",
        'total-log-rate': { "ui:title": "total log rate"},
        'retention-hours': { "ui:title": "retention hours"}
      },
      'cli-snippets': {
        "ui:title": "CLI Snippets",
        'number-of-snippets': { "ui:title": "number of snippets"}
      },
      'full-logging': { "ui:title": "full logging"}
    },
    services: {
      ADC: {
        'number-of-apps': { "ui:title": "number of apps"}
      }
    }
  };

  useEffect(()=> {
    const loadResources = async() => {
      setLoading(true)
      const validator = (await import("@rjsf/validator-ajv6")).default
      const Form = (await import("@rjsf/core")).default
      setJsResources({validator, Form})
      setLoading(false)
    }
    loadResources()
  },[])

  useEffect(() => {
    if (formData && onChange) {
      onChange({ name: props.name, value: formData })
    }
  }, [formData])

  return (
    <div className='hc-json-entitlements-wrapper'>
      {loading && <span>Loading Resources...</span>}
      {jsResources && <jsResources.Form
        validator ={jsResources.validator}
        schema={schema}
        omitExtraData
        uiSchema={uiSchema}
        liveOmit
        tagName='div'
        formData={formData}
        onChange={e => setFormData(e.formData)}>
        <Fragment />
      </jsResources.Form>}
    </div>
  )
}

export default HarmonyJson
