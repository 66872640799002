import React from 'react'
import Button from './Button'
import classNames from 'classnames'

const WizardButtons = ({previousHidden, nextEnabled, onNextClick, onPreviousClick, previousText, nextText, ...props}) =>
  <div>
    <div className='pull-left'>
      <Button className={classNames({hidden: previousHidden})} variant='default' onClick={onPreviousClick}>{previousText || 'Previous'}</Button>
    </div>
    <div className='pull-right'>
      <Button variant='primary' className={(nextEnabled ? '' : 'disabled')} onClick={nextEnabled ? onNextClick : null}>{nextText || 'Next'}</Button>
    </div>
  </div>

export default WizardButtons
