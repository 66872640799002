import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'
import Button from './Button'
import {
  Input,
  InputErrors,
  Label,
  inputClasses
} from './Input'
import { noop } from '../../util'
import { titleize } from '../../util/string'

export const formClasses = ({ required, type }) => ([
  { required, optional: !required },
  inputClasses[type]
])

export const FormActions = ({
  classNames = [],
  ...props
}) =>
  <Box
    classNames={['actions', ...classNames]}
    {...props}
  />

FormActions.propTypes = { classNames: PropTypes.array }

export const FormDescription = props =>
  <Box as='small' {...props} />

export const FormGroup = ({
  classNames = [],
  required,
  type,
  ...props
}) =>
  <Box
    classNames={[
      'form-group',
      'position-relative',
      ...formClasses({ required, type }),
      ...classNames
    ]}
    {...props}
  />

FormGroup.propTypes = {
  classNames: PropTypes.array,
  required: PropTypes.bool,
  type: PropTypes.string
}

export const FormInfo = ({
  classNames = [],
  ...props
}) =>
  <Box
    classNames={['form-info', ...classNames]}
    {...props}
  />

FormInfo.propTypes = { classNames: PropTypes.array }

export const FormInput = ({
  classNames = [],
  errors,
  label,
  onUnmount,
  hidden,
  skipLabel = false,
  value,
  ...props
}) =>
  <FormGroup required={props.required} type={props.type} classNames={[{ hidden }]}>
    { !skipLabel && <FormLabel
      required={props.required}
      type={props.type}
      htmlFor={props.id}
      disabled={props.disabled}
      children={label || titleize(props.name || props.id)}
    /> }
    <Input
      classNames={[...formClasses(props), ...classNames]}
      value={value || ""}
      {...props}
    />
    {errors &&
      <InputErrors>{errors}</InputErrors>
    }
  </FormGroup>

FormInput.defaultProps = {
  classNames: [],
  type: 'string'
}

FormInput.propTypes = {
  classNames: PropTypes.array,
  errors: PropTypes.array,
  onUnmount: PropTypes.fn
}

export const FormLabel = ({
  classNames = [],
  type,
  ...props
}) =>
  <Label
    classNames={[...classNames, inputClasses[type]]}
    {...props}
  />

FormInput.propTypes = {
  classNames: PropTypes.array,
  type: PropTypes.string
}

export const FormSection = ({
  classNames = [],
  ...props
}) =>
  <Box
    classNames={['form-section', ...classNames]}
    {...props}
  />

FormSection.propTypes = { classNames: PropTypes.array }

export const FormSubmit = ({ children, ...props }) =>
  <input
    className='btn btn-primary'
    type='submit'
    name='commit'
    {...props}
  />

export const FormText = ({
  classNames = [],
  variant,
  ...props
}) =>
  <Box
    as='small'
    classNames={['form-text', { 'text-muted': variant === 'muted' }, ...classNames]}
    {...props}
  />

FormText.propTypes = { classNames: PropTypes.array }

export const FormTitle = ({
  classNames = [],
  ...props
}) =>
  <Box
    as='h1'
    classNames={['h3', ...classNames]}
    {...props}
  />

FormTitle.propTypes = { classNames: PropTypes.array }

export const FormWrap = ({
  classNames = [],
  ...props
}) =>
  <Box
    classNames={[ 'form-wrap', ...classNames]}
    {...props}
  />

FormWrap.propTypes = { classNames: PropTypes.array }
