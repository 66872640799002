import React, { useContext } from 'react'
import _get from 'lodash'

import ComplianceErrors from './ComplianceErrors'
import { FormInput } from './Form'
import { Field, FormContext } from '../containers/Form'

// TODO test and see if thse are supposed to limit
// TODO investigate NaN error on slider
export const defaults = {
  small: { min: 25, def: 25 },
  medium: { min: 100, def: 500, max: 10000 },
  large: { min: 200, def: 1000, max: 10000 }
}

export const data = bandwidth => {
  if (bandwidth <= 10000) return { ...defaults.small, max: bandwidth }
  if (bandwidth <= 50000) return defaults.medium
  return defaults.large
}

export const isInBounds = (a, min, max) => (
  (a.allocated_bandwidth < min) || (a.allocated_bandwidth > max)
)

const BaseAllocatedInput = ({
  type,
  bandwidth,
  onChange,
  ...props
}) => {
  const handleChange = ({ target: { value } }) => {
    onChange && onChange({ name: props.name, value })
  }

  return (
    <Field
      as={FormInput}
      type='number'
      initialValue={type === 'max' ? bandwidth : data(bandwidth)[type]}
      onChange={handleChange}
      {...props}
    />
  )
}

export const DefaultAllocatedBandwidth = props =>
  <BaseAllocatedInput
    id='license_default_allocated_bandwidth'
    name='default_allocated_bandwidth'
    label='Default Allocated Bandwidth (in Mbps)'
    type='def'
    {...props}
  />

export const MaxAllocatedBandwidth = props =>
  <BaseAllocatedInput
    id='license_maximum_allocated_bandwidth'
    name='maximum_allocated_bandwidth'
    label='Maximum Allocated Bandwidth (in Mbps)'
    hint='A blank Maximum Allocated Bandwidth defaults to the License Bandwidth in Mbps.'
    type='max'
    {...props}
  />

export const MinAllocatedBandwidth = props =>
  <BaseAllocatedInput
    id='license_minimum_allocated_bandwidth'
    name='minimum_allocated_bandwidth'
    label='Minimum Allocated Bandwidth (in Mbps)'
    type='min'
    {...props}
  />

export const AllocatedBandwidths = props => {
  const {
    data,
    handleChange,
    inputProps = {}
  } = useContext(FormContext)

  const unFilteredActivations = _get(inputProps, 'allocated_bandwidths.activations')
  const activations = unFilteredActivations.filter(a => isInBounds(a, min, max))

  return (
    <>
      <MinAllocatedBandwidth bandwidth={data.bandwidth} onChange={e => handleChange(e)} />
      <DefaultAllocatedBandwidth bandwidth={data.bandwidth} onChange={e => handleChange(e)} />
      <MaxAllocatedBandwidth bandwidth={data.bandwidth} onChange={e => handleChange(e)} />
      {!!activations.length &&
        <ComplianceErrors children={activations} />
      }
    </>
  )
}

export default AllocatedBandwidths
