import React, {useState, useEffect, useCallback} from 'react'
import Card from 'react-bootstrap/Card'
import qs from 'query-string'
import MetricsChart from '../../../components/HarmonyMetrics/MetricsChart'
import MetricsOptions from './MetricsOptions'
import MapChart from './MapChart';
import ConfirmModal from '../../../components/Modals/ConfirmModal'
import capitalize from 'lodash/capitalize'
import useAsync  from '../hooks/useAsync'

const SuspendedMetricsCard = ({OptionComponent, ...props}) => {
  const {settings, id, index, onChartUpdated, chart} = {...props}
  const [chartSettings, setChartSettings] = useState()
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false)
  const [updatedChartLabel, setUpdatedChartLabel] = useState('')
  const { useSortable } = useAsync(import("@dnd-kit/sortable"), "@dnd-kit/sortable")
  
  const {
    listeners,
    setNodeRef,
    transition
  } = useSortable({id: id})

  const renderChart = () => {
    return chartSettings && chartSettings.starts_at && chartSettings.ends_at
  }

  const updateChartLabel = (newSettings) => {
    const capSettings = []
    const module = newSettings['module']
    const operator = module === 'apis' || module === 'users_created' ? ' | ' : ' & ';
    const selectedSettings = Object.keys(newSettings).filter(function(key) {
      if (newSettings[key] === true) {
        return key
      }
    })
    selectedSettings.forEach((item) => {
      capSettings.push(capitalize(item))
    })
    setUpdatedChartLabel(capSettings.join(operator))
  }

  const handleSettingsChange = (newSettings) => {
    setChartSettings(newSettings)
    updateChartLabel(newSettings)
    onChartUpdated && onChartUpdated(newSettings)
  }

  const handleIntervalChange = useCallback(
    (interval) => {
      const newSettings = {...chartSettings, interval: interval}
      setChartSettings(newSettings)
      onChartUpdated && onChartUpdated(newSettings)
    }, [chartSettings]
  )

  const handleRemovingChart = () => {
    setShowRemovalConfirmation(false)
    settings['deleted'] = true
    onChartUpdated && onChartUpdated(settings)
  }

  const showConfirmRemovalModal = () => {
    setShowRemovalConfirmation(true)
  }

  const hideConfirmRemovalModal = () => {
    setShowRemovalConfirmation(false)
  }

  const style = {
    transformOrigin: '0 0',
    gridRowStart: index < 2 ? 'span 2' : null,
    gridColumnStart: index < 2 ? 'span 2' : null,
    transition
  }

  const topActionBtnsStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 0
  }

  return (
    <div ref={setNodeRef}>
      <Card style={style}>
        <Card.Body>
          <Card.Title>
            <div className='btn-group' style={topActionBtnsStyle}>
              <button className='btn btn-link small' onClick={() => {
                showConfirmRemovalModal()
              }}
              title='Remove Chart' >
                <i className='fa fa-times' />
              </button>
              <button {...listeners} className='btn btn-link small'
                title='Move Chart' >
                <i className='fa fa-arrows' />
              </button>
            </div>
            {settings.label}
          </Card.Title>
          <MetricsOptions settings={settings} onSettingsChange={handleSettingsChange} OptionComponent={OptionComponent} />
          {renderChart() && !chart.map && <div className='text-center'>
            <hr />
            <MetricsChart
              interval={settings.interval}
              chartType={chart.type}
              onIntervalChange={handleIntervalChange}
              fetchURL={`${chart.url}?${qs.stringify(chartSettings)}`}
              intervalDependent={false}
              yTitle={chart.label + ' ' + updatedChartLabel} title='' />
          </div>}
          {renderChart() && chart.map && <div className='text-center'>
            <hr/>
            <MapChart fetchURL={`${chart.url}?${qs.stringify(chartSettings)}`} label={chart.label}/>
          </div>}
        </Card.Body>
      </Card>
      <ConfirmModal onClose={hideConfirmRemovalModal}
        onConfirmClick={handleRemovingChart}
        show={showRemovalConfirmation}
        body='Are you sure you want to remove this chart?'
        title='Chart Removal' />
    </div>
  )
}

export default React.memo(SuspendedMetricsCard)
