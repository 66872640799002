import times from 'lodash/times'
import random from 'lodash/random'

// Like Ruby's dig. Safely dig through a deep object
export const dig = (obj, ...keys) => keys.reduce(
  (o, k) => (o && k && o[k]),
  obj
)

// Not perfectly random, but good enough for our purposes
// You can optionally pass args to prepend
export const uid = (...args) => {
  const prefix = args.join('_')
  const str1 = Math.random().toString(36).substring(2, 15)
  const str2 = Math.random().toString(36).substring(2, 15)
  return `${prefix}${str1}${str2}`
}

// Convert between camelCase and snakeCase
export const toCamel = str => str.replace(/_[a-z]/g, m => m.toUpperCase().replace('_', ''))
export const toSnake = str => str.replace(/[A-Z]/g, m => `_${m.toLowerCase()}`)

export const truncate = (str, maxLength = 25) => (
  str.length >= maxLength
    ? str.slice(0, maxLength)
    : str
)

export const randomString = () => times(15, () => random(35).toString(36)).join('')