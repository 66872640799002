import React from 'react'
import SummaryRow from './SummaryRow'

const SummaryTrialRows = (props) => {
  return (
    <div>
      <SummaryRow label='Trial:' value={props.wizardState.trial.toString()} />
      {props.wizardState.trial &&
        <SummaryRow label='Trial Units:' value={props.wizardState.selectedTrialUnit} />
      }
      {props.wizardState.trial &&
        <SummaryRow label='Trial Increment:' value={props.wizardState.selectedTrialIncrement} />
      }
    </div>
  )
}

export default SummaryTrialRows
