import React from 'react'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'

const Toast = ({ message }) => {
  GLMToast(message)
  return <React.Fragment />
}

const Notification = ({ message, classes }) => {
  return (
    <React.Fragment>
      <GLMToastContainer toastClassName={classes} />
      <Toast message={message} />
    </React.Fragment>
  )
}

export default Notification
