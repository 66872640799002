import React, { Component } from 'react'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Align from '../components/Align'
import Box from '../components/Box'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  FormGroup,
  FormInput
} from '../components/Form'
import { Input, Label } from '../components/Input'
import { getCores } from '../../util/dataFormatters'
import getLabel from '../../util/chassisQtyBwHelpers'

// TODO: Fix padding when adding form nav
// TODO: Set initial value in parent on first render

const formattedBandwidth = value => {
  if (value > 999999) {
    return { unit: 'Tbps', value: value / 1000000 }
  } else if (value > 999) {
    return { unit: 'Gbps', value: value / 1000 }
  } else {
    return { unit: 'Mbps', value }
  }
}

const unformattedBandwidth = ({ unit, value }) => {
  switch (unit) {
    case 'Tbps':
      return value * 1000000
    case 'Gbps':
      return value * 1000
    default:
      return value
  }
}

const bandwidthLimits = max => ({
  Mbps: { min: 25, max: 999 },
  Gbps: { min: 1, max: (max < 1000000 ? max / 1000 : 999) },
  Tbps: { min: 1, max: (max / 1000000) }
})

const UnitRadio = props =>
  <Box className='mx-auto'>
    <Input type='radio' {...props} />
  </Box>

class BandwidthInput extends Component {
  static defaultProps = {
    initialValue: 1000,
    showMbps: true,
    showUnlimitedOption: false,
    defaultMax: 6
  }

  getValue = () => {
    return this.props.value || this.props.initialValue
  }

  state = {
    unlimited: this.getValue() === null,
    ...formattedBandwidth(this.getValue())
  }

  boundedValue = (value, limits) => {
    if (value >= limits.max) {
      return limits.max
    } else if (value <= limits.min || 0) {
      return limits.min
    } else {
      return value
    }
  }

  handleBandwidthChange = ({ currentTarget }) => {
    const { unit } = this.state
    this.setStateValues({ unit, value: currentTarget.value }, true)
  }

  handleUnlimitedChange = (checked) => {
    this.setStateValues({ unlimited: checked })
  }

  undoUnlimitedSetting = (checked) => {
    const { unit } = this.state
    const limits = this.limits()[unit]
    this.setStateValues({ unlimited: false, value: limits.max })
  }

  setUpstreamBandwidth = ({ value }) => {
    const { onChange, name } = this.props
    onChange({ name, value })
  }

  setStateValues = ({ unit, value, unlimited }, sliderCanGoToUnlimited) => {
    const adjustedUnit = unit ? unit : this.state.unit
    const limits = this.limits()[adjustedUnit]
    let adjustedUnlimited = unlimited === undefined ? this.state.unlimited : unlimited
    const boundedValue = this.boundedValue(value || this.state.value, limits, sliderCanGoToUnlimited)
    if (this.props.showUnlimitedOption && sliderCanGoToUnlimited && boundedValue === limits.max) {
      adjustedUnlimited = true
    }
    const unlimitedAdjustedValue = adjustedUnlimited ? null : boundedValue
    if (unlimitedAdjustedValue === null && adjustedUnlimited === false) {
      unlimitedAdjustedValue = limits.max
    }
    const unitlessValue = adjustedUnlimited ?  null :unformattedBandwidth({ unit: adjustedUnit, value: unlimitedAdjustedValue})
    this.setState({ unit: adjustedUnit, value: unlimitedAdjustedValue, unlimited: adjustedUnlimited }, () => this.setUpstreamBandwidth({ value: unitlessValue }))
  }

  handleRadioChange = ({ currentTarget: { value: unit } }) => {
    const limits = this.limits()[unit]
    const value = limits.min
    this.setStateValues({ unit, value })
  }

  limits = () => bandwidthLimits(this.props.defaultMax)

  render = () => (
    <>
      <Label>Bandwidth</Label>
      <br/>
      { this.props.showUnlimitedOption && <BootstrapSwitchButton
        checked={this.state.unlimited}
        onlabel={`UNLIMITED`}
        width={450}
        offlabel={`LIMITED`}
        onChange={this.handleUnlimitedChange}
      /> }
      { !this.state.unlimited &&
        <>
          <FormGroup type='range'>
            <Input
              type='range'
              name='bandwidth-range'
              label='Bandwidth'
              value={this.state.value}
              onChange={this.handleBandwidthChange}
              {...this.limits()[this.state.unit]}
            />
          </FormGroup>
          <Align as={FormGroup} className='mr2 ml2 mt2'>
            <Box className='mr2'>
              <Input
                className='mr8'
                type='number'
                name='bandwidth_in_mbps'
                value={this.state.value}
                onChange={this.handleBandwidthChange}
              />
            </Box>
            <Box type='output' className='py0 mr4' htmlFor='bandwidth-range'>
              {getLabel(this.state.value, this.state.unit, this.props.chassisQty)}
            </Box>
            <UnitRadio
              value='Tbps'
              checked={this.state.unit === 'Tbps'}
              onChange={this.handleRadioChange}
            />
            <UnitRadio
              value='Gbps'
              checked={this.state.unit === 'Gbps'}
              onChange={this.handleRadioChange}
            />
            {this.props.showMbps &&
              <UnitRadio
                value='Mbps'
                checked={this.state.unit === 'Mbps'}
                onChange={this.handleRadioChange}
              />
            }
          </Align>
        </> }
      { this.state.unlimited &&
        <>
          <FormGroup type='range'>
            <Input
              type='range'
              name='bandwidth-range'
              label='Bandwidth'
              value={9999}
              onClick={this.undoUnlimitedSetting}
              {...this.limits()[this.state.unit]}
            />
          </FormGroup>
          <Form.Group className='ml2'>
            <Row>
              <Col md={9} className='pt2'>
                UNLIMITED Bandwidth
              </Col>
            </Row>
          </Form.Group>
        </> }
    </>
  )
}

export default BandwidthInput
