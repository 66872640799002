import React, { useState } from 'react'
import Button from '../Button'
import EmailInput from './EmailInput'

/* eslint complexity: ["error", 21] */
export const UserInput = ({ values, newUid, orgUsers, onDelete, onAccess, licenseId, id }) => {
  const [email, setEmail] = useState(values.email)
  const authorizationId = values.authorizationId
  const isNew = values.isNew
  const deleted = values.deleted
  const canDestroy = values.canDestroy
  return (
    <div>
      { !deleted && <div onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onAccess(email, licenseId)
          setEmail('')
        }
      }}>
        <EmailInput
          value={email}
          {...(!isNew && { readOnly: true })}
          onChange={(e) => setEmail(e.target.value)} />
        <div className='pull-right'>
          {!!isNew &&
            <Button
              onClick={() => {
                onAccess(email, licenseId)
                setEmail('')
              }}
              className='btn'><small>Grant Access</small></Button>
          }
          {!orgUsers && !isNew && !!canDestroy &&
            <div className='px1'>
              <Button
                variant='danger'
                data-toggle='tooltip'
                data-placement='top'
                title='Remove User'
                onClick={() => onDelete(authorizationId)}
                className='btn-round'>
                <i className='fa fa-trash' />
              </Button>
            </div>
          }
        </div>
      </div> }
    </div>
  )
}

export default UserInput
