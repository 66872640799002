import React from 'react'
import Button from '../../Button'

const editLicenseURL = (props) => {
  if (props.fireEye && props.oem_update) {
    return `/oem/${props.licenseId}/edit`
  } else if (props.addon) {
    return `/licenses/${props.licenseId}/edit_addon_license`
  } else {
    return `/licenses/${props.licenseId}/edit${props.update ? '' : '_limited'}`
  }
}

const EditButton = props =>
  <Button
    data-toggle='tooltip'
    data-placement='top'
    className='btn-round'
    title='Edit License'
    href={editLicenseURL(props)}>
    <i className='fa fa-edit' />
  </Button>
export default EditButton
