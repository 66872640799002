import React, {useEffect, useState, Fragment} from 'react'
import ReactTooltip from 'react-tooltip'
import Spinner from '../Spinner'

const UserLandingPage = ({currentlandingPage}) => {
  const [landingPage, setLandingPage] = useState(currentlandingPage)
  const [linkState, setLinkState] = useState({})
  const [loading, setLoading] = useState(false)
  const currentPage = window.location.href

  useEffect(() => {
    const linkObj = { title: 'set this page as landing page', class: '' }
    if (currentPage === landingPage) {
      linkObj.title = 'unset current landing page'
      linkObj.class = 'current'
    }

    setLinkState(linkObj)
  }, [landingPage])

  const updateLandingPage = async (newLandingPage) => {
    setLoading(true)
    try {
      const response = await fetch('/users/internal/update_landing_page', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ landing_page: newLandingPage })
      })
      setLoading(false)
      if (response.ok) {
        setLandingPage(newLandingPage)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleClick = (event) => {
    event.preventDefault()
    if (landingPage === currentPage) {
      updateLandingPage(null)
    } else {
      updateLandingPage(currentPage)
    }
  }

  return <Fragment>
    <a href='/'
      onClick={handleClick}
      data-tip data-for={'landing-page-setup-link'}
      className={linkState.class}>
      {!loading && <i className='fa fa-thumb-tack' /> }
      {loading && <Spinner />}
    </a>
    <ReactTooltip id={'landing-page-setup-link'} place='bottom' data-html='true' effect='solid' wrapper='div'>
      {linkState.title}
    </ReactTooltip>
  </Fragment>
}

export default UserLandingPage
