import React, { Component } from 'react'
import RadioField from './RadioField'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Label from './Form/Label'
import Collapse from 'react-collapse'
import { presets } from 'react-motion'
import { yearMoment } from './helpers/moment'

import 'react-datepicker/dist/react-datepicker.css'
import '../../assets/stylesheets/components/ExpirationField.scss'

const DATE_FORMAT = 'DD/MM/YYYY'

export default class SubscriptionExpirationField extends Component {
  constructor (props) {
    super(props)
    const expiresAt = this.fixTime(this.props.expires_at)
    const startTime = this.fixTime(this.props.start_time)
    const fieldName = this.props.is_renewal ? 'renewal[new_expires_at]' : 'license[expires_at]'
    this.state = {
      field_name: fieldName,
      start_time: moment(startTime).utc().add(1, 'year').startOf('day'),
      selectedOption: expiresAt === undefined ? '' : 'Custom',
      expires_at: expiresAt ? moment(expiresAt).utc().format(DATE_FORMAT) : moment().utc().add(2, 'weeks').add.format(DATE_FORMAT),
      expires_at_datepicker: expiresAt ? moment(expiresAt).utc() : moment().utc().add(2, 'weeks')
    }

    this.oneyear = yearMoment(startTime, 1)
    this.threeyears = yearMoment(startTime, 3)
    this.fiveyears = yearMoment(startTime, 5)

    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleExpiresChange = this.handleExpiresChange.bind(this)
    this.toggleAutoRenewal = this.toggleAutoRenewal.bind(this)
    this.toggleExpirationUponRenewal = this.toggleExpirationUponRenewal.bind(this)
    this.toggleExpirationUponRenewal(this.state.expires_at_datepicker)
  }

  fixTime (time) {
    return (time === null ? undefined : time)
  }

  handleOptionChange (event) {
    this.setState({
      selectedOption: event.target.value
    })
    if (event.target.value === 'Custom') {
      this.toggleAutoRenewal(this.state.expires_at_datepicker)
    } else {
      $('.renewal_auto_renew').show()
      this.toggleExpirationUponRenewal(moment(event.target.value, 'DD/MM/YYYY'))
    }
  }

  handleExpiresChange (date) {
    this.setState({
      expires_at_datepicker: date,
      expires_at: moment(date).utc().format(DATE_FORMAT)
    })
    this.toggleAutoRenewal(date)
  }

  toggleAutoRenewal (date) {
    if (this.state.start_time > moment(date).utc()) {
      $('.renewal_auto_renew input:checked').first().attr('checked', false)
      $('.renewal_auto_renew').hide()
    } else {
      $('.renewal_auto_renew').show()
    }
    this.toggleExpirationUponRenewal(date)
  }

  toggleExpirationUponRenewal (date) {
    if (this.state.field_name === 'renewal[new_expires_at]') {
      $('#new_expires_at').text(moment(date).utc().format('LL'))
    }
  }

  render () {
    return (
      <div>
        <Label>Duration</Label>
        <p className='help-block'>License expiration date must be a future date.</p>
        <RadioField
          name={this.state.field_name}
          checked={this.state.selectedOption === this.oneyear}
          value={this.oneyear}
          onChange={this.handleOptionChange}
          label='One year' />
        <RadioField
          name={this.state.field_name}
          checked={this.state.selectedOption === this.threeyears}
          value={this.threeyears}
          onChange={this.handleOptionChange}
          label='Three years' />
        <RadioField
          name={this.state.field_name}
          checked={this.state.selectedOption === this.fiveyears}
          value={this.fiveyears}
          onChange={this.handleOptionChange}
          label='Five years' />
        <RadioField
          checked={this.state.selectedOption === 'Custom'}
          onChange={this.handleOptionChange}
          label='Custom'
          value='Custom' />
        <Collapse isOpened={this.state.selectedOption === 'Custom'} springConfig={presets.gentle}>
          <div className='form-section'>
            <Label>Expiration Date</Label>
            <div className='input-group'>
              <span className='input-group-addon input-group-prepend'><i className='fa fa-calendar' /></span>
              <input type='hidden'
                name={this.state.field_name}
                value={this.state.expires_at}
                className='hide-show-auto-renewal'
                disabled={this.state.selectedOption !== 'Custom'}
                onChange={this.handleOptionChange} />
              <DatePicker id='datepicker'
                name='expires_at_datepicker'
                disabled={this.state.selectedOption !== 'Custom'}
                selected={this.state.expires_at_datepicker}
                className='form-control'
                onChange={this.handleExpiresChange} />
            </div>
          </div>
        </Collapse>
      </div>
    )
  }
}
