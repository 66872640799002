import React from 'react'
import TypeAhead from '../containers/TypeAhead'

export const SalesOrderSelect = props => {
  const name = props.name || 'order_attributes.a10_sales_order_id'
  const value = props.value || ''
  const { isRequired } = props

  return (
    <TypeAhead
      input={{
        id: 'license_order_attributes_organization_id',
        name,
        required: isRequired,
        value: value
      }}
      label={{
        htmlFor: 'license_order_attributes_organization_id',
        required: isRequired,
        children: 'Sales Order Number'
      }}
      type='Order'
      submitField={name}
      {...props}
    />
  )
}

export default SalesOrderSelect
