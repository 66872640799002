import React, { useState, Fragment, useEffect } from 'react'
import TrialOrgCreatedGraph from './TrialOrgCreatedGraph'
import TrialLicensesCreatedGraph from './TrialLicensesCreatedGraph'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import forEach from 'lodash/forEach'
import TrialRow from './TrialRow'
import moment from 'moment'
import { getCalendarTimes, buildPlatformData, buildOrgData } from '../../util/dataFormatters'
import Spinner from '../components/Spinner'
import qs from 'query-string'
import Form from 'react-bootstrap/Form'
import { getMomentStr, getMoment } from '../../util/time'
import SortableTableHeader from '../components/SortableTableHeader'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../assets/stylesheets/components/ExpirationField.scss'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'
import { range } from 'lodash'
/* eslint-disable complexity */
const CustomRange = 'custom'

const getDateVal = (range, initialQueryParams, key) => {
  const dataRanges = {week: moment().subtract(1, 'w'), month: moment().subtract(1, 'M'), year: moment().subtract(1, 'Y')}

  let dateVal;

  if (range == CustomRange) {
    if (initialQueryParams[key]) {
      dateVal = getMoment(initialQueryParams[key])
    } else if (key == 'starts_at') {
      dateVal = dataRanges.week
    } else if (key == 'ends_at') {
      dateVal = moment()
    }
  } else {
    if (key == 'starts_at') {
      dateVal = dataRanges[range]
    } else if (key == 'ends_at') {
      dateVal = moment()
    }
  }
  return dateVal
}
const TrialLeadMetrics  = ({trialTypes}) => {
  const initialQueryParams =  qs.parse(window.location.search)
  const [rangeType, setRangeType] = useState(initialQueryParams.rangeType || 'week')
  const[state, setState] = useState({
    popupCustom: rangeType === CustomRange,
    validDateRange: true,
    filters: {
      start: getDateVal(rangeType, initialQueryParams, 'starts_at'),
      end: getDateVal(rangeType, initialQueryParams, 'ends_at'),
      trialType: initialQueryParams.trialType || '',
      customerOnly: initialQueryParams.customerOnly == 'true',
      searchTerm: initialQueryParams.searchTerm || '',
      sortKey: initialQueryParams.sortKey || null,
      sortDir: initialQueryParams.sortDir || null
    }
  })
  const [metricsData, setMetricsData] = useState([])

  const sortVal = {key: state.filters.sortKey, direction: state.filters.sortDir}
  const [loading, showLoading] = useState(false)
  const [activations, setActivations] = useState(0)
  const [orgsCreated, setOrgsCreated] = useState([])
  const [trialCount, setTrialsCount] = useState(0)
  const [percentTrialsActive, setPercentTrialsActive] = useState(0)
  const [searchDisabled, setSearchDisabled] = useState(false)

  useEffect(()=>{
    const {start, end} = state.filters
    if (start && end) {
      if (end < start) {
        setState({...state, validDateRange: false })
      } else {
        setState({...state, validDateRange: true })
        getMetrics()
      }
    }
  }, [])

  useEffect(()=>{
    if (rangeType === CustomRange) {
      setState({...state, popupCustom: true})
    } else {
      setState({
        ...state,
        filters: {
          ...state.filters, 
          start: getDateVal(rangeType, {}, 'starts_at'), 
          end: getDateVal(rangeType, {}, 'ends_at')
        },
        popupCustom: false
      })
    }
  }, [rangeType])


  const getSearchParams = () => {
    const {trialType, customerOnly, searchTerm, start, end} = state.filters
    let finalParams = {}
    let params = {
      trialType, customerOnly: customerOnly.toString(), rangeType, searchTerm,
      sortKey: sortVal.key, sortDir: sortVal.direction
    }
    if (rangeType == CustomRange) {
      params = {...params, starts_at: getMomentStr(start), ends_at: getMomentStr(end)}
    }
    const paramKeys = keys(params)
    forEach(paramKeys, (k) => {
      if (!isEmpty(params[k])) {
        finalParams[k] = params[k]
      }
    })
    return finalParams
  }

  useEffect(() => {
    const queryParams = qs.parse(window.location.search)
    if (isEqual(queryParams, getSearchParams())) {
      setSearchDisabled(true)
    } else {
      setSearchDisabled(false)
    }
  }, [rangeType, state.filters])


  const performSearch = () => {
    setQueryParams(getSearchParams())
  }

  const setQueryParams = (params) => {
    const queryParams = qs.parse(window.location.search)
    if (!isEqual(queryParams, params)) {
      window.location = `/trial_leads_metrics?${qs.stringify(params)}`
    }
  }



  useEffect(()=> {
    const activationsCount = metricsData.map(m => m.attributes.active_appliances).filter(n => n > 0).length
    const organizations = metricsData.filter(m => m.attributes.organization_created === true)
    const totalTrialsCount = metricsData.length
    const percentTrialsActivated = Math.ceil(activationsCount / totalTrialsCount * 100)
    setActivations(activationsCount)
    setOrgsCreated(organizations)
    setTrialsCount(totalTrialsCount)
    setPercentTrialsActive(percentTrialsActivated)
  }, [metricsData])


  const fetchUrl = (format) => {
    const [start, end] = getCalendarTimes(state.filters.start, state.filters.end)
    const params = {...state.filters, start, end}
    return `/api/trial_leads/trial_summary.${format}?${qs.stringify(params)}`
  }
  
  const fetchMetrics = () => {
    return fetch(
      fetchUrl('json'), {
        credentials: 'include',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        }
      })
  }

  const getMetrics = async () => {
    showLoading(true)
    try {
      const response = await fetchMetrics()
      const json = await response.json()
      setMetricsData(json.data)
    } catch (error) {
      setMetricsData([])
    } finally {
      showLoading(false)
    }
  }

  const handleDropdownChange = event => {
    setRangeType(event.target.value)
  }

  const handleTrialTypeChange = event => {
    setState({...state, filters: { ...state.filters, trialType: event.target.value }})
  }

  const handleCustomerCheckbox = () => {
    setState({...state, filters: { ...state.filters, customerOnly: !state.filters.customerOnly }})
  }

  const handleStartCalendarChange = values => {
    setState({...state, filters: { ...state.filters, start: values }})
  }

  const handleEndCalendarChange = values => {
    setState({...state, filters: { ...state.filters, end: values }})
  }

  const handleSearchFormSubmit = (e) => {
    e.preventDefault()
    performSearch()
  }

  const handleSearch = (e) => {
    setState({...state, filters: { ...state.filters, searchTerm: e.target.value }})
  }

  const handleCsvReport = () => {
    window.open(fetchUrl('csv'), '_blank');
  }

  const handleSort = (sortValue) => {
    const queryParams = qs.parse(window.location.search)
    const params = {...queryParams, sortKey: sortValue.key, sortDir: sortValue.direction }
    setQueryParams(params)
  }

  const summaryBorderStyle = {borderRight: '1px solid #dbdbdb'}

  return (
    <Fragment>
      <div className='row'>
        <div className="col-md-8">
          <h1 className='h2' style={{marginTop: 0}}>Lead Metrics</h1>
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-1 text-right'><b><small>Range</small></b></div>
            <div className='col-sm-4'>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Control as='select' className='form-control-custom-sm' value={rangeType} onChange={handleDropdownChange}>
                    <option>week</option>
                    <option>month</option>
                    <option>year</option>
                    <option>{CustomRange}</option>
                  </Form.Control>
                </div>
              </div>
              <div className='row py1'>
                <div className='col-md-6'>
                  <InputGroup>
                    <InputGroup.Prepend className='input-group-addon form-control-custom-sm'>
                      <i className='fa fa-calendar' />
                    </InputGroup.Prepend>
                    <DatePicker
                      className='form-control form-control-custom-sm'
                      id='datepicker'
                      name='expires_at_datepicker'
                      selected={state.filters.start}
                      disabled={!state.popupCustom}
                      onChange={handleStartCalendarChange}
                    />
                  </InputGroup>
                </div>
                <div className='col-md-6'>
                  <InputGroup>
                    <InputGroup.Prepend className='input-group-addon form-control-custom-sm'>
                      <i className='fa fa-calendar' />
                    </InputGroup.Prepend>
                    <DatePicker
                      className='form-control form-control-custom-sm'
                      id='datepicker'
                      name='expires_at_datepicker'
                      selected={state.filters.end}
                      disabled={!state.popupCustom}
                      onChange={handleEndCalendarChange}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='row'>
                <div className='col-sm-4 text-right'><b><small>Trial Type</small></b></div>
                <div className='col-md-8'>
                  <Form.Control as='select' className='form-control-custom-sm' value={state.filters.trialType} onChange={handleTrialTypeChange}>
                    <option value={''}>All</option>
                    {trialTypes.map((trialType, index) => 
                      <option key={index} value={trialType}>{trialType}</option>
                    )}
                  </Form.Control>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='row'>
                <div className='col-md-12'>
                  <form onSubmit={handleSearchFormSubmit}>
                    <InputGroup>
                      <Form.Control 
                        value={state.filters.searchTerm} 
                        onChange={handleSearch} 
                        placeholder="Search customer by email" 
                        className='form-control-custom-sm' />
                      <InputGroup.Append className='input-group-addon form-control-custom-sm'>
                        <a href="/" onClick={handleSearchFormSubmit}><i className='fa fa-search' /></a>
                      </InputGroup.Append>
                    </InputGroup>
                  </form>
                </div>
              </div>
              <div className='row py1'>
                <div className='col-md-12'>
                  <small>
                    <Form.Check 
                      type={'checkbox'}
                      id={`customer-only`}
                      checked={state.filters.customerOnly == true}
                      onClick={handleCustomerCheckbox}
                      label="&nbsp;Customers Only"
                    />
                  </small>
                </div>
              </div>
            </div>
            <div className='col-sm-1 text-right'>{loading && <Spinner/>}</div>
          </div>
          <div className='row'>
            <div className='col-md-12 text-right'>
              <button className='btn btn-primary btn-sm' disabled={searchDisabled} onClick={performSearch}>Search</button>
              <button className='btn btn-default btn-sm' onClick={handleCsvReport} style={{marginLeft: '2px'}}>
                <i className='fa fa-download'/> CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    
      {!state.validDateRange &&
        <div className='alert alert-warning center' role='alert'>Invalid date range. Start date must be before end date.</div>
      }
      <div className='row'>
      </div>
      {metricsData && metricsData.length > 0 && state.validDateRange && <Fragment>
        <div className='card mt2 mb2'>
          <div className='card-body'>
            <div className='row py2'>
              <div className='col-sm-3 text-center' style={summaryBorderStyle}>
                {trialCount} trial license{trialCount > 1 ? 's' : ''} created
              </div>
              <div className='col-sm-3 text-center' style={summaryBorderStyle}>
                {activations} activated license{activations > 1 ? 's' : ''}
              </div>
              <div className='col-sm-3 text-center' style={summaryBorderStyle}>
                {percentTrialsActive}% of licenses active
              </div>
              <div className='col-sm-3 text-center'>
                {orgsCreated && orgsCreated.length} organization{orgsCreated && orgsCreated.length > 1 ? 's' : ''} created
              </div>
            </div>
          </div>
        </div>
        <div className={trialCount > 40 ? '' : 'row'}>
          <div className={trialCount > 40 ? 'row' : 'col-md-6'}>
            <TrialOrgCreatedGraph
              data={buildOrgData(orgsCreated)} />
          </div>
          <div className={trialCount > 40 ? 'row' : 'col-md-6'}>
            <TrialLicensesCreatedGraph
              startTime={state.filters.start}
              endDate={state.filters.end}
              vthunders={buildPlatformData(metricsData, 'vthunder', 'vThunder')}
              webroots={buildPlatformData(metricsData, 'webroot', 'Webroot')}
              agalaxys={buildPlatformData(metricsData, 'agalaxy', 'aGalaxy')}
              capacityPools={buildPlatformData(metricsData, 'capacity_pool', 'Capacity Flexpool')}
              instancePools={buildPlatformData(metricsData, 'instance_pool', 'Instance Flexpool')}
              cylances={buildPlatformData(metricsData, 'cylance', 'Cylance')}
              qosmos={buildPlatformData(metricsData, 'qosmos', 'Qosmos')} />
          </div>
        </div>
        <div className='row'>
          <div className={`text-center ${loading ? '' : 'invisible'}`}><Spinner/></div>
          <table className='table table-slats table-layout-fixed'>
            <thead>
              <tr>
                <th>Contact Information</th>
                <th>License</th>
                <th>Organization</th>
                <SortableTableHeader
                  title='Created At'
                  trackKey='created_at'
                  onChange={handleSort}
                  sortKey={sortVal.key}
                  sortDirection={sortVal.direction} />
                <th>Trial Lead Info</th>
              </tr>
            </thead>
            <tbody>
              <TrialRow
                trialLeads={metricsData} />
            </tbody>
          </table>
        </div>
      </Fragment>}
      {metricsData && !metricsData.length && !loading &&
          <h5 className='center text-muted py1'>No results for that date range.</h5>
      }
      <GLMToastContainer/>
    </Fragment>
  )
}
/* eslint-enable complexity */

export default TrialLeadMetrics
