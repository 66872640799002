import React from 'react'
import Button from '../Button'

const editSkuURL = sku => {
  return `/skus/${sku.id}/edit`
}

const SkuRow = ({ skus }) => (
  skus.map(sku =>
    <tr key={'sku_' + sku.id}>
      <td className='ellipsis'>
        {sku.name}
      </td>
      <td className='ellipsis'>
        {sku.platform}
      </td>
      <td className='ellipsis'>
        {sku.description}
      </td>
      <td>
        <div className='btn-group-sm pull-right'>
          <Button
            className={'btn-round'}
            href={editSkuURL(sku)}>
            <i className='fa fa-edit' />
          </Button>
        </div>
      </td>
    </tr>
  )
)

export default SkuRow
