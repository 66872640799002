import React from 'react';
import UsersCreatedMetricsOptions from './UsersCreatedMetricsOptions'
import MetricsCard from './common/MetricsCard'

const UsersCreatedMetricsChart = (props) => 
  <MetricsCard 
    {...{...props, OptionComponent: UsersCreatedMetricsOptions, chart: {url: '/internal/dashboard_charts/users_created.json', type: 'line', label: 'Users'}}} 
  />

export default React.memo(UsersCreatedMetricsChart)
