import React, { Component } from 'react'
import EmailInput from '../EmailInput'
import WizardButtons from '../WizardButtons'
import { emailIsValid } from '../EmailIsValid'

class NewUserEmail extends Component {
  state = {
    inputValue: this.props.wizardState.email,
    nextEnabled: emailIsValid(this.props.wizardState.email)
  }

  isA10Email = (email) => {
    const loweCaseEmail = email.toLowerCase()
    return loweCaseEmail.endsWith('@a10networks.com') ||
      loweCaseEmail.endsWith('@a10boise.net')
  }

  updateInputValue = e => {
    this.setState({
      inputValue: e.target.value,
      nextEnabled: emailIsValid(e.target.value)
    })
  }

  onNext = async () => {
    if (!emailIsValid(this.state.inputValue)) {
      this.props.handleError('Please enter a valid email address.')
      return
    }
    if (!this.isA10Email(this.state.inputValue)) {
      /* eslint quotes: [0, "single"] */
      this.props.handleError(
        <>
          Email address must be an A10 Networks email account.<br />
          Not an A10 employee? <a href='https://get.a10networks.com/trial-downloads/'>Sign up for a trial here.</a>
        </>
      )
      return
    }
    if (!await this.isEmailAvailable()) {
      this.props.handleError(
        <>
        That email is already in our system. Please <a href='/users/sign_in'>log in</a> or use <a href='/users/password/new'>forgot your password</a>.
        </>
      )
      return
    }
    this.sendValidEmail()
    this.props.onNext({
      email: this.state.inputValue
    })
  }

  isEmailAvailable = async () => {
    try {
      const response = await fetch(`/api/new_users/available?email=${encodeURIComponent(this.state.inputValue)}`)
      if (response.ok) {
        const json = await response.json()
        return json.available
      }
    } catch (error) {
      this.props.handleError(error)
      return false
    }
  }

  sendValidEmail = async () => {
    try {
      await fetch('/api/new_users/send_user_email.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: this.state.inputValue })
      })
    } catch (error) {
      this.props.handleError(error)
    }
  }

  render () {
    return (
      <div>
        <div className='py1'>
          <label className='control-label'>Enter your email address:</label>
          <EmailInput
            value={this.state.inputValue}
            onChange={this.updateInputValue}
          />
        </div>
        <WizardButtons
          previousHidden
          onNextClick={this.onNext}
          nextEnabled={this.state.nextEnabled}
        />
      </div>
    )
  }
}

export default NewUserEmail
