import React from 'react'
import Box from '../components/Box'
import Label from '../components/Label'

export const IdpReadOnlyFields = ({ idpConfiguration }) => (
  <>
    <div className='row py1'>
      <div className='col-md-6'>
        <Label classNames={['text-muted']}>Signing Certificate Fingerprint</Label><br />
        <Box classNames={['text-wrap']} tag='span'>{idpConfiguration.idp_cert_fingerprint}</Box>
      </div>
      <div className='col-md-6'>
        <Label classNames={['text-muted']}>Fingerprint Algorithm</Label><br />
        <Box tag='span'>{idpConfiguration.idp_cert_fingerprint_algorithm}</Box>
      </div>
    </div>
    <div className='row py1'>
      <div className='col-md-6'>
        <Label classNames={['text-muted']}>Protocol Binding</Label><br />
        <Box tag='span'>{idpConfiguration.protocol_binding}</Box>
      </div>
      <div className='col-md-6'>
        <Label classNames={['text-muted']}>SSO Target URL</Label><br />
        <Box classNames={['text-wrap']} tag='span'>{idpConfiguration.sso_target_url}</Box>
      </div>
    </div>
  </>
)

export default IdpReadOnlyFields
