import React, {useState, useEffect, useRef} from 'react'
import moment from 'moment'
import useVictory from '../helpers/useVictory'
import Select from '../components/Select'
import Label from '../components/Label'
import Spinner from '../components/Spinner'

const FlyOut = ({ x, y, datum, dx, dy }) => {
  return (
    <g>
      <foreignObject x={x} y={y} width="180" height="200">
        <div className='flyout'>
          <strong>Top Consumers</strong>
          <ul>
            {datum.consumers.slice(0,10).map(c => <li key={c.appliance_uuid}>{c.appliance_uuid}</li>)}
          </ul>
        </div>
      </foreignObject>
    </g>
  )
}

const PoolConsumptionHistory = ({id, employee}) => {
  const [history, setHistory] = useState()
  const [values, setValues] = useState()
  const [max, setMax] = useState()
  const [divisor, setDivisor] = useState()
  const [label, setLabel] = useState()
  const [range, setRange] = useState(employee ? undefined : 'week')
  const [windowEnd, setWindowEnd] = useState(moment().subtract(1, 'days'))
  const [line, setLine] = useState()
  const [consumptionNote, setConsumptionNote] = useState('Snapshots at one hour intervals')
  const [loading, setLoading] = useState(false)
  const Victory = useVictory()

  const fetchHistory = () => {
    if (!range) {
      return
    }
    setLoading(true)
    fetch(`/pool_consumption_history/licenses/${id}.json?window_end=${windowEnd.unix()}&duration=${range}`).
      then(response => {
        if (response.ok) {
          response.json().
            then(data => {
              setHistory(data.histories)
            })
        }
      })
  }

  const assignValues = () => {
    if (!history) {
      return
    }
    setDivisor(history.pool_size >= 1000000 ? 1000000 : history.pool_size >= 1000 ? 1000 : 1)
    setLabel(history.pool_size >= 1000000 ? 'Tbps' : history.pool_size >= 1000 ? 'Gbps' : 'Mbps')
    setMax(history.pool_size)
    setValues(history.items)
    let length = history.items.length / 3
    length = length < 4 ? 4 : length
    let newLine = []
    while (newLine.length < length) {
      newLine.push(history.pool_size)
    }
    setLine(newLine)
    switch (range) {
      case 'week':
        setConsumptionNote('Snapshots at one hour intervals')
        break
      case 'month':
        setConsumptionNote('Snapshots at beginning of day intervals')
        break
      case 'year':
        setConsumptionNote('Snapshots at beginning of month intervals')
        break
    }
    setLoading(false)
  }

  useEffect(() => {
    assignValues()
  }, [history])

  useEffect(() => {
    fetchHistory()
  }, [windowEnd, range])

  const getHistoryTick = (hour) => {
    const date = moment(hour).utc()
    if (range === 'week') {
      return date.hour() === 12 ? date.format('MM/DD') : ''
    } else if (range === 'month') {
      return (date.dayOfYear() % 3) === 0 ? date.format('MM/DD') : ''
    }
    return date.format('MMM')
  }

  const handleRangeChange = e => {
    if (e.target.value) {
      setLoading(true)
    } else {
      setValues(null)
    }
    setRange(e.target.value)
  }

  const handlePrevious = () => {
    switch (range) {
      case 'week':
        setWindowEnd(moment(windowEnd).add(1, 'weeks'))
        break
      case 'month':
        setWindowEnd(moment(windowEnd).add(1, 'months'))
        break
      case 'year':
        setWindowEnd(moment(windowEnd).add(1, 'years'))
        break
    }
  }

  const handleReset = () => {
    setWindowEnd(moment().subtract(1, 'days'))
  }

  const handleNext = () => {
    switch (range) {
      case 'week':
        setWindowEnd(moment(windowEnd).subtract(1, 'weeks'))
        break
      case 'month':
        setWindowEnd(moment(windowEnd).subtract(1, 'months'))
        break
      case 'year':
        setWindowEnd(moment(windowEnd).subtract(1, 'years'))
        break
    }
  }

  return (
    <>
      <h4>FlexPool Consumption History</h4>
      {consumptionNote}
      <div className='row'>
        <div className='col-md-3'>
          <Label htmlFor='rangeSelect' className='text-muted'>Range:</Label>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
          <Select id='rangeSelect'
            name='rangeSelect'
            className='form-control'
            value={range}
            onChange={handleRangeChange}
            children={[{value: 'week', label: 'Week'},
              {value: 'month', label: 'Month'},
              {value: 'year', label: 'Year'}
            ]}
          />
        </div>
        {loading && <Spinner size={20} />}
      </div>
      {!!values &&
        <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
          <div>Period ending: {windowEnd.format("dddd, MMMM Do YYYY")}</div>
          {!values.length &&
            <div>Not enough data</div>
          }
          {!!values.length && Victory &&
            <Victory.Chart
              domainPadding={20}
              padding={{ top: 20, bottom: 20, left: 50, right: 50 }}
            >
              <Victory.Axis
                style={{
                  tickLabels: {fontSize: 8}
                }}
                tickValues={[values.map((h,i) => i)]}
                tickFormat={values.map(h => getHistoryTick(h.hour))}
              />
              <Victory.Axis
                dependentAxis
                orientation="right"
                domain={[0,max]}
                tickFormat={(x) => `${(x/divisor).toFixed(1)} ${label}`}
                style={{
                  tickLabels: {fontSize: 8}
                }}
              />
              <Victory.Axis
                dependentAxis
                tickFormat={(x) => `${((x/max)*100).toFixed(0)}%`}
                style={{
                  tickLabels: {fontSize: 8}
                }}
              />
              <Victory.Bar
                labels={({datum}) => ''}
                labelComponent={<Victory.Tooltip flyoutComponent={<FlyOut />} />}
                data={values}
                style={{data: { fill: data => (data.data[data.index].max_consumed / max >= 0.7 ? '#8a0c00' : '#1F78C4')}}}
                x='hour'
                y='max_consumed'
              />
              <Victory.Legend x={125} y={10}
                orientation="horizontal"
                colorScale={[ '#8a0c00' ]}
                data={[
                  { name: "Exceeds 70% consumption" }
                ]}
              />
            </Victory.Chart>
          }
          <div className='text-center'>
            <ul className='pagination unit'>
              <li><a onClick={handlePrevious} className='prev'>← Newer</a></li>
              <li><a onClick={handleReset}>Reset</a></li>
              <li><a onClick={handleNext} className='next'>Older →</a></li>
            </ul><br />
          </div>
        </div>
      }
    </>
  )
}

export default PoolConsumptionHistory
