import React, { Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import WizardForm from '../WizardForm'
import Dropdown from '../Dropdown'
import WizardButtons from '../WizardButtons'

class Trial extends WizardForm {
  state = {
    ...this.props.wizardState,
    selectedTrialUnit: this.props.wizardState.selectedTrialUnit || 'days',
    selectedTrialIncrement: this.props.wizardState.selectedTrialIncrement || 30,
    trialUnitOptions: [['Days', 'days']],
    trialIncrementOptions: [[30, 30]],
    nextEnabled: false
  }

  componentDidMount = () => {
    this.setNextEnabled()
  }

  setNextEnabled = () => {
    this.setState({
      nextEnabled: (!this.state.trial) || (this.state.selectedTrialUnit && this.state.selectedTrialIncrement)
    })
  }

  onTrialChange = (e) => {
    this.setState({
      trial: e.target.checked
    },
    this.setNextEnabled
    )
  }

  onTrialUnitChange = (e) => {
    this.setState({
      trialUnitOptions: e.target.value
    },
    this.setNextEnabled
    )
  }

  onTrialIncrementChange = (e) => {
    this.setState({
      trialIncrementOptions: e.target.value
    },
    this.setNextEnabled
    )
  }

  getWizardState = () => {
    return ({
      trial: this.state.trial,
      selectedTrialIncrement: this.state.selectedTrialIncrement,
      selectedTrialUnit: this.state.selectedTrialUnit
    })
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <Form.Check
          type='checkbox'
          id='sku_trial_checkbox'
          checked={this.state.trial}
          onChange={this.onTrialChange}
          label='&nbsp;Trial'
        />
      </div>
      <div className='py1' disabled={!this.state.trial}>
        <label className='control-label'>Trial Units</label>
        <Dropdown options={this.state.trialUnitOptions} value={this.state.selectedTrialUnit} onChange={this.onTrialUnitChange} />
      </div>
      <div className='py1' disabled={!this.state.trial}>
        <label className='control-label'>Trial Increment</label>
        <Dropdown options={this.state.trialIncrementOptions} value={this.state.selectedTrialIncrement} onChange={this.onTrialIncrementChange} />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled={this.state.nextEnabled}
      />
    </Fragment>
  )
}

export default Trial
