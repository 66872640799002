import React, { useEffect, useState } from 'react'
import Paginate from '../../Paginate'
import Spinner from '../Spinner'
import { formatDateTime } from '../HarmonyMetrics/helpers'

const HarmonyImportsIndex = ({currentPage}) => {
  const [entries, setEntries] = useState([])
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const loadEntries = async () => {
    setLoading(true)

    const response = await fetch(`/internal/harmony_controller_metrics_imports.json?page=${currentPage}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setEntries(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }
  useEffect(() => {
    loadEntries()
  }, [])

  useEffect(() => {
    if (entries.length > 0) {
      $('[data-toggle="tooltip"]').tooltip()
    }
  }, [entries])

  const gotTo = (page) => {
    window.location.href = `/internal/harmony_controller_metrics_imports?page=${page}`
  }

  const renderEntries = () => {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Imported At</th>
            <th>Activities<br />Imported</th>
            <th>Activities'<br />Entries<br />Stored</th>
            <th>Providers<br />created/updated</th>
            <th>Created By</th>
            <th>Error</th>
            <th>Delete<br />Reason</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, i) =>
            <tr key={i}>
              <td>{formatDateTime(entry.created_at)}</td>
              <td>{entry.activities_imported_count}</td>
              <td>{entry.activities_stored_count}</td>
              <td>{entry.providers_count}</td>
              <td>{entry.created_by_display_name}</td>
              <td><small>{entry.error_message}</small></td>
              <td><small>{formatDateTime(entry.deleted_at)}<br />{entry.delete_reason}</small></td>
              <td>
                {!entry.error_message &&
                  <a className='btn btn-default btn-round'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='View Import'
                    href={`/internal/harmony_controller_metrics_imports/${entry.id}`}>
                    <i className='fa fa-eye' />
                  </a>
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      <h3>HC Metrics Imports</h3>
      {loading && <Spinner />}
      {error && <div className='alert alert-danger'> {error} </div>}
      {entries.length === 0 && !loading && !error && <div className='alert alert-danger'> No Data available </div>}
      {entries.length > 0 && renderEntries()}
      { paginationData && paginationData.total_pages > 1 &&
      <Paginate
        pages={paginationData.total_pages}
        currentPage={paginationData.current_page}
        onPageNext={() => gotTo(currentPage + 1)}
        onPageBack={() => gotTo(currentPage - 1)}
        toPage={(pageNum) => gotTo(pageNum)} />
      }
      {loading && paginationData && <Spinner />}
    </div>
  )
}

export default HarmonyImportsIndex
