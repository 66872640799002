import React, { Component } from 'react'
import moment from 'moment'
import isUndefined from 'lodash/isUndefined'

import CalendarSelect from '../components/CalendarSelect'
import { Label, Input } from '../components/Input'
import { FormSection, FormText } from '../components/Form'
import { getMoment, getMomentStr } from '../../util/time'
import { data } from '../../data/expirations'

export class ExpiresAt extends Component {
  static defaultProps = {
    sku: {},
    reason: {}
  }


  state = this.props.initialValue
    ? {
      value: this.props.initialValue,
      index: data(this.props.initialValue, this.props.sku, this.props.canSetNever, this.props.reason).length - 1,
      calendarValue: getMoment(this.props.initialValue),
      open: true
    }
    : {
      value: null,
      index: 0,
      calendarValue: getMoment(moment().toDate(), 2, 'weeks'),
      open: false
    }
    
  componentDidUpdate = prevProps => {
    if ((prevProps.sku.name === this.props.sku.name) && (prevProps.reason === this.props.reason)) return
    const { value } = this.opts()[0]
    this.handleState({ index: 0, value })
  }

  expirationHelperText = (sku, reason) => {
    const flexpool_platforms = ['capacity_pool', 'instance_pool', 'harmony', 'vcpu_pool', 'subscriber_pool']


    if (sku.trial && sku.platform === 'threatstop')
      return 'Threatstop trial licenses expire in 60 days'
    else if (sku.trial)
      return 'Trial licenses require expirations set to 90 days or less.'
    else if (reason === 'customer_poc')
      return 'POC licenses require expirations set to 90 days or less and cannot be edited.'
    else if (reason === 'internal_testing')
      return 'Internal Testing licenses require expirations set to 1 year or less.'
    else
      return 'License expiration must be a future date.'
  }

  handleRadioChange = index => {
    const { label, value } = this.opts()[index]
    const open = label === 'Custom'
    this.handleState({ index, open, value })
  }

  handleCalendarChange = calendarValue => {
    const value = getMomentStr(calendarValue)
    this.handleState({ calendarValue, value: calendarValue })
  }

  handleState = state => {
    const { onChange, name } = this.props
    this.setState(
      { ...state },
      () => onChange({ name, value: this.state.value })
    )
  }

  startDatePresent = () => {
    const startDate = this.props.startsAt
    let startDatePresent = true

    if (isUndefined(startDate)) {
      startDatePresent = false
    } else if (startDate.length === 0) {
      startDatePresent = false
    }

    return startDatePresent
  }

  opts = () => {
    const startDate = this.startDatePresent() ? this.props.startsAt : moment()
    return data(startDate, this.props.sku, this.props.canSetNever, this.props.reason)
  }

  render () {
    const { sku, isEdit, reason } = this.props
    const { calendarValue, index, open, value } = this.state
    const dates = this.opts()
    const hint = this.expirationHelperText(sku, reason)
    return (
      <>
        {!!sku.product &&
        <FormSection>
          <Label>Set an expiration date</Label><br />
          <FormText variant='muted'>{hint}</FormText>
          {!(sku.trial && isEdit) && dates.map((rProps, i) =>
            <Input
              type='radio'
              inline
              key={i}
              index={i}
              checked={index === i}
              onChange={() => this.handleRadioChange(i)}
              {...rProps}
            />
          )}
          <CalendarSelect
            label='Expiration Date'
            open={open}
            value={calendarValue}
            onChange={this.handleCalendarChange}
            utcEnabled={true}
          />
        </FormSection>
        }
      </>
    )
  }

}

export default ExpiresAt
