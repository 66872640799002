import React from 'react'
import PropTypes from 'prop-types'

import Alert from './Alert'
import ComplianceError from './ComplianceError'

export const ComplianceErrors = ({ children: activations }) =>
  <Alert closable='false'>
    <h5>
      <strong>Warning!</strong> The following activations will be out of bounds
    </h5>
    {activations.map(a =>
      <ComplianceError key={a.id} children={a} />
    )}
  </Alert>

ComplianceErrors.propTypes = {
  children: PropTypes.array
}

export default ComplianceErrors
