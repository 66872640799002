import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Box from './Box'

export const AlertWrapper = ({
  variant = 'danger',
  toggle,
  ...props
}) =>
  <Box
    classNames={['alert', `alert-${variant}`, 'alert-dismissable']}
    color='info'
    {...props}
  />

export const CloseButton = props =>
  <Box
    as='button'
    className='close'
    {...props}
  >
    <Box
      as='span'
      children='×'
    />
  </Box>

export const Alert = ({
  children,
  closable,
  ...props
}) => {
  const [ visible, setVisible ] = useState(true)
  const handleClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (children) {
      setVisible(true)
    }
  }, [children])

  if (!visible || !children) return null
  return (
    <AlertWrapper toggle={handleClose} {...props}>
      {closable &&
        <CloseButton onClick={handleClose} />
      }
      {children}
    </AlertWrapper>
  )
}

Alert.propTypes = {
  closable: PropTypes.bool
}

export default Alert
