import React, { Component } from 'react'
import UserInput from '../UsersWithAccess/UserInput'
import { uid } from '../helpers/util'
import { send } from '../containers/IdpAddEditForm'
import _omit from 'lodash/omit'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'

const newUser = (email = '', isNew = true, canDestroy = true, deleted = false) =>
  ({ email, isNew, canDestroy, deleted })

const grantAccessToUser = async (email, supportContractId) => {
  return send(JSON.stringify(
    {
      authorization:
        {
          authorized_type: 'SupportContract',
          authorized_id: supportContractId,
          email: email
        }
    }),
  'POST',
  '/authorizations.json')
}

const deleteAccessToUser = async authorizedId => {
  return fetch(`/authorizations/${authorizedId}.json`, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application.json',
      'Content-Type': 'application/json'
    }
  })
}

const loadAuthUsers = async (supportContractId) => {
  return fetch(`/support_contracts/${supportContractId}/authorized_users.json`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  })
}

class SupportContractAuthUsers extends Component {
  constructor (props) {
    super(props)
    const newUid = uid()

    this.state = {
      newUid: newUid,
      users: {},
      ids: [],
      showError: false,
      errorMessage: ''
    }
  }

  addUser = async (email, supportContractId) => {
    const response = await grantAccessToUser(email, supportContractId)

    if (response.errors) {
      this.setState({ showError: true, errorMessage: response.errors })
    } else {
      GLMToast('User added successfully!', {className: 'alert alert-info'})
      this.setState({ users: {}, ids: [] })
      this.loadAuthUsers()
    }
  }

  removeUser = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      const response = await deleteAccessToUser(id)
      const json = await response.json()

      if (response.ok) {
        const updatedUsers = _omit(this.state.users, id)
        const updatedIds = Object.keys(updatedUsers)
        // Do not allow to destroy last authorized user
        if (updatedIds.length === 1) {
          const inseparableUser = updatedUsers[updatedIds[0]]
          inseparableUser.canDestroy = false
        }

        this.setState({ users: updatedUsers, ids: updatedIds })
        GLMToast('User removed successfully!', {className: 'alert alert-info'})
      } else {
        this.setState({ showError: true, errorMessage: json.errors })
      }
    }
  }

  loadAuthUsers = async () => {
    const response = await loadAuthUsers(this.props.supportContractId)
    const json = await response.json()
    let ids = []
    let users = []
    if (json.length > 0) {
      users = (json || []).reduce(
        (obj, user) => ({ ...obj, [user.authorizationId]: user }),
        {}
      )
      users = {...this.state.users, ...users}

      ids = Object.keys(users)
    }
    this.setState({ids, users})
  }

  dismissError = () => {
    this.setState({ showError: false })
  }

  componentDidMount () {
    this.loadAuthUsers()
  }

  render () {
    const { users, ids, newUid, showError, errorMessage } = this.state
    return (
      <div>
        { !!showError &&
          <div
            className='alert alert-danger alert-dismissable'
            role='alert'>
            <div
              className='close'
              datadismiss='alert'
              arialabel='close'
              onClick={this.dismissError}>
              <span ariahidden='true'>&times;</span>
            </div>
            {errorMessage}
          </div>
        }
        <div className='row'>
          <div className='col-md-7'>
            {this.props.canAuthorizeUser && <div className='row'>
              <div className='col-md-12'>
                <UserInput
                  values={newUser()}
                  newUid={newUid}
                  licenseId={this.props.supportContractId}
                  onAccess={this.addUser}
                />
              </div>
            </div>}
            <div className='row'>
              <div className='col-md-12'>
                {ids.map((key, index) =>
                  <UserInput
                    key={key}
                    id={key}
                    index={index}
                    values={users[key]}
                    newUid={newUid}
                    licenseId={this.props.supportContractId}
                    onAccess={this.addUser}
                    onDelete={this.removeUser}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <GLMToastContainer />
      </div>
    )
  }
}

export default SupportContractAuthUsers
