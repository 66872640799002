import React, { Component } from 'react'
import moment from 'moment'
import { UsageGraph } from './UsageGraph'
import { isUndefined } from 'lodash'

export default class Usage extends Component {
  state = {
    usageType: this.props.usageType,
    usageId: this.props.usageId,
    data: [],
    endTime: moment().utc().endOf('day'),
    startTime: moment().utc().startOf('day').subtract(5, 'days')
  }

  componentDidMount = async () => {
    await this.resetWeek()
  }

  loadUsages = async () => {
    const fixRespWindow = window => moment.unix(window).utc().toDate()
    const { usageId, usageType, endTime, startTime } = this.state
    const windowStart = startTime.unix()
    const windowEnd = endTime.unix()
    const response = await fetch(
      `/usage/${usageType}/${usageId}.json?window_end=${windowEnd}&window_start=${windowStart}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        }
      }
    )
    if (response.ok) {
      const json = await response.json()
      const data = json.usages.map(d => ({ ...d, windowEnd: fixRespWindow(d.windowEnd), totalUsage: parseInt(d.overage + d.usage) }))
      this.setState({ data })
    } else {
      this.setState({ error: 'Could not detect data.' })
    }
  }

  next = (event, number, unit) => {
    event.preventDefault()
    const startTime = this.state.startTime.add(number, unit)
    const endTime = this.state.endTime.add(number, unit)
    this.setState({ startTime, endTime }, this.loadUsages)
  }

  previous = (event, number, unit) => {
    event.preventDefault()
    const startTime = this.state.startTime.subtract(number, unit)
    const endTime = this.state.endTime.subtract(number, unit)
    this.setState({ startTime, endTime }, this.loadUsages)
  }

  resetWeek = (event) => {
    if (!isUndefined(event)) {
      event.preventDefault()
    }
    const endTime = moment().utc().endOf('day')
    const startTime = moment().utc().startOf('day').subtract(5, 'days')
    this.setState({ startTime, endTime }, this.loadUsages)
  }

  render () {
    const { startTime, endTime, data } = this.state
    return (
      <UsageGraph
        previous={this.previous}
        resetWeek={this.resetWeek}
        next={this.next}
        startTime={startTime}
        endTime={endTime}
        data={data}
      />
    )
  }
}
