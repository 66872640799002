import React, { useState } from 'react'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import random from 'lodash/random'
import toUpper from 'lodash/toUpper'
import qs from 'query-string'

const TwoFactoryVerification = ({secret}) => {
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [recoveryCodes, setRecoveryCodes] = useState([])
  const queryParams = qs.parse(window.location.search)
  const resetToken = queryParams.reset_token

  const generateRecoveryCode = () => toUpper(times(6, () => random(35).toString(36)).join(''))

  const generateRecoveryCodes = () => {
    const codes = []
    times(10, () => {
      codes.push(generateRecoveryCode())
    })
    setRecoveryCodes(codes)
  }

  const downloadRecoveryCodes = (e) => {
    e.preventDefault()
    const link = document.createElement('a')
    link.setAttribute('download', 'recovery_codes.txt')
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(recoveryCodes.join('\n')))
    link.click()
  }

  const handleCodeChange = (e) => {
    const val = e.target.value
    // Allow only 6 digit input values
    if (val.length <= 6) {
      setCode(val)
    }
    // Trigger verification call if code length is >= 6
    if (val.length >= 6) {
      setErrorMessage()
      testCodeDebounced(val)
    }
  }

  const handleSuccess = () => {
    setSuccess(true)
    setErrorMessage(false)
    generateRecoveryCodes()
    $('.act-btn').removeAttr('disabled')
  }

  const handleError = (error) => {
    setSuccess(false)
    setErrorMessage(error)
    $('.act-btn').attr('disabled', 'disabled')
  }

  const testCode = (code) => {
    setLoading(true)
    const fetchParams = {...queryParams, secret_key: secret}
    fetch(`/users/validate_2fa_otp/${encodeURIComponent(code)}?${qs.stringify(fetchParams)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        if (data.success) {
          handleSuccess()
        } else {
          handleError('Invalid Code, please try again')
        }
      })
      .catch((e) => {
        setLoading(false)
        handleError('Something went wrong')
      })
  }

  const testCodeDebounced = debounce(testCode, 150)

  return (
    <div>
      {
        !!success && <div>
          {resetToken && <input type='hidden' name="reset_token" value={resetToken} />}
          <p><b>Recovery Codes</b></p>
          <p>
            If you lose access to your phone or the authenticator app,
            use the following codes to access your account instead of the authenticator code,
            its recommended to download the following recovery codes and store them safely.
          </p>
          <p>
            {recoveryCodes.map((code, i) =>
              <span key={i}>
                <input type='hidden' name='user[recovery_codes][]' value={code} />
                <code style={{marginRight: '6px'}}>
                  {code}
                </code>
              </span>
            )}
          </p>
          <button className='btn btn-default btn-sm' onClick={downloadRecoveryCodes}>
            <i className='fa fa-download' /> Download Recovery Codes
          </button>
        </div>
      }
      {
        !success && <div>
          <p><b>Enter the code from authenticator</b></p>
          <p>After scanning the QR code, the authenticator will display a six-digit code, please enter the code below</p>
          <input type='text'
            value={code}
            onChange={handleCodeChange}
            maxLength='6'
            className='form-control'
            style={{maxWidth: '200px', display: 'inline-block', marginRight: '4px'}} />
          { loading && <i className='fa fa-spinner fa-pulse' />}
          { errorMessage && <div className='invalid-feedback red-1 mt1' style={{fontSize: '14px'}}>{errorMessage}</div> }
        </div>
      }
    </div>
  )
}

export default TwoFactoryVerification
