import React from 'react'
import PropTypes from 'prop-types'
import Box from './Box'

export const Button = ({
  classNames,
  variant,
  ...props
}) =>
  <Box
    as='a'
    classNames={['btn', `btn-${variant}`, ...classNames]}
    {...props}
  />

Button.defaultProps = {
  classNames: [],
  variant: 'default'
}

Button.propTypes = {
  classNames: PropTypes.array,
  variant: PropTypes.string
}

export default Button
