import React from 'react'
import Button from '../Button'

const newLicenseURL = (props) => {
  if (props.fireEyeUser) {
    return `/oem/new`
  } else if (props.organizationId) {
    return `/licenses/new?organization_id=${props.organizationId}`
  } else {
    return `/licenses/new`
  }
}

const buttonText = (props) => {
  if (props.organizationId) {
    return `Add License`
  } else {
    return `Add Trial License`
  }
}

const Empty = props =>
  <div className='center mt4'>
    <span className='fa-4x text-muted'>
      <i className='fa fa-hdd-o' />
    </span>
    <h4 className='p1'>
      { props.organizationId ? (
        `${props.organizationName} doesn't `
      ) : (
        "You don't "
      )}
         have any licenses yet.
    </h4>
    <div className='mt4'>
      <Button
        className={props.createOnOrg ? "btn btn-md btn-primar" : 'hidden'}
        href={newLicenseURL(props)}
        children={buttonText(props)} />
    </div>
  </div>

export default Empty
