import React, {useState, Fragment, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import { formatDateTime } from '../helpers/moment'

const LicenseNotes = ({onChange, allowInternal, initialVal, renderHtmlFields}) => {
  const [notes, setNotes] = useState(initialVal || [])

  const initialformVal = () => {
    return {
      note: '',
      internal: false
    }
  }

  useEffect(()=>{
    setNotes([...notes, initialformVal()])
  }, [])

  const validNote = (note) => {
    if (typeof(note.note) === 'string') {
      return note.note.trim().length > 0
    } else {
      return false
    }
  }

  const addNewNote = (e) => {
    e.preventDefault()
    setNotes([...notes, initialformVal()])
  }

  const removeNote = (e, note) => {
    e.preventDefault()
    if (!isUndefined(note.id)) {
      if (window.confirm('Are you sure you want to remove this note?')) {
        handleEntryChange(true, 'archived', note)
      }
    } else {
      setNotes(filter(notes, (n) => n !== note))
    }
  }

  const handleEntryChange = (val, field, note) => {
    const updatedNotes = notes.map((n) => {
      if (n === note) {
        return {...note, [field]: val}
      } else {
        return n
      }
    })
    setNotes(updatedNotes)
  }

  const validNotes = () => filter(notes, (n) => n.archived !== true)

  const formData = () => {
    const items = []
    notes.map((n) => {
      if (!validNote(n) && !isUndefined(n.id)) {
        items.push({...n, archived: true })
      } else if (validNote(n)) {
        items.push(n)
      }
    })
    return items
  }

  const handleOnChange = () => {
    setTimeout(()=>{
      onChange({name: 'license_notes_attributes', value: formData()})
    })
  }

  useEffect(()=>{
    onChange && handleOnChange()
  },[notes])

  const renderNotes = () => {
    return (
      <div className='mb1'>
        {validNotes().map((note, i) =>
          <div className={`row ${(isUndefined(note.id) || note.editable) ? 'mt1' : '' }`} key={i}>
            <div className={`${isUndefined(note.id) ? 'col-md-8' : 'col-md-12' }`}>
              {!isUndefined(note.id)  &&
               <small className='text-muted'>
                 {note.updated_by_name &&
                <Fragment>{note.updated_by_name} | </Fragment>
                 }
                 {formatDateTime(note.updated_at)}
                 <span className='note-actions'>
                   {note.internal &&  <i className='fa fa-lock text-primary' title="internal note"/>}
                   {!note.editable && <i className='fa fa-pencil text-muted cursor-pointer' title='Edit' onClick={() => handleEntryChange(true, 'editable', note)} /> }
                   <i className='fa fa-times text-muted cursor-pointer' title='Remove' onClick={(e) => removeNote(e, note)} />
                 </span>
               </small>
              }
              {(isUndefined(note.id) || note.editable) &&
              <Form.Control
                as='textarea'
                rows={2}
                placeholder='Add Note'
                value={note.note}
                onChange={(e) => handleEntryChange(e.target.value, 'note', note)}
              />
              }
              {!isUndefined(note.id) && !note.editable &&
                <div className='note-preview'>
                  <small>{note.note}</small>
                </div>
              }
              
            </div>
            {allowInternal && isUndefined(note.id) && <div className='col-md-2'>
              <Form.Check
                style={{padding: '6px'}}
                disabled={!isUndefined(note.id)}
                inline
                label='&nbsp;Internal'
                name={`Internal-${i}`}
                checked={note.internal}
                onChange={(e) => handleEntryChange(!note.internal, 'internal', note)}
                type='checkbox'
                id={`ic-${i}`}
              />
            </div>}
            {
              isUndefined(note.id) && 
              <div className='col-md-2'>
                <i className='fa fa-times text-danger cursor-pointer' title='Remove' onClick={(e) => removeNote(e, note)} />
              </div>
            }
          </div>
        )}
      </div>
    )
  }

  const renderInput = (note, field) => {
    return <input type="hidden" name={`license[license_notes_attributes][][${field}]`} value={note[field]}/> 
  }

  const renderInputFieldsForHTMLForm = () => {
    return (
      <Fragment>
        {formData().map((note, i) =>
          <Fragment key={i}>
            {!isUndefined(note.id) && renderInput(note, 'id')}
            {renderInput(note, 'note')}
            {renderInput(note, 'internal')}
            {note.archived && renderInput(note, 'archived') }
          </Fragment>
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Form.Group>
        <div className='row'>
          <Form.Label className='col-md-8'>
            {renderHtmlFields && formData().length > 0 && renderInputFieldsForHTMLForm()}
          Notes
          </Form.Label>
        </div>
        {notes.length > 0 && renderNotes()}
        <div>
          <a href="#" onClick={addNewNote}>Add new note</a>
        </div>
      </Form.Group>
    </Fragment>
  )
}

export default LicenseNotes
