import React, { useEffect, useState, Fragment } from 'react'
import Spinner from '../Spinner'
import orderBy from 'lodash/orderBy'
import SortableTableHeader from '../SortableTableHeader'
import { formatDateTime } from '../HarmonyMetrics/helpers'
import DeleteReasonModal from '../Modals/DeleteReasonModal'
import sumBy from 'lodash/sumBy'

const HarmonyImportsShow = ({importId}) => {
  const [importEntry, setImportEntry] = useState()
  const [loading, setLoading] = useState(true)
  const [sortVal, setSortVal] = useState({})

  useEffect(() => {
    fetch(`/internal/harmony_controller_reports_imports/${importId}.json`, { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        setImportEntry(data.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (importEntry && !sortVal.key) {
      setSortVal({ key: 'provider_name', direction: 'asc' })
    }
  }, [importEntry])

  useEffect(() => {
    if (importEntry && importEntry.report_items.length > 0) {
      const { key, direction } = sortVal
      const items = importEntry.report_items
      if (key && direction) {
        setImportEntry({...importEntry, report_items: orderBy(items, key, direction)})
      } else {
        setImportEntry({...importEntry, report_items: orderBy(items, 'provider_name', 'asc')})
      }
    }
  }, [sortVal])

  const sortHeader = (title, trackKey) => {
    const props = {
      onChange: setSortVal,
      sortKey: sortVal.key,
      sortDirection: sortVal.direction,
      renderPlainHeader: true,
      title,
      trackKey
    }
    return <SortableTableHeader {...props} />
  }

  const reportItemsSum = (key) => sumBy(importEntry.report_items, key)

  const renderSectionThree = () =>
    <table className='table table-responsive small-table table-bordered '>
      <thead>
        <tr>
          {sortHeader('Provider Name', 'provider_name')}
          {sortHeader('Total Devices', 'total_devices')}
          {sortHeader('Log Capacity Used (avg / second)', 'log_capacity_used')}
          {sortHeader('Pool Utilization', 'pool_utilization_percentage')}
          {sortHeader('Avg log export per device', 'average_log_export_per_device')}
        </tr>
      </thead>
      <tbody>
        {importEntry.report_items.map((item, i) =>
          <tr key={i}>
            <td>
              <b>{item.provider_name}</b>
            </td>
            <td>
              {item.total_devices}
            </td>
            <td>
              {item.log_capacity_used}
            </td>
            <td>
              {item.pool_utilization_percentage}%
            </td>
            <td>
              {item.average_log_export_per_device}
            </td>
          </tr>
        )}
        <tr style={{background: '#f3f2f2'}}>
          <td><b>Total:</b></td>
          <td>{reportItemsSum('total_devices')}</td>
          <td>{reportItemsSum('log_capacity_used')}</td>
          <td>{reportItemsSum('pool_utilization_percentage')}%</td>
          <td>{reportItemsSum('average_log_export_per_device')}</td>
        </tr>
      </tbody>
    </table>

  const renderSectionTwo = () =>
    <table className='table table-responsive small-table table-bordered '>
      <tbody>
        <tr>
          <td>Pool Type</td>
          <td>Shared</td>
        </tr>
        <tr>
          <td>Total Pool Capacity</td>
          <td>{importEntry.total_pool_capacity}</td>
        </tr>
        <tr>
          <td>Allocatable</td>
          <td>{importEntry.allocatable_percentage}</td>
        </tr>
      </tbody>
    </table>

  const renderSectionFour = () =>
    <table className='table table-responsive small-table table-bordered'>
      <tbody>
        <tr>
          <td>Avg log export per device</td>
          <td>{importEntry.avg_log_export_per_device}</td>
        </tr>
        <tr>
          <td>Max (avg log rate per device / per provider)</td>
          <td>{importEntry.max_avg_log_rate_per_device_per_provider}</td>
        </tr>
        <tr>
          <td>Pool capacity available for allocation</td>
          <td>{importEntry.pool_capacity_availabe_for_allocation}</td>
        </tr>
        <tr>
          <td>Devices available (Max)</td>
          <td>{importEntry.devices_available_max}</td>
        </tr>
        <tr>
          <td>Devices available (Avg)</td>
          <td>{importEntry.devices_available_avg}</td>
        </tr>
      </tbody>
    </table>

  const renderSectionOne = () =>
    <table className='table small-table table-bordered'>
      <thead>
        <tr>
          {sortHeader('Provider Name', 'provider_name')}
          {sortHeader('Total Tenants', 'total_tenants')}
          {sortHeader('Total Clusters', 'total_clusters')}
          {sortHeader('Total Devices', 'total_devices')}
          {sortHeader('Total Licenses', 'total_licenses')}
          {sortHeader('Total Access Groups', 'total_access_groups')}
          {sortHeader('Total Users', 'total_users')}
          {sortHeader('Total CLI Snippets', 'total_cli_snippets')}
          {sortHeader('Total Scheduled Backups', 'total_scheduled_backups')}
          {sortHeader('Total Agreements', 'total_agreements')}
          {sortHeader('User Authentication', 'user_authentication')}
          {sortHeader('MFA Enabled', 'mfa_enabled')}
          {sortHeader('Organization', 'organization')}
          <th>Log Pool type</th>
          <th>Log Capacity - Allocated (K)</th>
          {sortHeader('Log Capacity - Used(avg / second)', 'log_capacity_used')}
        </tr>
      </thead>
      <tbody>
        {importEntry.report_items.map((item, i) =>
          <tr key={i}>
            <td>
              <b>{item.provider_name}</b>
            </td>
            <td>
              {item.total_tenants}
            </td>
            <td>
              {item.total_clusters}
            </td>
            <td>
              {item.total_devices}
            </td>
            <td>
              {item.total_licenses}
            </td>
            <td>
              {item.total_access_groups}
            </td>
            <td>
              {item.total_users}
            </td>
            <td>
              {item.total_cli_snippets}
            </td>
            <td>
              {item.total_scheduled_backups}
            </td>
            <td>
              {item.total_agreements}
            </td>
            <td>
              {item.user_authentication}
            </td>
            <td>
              {item.mfa_enabled ? 'Yes' : 'No'}
            </td>
            <td>
              {item.organization}
            </td>
            <td>
              {importEntry.pool_type}
            </td>
            <td>
              {importEntry.total_pool_capacity_in_k}
            </td>
            <td>
              {item.log_capacity_used}
            </td>
          </tr>
        )}
        <tr style={{background: '#f3f2f2'}}>
          <td><b>Total:</b></td>
          <td>{reportItemsSum('total_tenants')}</td>
          <td>{reportItemsSum('total_clusters')}</td>
          <td>{reportItemsSum('total_devices')}</td>
          <td>{reportItemsSum('total_licenses')}</td>
          <td>{reportItemsSum('total_access_groups')}</td>
          <td>{reportItemsSum('total_users')}</td>
          <td>{reportItemsSum('total_cli_snippets')}</td>
          <td>{reportItemsSum('total_scheduled_backups')}</td>
          <td>{reportItemsSum('total_agreements')}</td>
          <td/>
          <td/>
          <td/>
          <td/>
          <td>{(importEntry.total_pool_capacity_in_k * importEntry.report_items.length).toFixed(2)}</td>
          <td>{reportItemsSum('log_capacity_used')}</td>
        </tr>
      </tbody>
    </table>

  if (!loading && !importEntry) {
    return <h3 className='text-center'>Not Found.</h3>
  }

  if (loading) {
    return <div className='text-center'><Spinner /></div>
  }

  if (importEntry) {
    return (
      <Fragment>
        <h3 className='h3'>
          Report Created On { formatDateTime(importEntry.created_at) }
          { importEntry.delete_reason === null &&
            <DeleteReasonModal
              deleteURL={`/internal/harmony_controller_reports_imports/${importId}.json`}
              redirectURL={`/internal/harmony_controller_reports_imports`} />
          }
        </h3>
        <h5>
          Report Date: {formatDateTime(importEntry.report_time)}
          <span className='text-muted'> | </span>
          Identifier: {importEntry.identifier || 'N/A'}
        </h5>
        {importEntry && importEntry.report_items.length > 0 &&
          <div className='row'>
            <div className='col-md-12'>{renderSectionOne()}</div>
            <div className='col-md-6'>{renderSectionTwo()}</div>
            <div className='col-md-12'>{renderSectionThree()}</div>
            <div className='col-md-6'>{renderSectionFour()}</div>
          </div>
        }
        { importEntry.delete_reason !== null &&
        <Fragment>
          <hr />
          <div className='row'>
            <div className='col-md-4'>
                  Import Deletion Date
              <br /><small>{formatDateTime(importEntry.deleted_at)}</small>
            </div>
            <div className='col-md-4'>
                  Deletion Reason
              <br /><small>{importEntry.delete_reason}</small>
            </div>
          </div>
        </Fragment> }
      </Fragment>
    )
  } else {
    return <Fragment />
  }
}

export default HarmonyImportsShow
