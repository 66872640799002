import momenttz from 'moment-timezone';

momenttz.tz.setDefault("UTC");

export const DATE_FORMAT = 'DD/MM/YYYY'

export const getMoment = (start, ...props) => momenttz(start, DATE_FORMAT).add(...props)

export const getMomentStr = (start, ...props) => getMoment(start, ...props).format(DATE_FORMAT)

export const dayMoment = (start, days) => {
  const prefix = days === 1 ? 'Day' : 'Days'

  return getMomentStr(start, days.toString(), prefix)
}

export const yearMoment = (start, years) => {
  const prefix = years === 1 ? 'Year' : 'Years'

  return getMomentStr(start, years.toString(), prefix)
}
