import React, {useState, useEffect} from 'react'
import isEmpty from 'lodash/isEmpty'
import DashboardDateSelector from '../../../components/DashboardDateSelector'

const MetricsOptions = ({settings, onSettingsChange, OptionComponent}) => {
  const [state, setState] = useState({...settings})
  useEffect(()=>{
    if (state && onSettingsChange && !isEmpty(state)) {
      onSettingsChange(state)
    }
  }, [state])

  const handleDateChange = (dateState) => {
    const {startDate, endDate} = dateState
    const newSettings = {...state, starts_at: moment(startDate).format('DD/MM/YYYY'), ends_at: moment(endDate).format('DD/MM/YYYY')}
    setState(newSettings)
  }

  const handleSettingsKey = (e, key) => {
    e.preventDefault()
    const newSettings = {...state, [key]: !state[key]}
    setState(newSettings)
  }

  const handleStateChange = (items) => {
    const newSettings = {...state, ...items}
    setState(newSettings)
  }

  return (
    <div className='row small'>
      <div className='col-md-5'>
        <DashboardDateSelector startDate={settings.starts_at} endDate={settings.ends_at} onChange={handleDateChange}/>
      </div>
      <div className='col-md-7 text-right'>
        <div className='btn-group'>
          {
            OptionComponent && 
            <OptionComponent onKeyChange={handleSettingsKey} state={state} stateChangeHandler={handleStateChange}/> 
          }
        </div>
      </div>
    </div>
  )
}

export default React.memo(MetricsOptions)
