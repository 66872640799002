import React, { Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import WizardForm from '../WizardForm'
import WizardButtons from '../WizardButtons'

class Description extends WizardForm {
  calculateDescription = () => {
    const wizard = this.props.wizardState
    return `${wizard.selectedProductText} ${wizard.selectedPlatformText} ${this.bandwidth()}${wizard.trial ? 'Trial' : ''}`.trimRight()
  }

  calculateShortDescription = () => {
    const wizard = this.props.wizardState
    return `${wizard.selectedPlatformText} ${this.bandwidth()}${wizard.trial ? 'Trial' : ''}`.trimRight()
  }
  bandwidth = () => {
    const wizard = this.props.wizardState
    if (wizard.isFlexpool) {
      return wizard.unlimitedBandwidth ? 'Unlimited ' : `${wizard.displayBandwidth} ${wizard.bandwidthUnit} `
    }
    return ''
  }

  state = {
    description: this.calculateDescription(),
    shortDescription: this.calculateShortDescription(),
    nextEnabled: true
  }

  componentDidMount = async () => {
    const name = await this.getSuggestedName(this.state.shortDescription.replace(/ /g, '_').replace(/()/g, '').toLowerCase())
    this.setState({
      skuName: name
    })
  }

  getSuggestedName = async (name) => {
    const response = await fetch(`/api/skus/name?name=${name}`)
    if (response.ok) {
      const json = await response.json()
      name = json.suggested_name
    }
    return name
  }

  onDescriptionChange = (e) => {
    this.setState({
      description: e.target.value
    }, this.setNextEnabled)
  }

  onShortDescriptionChange = (e) => {
    this.setState({
      shortDescription: e.target.value
    }, this.setNextEnabled)
  }

  onSkuNameChange = (e) => {
    this.setState({
      skuName: e.target.value
    }, this.setNextEnabled)
  }

  setNextEnabled = () => {
    this.setState({
      nextEnabled: this.state.shortDescription !== '' && this.state.description !== '' && this.state.skuName !== ''
    })
  }

  getWizardState = () => {
    return {
      description: this.state.description,
      shortDescription: this.state.shortDescription,
      skuName: this.state.skuName
    }
  }

  onNext = async () => {
    const suggestedName = await this.getSuggestedName(this.state.skuName)
    if (suggestedName === this.state.skuName) {
      this.props.onNext(this.getWizardState())
    } else {
      this.props.handleError(`That SKU name is taken.  Suggested name: ${suggestedName}`)
    }
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <label className='control-label'>Description</label>
        <Form.Control
          defaultValue={this.state.description}
          onChange={this.onDescriptionChange}
        />
      </div>
      <div className='py1'>
        <label className='control-label'>Short Description</label>
        <Form.Control
          defaultValue={this.state.shortDescription}
          onChange={this.onShortDescriptionChange}
        />
      </div>
      <div className='py1'>
        <label className='control-label'>Name</label>
        <Form.Control
          defaultValue={this.state.skuName}
          onChange={this.onSkuNameChange}
        />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled={this.state.nextEnabled}
      />
    </Fragment>
  )
}

export default Description
