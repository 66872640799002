import React, { useEffect } from 'react'
import { FormInput } from './Form'

export const InputField = ({ type, value, id, name, label, customonchange, ...props }) => {

  useEffect(()=>{
    setTimeout(() =>  props.onChange && props.min && props.onChange({ name, value: (value || props.min) }))
  },[])

  useEffect(()=>{
    customonchange && customonchange(Number(value))
  }, [value])

  return (
    <FormInput
      type={type}
      id={id}
      name={name}
      label={label}
      value={value}
      {...props}
    />
  )
}

export default InputField
