import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from '../../Spinner'
import 'isomorphic-fetch'
import WithHelpText from './WithHelpText'

const ProviderSummary = ({onClose}) => {
  const [summary, setSummary] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const loadSummary = async () => {
    setLoading(true)

    const response = await fetch(`/harmony_metrics/providers_summary.json${window.location.search}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setSummary(jsonData.data)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  useEffect(() => {
    loadSummary()
  }, [])

  const renderBlock = (title, items, infoKey) => {
    return (
      <div className='col-md-12'>
        <h5><b><WithHelpText infoKey={infoKey}>{title}</WithHelpText></b></h5>

        {items.map((item, i) =>
          <span className='badge btn-light' key={i} style={{margin: '0 10px 11px 0'}}>
            {item.key} <span className='badge badge-info'>{item.value}</span>
          </span>
        )}
        { items.length === 0 && <div className='col-md-3'>N/A</div> }
      </div>
    )
  }

  const activitiesCountForAllProviders = (activity) => {
    let totalCount = 0
    summary.activities_summary.providers.map((provider) => {
      totalCount += provider.activities[activity] || 0
    })
    return totalCount
  }

  const renderActivitiesSummary = () => {
    return (
      <table className='table small-table custom-hc-summary-table'>
        <thead>
          <tr>
            <th />
            {summary.activities_summary.providers.map((provider, i) =>
              <th key={i}>{provider.name}</th>
            )}
            <th><b>Total</b></th>
          </tr>
        </thead>
        <tbody>
          {summary.activities_summary.activity_keys.map((activity, i) =>
            <tr key={i}>
              <td>{activity}</td>
              {summary.activities_summary.providers.map((provider, pi) =>
                <td key={pi}>{provider.activities[activity] || 0}</td>
              )}
              <td><b>{activitiesCountForAllProviders(activity)}</b></td>
            </tr>
          )}
          <tr>
            <td><b>Total</b></td>
            {summary.activities_summary.providers.map((provider, pi) =>
              <td key={pi}><b>{provider.total_activities_count}</b></td>
            )}
          </tr>
        </tbody>
      </table>
    )
  }

  const renderSummary = () => {
    return (
      <div>
        <div className='row'>
          <div className='col-md-6 border'>
            <h5><b>Total Providers Having Activities: </b>{summary.total_with_activities}</h5>
          </div>
        </div>
        <hr />
        <div className='row'>
          { renderBlock('Providers By Region', summary.trials_by_region, 'provider_region') }
        </div>
        <hr />
        <div className='row'>
          { summary.activities_summary.activity_keys.length > 0 && summary.activities_summary.providers.length > 0 &&
            <div className='col-md-12'>
              <h5><b><WithHelpText infoKey='providers_activities'>Provider Activities Summary</WithHelpText></b></h5>
              {renderActivitiesSummary()}
            </div>
          }
        </div>
        <hr />
      </div>
    )
  }

  return (
    <Modal show onHide={() => onClose()} size='lg' animation={false} dialogClassName='adjust-header'>
      <Modal.Header closeButton>
        <Modal.Title>Providers Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeigh: '400px'}}>
        {loading && <div> Loading Summary <Spinner /></div> }
        {error && <div className='alert alert-danger'> {error} </div>}
        {!summary && !loading && !error && <div className='alert alert-danger'> No Data available </div>}
        {summary && renderSummary()}
      </Modal.Body>
    </Modal>

  )
}

export default ProviderSummary
