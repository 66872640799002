const cache = {}
const errorsCache = {}

const useAsync = (importPromise, cacheKey) => {
  const cachedModule = cache[cacheKey]
  if (cachedModule) return cachedModule

  if (errorsCache[cacheKey]) throw errorsCache[cacheKey]

  throw importPromise
    .then((mod) => (cache[cacheKey] = mod))
    .catch((err) => {
      errorsCache[cacheKey] = err
    })
};

export default useAsync
