import React, { Component } from 'react'

export default class TextField extends Component {
  constructor (props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.state = {
      value: this.props.initialValue
    }
  }

  handleInputChange (event) {
    this.setState({ value: event.target.value })
  }

  render () {
    if (this.props.show) {
      return (
        <div>
          <div className='form-group'>
            <label className='control-label'>{this.props.label}</label>
            <input
              id={this.props.id}
              name={this.props.name}
              className='form-control'
              value={this.state.value}
              min={this.props.min}
              onChange={this.handleInputChange}
              required={this.props.required}
            />
          </div>
        </div>
      )
    } else {
      return (null)
    }
  }
}
