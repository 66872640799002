import React, { useEffect, useState, Fragment, useRef } from 'react'
import CustomEmailRecipients from './CustomEmailRecipients'
import Spinner from './Spinner'
import { formatDateTime } from '../helpers/moment'
/* eslint-disable complexity */
const CustomEmailShow = ({customEmailId}) => {
  const [customEmail, setCustomEmail] = useState()
  const [loading, setLoading] = useState(true)
  const [bodyLoading, setBodyLoading] = useState(true)
  const iframeEl = useRef()

  useEffect(() => {
    fetch(`/custom_emails/${customEmailId}.json`, { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        setCustomEmail(data.custom_email)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const resizeIframe = () => {
    setBodyLoading(false)
    const bodyIframe = iframeEl.current
    bodyIframe.style.height = bodyIframe.contentWindow.document.documentElement.scrollHeight + 'px'
    const innerDoc = bodyIframe.contentDocument || bodyIframe.contentWindow.document
    if (innerDoc) {
      innerDoc.body.style.margin = '0px'
    }
  }

  const renderEmails = (emails) => {
    return (
      <div className='mb1'>
        {emails.map((email, i) =>
          <span className='badge' key={i} style={{marginRight: '2px'}}>
            {email}
          </span>
        )}
      </div>
    )
  }

  const customRecipientEmail = () => {
    return (customEmail.email_list_id || (customEmail.recipient_list && customEmail.recipient_list.length > 0))
  }

  const booleanIcon = (val) => {
    const iconClass = val ? 'fa-check text-success' : 'fa-times text-danger'
    return <i className={`fa ${iconClass}`} />
  }

  if (!loading && !customEmail) {
    return <h3 className='text-center'>Not Found.</h3>
  }

  if (loading) {
    return <div className='text-center'><Spinner /></div>
  }

  if (customEmail) {
    return (
      <Fragment>
        <h1 className='h3'>{ customEmail.subject }</h1>
        <hr />
        {customEmail.email_list_id && customEmail.email_list_name &&
          <div>
            <b>Recipient List: </b>
            <a href={`/email_lists/${customEmail.email_list_id}`}>
              {customEmail.email_list_name}
            </a>
          </div>
        }
        {!customEmail.email_list_id && customEmail.recipient_list && customEmail.recipient_list.length > 0 &&
        <div>
          <b> Recipient List:</b>
          { renderEmails(customEmail.recipient_list) }
          <hr />
        </div>
        }
        <div className='row'>
          <div className='col-md-8'>
            <p><b>Body:</b></p>
            { bodyLoading && <Spinner /> }
            <iframe
              src={`/custom_emails/${customEmailId}/body`}
              scrolling='no' onLoad={resizeIframe}
              ref={iframeEl}
              frameBorder={0} height={'100%'} width={'100%'} />
          </div>
          <div className='col-md-4'>
            { customEmail.created_by_id &&
              <div>
                <b>Created By:</b> <a href={`/users/${customEmail.created_by_id}`}>{customEmail.created_by_display_name || 'N/A'}</a>
              </div>
            }
            { !customEmail.created_by_id && <div><b>Created By:</b> N/A</div>}
            <div><b> Created At:</b> {formatDateTime(customEmail.created_at)}</div>
            <div><b> Recipients Count:</b> {customEmail.recipient_emails_count}</div>
            {!customRecipientEmail() &&
              <Fragment>
                <div><b> License Type:</b> {customEmail.license_type}</div>
                <div><b> Customers:</b> {booleanIcon(customEmail.customers)}</div>
                <div><b> Internal Employees:</b> {booleanIcon(customEmail.internal_users)}</div>
              </Fragment>
            }
            <hr />
            <a href={`/custom_emails/new?clone_email_id=${customEmailId}`}
              className='btn btn-primary'>
                Resend
            </a>
            <span> <CustomEmailRecipients customEmailId={customEmailId} batchesCount={customEmail.batches_count} /></span>
          </div>
        </div>
      </Fragment>
    )
  } else {
    return <Fragment />
  }
}
/* eslint-enable complexity */

export default CustomEmailShow
