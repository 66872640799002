class RolesDataApi {
  getInternalRoles = async () => {
    const response = await fetch('/api/new_users/internal_user_roles')
    if (response.ok) {
      const json = await response.json()
      return json.roles
    }
    return []
  }
}

export default RolesDataApi
