import React, { Fragment } from 'react'
import moment from 'moment'
import useVictory from '../helpers/useVictory'

const TrialOrgCreatedGraph = ({ data }) => {
  const Victory = useVictory()

  return (
    <Fragment>
      <div className='text-center'>
        <h4 className='text-muted h4 mt1'>Trial Organizations Created</h4>
      </div>
      { Victory &&
        <Victory.Chart
          height={200}
          width={500}
          theme={Victory.Theme.material}
          domainPadding={20}>
          <Victory.Axis dependentAxis />
          <Victory.Axis
            scale={{x: 'time'}}
            style={{tickLabels: {padding: 2, fontSize: 9}}}
            tickFormat={(t) => moment(t).utc().format('MMM D YYYY')} />
          <Victory.Bar
            data={data}
            barWidth={2}
            style={{labels: {fontSize: 10, fill: 'white', padding: 0}}}
            labelComponent={<Victory.Tooltip flyoutStyle={{fill: 'black', strokeWidth: 0}} />} />
        </Victory.Chart>
      }
    </Fragment>
  )
}

export default TrialOrgCreatedGraph
