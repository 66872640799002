import React from 'react'

import AddCustomRoles from './AddCustomRoles'
import SkuRoles from './SkuRoles'
import { Form, Field } from '../containers/Form'
import {
  FormActions,
  FormSubmit
} from '../components/Form'

export const EditSku = (props) => {
  const handleSuccess = () => {
    window.location = '/skus?updated'
  }

  return (
    <Form
      title='Update SKU'
      url={`/skus/${props.sku.id}.json`}
      method='PATCH'
      inputProps={props}
      onSuccess={handleSuccess}
    >

      <div className='col-md-6'>
        <Field
          as={SkuRoles}
          roles={props.roles}
          sku={props.sku}
          name='sku[roles]'
        />
      </div>

      <div className='col-md-6'>
        <Field
          as={AddCustomRoles}
          name='custom_roles'
        />
      </div>

      <div className='col-md-12'>
        <FormActions>
          <FormSubmit value='Update SKU' />
        </FormActions>
      </div>
    </Form>
  )
}

export default EditSku
