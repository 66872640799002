import React from 'react'

import Box from './Box'

export const Table = ({
  classNames = [],
  ...props
}) =>
  <Box
    as='table'
    classNames={['table', 'table-slats', 'table-layout-fixed', ...classNames]}
    {...props}
  />

export const THead = ({
  children: headers,
  ...props
}) =>
  <Box as='thead'>
    <Box as='tr'>
      {headers.map(h =>
        <Box
          as='th'
          key={h}
          children={h}
        />

      )}
    </Box>
  </Box>

export const TBody = props =>
  <Box
    as='tbody'
    {...props}
  />
