import React from 'react'
import NoOrganizationMessage from '../components/NoOrganizationMessage'
import OrganizationAddOptions from '../components/OrganizationAddOptions'

export const NoOrganization = (props) => {
  const query = new URLSearchParams(window.location.search)
  const returnUrl = query.get('source')

  return (
    <div className='container-fluid'>
      <NoOrganizationMessage {...props} />
      <OrganizationAddOptions returnUrl={returnUrl} {...props} />
    </div>
  )
}

export default NoOrganization
