import _merge from 'lodash/merge'
import _set from 'lodash/set'
import _get from 'lodash/get'
import _unset from 'lodash/unset'

import { deepCompact } from './object'

const defaultFilters = []

export const clear = (name, filters = defaultFilters) => oldState => {
  const newState = _merge({}, oldState)
  _unset(newState, name)
  return deepCompact(newState, filters)
}

export const insert = (name, value) => oldState => {
  const newState = _merge({}, oldState)
  _set(newState, name, value)
  return newState
}

export const update = (name, value, filters = defaultFilters) => (filters.includes(value)
  ? clear(name, filters)
  : insert(name, value)
)
