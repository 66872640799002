import moment from 'moment'
import {isUndefined, max, uniq} from 'lodash'

export const bitSpeeds = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps']
export const byteSize = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const formatBits = bits => {
  if (bits === 0) return '0 bps'
  const k = 1024
  const i = (bits >= 100) ? Math.floor(Math.log(bits / 100) / Math.log(k)) : 0
  return Math.round((bits / Math.pow(k, i))) + ' ' + bitSpeeds[i]
}

export const formatBytes = bytes => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const i = (bytes >= 100) ? Math.floor(Math.log(bytes / 100) / Math.log(k)) : 0
  return Math.round((bytes / Math.pow(k, i))) + ' ' + byteSize[i]
}

export const getTimes = dateRange => {
  const today = new Date()
  const end = Math.ceil(today.getTime() / 1000.0)
  const start = Math.floor((today.getTime() / 1000.0) - dateRange)
  return [start, end]
}

export const getCalendarTimes = (begin, finish) => {
  const start = Math.floor(begin.valueOf() / 1000.0)
  const end = Math.ceil(finish.valueOf() / 1000.0)
  return [start, end]
}

export const buildPlatformData = (metrics, platform, title) => {
  const counts = metrics.filter(m => m.attributes.platform === platform)
    .map(m => m.attributes.end_of_created_date)
    .reduce((accum, curr) => ({ ...accum, [curr]: (accum[curr] || 0) + 1 }), {})
  const data = Object.entries(counts).map(([k, v]) => ({
    x: parseInt(k) * 1000,
    y: v,
    label: `${v} ${title} license${v > 1 ? 's' : ''}\ncreated on ${moment(k * 1000).utc().format('MMM D YYYY')}`
  }))
  return data
}

export const buildOrgData = orgsCreated => {
  const counts = orgsCreated.map(m => m.attributes.end_of_created_date)
    .reduce((accum, curr) => ({ ...accum, [curr]: (accum[curr] || 0) + 1 }), {})
  const data = Object.entries(counts).map(([k, v]) => ({
    x: parseInt(k) * 1000,
    y: v,
    label: `${v} organization${v > 1 ? 's' : ''}\ncreated on ${moment(k * 1000).utc().format('MMM D YYYY')}`
  }))
  return data
}

export const graphZoomDomain = (data, startTime, endTime, field) => {
  const zoomDomain = { x: [startTime.toDate(), endTime.toDate()] }
  if (!isUndefined(data) && data.length > 0) {
    const fieldValues = data.map(item => item[field])
    zoomDomain.y = [0, max(fieldValues)]
  }
  return zoomDomain
}

export const timeTickFormat = (t, _p, visibleLabels) => {
  const dateArray = visibleLabels.map(date => moment(date).utc().format('MDY'))
  const dateFormat = uniq(dateArray).length === 1 ? 'MMM D \n h:mmA' : 'MMM D YYYY'
  return moment(t).utc().format(dateFormat)
}

export const getCores = (value) => {
  if (value < 20000) {
    return 4
  } else if (value < 40000) {
    return 8
  } else if (value < 60000) {
    return 14
  } else {
    return 24
  }
}
