import React from 'react'
import Radio from './Radio'
import Box from './Box'
import { FormGroup } from './Form'
import AddOrganization from '../containers/AddOrganization'
import OrganizationUserInstructions from './OrganizationUserInstructions'

class OrganizationAddOptions extends React.Component {
  state= {
    addingOrg: false,
    addingUser: false
  }

  addOrgChange = (e) => {
    this.setState({
      addingOrg: e.target.checked,
      addingUser: !e.target.checked
    })
  }

  addUserChange = (e) => {
    this.setState({
      addingOrg: !e.target.checked,
      addingUser: e.target.checked
    })
  }

  render() {
    return (
      <>
        <FormGroup className='container-fluid p0'>
          <Box className='col-12'>
            <Radio
              id='add_organization'
              name='organization'
              label='Create a new organization'
              hint='Create an organization of which you will be a member.'
              onChange={this.addOrgChange}
            />
          </Box>
          <Box className='col-12'>
            <Radio
              id='add_user'
              name='organization'
              label='Request your organization adds access to your account'
              hint='Steps to be added to an existing organization.'
              onChange={this.addUserChange}
            />
          </Box>
        </FormGroup>
        <AddOrganization {...this.props} visible={this.state.addingOrg}/>
        <OrganizationUserInstructions {...this.props} visible={this.state.addingUser}/>
      </>
    )
  }
}

export default OrganizationAddOptions
