import React from 'react'

import {
  FormActions,
  FormSubmit as Base
} from './Form'
import { FormContext } from '../containers/Form'

export const FormSubmit = props =>
  <FormContext.Consumer>
    {({ submitted }) =>
      <FormActions>
        <Base
          value='Create License'
          disabled={submitted}
          {...props}
        />
      </FormActions>
    }
  </FormContext.Consumer>

export default FormSubmit
