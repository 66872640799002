import React from 'react';
import ApiMetricsOptions from './ApiMetricsOptions'
import MetricsCard from './common/MetricsCard'

const ApiMetricsChart = (props) => 
  <MetricsCard 
    {...{
      ...props, 
      OptionComponent: ApiMetricsOptions, 
      chart: {
        url: '/internal/dashboard_charts/api_calls.json', 
        label: 'API calls'
      }
    }} 
  />

export default React.memo(ApiMetricsChart)
