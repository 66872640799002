import React, { Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import WizardForm from '../WizardForm'
import WizardButtons from '../WizardButtons'

class Counts extends WizardForm {
  state = {
    ...this.props.wizardState
  }

  componentDidMount = () => {
    if (this.props.wizardState.trial) {
      this.skipForm()
    }
  }

  onCpuCountChange = (e) => {
    this.setState({
      cpuCount: e.target.value
    })
  }

  onMaxAdcChange = (e) => {
    this.setState({
      maxAdc: e.target.value
    })
  }

  onMaxDevicesChange = (e) => {
    this.setState({
      maxDevices: e.target.value
    })
  }

  onMaxTpsChange = (e) => {
    this.setState({
      maxTps: e.target.value
    })
  }

  onUnlimitedChange = (e) => {
    this.setState({
      unlimitedDevices: e.target.checked
    })
  }

  getWizardState = () => {
    return {
      cpuCount: this.state.cpuCount,
      maxAdc: this.state.maxAdc,
      maxDevices: this.state.maxDevices,
      maxTps: this.state.maxTps,
      unlimitedDevices: this.state.unlimitedDevices
    }
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <label className='control-label'>CPU Count</label>
        <input type='number'
          className='form-control'
          min={0}
          value={this.state.cpuCount}
          onChange={this.onCpuCountChange} />
      </div>
      <div className='py1'>
        <label className='control-label'>Maximum ADC Objects</label>
        <input type='number'
          className='form-control'
          min={0}
          value={this.state.maxAdc}
          onChange={this.onMaxAdcChange} />
      </div>
      <div className='py1'>
        <label className='control-label'>Maximum TPS Objects</label>
        <input type='number'
          className='form-control'
          min={0}
          value={this.state.maxTps}
          onChange={this.onMaxTpsChange} />
      </div>
      <div className='py1'>
        <Form.Check
          type='checkbox'
          id='sku_unlimited_devices_checkbox'
          checked={this.state.unlimitedDevices}
          onChange={this.onUnlimitedChange}
          label='&nbsp;Unlimited Devices'
        />
      </div>
      <div className='py1' disabled={this.state.unlimitedDevices}>
        <label className='control-label'>Maximum Devices</label>
        <input type='number'
          className='form-control'
          min={0}
          value={this.state.maxDevices}
          onChange={this.onMaxDevicesChange} />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled
      />
    </Fragment>
  )
}

export default Counts
