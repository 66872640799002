import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _set from 'lodash/set'
import log from 'loglevel';

import { FormContext } from './Form'
import Box from '../components/Box'
import { FormInput } from '../components/Form'

export const FormField = ({ permitted, ...props }) => {
  const {
    attributes = {}, // PermittedAttributes from ApiSerializer
    data = {}, // Values hash set while using the form
    errors = {}, // Errors hash tracked while using the form
    handleChange,
    handleUnmount,
    initialValue,
    inputProps: backendProps = {}
  } = useContext(FormContext)

  const [haveSetDefaultValue, setHaveSetDefaultValue] = useState(false)
  const { name, getChange } = props
  const inputErrors = _get(errors, name)
  const {
    value: adjustedValue, // Value from NewLicenseSerializer
    initialValue: initValue, // Initial Value from NewLicenseSerializer
    ...inputProps
  } = _get(backendProps, name) || {}
  const permitValue = permitted || !Object.keys(attributes).length || _has(attributes, name)

  if (!permitValue && !haveSetDefaultValue) {
    log.debug(`Field not shown: '${name}' because not in attributes, check your policies if you expect it`)
  }

  let attributesValue = _get(attributes, name)
  const dataValue = _get(data, name)
  const isNew = !_has(attributes, "id")

  const calculateValue = (useDataValue) => {
    if (useDataValue) {
      return dataValue
    } else if (isNew) {
      if (initValue) {
        return initValue
      }
      if (initialValue) {
        return initialValue
      }
      return attributesValue
    } else {
      if (adjustedValue) {
        return adjustedValue
      }
      return attributesValue
    }
  }

  // set an InitialValue, and unset any value/errors if the component unmounts
  useEffect(() => {
    setHaveSetDefaultValue(true)
    if (!permitValue) {
      return
    }

    const val = calculateValue(false)
    // handler to set the data hash for form submittal
    handleChange({
      name,
      value: val,
      errors: inputErrors
    })

    // Custom on change passed in from parent component
    if (getChange) {
      getChange({
        name,
        value: val,
        errors: inputErrors
      })
    }

    return () => {
      handleUnmount(name)
    }
  }, [])

  // Intermediate handler to call our own `getChange` handler and then the original `handleChange` to update the form data collection
  const intermediateHandleChange = (e = {}) => {
    e && e.target && e.persist()
    const {
      name,
      value,
      checked,
      errors,
      type
    } = e.target ? e.target : e
    const newValue = type === 'checkbox' && checked === false ? false : checked || value

    handleChange(e)

    if (getChange) {
      getChange({
        name,
        value: newValue,
        errors: inputErrors
      })
    }
  }

  return (
    !!permitValue &&
     <Box
       as={FormInput}
       value={calculateValue(haveSetDefaultValue)}
       onChange={intermediateHandleChange}
       errors={inputErrors}
       {...inputProps}
       {...props}
     />
  )
}

export default FormField
