import React, { Component } from 'react'

export default class SubscriptionCustomBandwidth extends Component {
  constructor (props) {
    super(props)
    const fieldName = this.props.is_renewal ? 'renewal[new_bandwidth]' : 'license[bandwidth]'
    let bandwidth = this.props.bandwidth
    let limit = this.props.limit
    let unit = 'Tbps'
    let multiplier = 1000000
    let hideUnits = false
    let defaultMin = 1

    if (!bandwidth) {
      bandwidth = 1
    } else {
      if (bandwidth > 999999) {
        bandwidth = bandwidth / 1000000
      } else if (bandwidth > 999) {
        unit = 'Gbps'
        bandwidth = bandwidth / 1000
        multiplier = 1000
      } else {
        unit = 'Mbps'
        defaultMin = 25
        multiplier = 1
      }
    }

    if (limit > 999999 && unit === 'Tbps') {
      limit = limit / 1000000
    } else if (unit === 'Gbps') {
      limit = limit < 1000000 ? (limit / 1000) : 999
    } else if (unit === 'Mbps') {
      limit = 999
    }

    this.state = {
      fieldName,
      unit,
      limit,
      multiplier,
      hideUnits,
      defaultMin,
      value: bandwidth
    }

    this.changeValue = this.changeValue.bind(this)
    this.customValue = this.customValue.bind(this)
    this.changeUnit = this.changeUnit.bind(this)
  }

  changeValue (e) {
    this.setState({
      value: e.currentTarget.value,
      bandwidth_in_mbps: e.currentTarget.value
    }, function () {
      this.setBandwidth()
    })
  }

  customValue (e) {
    if (e.currentTarget.value > this.state.limit) {
      e.currentTarget.value = this.state.limit
    } else if (e.currentTarget.value < 0 || e.currentTarget.value === 0) {
      e.currentTarget.value = 1
    }

    this.setState({ value: e.currentTarget.value },
      function () {
        this.setBandwidth() 
      })
  }

  changeUnit (e) {
    if (e.currentTarget.value === 'Tbps') {
      this.setState({
        unit: 'Tbps',
        multiplier: 1000000,
        defaultMin: 1,
        limit: this.props.limit / 1000000,
        value: 1
      }, function () {
        this.setBandwidth() 
      })
    } else if (e.currentTarget.value === 'Gbps') {
      this.setState({
        unit: 'Gbps',
        multiplier: 1000,
        defaultMin: 1,
        limit: this.props.limit < 1000000 ? (this.props.limit / 1000) : 999,
        value: 1
      }, function () {
        this.setBandwidth() 
      })
    } else {
      this.setState({
        unit: 'Mbps',
        defaultMin: 25,
        multiplier: 1,
        limit: 999,
        value: 25
      }, function () {
        this.setBandwidth() 
      })
    }
  }

  setBandwidth () {
    var selectedBandwidth = this.state.value * this.state.multiplier
    var settings = this.bandwidthSettings(selectedBandwidth)
    if (selectedBandwidth <= 10000) {
      $('#license_minimum_allocated_bandwidth').val(settings.small.min)
      $('#license_default_allocated_bandwidth').val(settings.small.def)
      $('#license_maximum_allocated_bandwidth').val(settings.small.max)
    } else if (selectedBandwidth <= 50000) {
      $('#license_minimum_allocated_bandwidth').val(settings.medium.min)
      $('#license_default_allocated_bandwidth').val(settings.medium.def)
      $('#license_maximum_allocated_bandwidth').val(settings.medium.max)
    } else {
      $('#license_minimum_allocated_bandwidth').val(settings.large.min)
      $('#license_default_allocated_bandwidth').val(settings.large.def)
      $('#license_maximum_allocated_bandwidth').val(settings.large.max)
    }
    if (this.props.onBandwidthChange) {
      this.props.onBandwidthChange({
        selectedBandwidth: selectedBandwidth,
        displayBandwidth: this.state.value,
        unit: this.state.unit
      })
    }
  }

  bandwidthSettings (selectedBandwidth) {
    var settings = {
      small: {
        min: 25,
        def: 25,
        max: selectedBandwidth
      },
      medium: {
        min: 100,
        def: 500,
        max: 10000
      },
      large: {
        min: 200,
        def: 1000,
        max: 10000
      }
    }
    return settings
  }

  render () {
    const { defaultMin, limit, value, unit, hideUnits, fieldName, multiplier } = this.state
    return (
      <div>
        <label className='control-label' > Bandwidth </label>
        <div className='form-group' >
          <input type='range'
            name='bandwidth-range'
            min={defaultMin}
            max={limit}
            value={value}
            onChange={this.changeValue} />
        </div>
        <div className='form-group col-md-12' >
          <div className='col-md-4' >
            <input type='number'
              className='form-control'
              name='bandwidth_in_mbps'
              min={defaultMin}
              value={value}
              onChange={this.customValue} />
          </div>
          <div className='col-md-2' >
            <output htmlFor='bandwidth-range'>
              {value}{' ' + unit}
            </output>
          </div>
          <div className='col-md-2' style={{ paddingTop: '8px' }} >
            <label className='control-label unit' hidden={hideUnits}>
              <input type='radio' name='unit' value='Tbps' onClick={this.changeUnit} id='Tbps' checked={unit === 'Tbps'} onChange={(e) => this.setState({ selected: e.target.value })} />
              Tbps
            </label>
          </div>
          <div className='col-md-2' style={{ paddingTop: '8px' }} >
            <label className='control-label unit' hidden={hideUnits}>
              <input type='radio' name='unit' value='Gbps' onClick={this.changeUnit} id='Gbps' checked={unit === 'Gbps'} onChange={(e) => this.setState({ selected: e.target.value })} />
              Gbps
            </label>
          </div>
          <div className='col-md-2' style={{ paddingTop: '8px' }} hidden={hideUnits}>
            <label className='unit'>
              <input type='radio' name='unit' value='Mbps' onClick={this.changeUnit} id='Mbps' checked={unit === 'Mbps'} onChange={(e) => this.setState({ selected: e.target.value })} />
              Mbps
            </label>
          </div>
          <input type='number'
            value={value * multiplier}
            name={fieldName}
            onChange={(e) => this.setState({ })}
            hidden />
        </div>
      </div>
    )
  }
}
