import React, { Fragment } from 'react'
import WizardForm from '../WizardForm'
import WizardButtons from '../WizardButtons'
import Dropdown from '../Dropdown'

class FamilyAndProduct extends WizardForm {
  state = {
    ...this.props.wizardState,
    products: [],
    platforms: [],
    families: [],
    nextEnabled: false
  }

  componentDidMount = () => {
    this.loadFamilies()
  }

  loadFamilies = async () => {
    const response = await fetch('/api/skus/families')
    if (response.ok) {
      const json = await response.json()
      this.setState({
        families: json.families,
        selectedFamily: this.state.selectedFamily || json.families[0][1]
      },
      this.loadPlatforms
      )
    }
  }

  loadPlatforms = async () => {
    const response = await fetch(`/api/skus/platforms?family=${this.state.selectedFamily}`)
    if (response.ok) {
      const json = await response.json()
      const selected = json.platforms.includes(this.state.selectedPlatform) ? this.state.selectedPlatform : json.platforms[0][1]
      this.setState({
        platforms: json.platforms,
        selectedPlatform: selected
      },
      this.loadProducts
      )
    }
  }

  loadProducts = async () => {
    const response = await fetch(`/api/skus/products?family=${this.state.selectedFamily}&platform=${this.state.selectedPlatform}`)
    if (response.ok) {
      const json = await response.json()
      const selected = json.products.includes(this.state.selectedProduct) ? this.state.selectedProduct : json.products[0][1]
      this.setState({
        products: json.products,
        selectedProduct: selected
      },
      this.checkNextAvailable
      )
    }
  }

  onFamilyUpdated = (e) => {
    this.setState({
      selectedFamily: e.target.value
    },
    this.loadPlatforms,
    this.checkNextAvailable
    )
  }

  onProductUpdated = (e) => {
    this.setState({
      selectedProduct: e.target.value
    },
    this.checkNextAvailable
    )
  }

  onPlatformUpdated = (e) => {
    this.setState({
      selectedPlatform: e.target.value
    },
    this.loadProducts,
    this.checkNextAvailable
    )
  }

  checkNextAvailable = () => {
    this.setState({
      nextEnabled: this.state.selectedProduct.length &&
                   this.state.selectedPlatform.length &&
                   this.state.selectedProduct.length
    })
  }

  getWizardState = () => {
    return {
      selectedFamily: this.state.selectedFamily,
      selectedFamilyText: this.state.families.find(a => a[1] === this.state.selectedFamily)[0],
      selectedProduct: this.state.selectedProduct,
      selectedProductText: this.state.products.find(a => a[1] === this.state.selectedProduct)[0],
      selectedPlatform: this.state.selectedPlatform,
      selectedPlatformText: this.state.platforms.find(a => a[1] === this.state.selectedPlatform)[0],
      isFlexpool: ['capacity_pool', 'instance_pool', 'harmony'].includes(this.state.selectedPlatform)
    }
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <label className='control-label'>Family</label>
        <Dropdown options={this.state.families} value={this.state.selectedFamily} onChange={this.onFamilyUpdated} />
      </div>
      <div className='py1'>
        <label className='control-label'>Platform</label>
        <Dropdown options={this.state.platforms} value={this.state.selectedPlatform} onChange={this.onPlatformUpdated} />
      </div>
      <div className='py1'>
        <label className='control-label'>Product</label>
        <Dropdown options={this.state.products} value={this.state.selectedProduct} onChange={this.onProductUpdated} />
      </div>
      <WizardButtons
        previousHidden
        onNextClick={this.onNext}
        nextEnabled={this.state.nextEnabled}
      />
    </Fragment>
  )
}

export default FamilyAndProduct
