import React, { Component } from 'react'
import Spinner from './components/Spinner'

export default class PasswordField extends Component {
  constructor (props) {
    super(props)
    this.getFirstValue = this.getFirstValue.bind(this)
    this.handleSubmission = this.handleSubmission.bind(this)
    this.getSecondValue = this.getSecondValue.bind(this)
    this.getCurrentValue = this.getCurrentValue.bind(this)
    let title = this.props.user_creation ? 'Create Account' : 'Change Password'
    if (props.buttonLabel) {
      title = props.buttonLabel
    }

    this.state = {
      initialValue: '',
      secondValue: '',
      currentValue: '',
      hideErrorMessage: true,
      title: title,
      errorMessage: '',
      score: 0,
      resourceLoading: true
    }
  }

  getFirstValue (event) {
    this.setState({
      initialValue: event.target.value
    })
    this.testPassword(event.target.value)
  }

  getSecondValue (event) {
    this.setState({
      secondValue: event.target.value
    })
  }

  getCurrentValue (event) {
    this.setState({
      currentValue: event.target.value
    })
  }

  handleSubmission (event) {
    const { hideConfirmation } = this.props
    const { initialValue, secondValue } = this.state
    if (initialValue !== secondValue && !hideConfirmation) {
      event.preventDefault()
      this.setState({
        hideErrorMessage: false,
        errorMessage: 'Passwords must be the same.'
      })
    } else if (this.state.initialValue.length < 8) {
      event.preventDefault()
      this.setState({
        hideErrorMessage: false,
        errorMessage: 'Password must be a minimum of 8 characters.'
      })
    } else if (this.state.score < 3) {
      event.preventDefault()
      this.setState({
        hideErrorMessage: false,
        errorMessage: "Password must rate 'Good' or better"
      })
    } else if (event.target.value !== '' && (this.state.initialValue === this.state.secondValue && !hideConfirmation)) {
      this.setState({
        hideErrorMessage: true
      })
    }
  }
  async componentDidMount() {
    const zxcvbn = (await import("zxcvbn")).default
    this.setState({resourceLoading: false, zxcvbn})
  }

  testPassword = (password) => {
    const result = this.state.zxcvbn(password)
    this.setStrength(result.score)
  }

  setStrength = (score) => {
    let message = 'Weak'
    switch (score) {
      case 2:
        message = 'Okay'
        break
      case 3:
        message = 'Good'
        break
      case 4:
        message = 'Strong'
        break
    }
    this.setState({
      strengthMessage: message,
      score: score
    })
  }

  render () {
    const { title, hideErrorMessage, errorMessage } = this.state
    if (this.state.resourceLoading) {
      return <><Spinner/><br/><br/></>
    } else {
      return (
        <div>
          {
            !this.props.hideCurrent && <div className='form-group password optional user_password'>
              <label className='control-label password optional'>Current Password</label>
              <input className='form-control password optional' type='password' name='user[current_password]' onChange={this.getCurrentValue} />
            </div>
          }
  
          <div className='form-group password optional user_password'>
            <label className='control-label password optional'>Password</label>
            <input className='form-control password optional' type='password' name='user[password]' onChange={this.getFirstValue} />
            <p className='help-block'>Must be at least 8 characters</p>
          </div>
  
          {
            !this.props.hideConfirmation && <div className='form-group password optional user_password'>
              <label className='control-label password optional'>Confirm Password</label>
              <input className='form-control password optional' type='password' name='confirm_password' onChange={this.getSecondValue} />
            </div>
          }
          <p className='text-danger' hidden={hideErrorMessage}>{errorMessage}</p>
  
          <p className='help-block'>Must rate 'Good' or better. Use letters, numbers and/or characters.</p>
          <progress max='4' value={this.state.score} />
          <p className='help-block'>Password Strength: {this.state.strengthMessage}</p>
  
          <div className='actions'>
            <input type='submit' value={title} onClick={this.handleSubmission} className='btn btn-primary' />
            { this.props.cancelPath &&
              <a className='btn btn-link' href={this.props.cancelPath}>Cancel</a>
            }
          </div>
        </div>
      )
    }
  }
}
