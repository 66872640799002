import React, { useState, useEffect } from 'react'
import { Button } from './../components/Button'
import { Select } from './../components/Select'
import ToggleCard from './../components/ToggleCard'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'
import intersectionWith from 'lodash/intersectionWith'
import difference from 'lodash/difference'
import HarmonyOneClickTrialButton from './HarmonyOneClickTrialButton'
import OneClickTrialWithTrialLead from './OneClickTrialWithTrialLead'

export const details = {
  agalaxy_tps_trial: [
    'aGalaxy for Thunder TPS enables you to gain a global view of your environments to identify and remediate DDoS attacks',
    'With a centralized management system, administrators can  analyze their A10 Thunder TPS deployments, view Distributed Denial of Service attacks in real time and see the number of current connections handled by individual appliances'
  ],
  cfw_cap_sub_trial: [
    'Capacity License Portability for Hybrid Cloud',
    'The FlexPool software subscription model gives you the flexibility to allocate and distribute secure application services whenever and where ever you need them – across virtual, bare metal on traditional, and hybrid cloud infrastructures.'
  ],
  cfw_inst_sub_trial: [
    'Instance License Portability for Hybrid Cloud',
    'The FlexPool software subscription model gives you the flexibility to allocate and distribute secure application services as you go on an instance basis.'
  ],
  cylance_trial: [
    'Cylance combines A10 Thunder SSLi and Thunder CFW security platforms, offering increased security for governments, gaming, healthcare providers, financial institutions and other markets facing critical security threats',
    'A10’s SSL Insight technology, coupled with optional CylancePROTECT security, will empower organizations to identify and mitigate both inbound and outbound attacks — even if they’re encrypted by SSL'
  ],
  qosmos_trial: [
    'A10 Networks distributes Qosmos licenses add the capability of Qosmos on your device',
    'Qosmos offers security solutions for network activity, accuracy of map traffic flows, and immediately identifies suspicious behaviour, raising the speed and effectiveness of your malware detection'
  ],
  trial: [
    'Software for Virtualized Infrastructure',
    'Gain on-demand virtual appliances with advanced services that run on your choice of industry-leading hypervisors for maximum speed and flexibility.'
  ],
  webroot_ti_trial: [
    'A10 Networks distributes Webroot Threat Intelligence Security licenses adding the capability of Webroot TI protection on your device',
    'Webroot Threat Intelligence protects your customers from malicious URLs, IPs, files, and mobile apps by integrating accurate and near real-time threat intelligence into your network and endpoint protection'
  ],
  webroot_trial: [
    'A10 Networks distributes Webroot licenses which add the capability of harnessing the cloud and artificial intelligence to protect you against cyber threats',
    'Webroot offers endpoint protection, network protection, and security awareness training solutions built for managed service providers and small businesses'
  ],
  harmony_trial: [
    'Agile App Management & Analytics',
    'Implement centralized management and analytics for secure application services across on-premises data centers and public, private and hybrid clouds.'
  ],
  lightning_trial: [
    'Cloud-Native Application Delivery & Security',
    'Optimize the delivery and security of cloud-native applications and services, and enable centralized management of workloads across public, private and hybrid clouds.'
  ],
  'hc-saas-mbu-trial': [
    'Available in Self-Managed (software) and SaaS',
    'Gain single-pane-of-glass management and analytics for A10 secure application services across on-premises data centers, and public, private, and hybrid cloud deployments.'
  ]
}

export const submitOneClick = async (org, sku, desc) => {
  try {
    const response = await fetch(`/licenses/create_one_click_trial.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        trial_lead: {
          trial_type: sku,
          org_id: org
        }
      })
    })
    const json = await response.json()
    if (response.ok) {
      window.location.href = `/licenses/${json['license']['id']}?success=true`
    } else {
      GLMToast(`You have reached your limit with ${desc}s. Please contact A10 Sales for a full license.`,
        {className: 'alert alert-warning'})
    }
  } catch (error) {
    GLMToast('An error occured.', {className: 'alert alert-error'})
  }
}

export const getSkus = async (org) => {
  try {
    const response = await fetch(`/licenses/one_click_skus?org_id=${org}`)
    const json = await response.json()
    if (response.ok) {
      return json.skus
    } else {
      GLMToast('An error occured.', {className: 'alert alert-error'})
    }
  } catch (error) {
    GLMToast('An error occured.', {className: 'alert alert-error'})
  }
}

const customSortedKeys = (skuKeys) => {
  const preferrableOrder = ['trial', 'cfw_cap_sub_trial', 'agalaxy_tps_trial',
    'qosmos_trial', 'webroot_trial', 'webroot_ti_trial']
  return [...intersectionWith(preferrableOrder, skuKeys), ...difference(skuKeys, preferrableOrder).sort()]
}

export const TrialDashboard = ({orgs}) => {
  const [skuList, setSkuList] = useState()
  const [orgId, setOrgId] = useState(orgs[0].value)
  const [arrayRows, setArrayRows] = useState({})
  var i = 0
  var j = 0

  const refreshView = (skus, orgid) => {
    const descriptions = customSortedKeys(Object.keys(skus))

    const rows = Math.ceil(descriptions.length / 3)
    let workingRows = {}
    while (i < rows) {
      let end = j + 3 > descriptions.size ? descriptions.size - 1 : j + 3
      workingRows[i] = descriptions.slice(j, end)
      i++
      j += 3
    }
    setSkuList(skus)
    setArrayRows(workingRows)
  }

  useEffect(() => {
    getSkus(orgId)
      .then((skus) => {
        refreshView(skus, orgId)
      })
  }, [orgId])

  return (
    <div>
      { !!(orgs.length > 1) &&
        <div className='row py1'>
          <div className='col-md-4' />
          <div className='col-md-4'>
            <Select
              className='form-control'
              children={orgs}
              sorted={false}
              withPlaceholder={false}
              onChange={async (e) => {
                setOrgId(e.target.value)
              }} />
          </div>
        </div>
      }
      {
        Object.keys(arrayRows).map(i => (
          <div className='row py1' key={i}>
            {
              arrayRows[i] && arrayRows[i].map((sku, j) => (
                <div className='col-md-4' key={j}>
                  <ToggleCard
                    defaultOpen
                    label={''}>
                    <h3><small>{skuList[sku].description.toUpperCase()}</small></h3>
                    {details[sku] && details[sku].map((paragraph, key) => (
                      <span
                        key={key}
                        style={{margin: '1em', float: 'left', textAlign: 'left'}}>
                        {paragraph}
                      </span>
                    ))}
                    <br />
                    <span>
                      {sku !== 'hc-saas-mbu-trial' &&
                        <OneClickTrialWithTrialLead
                          trialType={sku}
                          orgId={orgId}
                          description={skuList[sku].description}
                          canCreate={!!skuList[sku].canCreate}/>
                      }
                      {sku === 'hc-saas-mbu-trial' &&
                        <HarmonyOneClickTrialButton
                          orgId={orgId}
                          canCreate={!!skuList[sku].canCreate}
                        />
                      }
                    </span>
                  </ToggleCard>
                </div>
              ))}
          </div>
        ))}
      <GLMToastContainer />
    </div>
  )
}

export default TrialDashboard
