import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Spinner from '../Spinner'
import Paginate from '../../Paginate'
import Activity from './Activity'
import ProviderSummary from './ProviderSummary'
import TrialsSummary from './TrialsSummary'
import qs from 'query-string'
import 'isomorphic-fetch'
import omit from 'lodash/omit'
import WithHelpText from './WithHelpText'
import { formatDateTime } from './helpers'
import SearchField from './SearchField'
/* eslint-disable complexity */
const HarmonyMetrics = ({reportDate, currentPage, activityKeys, sortKey}) => {
  const [providers, setProviders] = useState([])
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [currentProviderId, setCurrentProviderId] = useState()
  const [error, setError] = useState()
  const [page, setPage] = useState(currentPage)
  const [sortVal, setSortVal] = useState(sortKey)
  const [showProviderSummary, setShowProviderSummary] = useState(false)

  const queryParams = qs.parse(window.location.search)

  const [searchTerm, setSearchTerm] = useState(queryParams['q[search_data_cont]'])

  const fetchURL = (type) => {
    const fetchParams = omit(queryParams, ['sort_by_activity_key', 'q[search_data_cont]'])
    const params = {page: page}
    if (sortVal) {
      params['sort_by_activity_key'] = sortVal
    }
    if (searchTerm) {
      params['q[search_data_cont]'] = searchTerm
    }
    return `/harmony_metrics${type}?${qs.stringify({...fetchParams, ...params})}`
  }

  const loadProviders = async () => {
    setLoading(true)

    const response = await fetch(fetchURL('.json')).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setProviders(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  const setProviderForActivities = (event, id) => {
    event.preventDefault()
    setCurrentProviderId(id)
  }
  useEffect(() => {
    setPage(1)
  }, [sortVal, searchTerm])

  useEffect(() => {
    if (window.history.pushState) {
      window.history.pushState(null, '', fetchURL(''))
    }
    loadProviders()
  }, [page, sortVal, searchTerm])

  const renderSortSelectBox = () => {
    return (
      <Form.Group>
        <Form.Label>Sort by Activity</Form.Label>
        <Form.Control as='select' defaultValue={sortVal} onChange={(e) => setSortVal(e.target.value)}>
          <option value=''>None</option>
          {activityKeys.map((key, i) =>
            <option key={i} value={key}>
              {key}
            </option>
          )}
        </Form.Control>
      </Form.Group>
    )
  }

  const renderValueOrNA = (value) => {
    if (value === null) {
      return 'N/A'
    } else {
      return value
    }
  }

  const renderProviders = () => {
    return (
      <table className='table small-table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>UUID</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Organization</th>
            <th>Log Rate Capacity</th>
            <th>Avg Log Rate</th>
            <th>Type</th>
            {!sortVal && <th><WithHelpText infoKey='devices'> Number of devices </WithHelpText></th> }
            <th>License State</th>
            <th>Last Contacted at</th>
            {sortVal && <th>{sortVal}</th>}
            <th />
          </tr>
        </thead>
        <tbody>
          {providers.map((provider, i) =>
            <tr key={i}>
              <td>
                {provider.name}
                {!provider.trial_lead_id &&
                <span className='text-danger'><WithHelpText infoKey='not_in_glm' /></span>
                }
              </td>
              <td>{provider.provider_uuid}</td>
              <td>{provider.contact}</td>
              <td>{provider.email}</td>
              <td>{provider.organization_name}</td>
              <td>{renderValueOrNA(provider.log_rate_capacity)}</td>
              <td>{renderValueOrNA(provider.average_log_rate)}</td>
              <td>{renderValueOrNA(provider.provider_type)}</td>
              {!sortVal && <td>{renderValueOrNA(provider.device_count)}</td> }
              <td>
                {!provider.license_state.expired_at && <span>{provider.license_state.title}</span>}
                {
                  provider.license_state.expired_at &&
                  <div>
                    <b />Expired at: {formatDateTime(provider.license_state.expired_at)}
                  </div>
                }
              </td>
              <td>{formatDateTime(provider.last_active_at)}</td>
              {sortVal && <th>{provider.activity_count}</th>}
              <td>
                <small>
                  <WithHelpText infoKey='view_activities_btn' hideHelp={i !== 0}>
                    <a href='#' className='btn btn-default btn-round' onClick={(e) => {
                      setProviderForActivities(e, provider.id)
                    }}>
                      <i className='fa fa-eye' />
                    </a>
                  </WithHelpText>
                </small>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      <Activity providerId={currentProviderId} onClose={setCurrentProviderId} />
      <h4>Harmony Controller Metrics</h4>
      <h5 className='text-muted'>Report Updated At: {formatDateTime(reportDate)}</h5>
      <TrialsSummary />
      <hr />
      <div className='row'>
        <div className='col-md-6'>
          <h4><WithHelpText infoKey='active_providers_head'>Active Providers</WithHelpText></h4>
        </div>
        <div className='col-md-6 text-right'>
          <WithHelpText infoKey='show_provider_summary_btn'>
            <button className='btn btn-primary' onClick={() => setShowProviderSummary(true)}>Show Provider Summary</button>
          </WithHelpText>
          {showProviderSummary && <ProviderSummary onClose={() => setShowProviderSummary(false)} /> }
        </div>
      </div>
      <div className='row'>
        {activityKeys && activityKeys.length > 0 && <div className='col-md-4'>{renderSortSelectBox()}</div>}
        <div className='col-md-4'>
          <SearchField initVal={searchTerm} onSearch={(v) => setSearchTerm(v)} infoKey='search_providers_form' />
        </div>
      </div>
      {loading && <Spinner />}
      {error && <div className='alert alert-danger'> {error} </div>}
      {providers.length === 0 && !loading && !error && <div className='alert alert-danger'> No Data available </div>}
      {providers.length > 0 && renderProviders()}
      { paginationData && paginationData.total_pages > 1 &&
      <Paginate
        pages={paginationData.total_pages}
        currentPage={paginationData.current_page}
        onPageNext={() => setPage(page + 1)}
        onPageBack={() => setPage(page - 1)}
        toPage={(pageNum) => setPage(pageNum)} />
      }
      {loading && paginationData && paginationData.total_pages > 1 && <Spinner />}
    </div>
  )
}
/* eslint-enable complexity */

export default HarmonyMetrics
