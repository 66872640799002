import React, { Component } from 'react'
import ReactTooltip from "react-tooltip"
import UserFilter from '../UsersWithAccess/UserFilter'
import Spinner from '../Spinner'
import Button from '../components/Button'
import Link from '../components/Link'
import Truncate from '../components/Truncate'
import InfiniteScrollExtended from '../components/InfiniteScrollExtended'

const loadOrgUsers = async (orgId, page, query) => {
  return fetch(`/users/organization_users.json?organization_id=${orgId}&page=${page}&query=${encodeURIComponent(query)}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  })
}

const deleteUser = async id => {
  return fetch(`/user_organizations/${id}.json`, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application.json',
      'Content-Type': 'application/json'
    }
  })
}

class Users extends Component {
  orgRef = React.createRef();
  constructor (props) {
    super(props)
    const users = props.users || []

    this.state = {
      users,
      query: '',
      showError: false,
      errorMessage: '',
      moreOrgUsers: true,
      startPage: 1,
      initialLoad: false
    }
  }

  componentDidMount () {
    this.loadOrgUsers(1, true)
  }

  componentDidUpdate () {
    $('.org-users-cont [data-toggle]').tooltip({container: 'body'})
  }

  dismissError = () => {
    this.setState({ showError: false })
  }

  loadOrgUsers = async (page, scrollToTop) => {
    const response = await loadOrgUsers(this.props.orgId, page, this.state.query)
    const json = await response.json()
    const { data, pages } = json
    let users = []
    if (data.length > 0) {
      users = data || []
      if (page > 1) {
        users = [...this.state.users, ...users]
      }
    }


    this.setState({moreOrgUsers: page < pages})

    if (page <= pages) {
      this.setState({users})
    }

    if (page === 1) {
      if (scrollToTop) {
        this.orgRef.current.scrollTop = 0
      } else {
        this.orgRef.current.scrollTop = this.orgRef.current.scrollTop - 10
      }
    }
  }

  onFilterChange = (values) => {
    this.setState({moreOrgUsers: false, startPage: this.state.startPage - 1, query: values.user_filter}, () => {
      this.loadOrgUsers(1, true)
    })
  }

  removeUser = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      const response = await deleteUser(id).then((response) => {
        window.location.reload(false)
      })
    }
  }

  tooltipProps = (title) =>{
    return {
      'data-toggle': 'tooltip',
      'data-placement': 'top',
      'title': title
    }
  }

  render () {
    const { users, showError, errorMessage, startPage, initialLoad, moreOrgUsers, query } = this.state
    const { orgId } = this.props
    return (
      <div className='org-users-cont'>
        { users ? (<div>
          <div className='row mb-5'>
            <div className='col-md-5'>
              <br />
              <br />
              <h1 className='h3'>Users</h1>
            </div>
            <div className='col-md-7'>
              <div className='row'>
                { !!this.props.canAddUser && <Button
                  href={`/organizations/${this.props.orgId}/add_user`}
                  variant='primary'
                  className='pull-right mb2'><i className='fa fa-plus' style={{paddingTop: '0.2em'}}/> User</Button>
                }
              </div>
              <div className='row'>
                <UserFilter handleFilterChange={this.onFilterChange} />
              </div>
            </div>
          </div>
          { !!showError &&
              <div
                className='alert alert-danger alert-dismissable'
                role='alert'>
                <div
                  className='close'
                  datadismiss='alert'
                  arialabel='close'
                  onClick={this.dismissError}>
                  <span ariahidden='true'>&times;</span>
                </div>
                {errorMessage}
              </div>
          }
          <div className='row'>
            <div className='col-md-12'>
              <div className='auto-scrollable' ref={this.orgRef}>
                <InfiniteScrollExtended
                  pageStart={startPage}
                  initialLoad={initialLoad}
                  loadMore={this.loadOrgUsers}
                  hasMore={moreOrgUsers}
                  loader={<div className='col-sm-12 px0 text-center' key='org_loading'><Spinner className='align-bottom' /></div>}
                  useWindow={false}>
                  <ul className='slats'>
                    {users.map((user, index) =>
                      <li id={user.id} key={index} className='slat row'>
                        <div className='col-sm-3 user-name text-primary'>
                          <span>
                            <span class="fa-stack">
                              <i class="fa fa-circle fa-stack-2x"></i>
                              <i class="fa fa-user-o fa-stack-1x fa-inverse"/>
                            </span>
                            <Link className='h5 ml1' to={`/users/${user.id}`} children={user.name} />
                          </span>
                        </div>
                        <div className='col-sm-3 user-details'>
                          <a href={`/users/${user.id}`}
                            data-tip data-for={`${user.id}EmailToolTip`}
                            className='ellipsis' 
                            style={{display: 'inline-block', maxWidth: '100%'}}>
                            {user.email}
                          </a>
                          <ReactTooltip id={`${user.id}EmailToolTip`} place="top" data-html="true" effect="solid">
                            {user.email}
                          </ReactTooltip>
                          <br />
                          <small><i>Last seen {user.userActiveTime}</i></small>
                        </div>
                        <div className='col-sm-1'>
                          <div data-tip data-for={`${user.role}ToolTip`}>
                            {user.role}
                          </div>
                          <ReactTooltip id="OwnerToolTip" place="left" data-html="true" effect="solid">
                            <h5>Owner Abilities</h5>
                            <ul>
                              <li><strong>Overview</strong> - View organization summary</li>
                              <li><strong>Billing Info</strong> – View/Update customer information including SFCID</li>
                              <li><strong>Edit Organization</strong> - Edit basic organization details</li>
                              <li><strong>Users</strong> - Add/Edit/View/Delete Users on the organization</li>
                              <li><strong>Licenses</strong> - View/Edit all licenses on the organization, add new trials</li>
                              <li><strong>ELMs</strong> - View/Activate new ELMs</li>
                              <li><strong>Import ELM Usage</strong> - View uploaded ELM Activity</li>
                            </ul>
                          </ReactTooltip>
                          <ReactTooltip id="AdminToolTip" place="left" data-html="true" effect="solid">
                            <h5>Admin Abilities</h5>
                            <ul>
                              <li><strong>Overview</strong> - View organization overview</li>
                              <li><strong>Users</strong> - View organization user list with email name and role, and add new members</li>
                              <li><strong>Licenses</strong> - View/Edit all licenses on the organization, add new trials</li>
                              <li><strong>ELMs</strong> - View/Activate new ELMs</li>
                              <li><strong>Import ELM Usage</strong> - User can upload ELM usage</li>
                              <li><strong>Activity</strong> - View uploaded ELM Activity</li>
                            </ul>
                          </ReactTooltip>
                          <ReactTooltip id="MemberToolTip" place="left" data-html="true" effect="solid">
                            <h5>Member Abilities</h5>
                            <ul>
                              <li><strong>Overview</strong> - View organization overview</li>
                              <li><strong>Users</strong> - View organization user list with email name and role, but cannot modify it</li>
                              <li><strong>Licenses</strong> - Modify licenses they are authorized on, add new trials</li>
                              <li><strong>ELM</strong> - See ELM and Import ELM Usage sections, but can't add new ELM activations</li>
                              <li><strong>Import ELM Usage</strong> - User can upload ELM usage</li>
                            </ul>
                          </ReactTooltip>
                        </div>
                        <div className='col-sm-2'>
                          {
                            !!user.unconfirmed && <div>
                              <span><i className='fa fa-exclamation-circle mr1' style={{color: '#E67E22'}} /></span>
                              <span>
                                Unconfirmed Email:<br />
                                <Truncate children={user.email} maxLength={3} />
                              </span>
                            </div>
                          }
                        </div>
                        <div className='col-sm-3'>
                          <div className='btn-group pull-right actions btn-group-a10'>
                            {
                              !!user.authorizations && <Button href={`/users/${user.id}/authorizations`} 
                                {...this.tooltipProps('View Authorizations')} className='btn-round'>
                                <i className='fa fa-unlock' />
                              </Button>
                            }
                            {
                              !!user.audits && <Button href={`/users/${user.id}/audits`} className='btn-round'
                                {...this.tooltipProps('View History')}>
                                <i className='fa fa-history' />
                              </Button>
                            }
                            {
                              (!!user.canEditSales || !!user.canEditLimited) && <Button  className='btn-round'
                                href={user.canEditSales ? `/sales/users/${user.id}/edit?organization_id=${orgId}` : `/users/${user.id}/edit?organization_id=${orgId}`}
                                {...this.tooltipProps('Edit User')}>
                                <i className='fa fa-edit' />
                              </Button>
                            }
                            {
                              !!user.canDestroy && <Button onClick={() => this.removeUser(user.userOrgId)}
                                {...this.tooltipProps('Remove User')} className='btn-round'>
                                <i className='fa fa-times' />
                              </Button>
                            }
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                  {!users.length && !!query.length &&
                      <div>
                        <i>No organization users match '{query}'</i>
                      </div>
                  }
                </InfiniteScrollExtended>
              </div>
            </div>
          </div>
        </div>) : (
          <h1>This Organization does not have any users yet.</h1>
        )
        }
      </div>
    )
  }
}

export default Users
