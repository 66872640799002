import React, { Component } from 'react'
import Alert from './components/Alert'

export function wizard (...args) {
  let CurrentPage = args[0]

  return class extends Component {
    state = {
      errorMessage: '',
      index: 0,
      maxIndex: args.length - 1,
      wizardState: {...this.props.wizardState}
    }

    handleError = error => {
      this.setState({
        errorMessage: error
      })
    }

    setWizardState = (wizardState) => {
      this.setState({
        wizardState: {...this.state.wizardState, ...wizardState}
      })
    }

    onNext = (wizardState) => {
      if (this.state.index < this.state.maxIndex) {
        CurrentPage = args[this.state.index + 1]
        this.setState({
          lastIndex: this.state.index,
          index: this.state.index + 1,
          errorMessage: '',
          wizardState: {...this.state.wizardState, ...wizardState}
        })
      }
    }

    onPrevious = (wizardState) => {
      if (this.state.index > 0) {
        CurrentPage = args[this.state.index - 1]
        this.setState({
          lastIndex: this.state.index,
          index: this.state.index - 1,
          errorMessage: '',
          wizardState: {...this.state.wizardState, ...wizardState}
        })
      }
    }

    render () {
      return (
        <div>
          {!!this.state.wizardState.wizardTitle.length > 0 &&
            <h3>{this.state.wizardState.wizardTitle}</h3>
          }
          {!!this.state.errorMessage &&
            <Alert>
              {this.state.errorMessage}
            </Alert>
          }
          <CurrentPage
            handleError={this.handleError}
            onNext={this.onNext}
            onPrevious={this.onPrevious}
            setWizardState={this.setWizardState}
            wizardState={this.state.wizardState}
            currentIndex={this.state.index}
            lastIndex={this.state.lastIndex} />
        </div>
      )
    }
  }
}
