import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from 'react-tooltip'
import MetricsSettings from '../../../data/MetricsSettings'
import Button from '../../components/Button'
import Radio from '../../components/Radio'
import LicenseMetricsChart from './LicenseMetricsChart'
import ApiMetricsChart from './ApiMetricsChart'
import UsersCreatedMetricsChart from './UsersCreatedMetricsChart'
import VisitsChart from './VisitsChart'
import ChartGrid from '../ChartGrid'
import CatalogContext from './common/CatalogContext'
import MetricsSkuSelect from './common/MetricsSkuSelect'
import CheckinsMetricsChart from './CheckinsMetricsChart'

const MetricsDashboard = ({catalog}) => {
  const [dndObjs, setDndObjs] = useState()
  const [layout, setLayout] = useState(MetricsSettings.getLayout())
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState('')
  const [initialSettings, setInitialSettings] = useState({})
  const [sku, setSku] = useState({})
  const [activeId, setActiveId] = useState(null)
  const toggle = () => setIsOpen(!isOpen)

  const handleUpdateSettings = (newSettings) => {
    const newLayout = MetricsSettings.addLayout(newSettings)
    setLayout(newLayout)
  }

  const setLabel = (label) => {
    setInitialSettings({...initialSettings, label})
  }

  const loadDndResources = async () => {
    const {
      DndContext,
      closestCenter,
      DragOverlay
    } = await import('@dnd-kit/core')

    const {
      arrayMove,
      SortableContext,
      verticalListSortingStrategy
    } = await import('@dnd-kit/sortable')

    setDndObjs({
      DndContext, closestCenter, DragOverlay, arrayMove, SortableContext, verticalListSortingStrategy
    })
  }

  useEffect(() => {
    if (sku) {
      const sku_platform = sku.platform
      const sku_product = sku.product
      const sku_name = sku.name
      setInitialSettings({ ...initialSettings, sku_platform, sku_product, sku_name })
    }
    loadDndResources()
  }, [sku])

  const getChart = (settings) => {
    const chartProps = {
      settings,
      id: settings.hash,
      key: settings.hash,
      onChartUpdated: handleUpdateSettings
    }
    switch (settings.module) {
      case 'license':
        return <LicenseMetricsChart {...chartProps} />
      case 'users_created':
        return <UsersCreatedMetricsChart {...chartProps} />
      case 'appliance_checkins':
        return <CheckinsMetricsChart {...chartProps} />
      case 'apis':
        return <ApiMetricsChart {...chartProps} />
      case 'visits':
        return <VisitsChart {...chartProps} />
      default:
        return null
    }
  }

  const handleAddModule = (module) => {
    const {label} = initialSettings
    let params = {label, hash: '', module}
    if (module === 'license') {
      // For license module, merge all setttings for SKU information
      params = {...params, ...initialSettings}
    }
    const newLayout = MetricsSettings.addLayout(params)
    setLayout(newLayout)
  }

  const handleAddChart = () => {
    toggle()
    handleAddModule(selected)
  }

  const handleNewChart = () => {
    setInitialSettings({})
    setSku({})
    setSelected('')
    toggle()
  }

  const handleDragStart = (event) => {
    setActiveId(event.active.id)
  }

  const handleDragCancel = () => {
    setActiveId(null)
  }

  const arrayFindObjectByProp = (arr, prop, val) => {
    return arr.find(obj => obj[prop] === val)
  }

  const handleDragEnd = (event) => {
    const {active, over} = event
    setLayout((layout) => {
      const oldIndex = layout.indexOf(arrayFindObjectByProp(layout, 'hash', active.id))
      const newIndex = layout.indexOf(arrayFindObjectByProp(layout, 'hash', over.id))
      const newArray = dndObjs.arrayMove(layout, oldIndex, newIndex)
      MetricsSettings.setLayout(newArray)
      return newArray
    })
  }

  return (
    <CatalogContext.Provider value={catalog}>
      <Container fluid>
        <Row className='mb2'>
          <Col lg={12}>
            <Button variant='primary'
              id='add_button'
              key='add_button'
              data-tip
              data-for='add_button'
              onClick={handleNewChart}
              classNames={['pull-right btn-sm']}>
              <i className='fa fa-plus' />
            </Button>
            <ReactTooltip placement='top'
              id='add_button'
              place='left'
              data-html='true'
              effect='solid'
              wrapper='span'>
            Add Chart
            </ReactTooltip>
          </Col>
        </Row>
        {dndObjs && 
          <Row className='mb2'>
            <dndObjs.DndContext
              collisionDetection={dndObjs.closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
            >
              <dndObjs.SortableContext
                items={layout}
                strategy={dndObjs.verticalListSortingStrategy}>
                <ChartGrid columns={2}>
                  {!!layout &&
                    layout.map((item, index) => {
                      return getChart(item)
                    })
                  }
                </ChartGrid>
              </dndObjs.SortableContext>
              <dndObjs.DragOverlay>
                { activeId && getChart(arrayFindObjectByProp(layout, 'hash', activeId)) }
              </dndObjs.DragOverlay>
            </dndObjs.DndContext>
          </Row>
        }
        {isOpen && <Modal show onHide={toggle} size='md' animation={false} dialogClassName='adjust-header'>
          <Modal.Header>
          Add Chart
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className={selected === 'license' ? 'col-md-6' : 'col-md-12'}>
                <Form.Label>Select Chart Type</Form.Label>
                <hr className='hr-metrics-chart' />
                <Radio
                  label='Licenses'
                  checked={selected === 'license'}
                  onClick={() => {
                    setSelected('license'); setLabel('Licenses Metrics - ')
                  }}
                />
                <br />
                <Radio
                  label='Users Created'
                  checked={selected === 'users_created'}
                  onClick={() => {
                    setSelected('users_created'); setLabel('Users Created - ')
                  }}
                /><br />
                <Radio
                  label='Appliance Checkins'
                  checked={selected === 'appliance_checkins'}
                  onClick={() => {
                    setSelected('appliance_checkins'); setLabel('Appliance Checkins - ')
                  }}
                />
                <br />
                <Radio
                  label='API'
                  checked={selected === 'apis'}
                  onClick={() => {
                    setSelected('apis'); setLabel('API - ')
                  }}
                />
                <br />
                <Radio
                  label='Visits'
                  checked={selected === 'visits'}
                  onClick={() => {
                    setSelected('visits'); setLabel('Visits - ')
                  }}
                />
              </div>
              {selected === 'license' &&
              <div className='col-md-6'>
                <Form.Label>License Type</Form.Label>
                <hr className='hr-metrics-chart' />
                <div className='metrics-chart-catalog-container'>
                  <MetricsSkuSelect onSkuChange={setSku} sku={sku} />
                </div>
              </div>
              }
            </div>
            <hr className='hr-metrics-chart' />
            <Form.Label>Chart Title</Form.Label>
            <Form.Control as='input' id='label-input' name='label-input' value={initialSettings.label} readOnly={!selected} onChange={(e) => setLabel(e.target.value)} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary'
              onClick={toggle} >
            Cancel
            </Button>
            <Button variant='primary'
              disabled={!selected || !initialSettings.label}
              onClick={handleAddChart} >
            Add
            </Button>
          </Modal.Footer>
        </Modal>}
      </Container>
    </CatalogContext.Provider>
  )
}

export default MetricsDashboard
