import React, { PureComponent, Fragment } from 'react'
import Table from '../Table'
import SkuRow from './SkuRow'
import Paginate from '../Paginate'
import Spinner from '../Spinner'
import SkuFilter from './SkuFilter'

class SkuIndex extends PureComponent {
  static defaultProps = {
    headers: ['Name', 'Platform', 'Description'],
    currentPage: 1,
    skus: [],
    spinnerOn: true,
    query: ''
  }

  state = { ...this.props }

  componentDidMount () {
    this.toPage(1)
  }

  fetchPage = (page, query) => fetch(
    `/skus.json?page=${page}&query=${encodeURIComponent(query)}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json'
      }
    })

  toPage = (pageNumber) => {
    this.setState({ spinnerOn: true }, async () => {
      await this.toPageAction(pageNumber)
      this.setState({ spinnerOn: false })
    })
  }

  toPageAction = async pageNumber => {
    try {
      const response = await this.fetchPage(pageNumber, this.state.query)
      const data = await response.json()
      const totalPages = parseInt(response.headers.get('Total'), 10)
      this.setState({skus: data, currentPage: pageNumber, pages: totalPages})
    } catch (error) {
      this.setState({skus: this.props.skus, currentPage: 1})
    }
  }

  onPageNext = () => {
    this.setState({ spinnerOn: true }, async () => {
      await this.onPageNextAction()
      this.setState({ spinnerOn: false })
    })
  }

  onPageNextAction = async values => {
    const nextPage = this.state.currentPage + 1
    try {
      const response = await this.fetchPage(nextPage, this.state.query)
      const data = await response.json()
      this.setState({skus: data, currentPage: nextPage})
    } catch (error) {
      this.setState({skus: this.props.skus, currentPage: 1})
    }
  }

  onPageBack = () => {
    this.setState({ spinnerOn: true }, async () => {
      await this.onPageBackAction()
      this.setState({ spinnerOn: false })
    })
  }

  onPageBackAction = async values => {
    const backPage = this.state.currentPage - 1
    try {
      const response = await this.fetchPage(backPage, this.state.query)
      const data = await response.json()
      this.setState({skus: data, currentPage: backPage})
    } catch (error) {
      this.setState({skus: this.props.skus, currentPage: 1})
    }
  }

  onFilterChange = values => {
    this.setState({query: values.sku_filter}, () => {
      this.toPage(1)
    })
  }

  render () {
    return (
      <div className='col-md-12'>
        <div className='row py1'>
          <div className='col-md-6'>
            <h3>SKUs</h3>
          </div>
          <div className='col-md-6'>
            <SkuFilter handleFilterChange={this.onFilterChange} />
          </div>
        </div>
        <div className='row'>
          {this.state.skus.length > 0 ? (
            <Fragment>
              <Table
                headers={this.props.headers}>
                <SkuRow skus={this.state.skus} />
              </Table>
              {this.state.pages > 1 &&
                <div className='center'>
                  <Paginate
                    pages={this.state.pages}
                    currentPage={this.state.currentPage}
                    onPageNext={this.onPageNext}
                    onPageBack={this.onPageBack}
                    toPage={this.toPage} />
                </div>
              }
            </Fragment>
          ) : (this.state.spinnerOn ? (
            <center><Spinner /></center>
          ) : (
            <div className='center'>
              <h4>No SKUs available.</h4>
            </div>
          )
          )}
        </div>
      </div>
    )
  }
}

export default SkuIndex
