import crypto from 'crypto'
import moment from 'moment'

class MetricsSettings {
  static defaultLayout = []
  static defaultStartDate = moment().subtract(1, 'month').startOf('month').toDate()
  static defaultEndDate = moment().subtract(1, 'month').endOf('month').toDate()

  static hashKey = (setting) => {
    const hash = crypto
      .randomBytes(16)
      .toString('hex')
    return hash
  }

  static getLayout = () => {
    let layout = localStorage.getItem('metricslayout')
    if (layout && layout !== undefined){
      layout = JSON.parse(layout)
      layout.forEach(l => {
        if (l.starts_at === "Invalid date") {
          l.starts_at = this.defaultStartDate
        }
        if (l.ends_at === "Invalid date") {
          l.ends_at = this.defaultEndDate
        }
      })
    } else {
      layout = this.defaultLayout
    }
    return layout
  }

  static setLayout = (layout) => {
    localStorage.setItem('metricslayout', JSON.stringify(layout))
  }

  static addLayout = (setting) => {
    let layout = this.getLayout()
    const hash = setting.hash || this.hashKey(setting)
    const index = layout.findIndex((element) => element.hash === setting.hash || element.hash === hash)
    if (setting.deleted) {
      layout = this.deleteLayout(index)
    } else {
      setting.hash = hash
      if (index > -1) {
        layout[index] = setting
      } else {
        layout.splice(index, 0, setting)
      }
    }
    this.setLayout(layout)
    return layout
  }

  static deleteLayout = (index) => {
    const layout = this.getLayout()
    if (index < layout.length && index > -1) {
      layout.splice(index, 1)
      this.setLayout(layout)
    }
    return layout
  }

  static init = () => {
    let setting = {
      hash: '',
      label: 'License Metrics - Activated',
      module: 'license',
      activated: true,
      exported: false,
      expired: false,
      trial: false
    }
    setting.hash = this.hashKey(setting)
    this.defaultLayout.push(setting)

    setting = {
      hash: '',
      label: 'License Metrics - Expired',
      module: 'license',
      activated: false,
      exported: false,
      expired: true,
      trial: false
    }
    setting.hash = this.hashKey(setting)
    this.defaultLayout.push(setting)

    setting = {
      hash: '',
      label: 'License Metrics - Trials',
      module: 'license',
      activated: false,
      exported: false,
      expired: false,
      trial: true
    }
    setting.hash = this.hashKey(setting)
    this.defaultLayout.push(setting)
  }

  static initalize = this.init()
}

export default MetricsSettings
