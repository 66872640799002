import React from 'react'
import { InputGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Collapse from 'react-collapse'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../assets/stylesheets/components/ExpirationField.scss'
import { presets } from 'react-motion'

const CalendarSelect = props =>
  <Collapse isOpened={props.open} springConfig={presets.gentle}>
    <div className='form-section'>
      <Form.Label>{props.label}</Form.Label>
      <InputGroup>
        <InputGroup.Prepend className='input-group-addon'>
          <i className='fa fa-calendar' />
        </InputGroup.Prepend>
        <input
          type='hidden'
          name='license[expires_at]'
          value={props.value}
          disabled={!props.open}
          onChange={props.onInputChange}
        />
        <DatePicker
          className='form-control'
          id='datepicker'
          name='expires_at_datepicker'
          selected={props.calendarValue}
          disabled={!props.open}
          onChange={props.onCalendarChange}
        />
      </InputGroup>
    </div>
  </Collapse>

export default CalendarSelect
