import React, { useEffect, useState, Fragment } from 'react'
import Spinner from '../Spinner'
import { formatDateTime } from '../HarmonyMetrics/helpers'
import DeleteReasonModal from '../Modals/DeleteReasonModal'

const HarmonyImportsShow = ({importId}) => {
  const [importEntry, setImportEntry] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`/internal/harmony_controller_metrics_imports/${importId}.json`, { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        setImportEntry(data.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const renderRange = (range) => `${formatDateTime(range.start)} - ${formatDateTime(range.end)}`

  const providerActivities = (provider) => {
    if (importEntry.activities && importEntry.activities[provider.id]) {
      return importEntry.activities[provider.id]
    } else {
      return []
    }
  }

  const renderProviders = () => {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Provider</th>
            <th>Activities</th>
          </tr>
        </thead>
        <tbody>
          {importEntry.providers.map((provider, i) =>
            <tr key={i}>
              <td>
                Name: {provider.name} <br />
                UUID: {provider.provider_uuid}
              </td>
              <td>
                {providerActivities(provider).map((activity, i) =>
                  <div>
                    {activity.activity_key}: {activity.count}
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  if (!loading && !importEntry) {
    return <h3 className='text-center'>Not Found.</h3>
  }

  if (loading) {
    return <div className='text-center'><Spinner /></div>
  }

  if (importEntry) {
    return (
      <Fragment>
        <h3 className='h3'>
          { formatDateTime(importEntry.created_at) } Import Summary
          &nbsp;&nbsp;
          { importEntry.delete_reason === null &&
            <DeleteReasonModal
              deleteURL={`/internal/harmony_controller_metrics_imports/${importId}.json`}
              redirectURL={`/internal/harmony_controller_metrics_imports`} />
          }
        </h3>
        <hr />
        { importEntry.delete_reason === null &&
          <Fragment>
            <div className='row'>
              <div className='col-md-4'>
               Providers Imported/Updated
                <br /><small>{importEntry.providers.length}</small>
              </div>
              <div className='col-md-4'>
               Activities Imported
                <br /><small>{importEntry.total_activities_imported}</small>
              </div>
              <div className='col-md-4'>
               Activities's Entries Stored
                <br /><small>{importEntry.total_activities_stored}</small>
              </div>
              <div className='col-md-4'>
               Created By
                <br /><small>{importEntry.created_by_display_name}</small>
              </div>
              {importEntry.range && <div className='col-md-4'>
               Activities Range: <br /><small>{renderRange(importEntry.range)}</small>
              </div>}
            </div>
            <div>
              <hr />
              <h4>Activities by provider</h4>
              {importEntry && importEntry.providers.length > 0 && renderProviders()}
            </div>
          </Fragment> }
        { importEntry.delete_reason !== null &&
        <Fragment>
          <div className='row'>
            <div className='col-md-4'>
                  Import Deletion Date
              <br /><small>{formatDateTime(importEntry.deleted_at)}</small>
            </div>
            <div className='col-md-4'>
                  Deletion Reason
              <br /><small>{importEntry.delete_reason}</small>
            </div>
          </div>
        </Fragment> }
      </Fragment>
    )
  } else {
    return <Fragment />
  }
}

export default HarmonyImportsShow
