import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'

export const Option = ({
  label,
  value,
  ...props
}) =>
  <Box
    as='option'
    key={value || label}
    value={value || label || ''}
    children={label}
    {...props}
  />

export const Select = ({
  children = [],
  sorted = true,
  withPlaceholder = true,
  ...props
}) =>
  <Box as='select' {...props}>
    { withPlaceholder &&
      <Option value=''>{props.placeholder || 'Select an Option'}</Option>
    }
    {(sorted ? children.sort() : children).map(Option)}
  </Box>

Select.propTypes = {
  children: PropTypes.array,
  sorted: PropTypes.bool
}

export default Select
