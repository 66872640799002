import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import WizardButtons from '../WizardButtons'

class CreateOrg extends Component {
  state = {
    orgName: this.props.wizardState.orgName,
    nextEnabled: this.props.wizardState.orgName.length > 0
  }

  onNext = async () => {
    try {
      const response = await fetch('/api/new_users/create_org.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.props.wizardState.email,
          organization: this.state.orgName
        })
      })
      if (response.ok) {
        this.props.onNext({ orgName: this.state.orgName })
        return
      }
    } catch (error) {
      this.props.handleError(error)
      return
    }
    this.props.handleError('The organization could not be created.  Please try again.')
  }

  onPrevious = () => {
    this.props.onPrevious({
      orgName: this.state.orgName
    })
  }

  updateInputValue = (e) => {
    this.setState({
      orgName: e.target.value,
      nextEnabled: e.target.value.length > 0
    })
  }

  render () {
    return (
      <div>
        <div className='py1'>
          You will automatically be added to the A10 Networks Global organization, but now we need to create your personal organization.
          Please enter in a name for your personal organization. It can be as simple as your name or whatever you wish.
        </div>
        <div className='py2'>
          <label className='control-label'>Organization Name</label>
          <Form.Control
            value={this.state.orgName}
            onChange={e => this.updateInputValue(e)}
          />
        </div>
        <WizardButtons
          onPreviousClick={this.onPrevious}
          onNextClick={this.onNext}
          nextEnabled={this.state.nextEnabled}
        />
      </div>
    )
  }
}

export default CreateOrg
