import React, { useState } from 'react'
import Box from '../components/Box'
import Input from '../components/Input'
import Email from '../components/Email'
import Label from '../components/Label'
import Button from '../components/Button'
import Alert from '../components/Alert'
import FileDrop from '../components/FileDrop'
import { emailIsValid } from '../EmailIsValid'
import IdpDomainListEditor from './IdpDomainListEditor'
import IdpReadOnlyFields from './IdpReadOnlyFields'

export const send = async(body, method, url) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    })
    if (response.ok) {
      return await response.json()
    } else {
      try {
        return await response.json()
      } catch (_) {
        return { errors: ['An error occured communicating with the server.']}
      }
    }
  } catch (error) {
    return { errors: [error.message]}
  }
}

export const IdpAddEditForm = ({ organizationId, idpConfiguration, idpDomains }) => {
  const [idpId, setIdpId] = useState(idpConfiguration.id)
  const [idpName, setIdpName] = useState(idpConfiguration.name)
  const [administratorEmail, setAdministratorEmail] = useState(idpConfiguration.administrator_email)
  const [emailClaimIdentifier, setEmailClaimIdentifier] = useState(idpConfiguration.email_claim_identifier)
  const [idpConfig, setIdpConfig] = useState(idpConfiguration)
  const [domains, setDomains] = useState(idpDomains)
  const [errors, setErrors] = useState([])
  const [xml, setXml] = useState(idpConfiguration.xml_federation_metadata)
  const [nameIdentifierFormat, setNameIdentifierFormat] = useState(idpConfiguration.name_identifier_format)
  const [authnContext, setAuthnContext] = useState(idpConfiguration.authn_context)
  const namespace = 'urn:oasis:names:tc:SAML:2.0:metadata'

  let idp = {}
  let parseErrors = []

  const calculateFingerprint = async (cert) => {
    return await send(JSON.stringify({ certificate: cert }),
      'POST',
      '/idp_configurations/calculate_fingerprint.json')
  }

  const checkDomains = async () => {
    const url = idpId ?
      `/idp_configurations/check_domains.json?idp_configuration_id=${idpId}` :
      '/idp_configurations/check_domains.json'
    const response = await send(JSON.stringify({ domains: domains }),
      'POST',
      url)
    return response.errors.map((e,i) => (
      <p key={`de_${i}`}>{e}</p>
    ))
  }

  const saveIdpConfiguration = async (config) => {
    const method = config.id ? 'PUT' : 'POST'
    const url = config.id ?
      `/idp_configurations/${config.id}.json` :
      `/idp_configurations.json`
    return await send(JSON.stringify({ idp_configuration: config }),
      method,
      `${url}?organization_id=${organizationId}&current_organization=true`)
  }

  const setSso = (doc) => {
    const entityElements = doc.getElementsByTagNameNS(namespace,'EntityDescriptor')
    if (entityElements.length === 0) {
      parseErrors.push(<p key='e_1'>EntityDescriptor could not be found</p>)
      return
    }
    idp.idp_entity_id = entityElements[0].getAttribute('entityID')

    const ssoElements = doc.getElementsByTagNameNS(namespace,'SingleSignOnService')
    if (ssoElements.length === 0) {
      parseErrors.push(<p key='e_1'>SingleSignOnService could not be found</p>)
      return
    }

    let binding = ssoElements[0]

    for(let i = 0; i < ssoElements.length; i++) {
      if (ssoElements[i].getAttribute('Binding').endsWith('HTTP-POST')) {
        binding = ssoElements[i]
        break
      }
    }

    idp.idp_sso_target_url = binding.getAttribute('Location')
    idp.idp_slo_target_url = binding.getAttribute('Location')
    idp.protocol_binding = binding.getAttribute('Binding')
  }

  const setCertificate = async (doc) => {
    const cert = doc.getElementsByTagNameNS(namespace, 'IDPSSODescriptor')[0]
      .getElementsByTagNameNS('http://www.w3.org/2000/09/xmldsig#', 'X509Certificate')[0]
      .textContent
    const result = await calculateFingerprint(cert)
    if (result.errors && result.errors.length) {
      parseErrors.push(result.errors)
    } else {
      idp.idp_cert_fingerprint = result.fingerprint
      idp.idp_cert_fingerprint_algorithm = result.algorithm
    }
  }

  const onFileError = (e) => {
    setErrors([event.target.error.message])
    window.scrollTo(0,0)
  }

  const onFileLoaded = async (e) => {
    parseErrors = []
    idp = {
      idp_sso_target_url: '',
      idp_entity_id: '',
      protocol_binding: '',
      idp_cert_fingerprint: '',
      idp_cert_fingerprint_algorithm: ''
    }
    const contents = event.target.result
    setXml(contents)
    const parser = new DOMParser()
    const errorDoc = parser.parseFromString('INVALID', 'text/xml')
    const parseErrorNS = errorDoc.getElementsByTagName('parsererror')[0].namespaceURI
    const xmldoc = parser.parseFromString(contents, 'text/xml')
    if (xmldoc.getElementsByTagNameNS(parseErrorNS,'parsererror').length > 0) {
      setErrors(['The XML file is invalid and cannot be parsed.'])
      window.scrollTo(0,0)
      return
    }
    setSso(xmldoc)
    await setCertificate(xmldoc)
    if (parseErrors.length > 0) {
      setErrors(parseErrors)
      window.scrollTo(0,0)
    } else {
      setIdpConfig(idp)
    }
  }

  const onFilesAdded = (files) => {
    const reader = new FileReader()
    reader.onload = onFileLoaded
    reader.onerror = onFileError
    reader.readAsText(files[0])
  }

  const validateInput = async () => {
    const errors = []
    if (domains.length === 0) {
      errors.push(<p key='e_1'>At least one domain must be specified.</p>)
    } else {
      const e = await checkDomains()
      if (e.length) {
        errors.push(e)
      }
    }
    if (idpName.length === 0) {
      errors.push(<p key='e_2'>Configuration Name required.</p>)
    }
    if (administratorEmail.length > 0 && !emailIsValid(administratorEmail)) {
      errors.push(<p key='e_3'>Adminstrator Email invalid format.</p>)
    }
    if (authnContext.length === 0) {
      errors.push(<p key='e_5'>Authn Context required.</p>)
    }
    if (nameIdentifierFormat.length === 0) {
      errors.push(<p key='e_6'>Name Identifier Format required.</p>)
    }
    if (emailClaimIdentifier.length === 0) {
      errors.push(<p key='e_6'>Email Claim Identifier required.</p>)
    }
    if (!idpConfig ||
        idpConfig.idp_cert_fingerprint.length === 0 ||
        idpConfig.idp_cert_fingerprint_algorithm.length === 0 ||
        idpConfig.idp_sso_target_url.length === 0 ||
        idpConfig.idp_slo_target_url.length === 0 ||
        idpConfig.idp_entity_id.length === 0 ||
        idpConfig.protocol_binding.length === 0){
      errors.push(<p key='e_4'>XML Metatdata must be imported and complete.</p>)
    }
    return errors
  }

  const addDomain = (domain) => {
    if (domains.indexOf(domain) === -1) {
      domains.push(domain)
      setDomains(domains.slice(0))
    }
  }

  const saveClick = async () => {
    const errs = await validateInput()
    if (errs.length > 0) {
      setErrors(errs)
      window.scrollTo(0,0)
      return
    }
    idpConfig.id = idpId
    idpConfig.name = idpName
    idpConfig.administrator_email = administratorEmail
    idpConfig.email_claim_identifier = emailClaimIdentifier
    idpConfig.idp_domains = domains
    idpConfig.xml_federation_metadata = xml
    idpConfig.authn_context = authnContext
    idpConfig.name_identifier_format = nameIdentifierFormat
    const result = await saveIdpConfiguration(idpConfig)
    if (result.errors && result.errors.length) {
      const errors = result.errors.map((e,i) =>
        (<p key={`er_${i}`}>{e}</p>)
      )
      setErrors(errors)
      window.scrollTo(0,0)
    } else {
      window.location = `/idp_configurations/${result.id}?organization_id=${organizationId}&current_organization=true`
    }
  }

  return(
    <>
      {errors.length > 0 && <div className='row'>
        <div className='col-md-12'>
          <Alert>{errors}</Alert>
        </div>
      </div>}
      <div className='row'>
        <div className='col-md-12'>
          <h3>{idpId ? 'Edit' : 'Create'} IdP Configuration</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <hr />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <Label classNames={['text-muted']}>Configuration Name <sup>*</sup></Label><br />
          <Input value={idpName} onChange={(e) => setIdpName(e.target.value)} required />
        </div>
      </div>
      <div className='row py1'>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Adminstrator Email</Label><br />
          <Email value={administratorEmail}
            onChange={(e) => setAdministratorEmail(e.target.value)}
            placeholder='someone@example.com'
          />
        </div>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Email Claim Identifier <sup>*</sup></Label><br />
          <Input value={emailClaimIdentifier}
            onChange={(e) => setEmailClaimIdentifier(e.target.value)}
          />
        </div>
      </div>
      <div className='row py1'>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Name Identifier Format <sup>*</sup></Label><br />
          <Email value={nameIdentifierFormat}
            onChange={(e) => setNameIdentifierFormat(e.target.value)}
          />
        </div>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>Authn Context <sup>*</sup></Label><br />
          <Input value={authnContext}
            onChange={(e) => setAuthnContext(e.target.value)}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <hr />
        </div>
      </div>
      <FileDrop buttonText='Import Metadata XML'
        onFilesAdded={onFilesAdded}
        accept='text/xml'>
        <div className='row'>
          <div className='col-md-12'>
            <h4>XML Metadata <sup>*</sup></h4>
            Drag and drop your XML file here or click the button below.
          </div>
        </div>
        <IdpReadOnlyFields idpConfiguration={idpConfig} />
      </FileDrop>
      <div className='row'>
        <div className='col-md-12'>
          <hr />
        </div>
      </div>
      <IdpDomainListEditor idpDomains={domains}
        onAdd={(d) => addDomain(d)}
        onDelete={(i) => {
          domains.splice(i,1)
          setDomains(domains.splice(0))
        }}
      />
      <div className='row'>
        <div className='col-md-12'>
          <hr />
        </div>
      </div>
      <div className='row py1'>
        <div className='col-md-12'>
          <Button variant='primary' onClick={saveClick}>
              Save Configuration
          </Button>
        </div>
      </div>
    </>
  )
}

export default IdpAddEditForm
