import React from 'react'

const Stepper = ({steps, activeStep, nonLinear, stepFn}) => {
  const classList = (step) => {
    const list = ['item']
    if (step === activeStep) {
      list.push('active')
    }
    if (step < activeStep || nonLinear) {
      list.push('processed')
    }
    return list.join(' ')
  }

  const handleClick = (e, step) => {
    e.preventDefault()
    stepFn(step)
  }

  return (
    <div>
      <ol class='app-stepper'>
        {steps.map((step, i) =>
          <li class={classList(i)} key={i}>
            <a className='circle' href='#' onClick={(e) => handleClick(e, i)}>{i + 1}</a>
            <a className='title' href='#' onClick={(e) => handleClick(e, i)}>{step.title}</a>
          </li>
        )}
      </ol>
    </div>
  )
}

export default Stepper
