import React from 'react'
import Button from '../../Button'

const CreateActivationButton = props =>
  <Button
    data-toggle='tooltip'
    data-placement='top'
    className='btn-round'
    title='Activate Appliance'
    href={`/activations/new?token=${props.licenseToken}`}>
    <i className='fa fa-bolt' />
  </Button>

export default CreateActivationButton
