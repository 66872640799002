import InfiniteScroll from 'react-infinite-scroller'
import PropTypes from 'prop-types'

class InfiniteScrollExtended extends InfiniteScroll {
  componentDidUpdate(prevProps, prevState){
    if(this.props.pageStart !== prevProps.pageStart) {
      this.pageLoaded = 0
    }
    super.componentDidUpdate (prevProps,prevState)
  }
}

export default InfiniteScrollExtended
