import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'
import Link from './Link'

const statusColor = a => (
  (a.archived && 'danger') ||
  (!a.registered && 'warning') ||
  (a.active && 'primary') ||
  'default'
)

export const ComplianceError = ({ children: activation }) =>
  <Box className='outofbounds'>
    <Box
      as='span'
      classNames={['label', statusColor]}
      children={activation.status}
    />
    <Link
      className='s-small'
      maxLength={40}
      to={`/activations/${activation.id}/activity`}
      children={activation.display_name}
    />
    <Box
      as='span'
      className='fs-small'
      children={activation.device}
    />
    {activation.registered && activation.allocated_bandwidth &&
      <Box
        as='span'
        className='fs-small'
        children={`${activation.pretty_megabits} ${activation.cores}`}
      />
    }
  </Box>

ComplianceError.propTypes = {
  children: PropTypes.object
}

export default ComplianceError
