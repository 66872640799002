import React, { useState, useEffect, useContext } from 'react'
import Input from '../components/Input'
import { FormInput } from './Form'
import { FormContext } from '../containers/./Form'
import { lableize } from '../../util/string'

import Box from '../components/Box'

const portLableize = str => lableize(str.replace(/PORT/gi, "").replace(/_G_/gi, "_GIG_"))

const Delete = props =>
  <Box className='col-sm-1 text-center'>
    <Input
      type='button'
      variant='link'
      className='pull-right'
      {...props}
    >
      <i className='fa fa-times' />
    </Input>
  </Box>

function PortEditor (props) {
  const [ports, setPorts] = useState({...props.ports})

  const {
    attributes = {},
    data = {},
    errors = {},
    handleChange,
    handleUnmount,
    initialValue,
    inputProps: backendProps = {}
  } = useContext(FormContext)

  const handlePortChange = (e, key) => {
    let newPort = {...ports}
    newPort[key] = Number(e.target.value)
    setPorts(newPort)
    handleChange(e)
  }

  const handleDelete = (key) => {
    const target={
      name: key,
      value: null,
      type: 'input'
    }
    handleChange(target)
    let newPort = {...props.ports, ...ports}
    delete newPort[key]
    setPorts (newPort)
    props.onDelete(key)
  }

  useEffect(() => {
    let newPort = {...props.ports, ...ports}
    Object.keys(newPort).forEach((v, i) =>  {
      if (newPort[v] === null) {
        newPort[v] = props.ports[v]
      }
      const target={
        name: v,
        value: newPort[v],
        type: 'input'
      }
      handleChange(target)
    })
    setPorts (newPort)
  }, [props.ports])

  return (
    <div className="port-editor">
      {
        !!ports && Object.keys(ports).map((v,i) => {
          return ports[v] === null? null:
            <div key={v}>
              <div className="port-input">
                <FormInput name={v} label={portLableize(v)} id={'license_' + v} type="number" min="0" max="256" value={ports[v]} onChange={ (e) => handlePortChange(e,v)}/>
              </div>
              <div className='port-delete'>
                <br/>
                <Delete onClick={() => handleDelete(v)}/>
              </div>
            </div>
        })
      }
    </div>
  )
}

export default PortEditor
