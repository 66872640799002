import React, { Component, Fragment } from 'react'
import RadioField from './RadioField'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Collapse from 'react-collapse'
import { presets } from 'react-motion'
import { getMoment, getMomentStr, yearMoment } from './helpers/moment'
import Label from './Form/Label'

import 'react-datepicker/dist/react-datepicker.css'

const FIELD_NAME = 'license[expires_at]'
const DATE_FORMAT = 'DD/MM/YYYY'

export default class FireEyeExpirationField extends Component {
  constructor (props) {
    super(props)
    const expiresAt = this.fixTime(this.props.expires_at)
    const startTime = this.fixTime(this.props.start_time)
    const hideStartDate = props.hide_start_date || false
    this.state = {
      selectedOption: expiresAt === undefined ? '' : 'Custom',
      starts_at: startTime,
      starts_at_datepicker: getMoment(startTime),
      expires_at: expiresAt ? getMomentStr(expiresAt) : moment().utc().add(2, 'weeks').format(DATE_FORMAT),
      expires_at_datepicker: expiresAt ? getMoment(expiresAt) : moment().utc().add(2, 'weeks'),
      hideStartDate: hideStartDate
    }

    this.setYears(startTime)

    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.setYears = this.setYears.bind(this)
    this.handleStartsChange = this.handleStartsChange.bind(this)
    this.handleExpiresChange = this.handleExpiresChange.bind(this)
  }

  fixTime (time) {
    return (time === null ? undefined : time)
  }

  handleOptionChange (event) {
    this.setState({
      selectedOption: event.target.value
    })
  }

  handleExpiresChange (date) {
    this.setState({
      expires_at_datepicker: date,
      expires_at: getMomentStr(date)
    })
  }

  setYears (start) {
    this.oneyear = yearMoment(start, 1)
    this.twoyears = yearMoment(start, 2)
    this.threeyears = yearMoment(start, 3)
    this.fouryears = yearMoment(start, 4)
    this.fiveyears = yearMoment(start, 5)
  }

  handleStartsChange (date) {
    this.setState({
      starts_at_datepicker: date,
      starts_at: moment(date).utc().format(DATE_FORMAT)
    })
    this.setYears(date)
  }

  render () {
    return (
      <Fragment>
        <Label>Starts at</Label>
        <div className='input-group'>
          <span className='input-group-addon input-group-prepend'><i className='fa fa-calendar' /></span>
          {
            !this.state.hideStartDate &&
            <input type='hidden'
              name='license[starts_at]'
              value={this.state.starts_at} />
          }
          <DatePicker id='datepicker'
            name='starts_at_datepicker'
            selected={this.state.starts_at_datepicker}
            className='form-control fire-eye-expiration-datepicker'
            onChange={this.handleStartsChange} />
        </div>
        <br />
        <Label>Set an expiration period</Label>
        <p className='help-block'>License expiration date must be a future date.</p>
        <div className='row'>
          <div className='col-sm-6'>
            <RadioField
              name={FIELD_NAME}
              checked={this.state.selectedOption === this.oneyear}
              value={this.oneyear}
              onChange={this.handleOptionChange}
              label='1 year' />
            <RadioField
              name={FIELD_NAME}
              checked={this.state.selectedOption === this.twoyears}
              value={this.twoyears}
              onChange={this.handleOptionChange}
              label='2 years' />
            <RadioField
              name={FIELD_NAME}
              checked={this.state.selectedOption === this.threeyears}
              value={this.threeyears}
              onChange={this.handleOptionChange}
              label='3 years' />
          </div>
          <div className='col-sm-6'>
            <RadioField
              name={FIELD_NAME}
              checked={this.state.selectedOption === this.fouryears}
              value={this.fouryears}
              onChange={this.handleOptionChange}
              label='4 years' />
            <RadioField
              name={FIELD_NAME}
              checked={this.state.selectedOption === this.fiveyears}
              value={this.fiveyears}
              onChange={this.handleOptionChange}
              label='5 years' />
            <RadioField
              checked={this.state.selectedOption === 'Custom'}
              onChange={this.handleOptionChange}
              label='Custom'
              value='Custom' />
          </div>
        </div>
        <Collapse isOpened={this.state.selectedOption === 'Custom'} springConfig={presets.gentle}>
          <div className='form-section'>
            <Label>Expiration Date</Label>
            <div className='input-group'>
              <span className='input-group-addon input-group-prepend'><i className='fa fa-calendar' /></span>
              <input type='hidden'
                name={FIELD_NAME}
                value={this.state.expires_at}
                disabled={this.state.selectedOption !== 'Custom'}
                onChange={this.handleOptionChange} />
              <DatePicker id='datepicker'
                name='expires_at_datepicker'
                disabled={this.state.selectedOption !== 'Custom'}
                selected={this.state.expires_at_datepicker}
                className='form-control fire-eye-expiration-datepicker'
                onChange={this.handleExpiresChange} />
            </div>
          </div>
        </Collapse>
      </Fragment>
    )
  }
}
