import React, { Fragment } from 'react'
import WizardButtons from '../WizardButtons'
import WizardForm from '../WizardForm'
import Dropdown from '../Dropdown'

class Webroot extends WizardForm {
  state = {
    ...this.props.wizardState,
    webrootTypes: [],
    nextEnabled: false
  }

  componentDidMount = () => {
    if (this.props.wizardState.selectedFamily !== 'webroot') {
      this.skipForm()
      return
    }
    this.loadWebrootTypes()
  }

  loadWebrootTypes = async () => {
    const response = await fetch('/api/skus/webroot_types')
    if (response.ok) {
      const json = await response.json()
      this.setState({
        webrootTypes: json.webroot_types,
        selectedWebroot: this.state.selectedWebroot || json.webroot_types[0][1]
      },
      this.checkNextAvailable
      )
    }
  }

  onWebrootUpdated = (e) => {
    this.setState({
      selectedWebroot: e.target.value
    },
    this.checkNextAvailable
    )
  }

  checkNextAvailable = () => {
    this.setState({
      nextEnabled: this.state.selectedWebroot !== null && this.state.selectedWebroot !== ''
    })
  }

  getWizardState = () => ({
    selectedWebroot: this.state.selectedWebroot
  })

  render = () => (
    <Fragment>
      <div className='py1'>
        <label className='control-label'>Webroot Type</label>
        <Dropdown options={this.state.webrootTypes} value={this.state.selectedWebroot} onChange={this.onWebrootUpdated} />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled={this.state.nextEnabled}
      />
    </Fragment>
  )
}

export default Webroot
