import React, { Component } from 'react'
import ToggleCard from './../components/ToggleCard'
import Spinner from './../components/Spinner'
import { isEmpty } from 'lodash'

const fetchLicenseTypes = async params => {
  return fetch(
    `/reports/product.json?report_form%5Bmodel%5D=license&report_form%5Barchived%5D=${params.archived || ''}&organization_filter=${params.organization_filter || ''}&report_form%5Bplatform%5D=${params.platform || ''}&report_form%5Bproduct%5D=${params.product || ''}&report_form%5Blicense_type%5D=${params.license_type || ''}&report_form%5Btrial%5D=${params.trial || ''}&report_form%5Bexclude_employee_owned%5D=${params.exclude_employee_owned || ''}&report_form%5Bwith_activations%5D=${params.with_activations || ''}&report_form%5Bwith_rma_requests%5D=${params.with_rma_requests || ''}&report_form%5Bwith_licenses%5D=${params.with_licenses || ''}&report_form%5Bwith_license_pools%5D=${params.with_license_pools || ''}&report_form%5Bwith_appliances%5D=${params.with_appliances || ''}&report_form%5Bwith_data_usage%5D=${params.with_data_usage || ''}&report_form%5Bcreated_by%5D=${params.created_by || ''}&report_form%5Bcreated_at%5D=${params.created_at || ''}&report_form%5Bcreated_range_begin%5D=${params.created_range_begin || ''}&report_form%5Bcreated_range_end%5D=${params.created_range_end || ''}&report_form%5Bexpires_by_select%5D=${params.expires_by_select || ''}&report_form%5Bexpire_date%5D=${params.expire_date || ''}&report_form%5Bexpired%5D=${params.expired || ''}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json'
      }
    })
}

class ExpandProductSummary extends Component {
  state = {
    productTypes: {}
  }

  openProductTypes = async params => {
    if (this.state.productTypes.results) {
      return
    }
    try {
      const response = await fetchLicenseTypes(params)
      const json = await response.json()
      this.setState({ productTypes: { results: json } })
    } catch (error) {
      this.setState({ productTypes: { results: {} } })
    }
  }

  render () {
    let results = []
    if (this.state.productTypes.results) {
      if (_.isEmpty(this.state.productTypes.results[0])) {
        results = (<span>No results</span>)
      } else {
        results = this.state.productTypes.results.map(sku => {
          return (
            <span key={sku.name}>
              {sku.total} ({sku.with_activations}) - {sku.sku}<br />
            </span>
          )
        })
      }
    }

    const title = typeof this.props.params.product === 'object' ? this.props.params.product.sku : this.props.params.product
    return (
      <>
        {
          typeof this.props.params.product !== 'object' ? (
            <div
              className='py1'
              onClick={() => this.openProductTypes(this.props.params)}>
              <ToggleCard
                defaultOpen={false}
                label={ title.toUpperCase()}>
                { this.state.productTypes.results ? (
                  <span>
                    {results}
                  </span>
                ) : (
                  <Spinner />
                )
                }
              </ToggleCard>
            </div>
          ) : (
            <span key={this.props.params.product.name}>
              {this.props.params.product.total} ({this.props.params.product.with_activations}) - {title}<br />
            </span>
          )
        }
      </>
    )
  }
}

export default ExpandProductSummary
