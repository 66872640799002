import React, { Component } from 'react'
import moment from 'moment'
import ThroughputGraph from './ThroughputGraph'
import { isUndefined } from 'lodash'

class Throughput extends Component {
  state = {
    throughputType: this.props.throughputType,
    throughputId: this.props.throughputId,
    data: [],
    endTime: moment().utc().endOf('day'),
    startTime: moment().utc().startOf('day').subtract(5, 'days')
  }

  componentDidMount = async () => {
    await this.resetWeek()
  }

  loadThroughputs = async () => {
    const fixRespWindow = window => moment.unix(window).utc().toDate()
    const { throughputId, throughputType, endTime, startTime } = this.state
    const windowStart = startTime.unix()
    const windowEnd = endTime.unix()

    const response = await fetch(
      `/throughput/${throughputType}/${throughputId}.json?window_end=${windowEnd}&window_start=${windowStart}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        }
      }
    )
    if (response.ok) {
      const json = await response.json()
      const data = json.throughputs.map(d => ({ ...d, windowEnd: fixRespWindow(d.windowEnd) }))
      this.setState({ data })
    } else {
      this.setState({ error: 'something from response probably' })
    }
  }

  next = (event, number, unit) => {
    event.preventDefault()
    const startTime = this.state.startTime.add(number, unit)
    const endTime = this.state.endTime.add(number, unit)
    this.setState({ startTime, endTime }, this.loadThroughputs)
  }

  previous = (event, number, unit) => {
    event.preventDefault()
    const startTime = this.state.startTime.subtract(number, unit)
    const endTime = this.state.endTime.subtract(number, unit)
    this.setState({ startTime, endTime }, this.loadThroughputs)
  }

  resetWeek = (event) => {
    if (!isUndefined(event)) {
      event.preventDefault()
    }
    const endTime = moment().utc().endOf('day')
    const startTime = moment().utc().startOf('day').subtract(5, 'days')
    this.setState({ startTime, endTime }, this.loadThroughputs)
  }

  render () {
    const { startTime, endTime, data } = this.state
    return (
      <ThroughputGraph
        previous={this.previous}
        resetWeek={this.resetWeek}
        next={this.next}
        startTime={startTime}
        endTime={endTime}
        data={data}
      />
    )
  }
}

export default Throughput
