import React from 'react'
import Button from '../../Button'

const RenewalButton = props =>
  <Button
    data-toggle='tooltip'
    data-placement='top'
    className='btn-round'
    title='Renew License'
    href={`/licenses/${props.licenseId}/renewals/new`}>
    <i className='fa fa-plus' />
  </Button>

export default RenewalButton
