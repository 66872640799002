import React from 'react'
import { FormInput } from './Form'

export const Notes = props =>
  <FormInput
    type='textarea'
    id='license_notes'
    name='notes'
    {...props}
  />

export default Notes
