
// Not perfectly random, but good enough for our purposes
// You can optionally pass args to prepend
export const uid = (...args) => {
  const prefix = args.join('_')
  const str1 = Math.random().toString(36).substring(2, 15)
  const str2 = Math.random().toString(36).substring(2, 15)
  return `${prefix}${str1}${str2}`
}


export const noop = () => {}

export function emailIsValid (email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)
}
