import React, { useState } from 'react'
import acceptCheck from 'attr-accept'
import PropTypes from 'prop-types'
import Button from './Button'

export const FileDrop = ({
  children,
  buttonTop,
  buttonText,
  onFilesAdded,
  multiple,
  accept,
  ...props
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const fileRef = React.createRef()

  const openFile = () => {
    fileRef.current.click();
  }

  const fileListToArray = (list) => {
    const array = []
    for (var i = 0; i < list.length; i++) {
      if (acceptCheck(list.item(i), accept)) {
        array.push(list.item(i))
      }
    }
    return array
  }

  const onAdded = (e) => {
    const files = e.target.files
    if (onFilesAdded) {
      const array = fileListToArray(files)
      if (array.length) {
        onFilesAdded(array)
      }
    }
  }

  const onDragOver = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const onDragLeave = () => {
    setIsDragging(false)
  }

  const onDrop = (e) => {
    e.preventDefault()
    setIsDragging(false)
    const files = e.dataTransfer.files
    if (onFilesAdded) {
      let array = fileListToArray(files)
      if (array.length) {
        if (!multiple) {
          array = array.slice(0,1)
        }
        onFilesAdded(array)
      }
    }
  }

  return (
    <div className={isDragging?'drop-highlight':'drop-normal'}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}>
      {(buttonTop && buttonText) && <div className='row py1'>
        <div className='col-md-12'>
          <Button onClick={openFile}>
            {buttonText}
          </Button>
        </div>
      </div>}
      {children}
      {(!buttonTop && buttonText) && <div className='row py1'>
        <div className='col-md-12'>
          <Button onClick={openFile}>
            {buttonText}
          </Button>
        </div>
      </div>}
      <input type='file'
        className='drop-hide'
        multiple={multiple}
        ref={fileRef}
        onChange={onAdded}
        accept={accept} />
    </div>
  )
}

FileDrop.propTypes = {
  buttonTop: PropTypes.bool,
  buttonText: PropTypes.string,
  multiple: PropTypes.bool,
  acceptTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
}

export default FileDrop
