import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'

export const Align = ({
  classNames = [],
  ...props
}) =>
  <Box
    classNames={[
      'flex',
      'items-center',
      'justify-center',
      ...classNames
    ]}
    {...props}
  />

Align.propTypes = {
  classNames: PropTypes.array
}

export default Align
