import React, { Component } from 'react'
import WizardButtons from '../WizardButtons'

class AcceptEULA extends Component {
  state = {
    eula: ''
  }

  getEula = async () => {
    try {
      const response = await fetch('/api/new_users/eula')
      if (response.ok) {
        const json = await response.json()
        this.setState({ eula: json.eula })
      }
    } catch (error) {
      this.props.handleError(error)
    }
  }

  componentDidMount = async () => {
    await this.getEula()
  }

  onNext = async () => {
    if (!await this.createNewUser()) {
      return
    }
    if (!await this.acceptEula()) {
      return
    }
    this.props.onNext()
  }

  acceptEula = async () => {
    try {
      const response = await fetch('/api/new_users/accept_eula.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.props.wizardState.email,
          token: this.props.wizardState.verifyCode
        })
      })
      if (response.ok) {
        return true
      } else {
        const json = await response.json()
        this.props.handleError(json.error)
      }
    } catch (error) {
      this.props.handleError(error)
    }
    return false
  }

  /* eslint camelcase: [ 0 ] */
  createNewUser = async () => {
    try {
      const response = await fetch('/api/new_users/new_user.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: this.props.wizardState.password,
          password_confirmation: this.props.wizardState.verifyPassword,
          email: this.props.wizardState.email,
          token: this.props.wizardState.verifyCode,
          role: this.props.wizardState.roleSelected
        })
      })
      if (response.ok) {
        return true
      } else {
        const json = await response.json()
        this.props.handleError(json.error)
      }
    } catch (error) {
      this.props.handleError(error)
    }
    return false
  }

  render () {
    return (
      <div>
        <div className='pre-scrollable' dangerouslySetInnerHTML={{__html: this.state.eula}} />
        <p className='py1'>By clicking 'Accept & Agree' your account will be created and A10 support notified to activate your account.</p>
        <WizardButtons
          onPreviousClick={this.props.onPrevious}
          onNextClick={this.onNext}
          nextEnabled
          nextText='Accept & Agree'
        />
      </div>
    )
  }
}

export default AcceptEULA
