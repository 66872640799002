import React from 'react'
import AddUser1 from 'images/add-org-user-1.png'
import AddUser2 from 'images/add-org-user-2.png'

export const OrganizationUserInstructions = (props) => {
  if (!props.visible) {
    return null
  }
  const userImage1 = Object.keys(AddUser1).length ? AddUser1 : '/images/add-org-user-1.png'
  const userImage2 = Object.keys(AddUser2).length ? AddUser2 : '/images/add-org-user-2.png'
  return(
    <>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h4>Request the owner or admin of the organization that you wish to join follow the below steps</h4>
        </div>
      </div>
      <div className='row text-muted'>
        <div className='col-md-2'/>
        <div className='col-md-8'>
          <ul>
            <li>Login as the owner of the desired organization.</li>
            <li>Select 'Organizations' from the top right menu.</li>
            <li>Navigate to the desired organization.</li>
            <li>Navigate to 'Users' on the left menu.</li>
          </ul>
        </div>
      </div>
      <div className='row text-center'>
        <div className='col-md-2'/>
        <div className='col-md-8 example-image'>
          <img src={userImage1} alt='Add user example 1'/>
        </div>
      </div>
      <div className='row py1' />
      <div className='row text-muted'>
        <div className='col-md-2'/>
        <div className='col-md-8'>
          <ul>
            <li>Enter the user's email that you want to add to the organization and select their role.</li>
            <li>Click 'Add User'.</li>
          </ul>
        </div>
      </div>
      <div className='row text-center'>
        <div className='col-md-2'/>
        <div className='col-md-8 example-image'>
          <img src={userImage2} alt='Add user example 2'/>
        </div>
      </div>
    </>
  )
}

export default OrganizationUserInstructions
