import React from 'react';
import LicenseMetricsOptions from './LicenseMetricsOptions'
import MetricsCard from './common/MetricsCard'

const LicenseMetricsChart = (props) => 
  <MetricsCard 
    {...{...props, OptionComponent: LicenseMetricsOptions, chart: {url: '/internal/dashboard_charts/licenses.json', label: 'Licenses'}}} 
  />

export default React.memo(LicenseMetricsChart)
