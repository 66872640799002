import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import debounce from 'lodash/debounce'
import WizardButtons from '../WizardButtons'

class StrongPassword extends Component {
  state = {
    password: this.props.wizardState.password,
    verifyPassword: this.props.wizardState.verifyPassword,
    score: this.props.wizardState.score,
    strengthMessage: this.props.wizardState.strengthMessage,
    passwordsMatch: this.props.wizardState.password === this.props.wizardState.verifyPassword,
    nextEnabled: this.props.wizardState.password === this.props.wizardState.verifyPassword && this.props.wizardState.score > 2
  }

  onNext = () => {
    const result = this.passwordsMatch() && this.state.score > 1
    if (!result) {
      this.props.handleError("Must rate 'Good' or better. Use letters, numbers and/or characters. Verify that both passwords match.")
      return
    }
    this.props.onNext({
      password: this.state.password,
      verifyPassword: this.state.verifyPassword,
      score: this.state.score,
      strengthMessage: this.state.strengthMessage
    })
  }

  onPrevious = () => {
    this.props.onPrevious({
      password: this.state.password,
      verifyPassword: this.state.verifyPassword,
      score: this.state.score,
      strengthMessage: this.state.strengthMessage
    })
  }

  updatePassword = e => {
    this.setState({
      password: e.target.value
    })
    this.testPassword(e.target.value)
  }

  updateVerifyPassword = e => {
    this.setState({
      verifyPassword: e.target.value,
      nextEnabled: this.passwordsMatch(e.target.value) && this.state.score > 2
    })
  }

  callTestPassword = async (password) => {
    try {
      const response = await fetch('/api/new_users/test_password.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password: password })
      })
      if (response.ok) {
        const json = await response.json()
        this.setStrength(json.score)
      }
    } catch (error) {
      this.props.handleError(error)
    }
  }

  testPassword = debounce(this.callTestPassword, 150)

  setStrength = (score) => {
    let message = 'Weak'
    switch (score) {
      case 2:
        message = 'Okay'
        break
      case 3:
        message = 'Good'
        break
      case 4:
        message = 'Strong'
        break
    }
    this.setState({
      strengthMessage: message,
      score: score,
      nextEnabled: this.passwordsMatch() && score > 2
    })
  }

  passwordsMatch = (verifyPassword) => {
    let matched = this.state.password === (verifyPassword || this.state.verifyPassword)
    this.setState({
      passwordsMatch: matched
    })
    return matched
  }

  render () {
    return (
      <div>
        <div className='py1'>
          <div>
            <label className='control-label'>Password</label>
            <Form.Control
              type='password'
              value={this.state.password}
              onChange={e => this.updatePassword(e)}
            />
            <p className='help-block'>Must rate 'Good' or better. Use letters, numbers and/or characters.</p>
            <progress max='4' value={this.state.score} />
            <p className='help-block'>Password Strength: {this.state.strengthMessage}</p>
          </div>
          <div>
            <label className='control-label'>Confirm Password</label>
            <Form.Control
              type='password'
              value={this.state.verifyPassword}
              onChange={e => this.updateVerifyPassword(e)}
            />
            <p className={'help-block' + (this.state.passwordsMatch ? ' hidden' : '')}>Passwords do not match</p>
          </div>
        </div>
        <WizardButtons
          onPreviousClick={this.onPrevious}
          onNextClick={this.onNext}
          nextEnabled={this.state.nextEnabled}
        />
      </div>
    )
  }
}

export default StrongPassword
