import React from 'react'
import Form from 'react-bootstrap/Form'

const Dropdown = ({ options, defaultOption, ...props }) =>
  <Form.Control as='select' {...props}>
    {options.map(([label, v]) =>
      <option
        key={v || label}
        value={v || label}
        children={label}
      />
    )}
  </Form.Control>

export default Dropdown
