import React from 'react'
import OrganizationQuickAdd from '../components/OrganizationQuickAdd'

class AddOrganization extends React.Component {
  onSubmit = async (org) => {
    /* eslint camelcase: [ 0 ] */
    const organization = {
      name: org.companyName,
      phone_numbers: [org.contactPhone]
    }
    try {
      const response = await fetch('/api/new_organizations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({organization: organization})
      })
      const data = await response.json()
      if (!response.ok) {
        if (data.messages) {
          return data.messages
        }
        if (data.errors) {
          return data.errors
        }
        return ['An error occurred posting the organization.  Please contact support for assistance.']
      }
    } catch (_) {
      return ['An error occurred posting the organization.  Please contact support for assistance.']
    }
    return null
  }

  render() {
    return (<OrganizationQuickAdd onSubmit={this.onSubmit} {...this.props} />)
  }
}

export default AddOrganization
