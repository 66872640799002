import React, { useEffect, useState } from 'react'
import Paginate from '../../Paginate'
import Spinner from '../Spinner'
import { formatDateTime } from '../HarmonyMetrics/helpers'

const HarmonyImportsIndex = ({currentPage}) => {
  const [entries, setEntries] = useState([])
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const baseURL = '/internal/harmony_controller_reports_imports'

  const loadEntries = async () => {
    setLoading(true)

    const response = await fetch(`${baseURL}.json?page=${currentPage}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setEntries(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }
  useEffect(() => {
    loadEntries()
  }, [])

  useEffect(() => {
    if (entries.length > 0) {
      $('[data-toggle="tooltip"]').tooltip()
    }
  }, [entries])

  const gotTo = (page) => {
    window.location.href = `${baseURL}?page=${page}`
  }

  const renderEntries = () => {
    return (
      <table className='table small-table'>
        <thead>
          <tr>
            <th>Imported At</th>
            <th>Report Date</th>
            <th>Identifier</th>
            <th>Providers Imported</th>
            <th>Pool Type</th>
            <th>Total Pool Capacity</th>
            <th>Allocatable Percentage</th>
            <th>Created By</th>
            <th>Error</th>
            <th>Delete<br />Reason</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, i) =>
            <tr key={i}>
              <td>{formatDateTime(entry.created_at)}</td>
              <td>{formatDateTime(entry.report_time)}</td>
              <td>{entry.identifier}</td>
              <td>{entry.providers_count}</td>
              <td>{entry.pool_type}</td>
              <td>{entry.total_pool_capacity}</td>
              <td>{entry.allocatable_percentage}</td>
              <td>{entry.created_by_display_name}</td>
              <td>{entry.error_message}</td>
              <td>{formatDateTime(entry.deleted_at)}<br />{entry.delete_reason}</td>
              <td>
                {!entry.error_message &&
                <a className='btn btn-default btn-round'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='View Import'
                  href={`${baseURL}/${entry.id}`}>
                  <i className='fa fa-eye' />
                </a>
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      <h3>HC Metrics Capacity Dashboard Imports</h3>
      {loading && <Spinner />}
      {error && <div className='alert alert-danger'> {error} </div>}
      {entries.length === 0 && !loading && !error && <div className='alert alert-danger'> No Data available </div>}
      {entries.length > 0 && renderEntries()}
      { paginationData && paginationData.total_pages > 1 &&
      <Paginate
        pages={paginationData.total_pages}
        currentPage={paginationData.current_page}
        onPageNext={() => gotTo(currentPage + 1)}
        onPageBack={() => gotTo(currentPage - 1)}
        toPage={(pageNum) => gotTo(pageNum)} />
      }
      {loading && paginationData && <Spinner />}
    </div>
  )
}

export default HarmonyImportsIndex
