import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-collapse'
import { presets } from 'react-motion'

export const ToggleCard = ({
  classNames = [],
  defaultOpen = false,
  label,
  children,
  togglePlusStyle,
  ...props
}) => {
  const [ open, setOpen ] = useState(defaultOpen)

  return (
    <Card className={[...classNames, open ? 'open' : 'closed', 'togglable']}>
      <Card.Header onClick={() => {
        setOpen(!open)
      }}>
        { label } <div className="toggle-plus pull-right" style={togglePlusStyle}>{ open ? '-' : '+' }</div>
      </Card.Header>
      <Collapse isOpened={open} springConfig={presets.gentle}>
        <Card.Body>
          <Card.Text as="div">
            { children }
          </Card.Text>
        </Card.Body>
      </Collapse>
    </Card>
  )
}

export default ToggleCard
