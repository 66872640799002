import React, { Fragment, useEffect, useState } from 'react'
import isUndefined from 'lodash/isUndefined'
import 'isomorphic-fetch'
import Modal from 'react-bootstrap/Modal'
import qs from 'query-string'
import omit from 'lodash/omit'
import MetricsChart from './MetricsChart'
import ActivityLogs from './ActivityLogs'
import Spinner from '../Spinner'

const Activity = ({ providerId, onClose }) => {
  const [activities, setActivities] = useState({})
  const [currentProviderResponse, setCurrentProviderResponse] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [currentActivityKey, setCurrentActivityKey] = useState()
  const [chartURL, setChartURL] = useState()
  const [activityView, setActivityView] = useState('chart')

  const scrollStyle = {
    position: 'absolute',
    top: '10px',
    bottom: '0',
    overflowY: 'auto',
    minHeight: '200px'
  }

  const loadProvider = async () => {
    setLoading(true)

    const response = await fetch(`/harmony_metrics/${providerId}.json${window.location.search}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setActivities({...activities, [providerId]: jsonData.data})
      setCurrentProviderResponse(jsonData.data)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  useEffect(() => {
    setError()
    setCurrentProviderResponse()
    if (providerId) {
      setChartURL(`/internal/harmony_metrics_charts/${providerId}/activities_by_provider.json${window.location.search}`)
      if (isUndefined(activities[providerId])) {
        loadProvider()
      } else {
        setCurrentProviderResponse(activities[providerId])
      }
    }
  }, [providerId])

  const handleActivityView = (e, view) => {
    e.preventDefault()
    setActivityView(view)
  }

  const setCurrentActivity = (e, key) => {
    e.preventDefault()
    const params = {}
    const queryParams = omit(qs.parse(window.location.search), ['activity_key'])

    if (currentActivityKey === key) {
      setCurrentActivityKey()
    } else {
      params.activity_key = key
      setCurrentActivityKey(key)
    }
    setChartURL(`/internal/harmony_metrics_charts/${providerId}/activities_by_provider.json?${qs.stringify({...queryParams, ...params})}`)
  }

  const handleCloseAction = () => {
    setChartURL()
    setCurrentActivityKey()
    onClose()
  }

  const renderActivities = () => {
    return (
      <div className='row'>
        <div className='col-md-4' style={scrollStyle}>
          <ul className='nav nav-pills'>
            {Object.keys(currentProviderResponse).map((key, i) =>
              <li className={`nav-link ${currentActivityKey === key ? 'active' : ''}`} key={i}>
                <a className='nav-link' href='#' onClick={(e) => setCurrentActivity(e, key)}>
                  <b>{key}:</b> {currentProviderResponse[key]}
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className='col-md-8 text-center col-md-offset-4' style={scrollStyle}>
          <div className='row'>
            <div className='col-md-12'>
              {renderTabs()}
            </div>
          </div>
          { chartURL &&
          <div>
            {activityView === 'chart' &&
              <MetricsChart
                yTitle='Number of activities'
                fetchURL={chartURL}
              />
            }
            {activityView === 'logs' &&
              <ActivityLogs
                providerId={providerId}
                activityKey={currentActivityKey}
              />
            }
          </div>
          }
        </div>
      </div>
    )
  }

  const renderTabs = () => {
    return (
      <ul className='nav nav-tabs' style={{fontSize: '12px', marginBottom: '5px'}}>
        <li className={`nav-item ${activityView === 'chart' ? 'active' : ''}`}>
          <a className='nav-link'
            onClick={(e) => handleActivityView(e, 'chart')}
            href='#' style={{padding: '3px 8px'}}>
              Chart View
          </a>
        </li>
        <li className={`nav-item ${activityView === 'logs' ? 'active' : ''}`}>
          <a className='nav-link'
            onClick={(e) => handleActivityView(e, 'logs')}
            href='#' style={{padding: '3px 8px'}}>
              Logs View
          </a>
        </li>
        <li className='nav-item' style={{float: 'right'}}>
          <span className='nav-link disabled' style={{padding: '3px 8px'}}>
            {currentActivityKey ? `${currentActivityKey} activity` : `All activities`}
            { currentActivityKey &&
            <Fragment>
              &nbsp;
              <a href='#' onClick={(e) => setCurrentActivity(e, currentActivityKey)}>(view all)</a>
            </Fragment>
            }
          </span>
        </li>
      </ul>
    )
  }

  return (
    <div className='modal-fade-backdrop'>
      {
        providerId &&
        <Modal show onHide={handleCloseAction} size='lg' animation={false} dialogClassName='adjust-header'>
          <Modal.Header closeButton>
            <Modal.Title style={{margin: 0}}>Activities</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{minHeight: '500px'}}>
            { currentProviderResponse && renderActivities() }
            { loading && <div className='text-center'><Spinner /></div> }
            { error && <div className='alert alert-danger'>{error}</div>}
            { currentProviderResponse && currentProviderResponse.length === 0 && !loading && <div className='alert alert-danger'>No activities found</div>}
          </Modal.Body>
        </Modal>
      }
    </div>
  )
}

export default Activity
