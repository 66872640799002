import React from 'react';
import ReactDOM from "react-dom/client";

const ReactRails =  () => {
  const renderComponent = (component, props, target) => {
    const element = React.createElement(component, props);
    const root = ReactDOM.createRoot(target);
  
    root.render(element);
  };

  const cleanupNode = (node) => {
    node.removeAttribute('data-react-component')
    node.removeAttribute('data-react-props')
  }

  /* eslint-disable global-require */
  const mountComponentNode = (node) => {
    const componentAttr = node.dataset.reactComponent;
    let component = require(`./${componentAttr}`)
    
    if (component.__esModule) {
      component = component["default"]
    }
  
    const propsAttr = node.dataset.reactProps;  
    const props = propsAttr ? JSON.parse(propsAttr) : {};
    renderComponent(component, props, node);
    cleanupNode(node)
  };
  /* eslint-enable global-require */
  
  const mountComponentNodes = (nodes) => {
    nodes.forEach(mountComponentNode);
  };
  
  const mountComponents = () => {
    const componentNodes = document.querySelectorAll('[data-react-component]');
    mountComponentNodes(componentNodes);
  };
  
  document.addEventListener('turbolinks:load', () => {
    mountComponents();
  });
};

export default ReactRails
