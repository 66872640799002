import React, {useContext} from 'react'
import CatalogContext from './CatalogContext'
import SkuSelect from '../../SkuSelect'

const MetricsSkuSelect = ({sku, onSkuChange}) => {
  const catalog = useContext(CatalogContext)
  return (
    <SkuSelect
      catalog={catalog}
      platformplaceholder='Any'
      productplaceholder='Any'
      licensetypeplaceholder='Any'
      onSkuChange={onSkuChange}
      required={false}
      isNew
      sku={sku}
      addon={false} />
  )
}

export default MetricsSkuSelect
