import React, { useState, useEffect } from 'react'

import { debouncedFetch } from '../../util/fetch'
import { noop } from '../../util'

export const useFetch = ({
  wait = 200,
  ...opts
}, dependsOn = []) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})

  const doFetch = () => {
    setLoading(true)
  }

  const callFetch = async () => {
    const fetchData = await debouncedFetch(wait)(opts)
    setState({ loading: false, ...fetchData })
  }

  useEffect(() => {
    if (loading) callFetch()
  }, [loading, ...dependsOn])

  return { doFetch, loading, ...state }
}

export const Fetch = ({ children, ...props }) => {
  const fetchData = useFetch(prop)
  return children && children(fetchValues)
}

Fetch.defaultProps = {
  authenticate: true,
  body: {},
  children: noop,
  wait: 200,
  headers: {},
  query: {}
}

export default Fetch
