import React from 'react'
import Form from 'react-bootstrap/Form'
import { classStr, requiredStr } from '../helpers/form'

const Label = ({ children, className, ...props }) =>
  <Form.Label
    className={classStr(requiredStr(props.required), className)}
    {...props}
  >
    {children}
    {props.required && ' *'}
  </Form.Label>

export default Label
