import React, { Fragment } from 'react'
import WizardButtons from '../WizardButtons'
import WizardForm from '../WizardForm'

class Success extends WizardForm {
  onNext = () => {
    window.location.href = '/'
  }

  render = () => (
    <Fragment>
      You have succesfully created <b>{this.props.wizardState.description}</b>
      <WizardButtons
        previousHidden
        nextEnabled
        nextText='Home'
        onNextClick={this.onNext}
      />
    </Fragment>
  )
}

export default Success
