// Like Ruby's dig. Safely dig through a deep object
export const dig = (obj, ...keys) => keys.reduce(
  (o, k) => (o && k && o[k]),
  obj
)

// Like Ruby's Array#to_h. Transform an array of 2-element arrays,
// transform into an object
export const toObj = array => array.reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {})

// Like Ruby's compact. Remove empty keys form object
export const compact = obj => toObj(
  Object.entries(obj || {}).filter(([_, v]) => !!v)
)

// Higher-order functions for objects (filter, map, reduce)
// pass in an object, get one back
// Curried. Call like so: `iter({})('values')('filter')([k, v] => !!v)`
export const iter = obj => type => iter => (...opts) =>
  toObj(Object[type](obj)[iter](...opts))

export const map = obj => (...opts) => iter(obj)('entries')('map')(...opts)

export const filter = obj => (...opts) => iter(obj)('entries')('filter')(...opts)

// Leave in null, 0, etc
export const deepCompact = (obj, filters = null) => {
  const bad = filters || [undefined, '', null]
  const isPresent = v => !bad.includes(v)

  if (!isPresent(obj)) return

  if (Array.isArray(obj)) {
    if (obj.length) {
      return obj.map((i) => deepCompact(i, filters)).filter(isPresent)
    } else {
      return
    }
  }

  if (!!obj && typeof obj === 'object') {
    const entries = Object.entries(obj)

    if (entries.length) {
      return toObj(
        entries.map(([k, v]) => ([k, deepCompact(v)]))
          .filter(([_, v]) => isPresent(v))
      )
    } else {
      return
    }
  }

  return obj
}
