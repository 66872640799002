import React, { Component } from 'react'
import Downshift from 'downshift'
import 'string.prototype.startswith' // TODO is this necessary with the polyfill?

import { Label, Input } from '../components/Input'

const stateReducer = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.mouseUp:
      return {
        ...changes,
        inputValue: state.inputValue
      }
    default:
      return changes
  }
}

class TypeAhead extends Component {
  static defaultProps = {
    input: { id: 'typeahead-input' },
    item: { className: 'downshift-item' },
    items: [],
    label: { required: false },
    menu: { className: 'downshift-menu string required', style: {width: '100%', 'maxHeight': '10em', overflow: 'auto'} },
    root: { className: 'form-group string required' }
  }

  handleStateChange = changes => {
    const { onChange, submitField } = this.props
    const { inputValue, selectedItem, type } = changes
    const { clickItem, keyDownEnter } = Downshift.stateChangeTypes
    if (!onChange) return

    // if we got a value from the input field (not the select), set it, even if empty
    // otherwise, only set the value from select if it has a value.
    if (changes.hasOwnProperty('inputValue') && type !== clickItem && type !== keyDownEnter) {
      onChange({ name: submitField, value: inputValue })
    } else {
      selectedItem && onChange({ name: submitField,  value: selectedItem })
    }
  }

  getItems = (items, inputValue) => (
    (this.itemIsNew(inputValue, items) ? [inputValue] : [])
      .concat(
        items.filter(i => i.startsWith(inputValue)))
  )

  itemIsNew = (item, items) => !!item && (items.indexOf(item) < 0)

  render = () => {
    const { items, input, inputValue, item, label, menu, root, type } = this.props

    return (
      <Downshift
        onStateChange={this.handleStateChange}
        inputValue={inputValue}
        selectedItem={inputValue}
        stateReducer={stateReducer}
        defaultHighlightedIndex={0}
        onInputValueChange={this.handleInputChange}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu
        }) => (
          <div {...root}>
            <Label {...getLabelProps({ htmlFor: input.id, ...label })} />
            <Input {...getInputProps({ onFocus: openMenu, ...input })} />
            <div {...getMenuProps({ ...menu, hidden: !isOpen })}>
              {isOpen &&
                this.getItems(items, inputValue).map((i, index) => (
                  <div {...getItemProps({
                    ...item,
                    key: i,
                    item: i || inputValue,
                    index: index,
                    value: i,
                    ...(highlightedIndex === index && {
                      style: { background: 'rgba(0,0,0,.03)' }
                    })
                  })}>
                    {type &&
                      <span className='text-muted'>
                        {this.itemIsNew(i, items) && 'Create New '}{type}{': '}
                      </span>
                    }
                    <strong>{i}</strong>
                  </div>
                ))
              }
            </div>
          </div>
        )}
      </Downshift>
    )
  }
}

export default TypeAhead
