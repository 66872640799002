import React from 'react'
import PropTypes from 'prop-types'

import { FormInput } from './Form'

export const SubscriberCount = ({ ...props }) =>
  <FormInput
    id='license_subscriber_count'
    name='subscriber_count'
    label='Number Of Subscribers'
    {...props}
  />

export default SubscriberCount
