import React from 'react'
import Tooltip from './Tooltip'
import { truncate } from './helpers/util'

const Truncate = ({ children = '', maxLength = 25, ...props }) =>
  <Tooltip {...props}>
    {children && children.length >= maxLength
      ? (<span
        className='ellipsis'
        data-original-title={children}
        children={truncate(children, maxLength)}
      />)
      : children
    }
  </Tooltip>

export default Truncate
