import React from 'react'
import Button from '../../Button'

const newLicenseURL = (organizationId, orderId, fireEyeUser) => {
  if (organizationId) {
    return `${partialURL(fireEyeUser)}?organization_id=${organizationId}`
  } else {
    return partialURL(fireEyeUser)
  }
}

const deterministicAddPath = (organizationId, orderId, fireEyeUser, isMortalUser) => {
  return isMortalUser && !fireEyeUser ? '/licenses/new_one_click_trial' : newLicenseURL(organizationId, orderId, fireEyeUser)
}

const partialURL = (fireEyeUser) => {
  if (fireEyeUser) {
    return `/oem/new`
  } else {
    return `/licenses/new`
  }
}

const AddLicenseButton = props =>
  <div className='text-right'>
    <Button
      variant={'primary'}
      className={props.createOnOrg || props.fireEyeUser ? "btn-primary" : 'hidden'}
      href={
        deterministicAddPath(props.organizationId, props.orderId, props.fireEyeUser, props.isMortalUser)
      }>
      <span><i className='fa fa-plus' /> License</span>
    </Button>
  </div>

export default AddLicenseButton
