import React from 'react'
import {
  FormInput,
  FormSection
} from './Form'

export const InternalNotes = props =>
  <FormInput
    type='textarea'
    id='license_internal_notes'
    name='internal_notes'
    {...props}
  />

export const PartnerName = props =>
  <FormInput
    id='license_partner_name'
    name='partner_name'
    label='Partner/Distributor Name'
    {...props}
  />

export const SfdcId = props =>
  <FormInput
    id='license_sfdc_partner_id'
    name='sfdc_partner_id'
    label='Partner Acct 18 Digit ID #'
    maxLength='18'
    size='18'
    {...props}
  />

export const Reason = props =>
  <FormSection>
    <FormInput
      type='select'
      placeholder='Select a reason'
      id='license_reason'
      name='reason'
      required
      children={[]}
      {...props}
    />
  </FormSection>

export const OraclePart = props =>
  <FormInput
    id='license_oracle_part'
    name='oracle_part'
    label='Oracle Part #'
    {...props}
  />

export const OracleLine = props =>
  <FormInput
    id='license_oracle_line'
    name='oracle_line'
    label='Oracle Line #'
    {...props}
  />

export const CustomerPoNumber = props =>
  <FormInput
    id='license_bill_to_purchase_order_id'
    name='bill_to_purchase_order_id'
    label='Customer PO Number'
    {...props}
  />

export const ServiceContractId = props =>
  <FormInput
    id='license_service_contract_id'
    name='service_contract_id'
    label='Service Contract #'
    {...props}
  />
