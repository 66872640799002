import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const RangeSlider = ({title, onChange, initialValue, name, unit, min, max, step, value, ...props}) => {
  const [rangeVal, setRangeVal] = useState(value || initialValue || min)
  const [unlimited, setUnlimited] = useState(!(value || initialValue))
  const rangeStep = step || 1

  const handleChange = (e) => {
    let targetVal = e.target.value
    if (targetVal > max) {
      targetVal = max
    } else if (targetVal < min) {
      targetVal = min
    }

    setRangeVal(targetVal)

    if (targetVal === max) {
      setUnlimited(true)
      onChange && onChange({ name, value: null })
    } else {
      onChange && onChange({ name, value: targetVal })
    }
  }

  const handleUnlimitedChange = (e) => {
    setUnlimited(false)
    setRangeVal(max)
    onChange && onChange({ name, value: max })
  }

  return (
    <div>
      <Form.Group>
        <Form.Label>{title}</Form.Label><br/>
        <BootstrapSwitchButton
          checked={unlimited}
          onlabel={`UNLIMITED`}
          width={450}
          offlabel={`LIMITED`}
          onChange={(checked) => {
            onChange && onChange({ name, value: checked ? null : rangeVal })
            setUnlimited(checked)
          }}
        />
      </Form.Group>
      <Form.Group>
        { !unlimited && <input type='range' custom="true" onChange={handleChange} value={rangeVal} name={name} min={min} max={max} step={rangeStep} /> }
        { unlimited &&
            <>
              <input type='range' custom="true" defaultValue={1} min={0} max={1} step={1} onClick={handleUnlimitedChange} />
              <input type='hidden' value='' name={name}  />
            </>
        }
      </Form.Group>
      <Form.Group className='ml2'>
        <Row>
          <Col md={3} style={{display: unlimited ? 'none' : 'block' }}>
            <Form.Control type='number' onChange={handleChange} value={rangeVal} min={min} max={max} step={rangeStep} />
          </Col>
          <Col md={9} className='pt2'>
            { unlimited ? `UNLIMITED ${unit}` : `${rangeVal} ${unit}`}
          </Col>
        </Row>
      </Form.Group>
    </div>
  )
}

export default RangeSlider
