import React, { Fragment } from 'react'
import WizardForm from '../WizardForm'
import WizardButtons from '../WizardButtons'
import SummaryRow from './SummaryRow'
import SummaryTrialRows from './SummaryTrialRows'
import SummaryFlexpoolRows from './SummaryFlexpoolRows'

/* eslint camelcase: [ 0 ] */
class Summary extends WizardForm {
  state = {
    wizardTitle: this.props.wizardState.wizardTitle
  }

  componentDidMount = () => {
    this.props.setWizardState({
      wizardTitle: ''
    })
  }

  componentWillUnmount = () => {
    this.props.setWizardState({
      wizardTitle: this.state.wizardTitle
    })
  }

  gatherTrialData = (sku, wizard) => {
    let result = { ...sku }
    if (wizard.trial) {
      result.expiration_interval = parseInt(wizard.selectedTrialIncrement, 10)
      result.expiration_unit = wizard.selectedTrialUnit
    }
    return result
  }

  gatherFlexpoolData = (sku, wizard) => {
    let result = { ...sku }
    if (wizard.isFlexpool) {
      result.unlimited_bandwidth = wizard.unlimitedBandwidth
      if (!wizard.unlimitedBandwidth) {
        result.bandwidth_in_mbps = parseInt(wizard.selectedBandwidth, 10)
      }
    }
    return result
  }

  gatherSkuData = () => {
    let wizard = this.props.wizardState
    let sku = {
      family: wizard.selectedFamily,
      platform: wizard.selectedPlatform,
      product: wizard.selectedProduct,
      trial: wizard.trial,
      entitlements: wizard.selectedEntitlements.map(s => s.value),
      roles: wizard.selectedRoles.map(s => s.value),
      unlimited_devices: wizard.unlimitedDevices,
      supported_versions: wizard.selectedVersions.map(s => s.value),
      description: wizard.description,
      short_description: wizard.shortDescription,
      name: wizard.skuName
    }
    sku = this.gatherTrialData(sku, wizard)
    if (wizard.selectedFamily === 'webroot') {
      sku.webroot_type = wizard.selectedWebroot
    }
    sku = this.gatherFlexpoolData(sku, wizard)
    if (!wizard.unlimitedDevices) {
      sku.maximum_devices = parseInt(wizard.maxDevices, 10)
    }
    if (wizard.cpuCount) {
      sku.cpu_count = parseInt(wizard.cpuCount, 10)
    }
    if (wizard.maxAdc) {
      sku.maximum_adc_objects = parseInt(wizard.maxAdc, 10)
    }
    if (wizard.maxTps) {
      sku.maximum_tps_objects = parseInt(wizard.maxTps, 10)
    }
    return sku
  }

  saveSku = async (sku) => {
    try {
      const response = await fetch('/api/skus.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({sku: sku})
      })
      if (response.ok) {
        return true
      } else {
        const json = await response.json()
        this.props.handleError(json.errors)
      }
    } catch (error) {
      this.props.handleError(error)
    }
    return false
  }

  onNext = async () => {
    const sku = this.gatherSkuData()
    if (await this.saveSku(sku)) {
      this.props.onNext({})
    }
  }

  render () {
    let wizard = this.props.wizardState
    return (
      <Fragment>
        <div className='container-fluid'>
          <div className='col-md-12 text-center'>
            <h3>Please review your choices</h3>
          </div>
          <SummaryRow label='Family:' value={wizard.selectedFamilyText} />
          <SummaryRow label='Platform:' value={wizard.selectedPlatformText} />
          <SummaryRow label='Product:' value={wizard.selectedProductText} />
          <SummaryTrialRows wizardState={wizard} />
          {wizard.selectedFamily === 'webroot' &&
            <SummaryRow label='Webroot Type:' value={wizard.selectedWebroot} />
          }
          {(!wizard.trial) &&
            <SummaryRow label='Entitlements:'
              value={wizard.selectedEntitlements.map(s => s.label).join(', ')} />
          }
          {(!wizard.trial) &&
            <SummaryRow label='Roles:'
              value={wizard.selectedRoles.map(s => s.label).join(', ')} />
          }
          {(!wizard.trial) &&
            <SummaryRow label='OEM Roles:'
              value={wizard.selectedOemRoles.map(s => s.label).join(', ')} />
          }
          <SummaryFlexpoolRows wizardState={wizard} />
          <SummaryRow label='CPU Count:' value={wizard.cpuCount} />
          <SummaryRow label='Maximum ADC Objects:' value={wizard.maxAdc} />
          <SummaryRow label='Maximum TPS Objects:' value={wizard.maxTps} />
          <SummaryRow label='Unlimited Devices:' value={wizard.unlimitedDevices.toString()} />
          {(!wizard.unlimitedDevices) &&
            <SummaryRow label='Maximum Devices:' value={wizard.maxDevices} />
          }
          {(!wizard.trial) &&
            <SummaryRow label='Versions:'
              value={wizard.selectedVersions.map(s => s.label).join(', ')} />
          }
          <SummaryRow label='Description:' value={wizard.description} />
          <SummaryRow label='Short Description:' value={wizard.shortDescription} />
          <SummaryRow label='SKU Name:' value={wizard.skuName} />
        </div>
        <WizardButtons
          onPreviousClick={this.onPrevious}
          onNextClick={this.onNext}
          nextEnabled
          nextText='Create SKU'
        />
      </Fragment>
    )
  }
}

export default Summary
