import { omit, values } from 'lodash'
import React, { Component, Fragment } from 'react'

import { Input } from '../components/Input'

class AddCustomRoles extends Component {
  state = { fields: {}, rowNum: 0 };

  addField = event => {
    event.preventDefault()
    this.setState({
      fields: { ...this.state.fields, [this.state.rowNum]: '' },
      rowNum: this.state.rowNum + 1
    })
  };

  removeField = (key, event) => {
    event.preventDefault()
    this.setState({ fields: omit(this.state.fields, key) })
    this.handleFormState()
  };

  handleInputChange = (key, event) => {
    this.setState({
      fields: { ...this.state.fields, [key]: event.target.value }
    })
    this.handleFormState()
  };

  handleFormState () {
    const { onChange, name } = this.props
    this.setState(
      { ...this.state.fields },
      () => onChange({ name, value: values(this.state.fields) })
    )
  }

  renderField = key => {
    return (
      <div className='row form-group' key={key}>
        <div className='col-md-10'>
          <Input
            type='text'
            id={key}
            placeholder='Add Role'
            value={this.state.fields[key]}
            onChange={e => this.handleInputChange(key, e)}
          />
        </div>
        <div className='col-md-2'>
          <a
            href='#'
            className='btn btn-link'
            onClick={event => this.removeField(key, event)}
          >
            <i className='fa fa-times' aria-hidden='true' />
          </a>
        </div>
      </div>
    )
  };

  renderFields = () => {
    return Object.keys(this.state.fields).map(key => this.renderField(key))
  };

  render = () => (
    <Fragment>
      {this.renderFields()}
      <a
        href='#'
        className='btn btn-primary'
        onClick={this.addField}
      >
        Add Custom Role
      </a>
    </Fragment>
  );
}

export default AddCustomRoles
