import React, { useState, useEffect } from 'react'

const TwoFactorFormFields = ({recoveryCodePrefix}) => {
  const [useRecoveryCode, setUseRecoveryCode] = useState(false)
  const [codeVal, setCodeVal] = useState('')
  const [recoveryCodeVal, setRecoveryCodeVal] = useState('')

  useEffect(() => {
    let offsetLeft = 0
    if (useRecoveryCode) {
      const scrollWidth = $('.scroll-container')[0].scrollWidth
      const outerWidth = $('.scroll-container').outerWidth()
      offsetLeft = scrollWidth - outerWidth
    }
    setCodeVal('')
    setRecoveryCodeVal('')
    $('html, body, .scroll-container').animate({scrollLeft: offsetLeft}, 200)
  }, [useRecoveryCode])

  useEffect(()=> {
    if (useRecoveryCode && recoveryCodeVal.length > 0) {
      setCodeVal(`${recoveryCodePrefix}${recoveryCodeVal}`)
    }
  }, [recoveryCodeVal])

  const handleRecoveryCodeClick = (e) => {
    e.preventDefault()
    setUseRecoveryCode(true)
  }

  const handleCodeClick = (e) => {
    e.preventDefault()
    setUseRecoveryCode(false)
  }

  const scrollStyle = {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: 0
  }

  const scrollContainerStyle = {
    position: 'relative',
    height: '106px',
    overflowX: 'hidden',
    overflowY: 'hidden'
  }

  return (
    <div className='row scroll-container' style={scrollContainerStyle}>
      <input type='hidden' name='code' id='code' value={codeVal} />
      <div className='col-md-12' style={scrollStyle}>
        {!useRecoveryCode && <div className='form-group'>
          <label className='control-label required'>Enter your personal code</label>
          <input type='text' 
            name='fake-code'
            value={codeVal}
            id='fake-code-id' 
            className='form-control' 
            onChange={(e) => setCodeVal(e.target.value)} />
          <small id='emailHelp' class='form-text text-muted'>
            If you no longer have access to your authenticator you can use one of your
            <a href='#' onClick={handleRecoveryCodeClick}> recovery codes </a>
            or contact
            <a href='mailto:support@a10networks.com'> support@a10networks.com </a>
          </small>
        </div>}
      </div>

      <div className='col-md-12 col-md-offset-12' style={scrollStyle}>
        {useRecoveryCode && <div className='form-group'>
          <label className='control-label required'>
            Enter your recovery code
          </label>
          <input type='text' 
            name='recovery-code'
            value={recoveryCodeVal}
            id='recovery-code-id' 
            className='form-control' 
            onChange={(e) => setRecoveryCodeVal(e.target.value)} />
          <small id='emailHelp' class='form-text text-muted'>
            <a href='#' onClick={handleCodeClick}> click here </a> if you have access to your authenticator,
            If you no longer have access to your recovery codes contact
            <a href='mailto:support@a10networks.com'> support@a10networks.com </a>
          </small>
        </div>}
      </div>
    </div>
  )
}

export default TwoFactorFormFields
