import React, { Component, Fragment } from 'react'
import Button from '../Button'

import { FormInput } from '../components/Form'
import { dig } from '../../util/object'

const isTrialPlatform = platform => !platform.includes('_pool') || platform === 'trial'

const getDefault = (catalog, ...keys) => {
  const catalogKeys = Object.keys(catalog)
  return catalogKeys.length === 1 ? catalogKeys[0] : ''
}

const catalogDig = (catalog, plat = null, prod = null, name = null) => {
  const platform = plat || getDefault(catalog)
  const product = prod || getDefault(dig(catalog, platform, 'products'))
  const skuName = name || getDefault(dig(catalog, platform, 'products', product, 'skus'))
  const sku = dig(catalog, platform, 'products', product, 'skus', skuName) || {}
  return { platform, product, ...sku }
}

class SkuSelect extends Component {
  static defaultProps = {
    sku: {},
    required: true
  }

  handlePlatformChange = ({ target: { value: platform } }) => {
    const { catalog } = this.props
    if (platform) {
      const sku = catalogDig(catalog, platform)
      this.handleChange(sku)
    } else {
      this.handleChange({})
    }
  }

  handleProductChange = ({ target: { value: product } }) => {
    const { catalog, addon, sku: { platform } } = this.props

    if (platform && product) {
      const sku = catalogDig(catalog, platform, product)
      this.handleChange(sku)
    } else if (addon && product) {
      const sku = catalogDig(catalog, 'instance_pool', product)
      this.handleChange(sku)
    } else {
      this.handleChange({ platform })
    }
  }

  handleSkuChange = event => {
    const { addon, catalog, sku: { platform, product } } = this.props
    const skuName = event.target.value

    if (addon && skuName) {
      const sku = catalogDig(catalog, 'instance_pool', product, skuName)
      this.handleChange(sku, sku.name)
    } else {
      if (platform && product && skuName) {
        const sku = catalogDig(catalog, platform, product, skuName)
        this.handleChange(sku, sku.name)
      } else {
        this.handleChange({ platform, product })
      }
    }
  }

  handleChange = sku => {
    const { onChange, onSkuChange, name } = this.props
    onChange && onChange({ name, value: sku.name })
    onSkuChange && onSkuChange(sku)
  }

  getSortedOptions = obj => Object.entries(obj).sort().map(([name, values]) => ({
    value: name, label: values.description
  }))

  getOptions = obj => Object.entries(obj).map(([name, values]) => ({
    value: name, label: values.description
  }))

  licenseTypeAlreadySelected = (skuOptions, selectedSKU) => {
    if (skuOptions.length === 1 && skuOptions[0].value === selectedSKU.name) {
      return true
    } else {
      return false
    }
  }

  render () {
    const { catalog, errors, sku, onSkuChange, addon, upgradeTrial, canUpgrade, onUpgradeChange, isNew, ...props } = this.props
    const isDeprecated = sku.roles && sku.roles.includes('deprecated')
    const { platform, product, licenseType, name } = sku
    let licenseSkuOptions = []

    if  (platform && product) {
      licenseSkuOptions = this.getOptions(catalog[platform].products[product].skus)
    }
    const sortAlphaNum = (a, b) => a.label.localeCompare(b.label, 'en', { numeric: true })
    licenseSkuOptions = licenseSkuOptions.sort(sortAlphaNum)
    const {
      platformfieldname,
      productfieldname,
      platformplaceholder,
      productplaceholder,
      licensetypeplaceholder
    } = this.props
    const required = this.props.required

    return (
      <Fragment>
        <FormInput
          type='select'
          label='Platform'
          placeholder={platformplaceholder || 'Select a license platform'}
          required={required}
          id='license_platform'
          name={platformfieldname || 'product'}
          value={platform}
          hidden={addon || (isNew && Object.keys(catalog).length === 1 && Object.keys(catalog)[0] !== 'trial')}
          disabled={!isNew}
          onChange={this.handlePlatformChange}
          children={this.getSortedOptions(catalog)}
        />

        { upgradeTrial && !canUpgrade && <div className='row'>
          <div className='col-md-12'>
            <FormInput
              type='button'
              className='pull-right'
              name='upgrade_to_full_license'
              onClick={onUpgradeChange}
              skipLabel={true}
            >
                  Upgrade to Full License
            </FormInput>
          </div>
        </div> }

        {!addon && platform &&
          <FormInput
            type='select'
            label='Product'
            placeholder={productplaceholder || 'Select a license product'}
            required={required}
            id='license_product'
            name={productfieldname || 'product'}
            value={product}
            hidden={isNew && Object.keys(catalog[platform].products).length === 1}
            disabled={!isNew && !canUpgrade && !isDeprecated}
            onChange={this.handleProductChange}
            children={this.getSortedOptions(catalog[platform].products)}
          />
        }

        {!addon && platform && product &&
          <FormInput
            type='select'
            label='License SKU'
            placeholder={licensetypeplaceholder || 'Select a license type'}
            required={required}
            disabled={!canUpgrade && upgradeTrial}
            sorted={false}
            value={licenseType || name}
            children={licenseSkuOptions}
            {...props}
            onChange={this.handleSkuChange}
          />
        }

        {addon && !!isNew &&
          <FormInput
            type='select'
            label='Product'
            placeholder={productplaceholder || 'Select a license product'}
            id='license_product'
            name={productfieldname || 'product'}
            value={product}
            disabled={!canUpgrade && !isDeprecated}
            onChange={this.handleProductChange}
            children={this.getSortedOptions(catalog['instance_pool'].products)}
          />
        }

        {product && addon &&
          <FormInput
            type='select'
            label='License SKU'
            placeholder={licensetypeplaceholder || 'Select a license type'}
            required={required}
            value={licenseType}
            disabled={!canUpgrade && upgradeTrial}
            sorted={false}
            children={this.getOptions(catalog['instance_pool'].products[product].skus)}
            {...props}
            onChange={this.handleSkuChange}
          />
        }
      </Fragment>
    )
  }
}

export default SkuSelect
