import React, {useState, useEffect, Fragment} from 'react'
import 'isomorphic-fetch'
import Paginate from '../../Paginate'
import qs from 'query-string'
import moment from 'moment'
import { formatDateTime } from './helpers'

const ActivityLogs = ({providerId, activityKey}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [paginationData, setPaginationData] = useState()
  const [error, setError] = useState()
  const [page, setPage] = useState(1)

  const loadData = async (pageNo) => {
    const queryParams = qs.parse(window.location.search)
    const fetchParams = {...queryParams, page: pageNo, activity: activityKey}
    const fetchUrl = `/harmony_metrics/${providerId}/provider_activity_logs?${qs.stringify(fetchParams)}`
    setLoading(true)

    const response = await fetch(fetchUrl).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setData(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  const handlePage = (page) => {
    setPage(page)
    loadData(page)
  }

  const triggeredRange = (dateTimeString) => {
    if (dateTimeString) {
      const startTime = moment(dateTimeString)
      const endTime = moment(dateTimeString).add(1, 'hour')
      if (startTime.isSame(endTime, 'date')) {
        const triggeredDate = moment(startTime).format('DD-MMM-YY')
        const formattedStartTime = moment(startTime).format('HH:mm')
        const formattedEndTime = moment(endTime).format('HH:mm')
        return `${triggeredDate} (${formattedStartTime} - ${formattedEndTime})`
      } else {
        return `${formatDateTime(startTime)} to ${formatDateTime(endTime)}`
      }
    } else {
      return 'N/A'
    }
  }

  useEffect(() => {
    setData()
    setPage(1)
    loadData(1)
  }, [activityKey])

  const renderData = () => {
    return (
      <table className='table small-table sticky-header'>
        <thead style={{top: 0}}>
          <tr>
            <th>Activity</th>
            <th>User</th>
            <th>Triggered Range</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {data.map((a, i) =>
            <tr key={i}>
              <td>{a.activity_key}</td>
              <td>{a.user}</td>
              <td>{triggeredRange(a.triggered_at)}</td>
              <td>{a.total_in_hour}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div className='text-left'>
      {loading && <i className='fa-spinner fa fa-pulse' /> }
      {error && <div className='alert alert-danger'> {error} </div>}
      {data && renderData()}
      { paginationData && paginationData.total_pages > 1 &&
        <Fragment>
          <hr />
          <Paginate
            pages={paginationData.total_pages}
            currentPage={paginationData.current_page}
            onPageNext={() => handlePage(page + 1)}
            onPageBack={() => handlePage(page - 1)}
            toPage={(pageNum) => handlePage(pageNum)} />
          {loading && <Fragment>&nbsp;<i className='fa-spinner fa fa-pulse' /></Fragment>}
        </Fragment>
      }
    </div>

  )
}

export default ActivityLogs
