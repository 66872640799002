import React, { Children } from 'react'
import PropTypes from 'prop-types'

import Box from './Box'
import Button from './Button'
import Checkbox from './Checkbox'
import Label from './Label'
import Radio from './Radio'
import Select from './Select'

export { Checkbox, Label, Radio, Select }

export const inputClasses = {
  text: 'string',
  textarea: 'text',
  number: 'integer',
  checkbox: 'boolean',
  range: 'integer'
}

export const InputError = props =>
  <Box
    as='small'
    className='text-danger'
    {...props}
  />

export const InputErrors = ({
  children = [],
  ...props
}) => {
  const errors = Children.toArray(children)

  return (
    <>
      {!!errors.length && errors.map((error, i) =>
        <InputError
          key={i}
          children={error}
          {...props}
        />
      )}
    </>
  )
}

InputErrors.propTypes = {
  children: PropTypes.array
}

export const InputGroup = ({
  classNames = [],
  ...props
}) =>
  <Box
    as='span'
    classNames={['input-group', ...classNames]}
    {...props}
  />

InputGroup.propTypes = {
  classNames: PropTypes.array
}

export const InputGroupAddon = ({
  classNames = [],
  ...props
}) =>
  <Box
    as='span'
    classNames={['input-group-addon', ...classNames]}
    {...props}
  />

InputGroupAddon.propTypes = {
  classNames: PropTypes.array
}

export const Input = ({
  classNames = [],
  type = 'input',
  ...props
}) => {
  const tags = {
    button: Button,
    radio: Radio,
    select: Select,
    checkbox: Checkbox,
    textarea: 'textarea',
    default: 'input'
  }
  const unStyledInputs = ['button', 'checkbox', 'radio', 'range']
  const Tag = tags[type] || tags.default
  const inputClasses = { 'form-control': !unStyledInputs.includes(type) }

  return (
    <Box
      as={Tag}
      classNames={[inputClasses, ...classNames]}
      {...(Tag === tags.default && { type })}
      {...props}
    />
  )
}

Input.propTypes = {
  classNames: PropTypes.array,
  type: PropTypes.string
}

export default Input
