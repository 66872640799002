import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FileDrop from '../components/FileDrop'
import Input from '../components/Input'
import Label from '../components/Label'
import Link from '../components/Link'
import Button from '../components/Button'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'
import SubscriberFile from './SubscriberFile'

export const SubscriberAuditFiles = ({licenseid}) => {
  const todaysDate = new Date()
  const [quarter, setQuarter] = useState(1)
  const [year, setYear] = useState(todaysDate.getFullYear())
  const [files, setFiles] = useState([])

  const loadFiles = async () => {
    const response = await fetch(`/subscriber_audit_files?licenseid=${licenseid}`, {
      headers: {
        Accept: 'application/json'
      }
    })
    if (!response.ok) {
      GLMToast('An error occurred getting the file list.', {className: 'alert alert-error'})
      return
    }
    const data = await response.json()
    setFiles(data)
  }

  useEffect(() => {
    loadFiles()
  },[])

  const postFile = async (file) => {
    if (! file.name.includes('.csv') && ! file.name.includes('.CSV')) {
      GLMToast('The file must be a .csv', {className: 'alert alert-error'})
      return
    }
    let data = new FormData()
    data.append('files[]', file)
    data.append('quarter', quarter)
    data.append('year', year)
    data.append('licenseid', licenseid)
    const response = await fetch('/subscriber_audit_files', {
      method: 'POST',
      body: data,
      credentials: 'include',
      headers: {
        Accept: 'application/json'
      }
    })
    if (!response.ok) {
      GLMToast('An error occurred uploading the file.', {className: 'alert alert-error'})
      return
    } else {
      GLMToast('License audit file uploaded.', {className: 'alert alert-info'})
    }
    const newfile = await response.json()
    let filelist = files.map(f => f)
    filelist.unshift(newfile)
    setFiles(filelist)
  }

  const handleFilesAdded = files => {
    files.forEach(f => postFile(f))
  }

  const handleUndelete = async id => {
    const response = await fetch(`/subscriber_audit_files/${id}?licenseid=${licenseid}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json'
      }
    })
    if (!response.ok) {
      GLMToast('An error occurred restoring the file.', {className: 'alert alert-error'})
      return
    }
    const newfile = await response.json()
    let filelist = files.filter(f => f.id !== id)
    filelist.unshift(newfile)
    setFiles(filelist)
  }

  const handleDelete = async id => {
    const response = await fetch(`/subscriber_audit_files/${id}?licenseid=${licenseid}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json'
      }
    })
    if (!response.ok) {
      GLMToast('An error occurred deleteing the file.', {className: 'alert alert-error'})
      return
    }
    const filelist = files.filter(f => f.id !== id)
    setFiles(filelist)
    GLMToast(<div>File deleted.  <Button variant='secondary' onClick={() => handleUndelete(id)}>Click to undo</Button></div>, {className: 'alert alert-info'})
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <FileDrop onFilesAdded={handleFilesAdded}
              buttonText='Upload CSV'
              accept=''
              multiple>
              <div className='text-muted'>
                <div className='text-center'>Drop CSV files here to upload.</div>
                <Label>Quarter: <Input type='number' value={quarter} min={1} max={4} onChange={e => setQuarter(e.target.value)} /></Label>&nbsp;&nbsp;
                <Label>Year: <Input type='number' value={year} min={2021} max={2099} onChange={e => setYear(e.target.value)} /></Label>
              </div>
            </FileDrop>
          </Col>
        </Row>
        <Row>
          <Col className='py1'>
            <Link href='/subscriber_audit_files/template_csv' target='_blank'>Download Template</Link>
          </Col>
        </Row>
        {files.length !== 0 &&
          <Row className='subscriber-row subscriber-row-header'>
            <Col lg={2}>File Name</Col>
            <Col lg={2}>Year</Col>
            <Col lg={1}>Quarter</Col>
            <Col lg={2}>Size</Col>
            <Col lg={3}>Uploaded</Col>
          </Row>
        }
        {files.length !== 0 &&
          <>
            {files.map((f,i) => {
              return <SubscriberFile key={i} rownumber={i} file={f} licenseid={licenseid} onDelete={handleDelete} />
            })}
          </>
        }
      </Container>
      <GLMToastContainer />
    </>
  )
}

export default SubscriberAuditFiles
