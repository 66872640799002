import React, {useState, useEffect, Fragment} from 'react'
import Spinner from './Spinner'
import { formatDateTime } from '../helpers/moment'

const SendgridMessageInfo = ({messageId, onClose, customEmailId}) => {
  const [msgInfo, setMsgInfo] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [sendgridErrors, setSendgridErrors] = useState()

  const loadMessageInfo = async () => {
    setLoading(true)
    setMsgInfo()
    setError()
    setSendgridErrors()

    const response = await fetch(`/custom_emails/sendgrid_message_info?sendgrid_message_id=${messageId}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      if (jsonData.error) {
        setError(jsonData.error)
      } else if (jsonData.errors) {
        setSendgridErrors(jsonData.errors)
      } else if (jsonData.message) {
        setError(jsonData.message)
      } else {
        setMsgInfo(jsonData)
      }
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  const renderStatus = (status) => {
    let bgColor = ''
    if (status === 'delivered') {
      bgColor = '#28a745'
    }
    if (status === 'not_delivered') {
      bgColor = '#E74C3C'
    }
    return <span className='badge' style={{backgroundColor: bgColor}}>{status}</span>
  }

  const renderSendgridErrors = () => {
    return (
      <div className='alert alert-danger'>
        <h4>Following Sendgrid errors returned</h4>
        <ul>
          {sendgridErrors.map((err, i) =>
            <li key={i}>{err.message}</li>
          )}
        </ul>
      </div>
    )
  }

  const handleClose = (e) => {
    e.preventDefault()
    onClose()
  }

  const renderCloseButton = () => {
    return (
      <div className='row' style={
        {top: 0, position: 'sticky', background: 'white', borderBottom: '1px solid #e5e5e5'}
      }>
        <div className='col-md-12'>
          <a href='#' onClick={handleClose}>
            <i className='fa fa-arrow-left' />
          </a>
        </div>
      </div>
    )
  }

  const renderEvents = () => {
    return (
      <Fragment>
        <hr />
        <div><b>Events</b></div>
        <table className='table'>
          <thead>
            <tr>
              <th><small>Name</small></th>
              <th><small>Reason</small></th>
              <th><small>Attempt Number</small></th>
              <th><small>Processed At</small></th>
              <th />
            </tr>
          </thead>
          <tbody>
            {msgInfo.events.map((event, i) =>
              <tr key={i}>
                <td><small>{event.event_name || 'N/A'}</small></td>
                <td><small>{event.reason || 'N/A'}</small></td>
                <td><small>{event.attempt_num || 'N/A'}</small></td>
                <td><small>{formatDateTime(event.processed)}</small></td>
              </tr>
            )}
          </tbody>
        </table>
      </Fragment>
    )
  }

  const renderMessageInfo = () => {
    return (
      <Fragment>
        <table className='table'>
          <tbody>
            <tr>
              <td><b><small>Subject:</small></b></td>
              <td><small>{msgInfo.subject}</small></td>
            </tr>
            <tr>
              <td><b><small>From:</small></b></td>
              <td><small>{msgInfo.from_email}</small></td>
            </tr>
            <tr>
              <td><b><small>To:</small></b></td>
              <td>
                <small>{msgInfo.to_email}</small>
                <a href={`/custom_emails/new?clone_email_id=${customEmailId}&sendTo=${encodeURIComponent(msgInfo.to_email)}`}>
                  &nbsp;<i className='fa fa-paper-plane' />
                </a>
              </td>
            </tr>
            <tr>
              <td><b><small>Status:</small></b></td>
              <td><small>{renderStatus(msgInfo.status)}</small></td>
            </tr>
          </tbody>
        </table>
        {msgInfo.events && msgInfo.events.length > 0 && renderEvents()}
      </Fragment>
    )
  }

  useEffect(() => {
    if (messageId) {
      loadMessageInfo()
    }
  }, [messageId])

  return (
    <Fragment>
      {renderCloseButton()}
      {loading && <div>Loading message details from sendgrid <Spinner /></div> }
      {error && <div className='alert alert-danger'> {error} </div>}
      {sendgridErrors && sendgridErrors.length > 0 && renderSendgridErrors()}
      {msgInfo && renderMessageInfo()}
    </Fragment>
  )
}

export default SendgridMessageInfo
