import React from 'react'
import Button from '../../Button'

const UsbTransferButton = props =>
  <Button
    data-toggle='tooltip'
    data-placement='top'
    className='btn-round'
    title='Transfer License'
    href={`/licenses/${props.licenseId}/usb_transfer`}>
    <i className='fa fa-users' />
  </Button>

export default UsbTransferButton
