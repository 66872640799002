import React, { Component } from 'react'
import { FormSection } from './Form'
import { Checkbox } from './Input'
import Label from '../Form/Label'

export const formData = hash => {
  return Object.values(hash).map(({email, id, checked}) => ({email, id, destroy: !checked}))
}

export const resumeEditState = array => {
  const items = {}
  array.map(i => {
    items[i.email] = {...items[i.email], checked: true, email: i.email, id: i.id}
  })
  return items
}

export const isChecked = (dict, item) => {
  return dict !== {} && dict[item] && !!dict[item].checked
}

export class AuthorizedUsersCheckboxList extends Component {
  static defaultProps = {
    checkedList: []
  }

  constructor (props) {
    super(props)
    this.state = {
      items: resumeEditState(props.checkedList)
    }
  }

  handleCheck = e => {
    const { ...items } = this.state.items
    const item = e.target
    const checked = this.state.items[item.value] && this.state.items[item.value].checked
    items[item.value] = { ...items[item.value], email: item.value, checked: !checked, id: item.id }
    this.handleState({ items })
  }

  handleState = values => {
    const { onChange, name } = this.props
    this.setState(
      { ...values },
      () => onChange({ name, value: formData(this.state.items) })
    )
  }

  render () {
    return (
      <FormSection>
        <Label>{this.props.label}</Label>
        {
          this.props.checkboxList.map((user, i) =>
            <div key={i} className='row m0 py1'>
              <Checkbox
                id={user.id}
                value={user.email}
                checked={isChecked(this.state.items, user.email)}
                onChange={this.handleCheck}
              />
            </div>
          )
        }
      </FormSection>
    )
  }
}

export default AuthorizedUsersCheckboxList
