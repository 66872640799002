import React from 'react'

import { FormGroup } from './Form'
import { Checkbox } from './Input'

export const AutoRenew = props =>
  <FormGroup className='boolean'>
    <Checkbox
      id='license_auto_renew'
      name='auto_renew'
      label='Enable Automatic Renewal'
      hint='License will renew 30 days prior to expiration date.'
      {...props}
    />
  </FormGroup>

export default AutoRenew
