import React, {useState, useEffect, Fragment} from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from '../../Spinner'
import 'isomorphic-fetch'
import Paginate from '../../Paginate'
import qs from 'query-string'
import { formatDateTimeUTC } from './helpers'

const TrialLeadChartEntries = ({chartItemRange, onClose}) => {
  const [loading, setLoading] = useState(false)
  const [trialLeads, setTrialLeads] = useState()
  const [paginationData, setPaginationData] = useState()
  const [error, setError] = useState()
  const [page, setPage] = useState(1)

  const loadData = async () => {
    const queryParams = qs.parse(window.location.search)
    const { startTime, endTime } = chartItemRange
    const fetchParams = {...queryParams, chart_start_time: startTime, chart_end_time: endTime, page}
    const fetchUrl = `/internal/harmony_metrics_charts/trial_lead_entries?${qs.stringify(fetchParams)}`
    setLoading(true)

    const response = await fetch(fetchUrl).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setTrialLeads(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [page])

  const renderTrialLeads = () => {
    return (
      <table className='table small-table sticky-header'>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Country</th>
            <th>State</th>
            <th>Company</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {trialLeads.map((trialLead, i) =>
            <tr key={i}>
              <td>{trialLead.first_name}</td>
              <td>{trialLead.last_name}</td>
              <td>{trialLead.email}</td>
              <td>{trialLead.phone_number}</td>
              <td>{trialLead.country}</td>
              <td>{trialLead.state}</td>
              <td>{trialLead.company}</td>
              <td>{formatDateTimeUTC(trialLead.created_at)}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <Modal show onHide={() => onClose()} size='lg' animation={false} dialogClassName='adjust-header'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>Trial Lead entries {paginationData && <span>({paginationData.total_count})</span>}</div>
          {trialLeads &&
            <small>
              {formatDateTimeUTC(chartItemRange.startTime)} - {formatDateTimeUTC(chartItemRange.endTime)}
            </small>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeigh: '400px'}}>
        {loading && <div><Spinner /></div> }
        {error && <div className='alert alert-danger'> {error} </div>}
        {trialLeads && renderTrialLeads()}
        { paginationData && paginationData.total_pages > 1 &&
        <Fragment>
          <hr />
          <Paginate
            pages={paginationData.total_pages}
            currentPage={paginationData.current_page}
            onPageNext={() => setPage(page + 1)}
            onPageBack={() => setPage(page - 1)}
            toPage={(pageNum) => setPage(pageNum)} />
        </Fragment>
        }
        {loading && paginationData && <Fragment>&nbsp;<Spinner /></Fragment>}
      </Modal.Body>
    </Modal>

  )
}

export default TrialLeadChartEntries
