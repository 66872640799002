import React, { useState, useRef, useEffect, Suspense, lazy } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import moment from 'moment'

const DateRangePicker = lazy(() => import('react-date-range').then(module => ({default: module.DateRangePicker})))

const DashboardDateSelector = ({startDate, endDate, onChange}) => {
  const dateRef = useRef(null)
  const DATE_FORMAT = 'DD/MM/YYYY'
  const [listening, setListening] = useState(false)
  const defaultStartDate = moment().subtract(1, 'month').startOf('month').toDate();
  const defaultEndDate = moment().subtract(1, 'month').endOf('month').toDate();
  let initStart;
  let initEnd;
  if (startDate) {
    try {
      initStart = moment(startDate, DATE_FORMAT).toDate()
    } catch (_) {
      initStart = defaultStartDate
    }
  }
  if (endDate) {
    try {
      initEnd = moment(endDate, DATE_FORMAT).toDate()
    } catch (_) {
      initEnd = defaultEndDate
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState([{
    startDate: initStart || defaultStartDate,
    endDate: initEnd || defaultEndDate,
    key: 'selection'
  }])
  const [newStart, setNewStart] = useState(initStart || defaultStartDate)
  const [newEnd, setNewEnd] = useState(initEnd || defaultEndDate)

  const listenForOutsideClicks = (listening, setListening, ref, setIsOpen) => {
    return () => {
      if (listening) return
      if (!ref.current) return
      setListening(true);
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (ref.current.contains(evt.target)) return;
          setIsOpen(false);
        });
      });
    }
  }

  useEffect(listenForOutsideClicks(
    listening,
    setListening,
    dateRef,
    setIsOpen
  ))

  const toggle = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  useEffect(()=>{
    if (!isOpen && onChange) {
      setTimeout(()=>{
        onChange({startDate: newStart, endDate: newEnd})
      })
    }
  },[isOpen, newStart, newEnd])

  const handleDateSelected = (selection) => {
    let {startDate, endDate, key} = selection
    setNewStart(startDate)
    setNewEnd(endDate)
    setState([{startDate, endDate, key}])
  }

  return (
    <div ref={dateRef}>
      <a href="#" onClick={toggle}><i className='fa fa-calendar'/></a>
      {!!newStart &&
        <span className='ml1'>{moment(newStart).format('MM/DD/YYYY')} - {moment(newEnd).format('MM/DD/YYYY')}</span>
      }
      {isOpen &&
        <Suspense fallback={<div>Loading resources...</div>}>
          <DateRangePicker
            onChange={item => handleDateSelected(item.selection)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            maxDate={new Date()}
            direction="horizontal"
            preventSnapRefocus={true}
          />
        </Suspense>
      }

    </div>
  )
}

export default DashboardDateSelector
