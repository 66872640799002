import React from 'react'
import Truncate from './Truncate'

const Link = ({ children, to, maxLength = 25, ...props }) =>
  <a href={to} {...props}>
    <Truncate maxLength={maxLength}>
      {children}
    </Truncate>
  </a>

export default Link
