import React, { Component, lazy, Suspense } from 'react'
import { formatBytes, formatBits } from '../../util/dataFormatters'
const CSVLink = lazy(() => import('react-csv').then(module => ({default: module.CSVLink})))

class Report extends Component {
  state = {
    throughputType: this.props.throughputType,
    throughputId: this.props.throughputId,
    data: [],
    buckets: [{name: '5Mbps', maxbits: 5242880, count: 0}, {name: '10Mbps', maxbits: 10485760, count: 0}, {name: '50Mbps', maxbits: 52428800, count: 0}, {name: '100Mbps', maxbits: 104857600, count: 0}, {name: '500Mbps', maxbits: 524288000, count: 0}, {name: '1Gbps', maxbits: 1073741824, count: 0}, {name: '10Gbps', maxbits: 10737418240, count: 0}, {name: '50Gbps', maxbits: 53687091200, count: 0}, {name: '100Gbps', maxbits: 107374182400, count: 0}, {name: '500Gbps', maxbits: 536870912000, count: 0}, {name: '1Tbps', maxbits: 1099511560222, count: 0}, {name: '10Tbps', maxbits: 10995115602220, count: 0}]
  }

  findBucket (bits) {
    const { buckets } = this.state
    for (let b in buckets) {
      if (bits <= buckets[b].maxbits) {
        return b
      }
    }
  }

  csvData = (allocateBandwidthBuckets, peakOverageBuckets, peakBuckets, startTime, title, usage, overage, peakBandwidth) => {
    const title2 = `Throughput Report for ${startTime.format('MMMM')} ${startTime.format('YYYY')}`
    const usage2 = `Usage ${formatBytes(usage)}`
    const overage2 = `Overage ${formatBytes(overage)}`
    const peakBandwidth2 = `Highest Throughput ${formatBits(peakBandwidth)}`
    const allocateBandwidthBucketsNames =
      allocateBandwidthBuckets.map(function (bucket, index) {
        return bucket.name
      })
    const allocateBandwidthBucketsData =
      allocateBandwidthBuckets.map(function (bucket, index) {
        return bucket.count
      })
    const allocatePeakOverageBucketsNames =
      peakOverageBuckets.map(function (bucket, index) {
        return bucket.name
      })
    const allocatePeakOverageBucketsData =
      peakOverageBuckets.map(function (bucket, index) {
        return bucket.count
      })
    const allocatePeakBucketsNames =
      peakBuckets.map(function (bucket, index) {
        return bucket.name
      })
    const allocatePeakBucketsData =
      peakBuckets.map(function (bucket, index) {
        return bucket.count
      })
    return [[], [title], [title2], [], [usage2], [overage2], [peakBandwidth2], [], ['Peak Throughput'], [...allocatePeakBucketsNames], [...allocatePeakBucketsData], ['Peak Allocated Throughput'], [...allocateBandwidthBucketsNames], [...allocateBandwidthBucketsData], ['Peak Overage Throughput'], [...allocatePeakOverageBucketsNames], [...allocatePeakOverageBucketsData]]
  }

  render = () => {
    const { previousMonth, resetMonth, nextMonth, throughputs, usage, overage, startTime, title } = this.props
    const { buckets } = this.state
    const allocateBandwidthBuckets = buckets.map(b => ({ ...b }))
    const peakOverageBuckets = buckets.map(b => ({ ...b }))
    const peakBuckets = buckets.map(b => ({ ...b }))
    let peakBandwidth = 0
    let b
    /* eslint-disable guard-for-in */
    for (let i in throughputs) {
      if (throughputs[i].peakBandwidthBits > peakBandwidth) {
        peakBandwidth = throughputs[i].peakBandwidthBits
      }

      b = this.findBucket(throughputs[i].allocateBandwidthBits)
      allocateBandwidthBuckets[b].count += 1

      b = this.findBucket(throughputs[i].peakOverageBandwidthBits)
      peakOverageBuckets[b].count += 1

      b = this.findBucket(throughputs[i].peakBandwidthBits)
      peakBuckets[b].count += 1
    }
    /* eslint-enable guard-for-in */

    return (<div>
      <div className='text-center'>
        <h4 className='text-muted h4 mt1'>Throughput Report for {startTime.format('MMMM')} {startTime.format('YYYY')}</h4>
        <h6 className='text-muted mt1'>Below is the number of 15 minute windows that have fallen into these usages</h6><br />
        <h6 className='text-muted h6 mt1'>
          Peak Throughput<br />
          <small>(Total 15 Minute window count)</small>
        </h6>
      </div>
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
            {peakBuckets.map(function (bucket, index) {
              return <th className='text-muted' key={index}>{bucket.name}</th>
            })}
          </tr>
          <tr>
            {peakBuckets.map(function (bucket, index) {
              return <td key={index}>{bucket.count}</td>
            })}
          </tr>
        </tbody>
      </table>
      <div className='text-center'>
        <h6 className='text-muted h6 mt1'>
          Peak Allocated Throughput<br />
          <small>(Total 15 Minute window count)</small>
        </h6>
      </div>
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
            {allocateBandwidthBuckets.map(function (bucket, index) {
              return <th className='text-muted' key={index}>{bucket.name}</th>
            })}
          </tr>
          <tr>
            {allocateBandwidthBuckets.map(function (bucket, index) {
              return <td key={index}>{bucket.count}</td>
            })}
          </tr>
        </tbody>
      </table>
      <div className='text-center'>
        <h6 className='text-muted h6 mt1'>
          Peak Overage Throughput<br />
          <small>(Total 15 Minute window count)</small>
        </h6>
      </div>
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
            {peakOverageBuckets.map(function (bucket, index) {
              return <th className='text-muted' key={index}>{bucket.name}</th>
            })}
          </tr>
          <tr>
            {peakOverageBuckets.map(function (bucket, index) {
              return <td key={index}>{bucket.count}</td>
            })}
          </tr>
        </tbody>
      </table>
      <div className='text-center'>
        <ul className='pagination'>
          <li><a onClick={previousMonth.bind(this)} className='prev'>← Previous</a></li>
          <li><a onClick={resetMonth.bind(this)}>Reset</a></li>
          <li><a onClick={nextMonth.bind(this)} className='next'>Next →</a></li>
        </ul><br />
        <Suspense fallback='Loading resources...'>
          <CSVLink filename={`${title}-overage-report${startTime.format('MMMM')}-${startTime.format('YYYY')}.csv`} data={this.csvData(allocateBandwidthBuckets, peakOverageBuckets, peakBuckets, startTime, title, usage, overage, peakBandwidth)}>CSV Download</CSVLink>
        </Suspense>
      </div>
    </div>)
  }
}

export default Report
