import React from 'react'
import { classStr } from './helpers/form'

const Button = ({
  className,
  variant = 'default',
  ...props
}) =>
  <a className={classStr('btn', `btn-${variant}`, className)} {...props} />

export default Button
