import React from 'react'

import {
  FormGroup,
  FormSection,
  FormText
} from './Form'
import { Checkbox } from './Input'

export const ShellAccess = props =>
  <FormSection>
    <FormGroup>
      <Checkbox
        id='license_shell_access'
        name='shell_access'
        label='Grant Console Access'
        hint='Allows special-case development from trusted partners.'
        {...props}
      />
    </FormGroup>
  </FormSection>

export default ShellAccess
