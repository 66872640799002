import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import WizardButtons from '../WizardButtons'

class VerifyEmail extends Component {
  state = {
    value: this.props.wizardState.verifyCode,
    nextEnabled: this.props.wizardState.verifyCode.length > 0
  }

  handleChange = e => {
    this.setState({
      value: e.target.value,
      nextEnabled: e.target.value.length > 0
    })
  }

  onNext = async () => {
    try {
      const response = await fetch(`/api/new_users/validate?token=${this.state.value}&email=${encodeURIComponent(this.props.wizardState.email)}`)
      if (response.ok) {
        const json = await response.json()
        if (json.valid) {
          this.props.onNext({ verifyCode: this.state.value })
          return
        }
      }
    } catch (error) {
      this.props.handleError(error)
      return
    }
    this.props.handleError('The validation code could not be verifed.  Please try again.')
  }

  onPrevious = () => {
    this.props.onPrevious({ verifyCode: this.state.value })
  }

  render () {
    return (
      <div>
        <div className='py1'>
          <label className='control-label'>Enter verification code you received in your email.</label>
          <Form.Control
            onChange={this.handleChange}
            value={this.state.value}
          />
        </div>
        <WizardButtons
          onPreviousClick={this.onPrevious}
          onNextClick={this.onNext}
          nextEnabled={this.state.nextEnabled}
        />
      </div>
    )
  }
}

export default VerifyEmail
