import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'
import { FormText } from './Form'
import { InputErrors } from './Input'

export const Hint = props =>
  <>
    <br />
    <FormText variant='muted' {...props} />
  </>

export const Wrapper = ({
  classNames = [],
  ...props
}) =>
  <Box
    classNames={['checkbox', ...classNames]}
    {...props}
  />

export const Checkbox = ({
  classNames = [],
  errors,
  hint,
  label,
  inline = false,
  ...props
}) => {
  const Outer = inline ? Wrapper : React.Fragment
  const htmlFor = props.name || props.id

  return (
    <Outer>
      <Box
        as='input'
        type='checkbox'
        classNames={['boolean', 'form-check-input', ...classNames]}
        checked={props.value}
        {...props}
      />
      {' '}{label || props.value}
      {hint && <Hint children={hint} />}
      {errors && <InputErrors>{errors}</InputErrors>}
    </Outer>
  )
}

Checkbox.propTypes = {
  classNames: PropTypes.array,
  errors: PropTypes.array,
  hint: PropTypes.string,
  inline: PropTypes.bool
}

export default Checkbox
