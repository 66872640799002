import React, { Component } from 'react'
import UserInput from './UserInput'
import UserFilter from './UserFilter'
import Spinner from '../Spinner'
import { uid } from '../helpers/util'
import { send } from '../containers/IdpAddEditForm'
import InfiniteScrollExtended from '../components/InfiniteScrollExtended'
import ThreatstopUsers from './ThreatstopUsers'

const newUser = (email = '', isNew = true, canDestroy = true, deleted = false, required = true) =>
  ({ email, isNew, canDestroy, deleted, required })

const grantAccessToUser = async (email, licenseId) => {
  return send(JSON.stringify(
    {
      authorization:
        {
          authorized_type: 'License',
          authorized_id: licenseId,
          email: email
        }
    }),
  'POST',
  '/authorizations.json')
}

const deleteAccessToUser = async authorizedId => {
  return fetch(`/authorizations/${authorizedId}.json`, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application.json',
      'Content-Type': 'application/json'
    }
  })
}

const loadAuthUsers = async (licenseId, page, query) => {
  return fetch(`/licenses/${licenseId}/authorized_users.json?page=${page}&query=${encodeURIComponent(query)}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  })
}

class UsersWithAccess extends Component {
  authRef = React.createRef();
  constructor (props) {
    super(props)
    const authUsers = (props.authUsers || []).reduce(
      (obj, user) => ({ ...obj, [uid()]: user }),
      {}
    )
    const newUid = uid()
    const ids = []

    this.state = {
      newUid: newUid,
      authUsers,
      ids,
      query: '',
      showError: false,
      errorMessage: '',
      moreAuthUsers: true,
      startPage: 1,
      initialLoad: false
    }
  }

  componentDidMount () {
    this.loadAuthUsers(1)
  }

  componentDidUpdate () {
    $('.license-users-cont [data-toggle]').tooltip({container: 'body'})
  }

  addUser = async (email, licenseId) => {
    const response = await grantAccessToUser(email, licenseId)

    if (response.errors) {
      this.setState({ showError: true, errorMessage: response.errors })
    } else {
      this.setState({ authUsers: {}, ids: [], showError: false, startPage: this.state.startPage - 1, initialLoad: true, moreAuthUsers: true })
    }
  }

  removeUser = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      const response = await deleteAccessToUser(id)
      const json = await response.json()

      if (response.ok) {
        this.setState({ authUsers: {}, ids: [], showError: false, startPage: this.state.startPage - 1, initialLoad: true, moreAuthUsers: true })
      } else {
        this.setState({ showError: true, errorMessage: json.errors })
      }
    }
  }

  dismissError = () => {
    this.setState({ showError: false })
  }

  loadAuthUsers = async (page) => {
    const response = await loadAuthUsers(this.props.licenseId, page, this.state.query)
    const json = await response.json()
    let ids = []
    let authUsers = []
    if (json.length > 0) {
      authUsers = (json || []).reduce(
        (obj, user) => ({ ...obj, [uid()]: user }),
        {}
      )
      if (page > 1) {
        authUsers = {...this.state.authUsers, ...authUsers}
      }
      ids = Object.keys(authUsers)
      this.setState({ids, authUsers, moreAuthUsers: json.length === this.props.pageLength})
    } else {
      this.setState({ moreAuthUsers: false })
    }

    if (page === 1) {
      this.authRef.current.scrollTop = 0
    }
  }

  onFilterChange = (values) => {
    this.setState({moreAuthUsers: false, startPage: this.state.startPage - 1, query: values.user_filter}, () => {
      this.loadAuthUsers(1)
    })
  }

  render () {
    const { authUsers, ids, newUid, showError, errorMessage, startPage, initialLoad, moreAuthUsers, query } = this.state
    return (
      <div className='license-users-cont'>
        <div className='row'>
          <div className='col-md-5'>
            <h1 className='h3'>
              Users With Access
            </h1>
          </div>
          <div className='col-md-7'>
            <div className='container-fluid'>
              <UserFilter handleFilterChange={this.onFilterChange} />
            </div>
          </div>
        </div>
        { !!showError &&
          <div
            className='alert alert-danger alert-dismissable'
            role='alert'>
            <div
              className='close'
              datadismiss='alert'
              arialabel='close'
              onClick={this.dismissError}>
              <span ariahidden='true'>&times;</span>
            </div>
            {errorMessage}
          </div>
        }
        <div className='row'>
          {
            this.props.orgId && <div className='col-md-12 p1'>
              <i>
              Organization <a href={`/organizations/${this.props.orgId}/users`}>owners and admins</a> have access to this license.
              </i>

              { this.props.showThreatstopUsers && <ThreatstopUsers licenseId={this.props.licenseId}/>}
            </div>
          }
        </div>
        <div className='row'>
          <div className='col-md-9'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <h4>Users with granted access to this license</h4>
                </div>
              </div>
              {this.props.canAuthorizeUser && <div className='row'>
                <div className='col-md-12'>
                  <UserInput
                    values={newUser()}
                    newUid={newUid}
                    licenseId={this.props.licenseId}
                    onAccess={this.addUser}
                  />
                </div>
              </div>}
              <div className='row'>
                <div className='col-md-12'>
                  <div className='auto-scrollable' ref={this.authRef}>
                    <InfiniteScrollExtended
                      pageStart={startPage}
                      initialLoad={initialLoad}
                      loadMore={this.loadAuthUsers}
                      hasMore={moreAuthUsers}
                      loader={<div className='col-sm-9 px0 text-center' key='auth_loading'><Spinner className='align-bottom' /></div>}
                      useWindow={false}>
                      {ids.map((key, index) =>
                        <UserInput
                          key={key}
                          id={key}
                          index={index}
                          values={authUsers[key]}
                          newUid={newUid}
                          licenseId={this.props.licenseId}
                          onAccess={this.addUser}
                          onDelete={this.removeUser}
                        />
                      )}
                      {!ids.length && !!query.length &&
                        <div>
                          <i>No users match '{query}'</i>
                        </div>
                      }
                    </InfiniteScrollExtended>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UsersWithAccess
