import React, {Fragment, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import startCase from 'lodash/startCase'


const TrialRow = ({ trialLeads }) => {
  const [trialLead, setTrialLead] = useState()

  const handleClick = (event, trialLeadInfo) => {
    event.preventDefault()
    setTrialLead(trialLeadInfo)
  }
  const isEmpty = (value) => {
    return (value == null || value.trim().length === 0)
  }
  const print = (value, prefix='', postfix='') => {
    if (!isEmpty(value)) {
      return prefix + value + postfix
    }
    return ""
  }

  const renderTrialLeads = () => (
    trialLeads.map(lead =>
      <tr key={lead.id}>
        <td>
          <p className='ellipsis'>
            <i className='fa fa-envelope' />{` ${lead.attributes.email || 'Unavailable'}`}
          </p>
          <p className='ellipsis'>
            <i className='fa fa-phone' />{` ${lead.attributes.phone_number || 'Unavailable'}`}
          </p>
        </td>
        <td className='ellipsis'>
          <p><a href={`/licenses/${lead.attributes.license_id}`}>{lead.attributes.license_name}</a></p>
          <p style={{ marginBottom: 0 }}><small>{lead.attributes.license_type}</small></p>
          <p style={{ marginBottom: 0 }}><small>{lead.attributes.platform}</small></p>
          {!lead.attributes.license_id && '-'}
        </td>
        <td className='ellipsis'>
          {lead.attributes.organization_id > 0 && <p style={{ marginBottom: 0 }}><a href={`/organizations/${lead.attributes.organization_id}`}>{`${lead.attributes.organization_name || 'No Name'}`}</a></p>}
          {!lead.attributes.organization_name && <p>-</p>}
          <p>
            {lead.attributes.organization_created &&
              'Created thru A10 trials'}
          </p>
        </td>
        <td className='ellipsis'><p>{lead.attributes.created_at}</p></td>
        <td className='ellipsis'>
          <p>
            <a href="#" className="btn btn-default btn-round pull-left mr3px" onClick={(e) => handleClick(e, lead.attributes)}><i class='fa fa-search'/></a>
          </p>
        </td>
      </tr>
    )
  )

  const renderTrialLeadModal = () => {
    const validKeys = keys(omit(trialLead, ['marketo_cookie', 'utmvalues', 'contract_id', 'id', 'created_at', 'updated_at','end_of_created_date']))
    const tdStyle = {padding: '2px 8px'}
    return (
      <Modal show onHide={()=> setTrialLead()} size='lg' animation={false} dialogClassName='adjust-header'>
        <Modal.Header closeButton>
          <Modal.Title>
            Trial Lead Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='table table-bordered'>
            <tbody>
              {validKeys.map(key =>
                !!trialLead[key] &&
                  <tr>
                    <td style={tdStyle}><small><b>{startCase(key)}</b></small></td>
                    <td style={tdStyle}><small>{trialLead[key]}</small></td>
                  </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Fragment>
      {renderTrialLeads()}
      {trialLead && renderTrialLeadModal()}
    </Fragment>
  )
}

export default React.memo(TrialRow)
