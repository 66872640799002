import React from 'react'
import Box from './Box'
import { FormGroup } from './Form'
import { Input, Label, InputErrors } from './Input'
import Button from './Button'

class OrganizationQuickAdd extends React.Component {
  state = {
    companyName: '',
    contactPhone: '',
    companyNameClass: '',
    errors: []
  }

  onCompanyChange = (e) => {
    this.setState({
      companyName: e.target.value
    })
  }

  onPhoneChange = (e) => {
    this.setState({
      contactPhone: e.target.value
    })
  }

  validateCompanyName = () => {
    let className = ''
    let error = null
    if (!this.state.companyName || this.state.companyName.trim().length === 0) {
      error = 'Company name is required'
      className = 'alert-danger'
    }
    this.setState({
      companyNameClass: className
    })
    return error
  }

  validate = () => {
    const errors = []

    let error = this.validateCompanyName()
    if (error) {
      errors.push(error)
    }
    this.setState({
      errors: errors
    })
    return errors.length === 0
  }

  onClick = async () => {
    if (!this.validate()){
      return
    }
    const org = {
      companyName: this.state.companyName,
      contactPhone: this.state.contactPhone
    }
    const errors = await this.props.onSubmit(org)
    if (errors && errors.length > 0){
      this.setState({
        errors: errors
      })
    } else {
      if(this.props.returnUrl) {
        window.location.href = this.props.returnUrl
      }
    }
  }

  render() {
    if (!this.props.visible) {
      return null
    }
    return (
      <div className='row'>
        {this.state.errors &&
          <InputErrors>
            {
              this.state.errors.map((e,i) => {
                return <li key={i}>{e}</li>
              })
            }
          </InputErrors>
        }
        <FormGroup className='container-fluid p0'>
          <Box className='col-12 py1'>
            <Label required type='string'>
              Company Name
            </Label>
            <Input required
              type='text'
              className={this.state.companyNameClass}
              value={this.state.companyName}
              onChange={this.onCompanyChange}
            />
          </Box>
          <Box className='col-12 py1'>
            <Label type='string'>
              Contact Phone Number
            </Label>
            <Input type='text'
              value={this.state.contactPhone}
              onChange={this.onPhoneChange}
            />
          </Box>
          <Box className='col-12 py1'>
            <Button type='button'
              className='btn btn-primary pull-right'
              onClick={this.onClick}
            >Create</Button>
          </Box>
        </FormGroup>
      </div>
    )
  }
}

export default OrganizationQuickAdd
