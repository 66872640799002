import React, { useState, useContext } from 'react'
import { FormContext } from '../containers/./Form'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from './Select'
import PortEditor from './PortEditor'
import { Label } from './Input'
import { lableize } from '../../util/string'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const portLableize = str => lableize(str.replace(/PORT/gi, "").replace(/_G_/gi, "_GIG_"))

function PortSelector (props) {

  const [ports, setPorts] = useState({...props.port_array})
  const [availablePorts, setAvailablePorts] = useState(Object.keys(props.port_array).filter((p) => props.port_array[p] === null).map((p) => {
    return {value: p, label: portLableize(p)}
  }))
  const [unlimited, setUnlimited] = useState(Object.keys(props.port_array).filter((p) => props.port_array[p] !== null).length === 0)
  const {
    handleChange
  } = useContext(FormContext)

  const handleSelectChange = (e) => {
    let newPorts = {...ports}
    newPorts[e.target.value] = 1
    setPorts(newPorts)
    setAvailablePorts(Object.keys(newPorts).filter((p) => newPorts[p] === null).map((p) => {
      return {value: p, label: portLableize(p)}
    }))
  }

  const handleDelete = (key) => {
    let newPorts = {...ports}
    newPorts[key] = null
    const resultingPorts = Object.keys(newPorts).filter((p) => newPorts[p] === null).map((p) => {
      return {value: p, label: portLableize(p)}
    })
    setPorts(newPorts)
    setAvailablePorts(resultingPorts)
    setUnlimited(Object.keys(newPorts).filter((v,i) => newPorts[v] !== null).length === 0)
  }

  const saveUnlimited = () => {
    Object.keys(props.port_array).forEach((v, i) =>  {
      const target={
        name: v,
        value: null,
        type: 'input'
      }
      handleChange(target)
    })
  }

  return (
    <div className="port-selector">
      <Label type='string'>
        Available Ports
      </Label>
      <br/>
      <BootstrapSwitchButton
        checked={unlimited}
        onlabel={`UNLIMITED`}
        width={450}
        offlabel={`LIMITED`}
        onChange={(checked) => {
          setUnlimited(checked)
          if (checked) {
            saveUnlimited()
          }
        }}
      />
      { unlimited &&
          <Form.Group className='ml2'>
            <Row>
              <Col md={9} className='pt2'>
                UNLIMITED Ports
              </Col>
            </Row>
          </Form.Group> }
      { !unlimited &&
          <>
            <br/><br/>
            <Select
              placeholder={'Add Available Ports'}
              className='form-control'
              children={availablePorts}
              onChange={handleSelectChange}
            />
            <br/>
            { Object.keys(ports).filter((v,i) => ports[v] !== null).length !== 0 && <Row className="fieldsetborder">
              <Col sm={12}>
                <PortEditor
                  ports={ports}
                  onDelete={handleDelete}
                />
              </Col>
            </Row> }
            <br/>
          </>
      }
    </div>
  )
}

export default PortSelector
