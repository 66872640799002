import React from 'react'
import Label from './Form/Label'

const RadioField = ({ label, ...props }) =>
  <div className='radio'>
    <Label>
      <input
        type='radio'
        {...props}
      />
      {label}
    </Label>
  </div>

export default RadioField
