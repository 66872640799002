import React, { useEffect, useState, Fragment, useRef } from 'react'
import Spinner from '../Spinner'
import { formatDateTime } from '../../helpers/moment'
import ActionButtons from './ActionButtons'
/* eslint-disable complexity */
const RecipientListShow = ({id}) => {
  const [list, setList] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`/email_lists/${id}.json`, { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        setList(data.email_list)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])

  const renderEmails = (emails) => {
    return (
      <div className='row'>
        {emails.map((email, i) =>
          <div className='col-md-3 small' key={i} style={{wordWrap: 'break-word', marginBottom: '10px'}}>
            {email}
          </div>
        )}
      </div>
    )
  }

  if (!loading && !list) {
    return <h3 className='text-center'>Not Found.</h3>
  }

  if (loading) {
    return <div className='text-center'><Spinner /></div>
  }

  if (list) {
    return (
      <Fragment>
        <div className='row'>
          <div className='col-md-10'>
            <h1 className='h3'>{ list.name }</h1>
          </div>
          <div className='col-md-2 text-right'>
            <ActionButtons id={list.id} />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <small>
              <b> Created At:</b> {formatDateTime(list.created_at)} | 
              <b> Recipients Count:</b> {list.emails.length} |
              <b> Email Blasts:</b> {list.custom_emails_count}
            </small>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-12'>
            <b> Recipient List:</b>
            { list.emails && list.emails.length > 0 &&
            renderEmails(list.emails)
            }
            <hr />
          </div>
        </div>
      </Fragment>
    )
  } else {
    return <Fragment />
  }
}
/* eslint-enable complexity */

export default RecipientListShow
