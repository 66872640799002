import { getMomentStr } from '../util/time'

export const fireEyeData = start => ([
  {
    label: 'One year',
    value: getMomentStr(start, '1', 'year')
  },
  {
    label: 'Two years',
    value: getMomentStr(start, '2', 'years')
  },
  {
    label: 'Three years',
    value: getMomentStr(start, '3', 'years')
  },
  {
    label: 'Four years',
    value: getMomentStr(start, '4', 'years')
  },
  {
    label: 'Five years',
    value: getMomentStr(start, '5', 'years')
  }
])

export const flexpoolThreatstopCapAndThunderData = start => ([
  {
    label: 'One year',
    value: getMomentStr(start, '1', 'year')
  },
  {
    label: 'Three Years',
    value: getMomentStr(start, '3', 'years')
  },
  {
    label: 'Five Years',
    value: getMomentStr(start, '5', 'years')
  },
  {
    label: 'Custom',
    value: getMomentStr(start, '2', 'weeks')
  }
])

export const neverAndYearsToFive = start => ([
  {
    label: 'Never',
    value: ''
  },
  {
    label: 'One year',
    value: getMomentStr(start, '1', 'year')
  },
  {
    label: 'Three Years',
    value: getMomentStr(start, '3', 'years')
  },
  {
    label: 'Five Years',
    value: getMomentStr(start, '5', 'years')
  },
  {
    label: 'Custom',
    value: getMomentStr(start, '2', 'weeks')
  }
])

export const perpetualData = start => ([
  {
    label: 'Never',
    value: ''
  },
  {
    label: '30 Days',
    value: getMomentStr(start, '30', 'days')
  },
  {
    label: '60 Days',
    value: getMomentStr(start, '60', 'days')
  },
  {
    label: '90 Days',
    value: getMomentStr(start, '90', 'days')
  },
  {
    label: 'One year',
    value: getMomentStr(start, '1', 'year')
  },
  {
    label: 'Custom',
    value: getMomentStr(start, '2', 'weeks')
  }
])

export const oneYear = start => ([
  {
    label: '30 Days',
    value: getMomentStr(start, '30', 'days')
  },
  {
    label: '60 Days',
    value: getMomentStr(start, '60', 'days')
  },
  {
    label: '90 Days',
    value: getMomentStr(start, '90', 'days')
  },
  {
    label: 'One year',
    value: getMomentStr(start, '1', 'year')
  },
  {
    label: 'Custom',
    value: getMomentStr(start, '2', 'weeks')
  }
])

export const trialData = start => ([
  {
    label: '30 Days',
    value: getMomentStr(start, '30', 'days')
  },
  {
    label: '60 Days',
    value: getMomentStr(start, '60', 'days')
  },
  {
    label: '90 Days',
    value: getMomentStr(start, '90', 'days')
  },
  {
    label: 'Custom',
    value: getMomentStr(start, '2', 'weeks')
  }
])

export const qosmosData = start => ([
  {
    label: '30 Days',
    value: getMomentStr(start, '30', 'days')
  },
  {
    label: '60 Days',
    value: getMomentStr(start, '60', 'days')
  },
  {
    label: '90 Days',
    value: getMomentStr(start, '90', 'days')
  },
  {
    label: 'Custom',
    value: getMomentStr(start, '2', 'weeks')
  }
])

export const threatstoptrialData = start => ([
  {
    label: '60 Days',
    value: getMomentStr(start, '60', 'days')
  }
])

const showNever = (canSetNever, sku) => {
  if (canSetNever && !sku.flexpool && !sku.trial) {
    return [
      {
        label: 'Never',
        value: ''
      }
    ]
  } else {
    return []
  }
}

export const checkSkuTypes = sku => (
  (sku.flexpool || sku.platform === 'thunder' || sku.platform === 'threatstop' || sku.platform === 'cap_visibility' || sku.platform === 'a10_ti')
)

export const data = (start, sku, canSetNever, reason) => {
  if (sku.trial) return sku.platform === 'threatstop' ? threatstoptrialData(start) : showNever(canSetNever, sku).concat(trialData(start))
  if (reason === 'customer_poc') return trialData(start)
  if (reason === 'internal_testing') return oneYear(start)
  if (sku.platform === 'ngwaf' || (sku.platform === 'threatstop' && !sku.trial)) return flexpoolThreatstopCapAndThunderData(start)
  if (sku.platform === 'thunder' && canSetNever) return neverAndYearsToFive(start)
  if (sku.platform === 'qosmos') return qosmosData(start)
  if (sku.name === 'hc-sw-mbu-unlimited' || sku.name === 'hc-saas-mbu-unlimited') return neverAndYearsToFive(start)
  if (checkSkuTypes(sku)) return showNever(canSetNever, sku).concat(flexpoolThreatstopCapAndThunderData(start))
  if (sku.platform === 'fire_eye') return fireEyeData(start)
  return perpetualData(start)
}
