import {useState, useEffect} from 'react'
import { VictoryVoronoiContainer } from 'victory';

const useVictory = () => {
  const [victory, setVictory] = useState();
  
  useEffect(() => {
    const loadVictory = async () => {
      const module = await import('victory')
      setVictory({
        Chart: module.VictoryChart,
        Axis: module.VictoryAxis,
        Bar: module.VictoryBar,
        Theme: module.VictoryTheme,
        ZoomContainer: module.VictoryZoomContainer,
        Tooltip: module.VictoryTooltip,
        Legend: module.VictoryLegend,
        Stack: module.VictoryStack,
        VoronoiContainer: module.VictoryVoronoiContainer,
        Line: module.VictoryLine
      })
    }

    loadVictory()
  }, []);
  
  return victory;
}

export default useVictory
