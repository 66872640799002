import React, {useState, useEffect, Fragment, Suspense} from 'react'
import map from 'lodash/map'
import Spinner from '../../Spinner'
import isUndefined from 'lodash/isUndefined'
import momenttz from 'moment-timezone'

momenttz.tz.setDefault('UTC')

const ReactHighcharts = React.lazy(() => import('react-highcharts'))

const MetricsChart = ({fetchURL, yTitle, title, onClick, interval, onIntervalChange, chartType, intervalDependent}) => {
  const [chartConfig, setChartConfig] = useState([])
  const [chartInterval, seChartInterval] = useState(interval || 'day')
  const [disabledInterval, setDisabledInterval] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const intervalKeys = ['day', 'week', 'month', 'year']

  useEffect(() => {
    if (onIntervalChange) {
      onIntervalChange(chartInterval)
    }
  }, [chartInterval])

  const handleChartClick = (category, cInterval) => {
    if (isUndefined(onClick)) {
      return
    }
    const range = {}
    // date format for week and day
    let dateFormat = 'MMM-DD YYYY'
    if (cInterval === 'month') {
      dateFormat = 'MMM-YYYY'
    } else if (cInterval === 'year') {
      dateFormat = 'YYYY'
    }
    const momentObj = momenttz(category, dateFormat)
    range.startTime = momentObj.format()
    range.endTime = momentObj.endOf(cInterval).format()
    onClick(range)
  }

  const prepareChartData = (data, cInterval) => {
    const config = {
      chart: {
        type: chartType
      },
      title: {
        text: title
      },
      xAxis: {
        categories: map(Object.keys(data), item => item),
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: yTitle
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.32,
          borderWidth: 0
        }
      },
      series: [{
        name: yTitle,
        animation: false,
        data: map(Object.values(data), item => item),
        cursor: isUndefined(onClick) ? '' : 'pointer',
        point: {
          events: {
            click: (e) => handleChartClick(e.point.category, cInterval)
          }
        }
      }]
    }

    setChartConfig(config)
  }

  const handleChartInterval = (event, interval) => {
    event.preventDefault()
    if (intervalDependent) {
      loadChartData(interval)
    }
    seChartInterval(interval)
  }

  const loadChartData = (int) => {
    setLoading(true)
    let intervalParam = `&interval=${int}`
    if (fetchURL.indexOf('?') === -1) {
      intervalParam = `?interval=${int}`
    }

    fetch(`${fetchURL}${intervalParam}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })
      .then((response) => {
        if (response.status === 200 || response.status === 422) {
          return response.json()
        } else {
          throw new Error(response.statusText || 'Something went wrong')
        }
      })
      .then(jsonData => {
        if (jsonData.error) {
          throw new Error(jsonData.error)
        } else {
          setLoading(false)
          /* eslint-disable camelcase */
          const { data, chart_interval } = jsonData
          // If the interval passed is not returned that means backend range validation updated the interval
          // so disable the passed interval
          if (int !== chart_interval) {
            setDisabledInterval(intervalKeys[intervalKeys.indexOf(chart_interval) - 1])
          }
          seChartInterval(chart_interval)
          prepareChartData(data, chart_interval)
          /* eslint-enable camelcase */
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message)
      })
  }

  useEffect(() => {
    loadChartData(chartInterval)
  }, [fetchURL])

  const btnStyle = {
    outline: 'none',
    padding: '0 4px',
    fontSize: '12px',
    fontWeight: 'bold'
  }

  if (!loading && error) {
    return <div className='alert alert-danger'>{error}</div>
  }

  return (
    <Fragment>
      <div className='btn-group' disabled={loading}>

        <button
          className={`${chartInterval === 'day' ? 'btn btn-primary' : 'btn btn-secondary'}`}
          onClick={(e) => handleChartInterval(e, 'day')}
          style={btnStyle}
          disabled={['day', 'week', 'month'].indexOf(disabledInterval) > -1}
        >
            Daily
        </button>
        <button
          className={`${chartInterval === 'week' ? 'btn btn-primary' : 'btn btn-secondary'}`}
          onClick={(e) => handleChartInterval(e, 'week')}
          style={btnStyle}
          disabled={['week', 'month'].indexOf(disabledInterval) > -1}
        >
            Weekly
        </button>
        <button
          className={`${chartInterval === 'month' ? 'btn btn-primary' : 'btn btn-secondary'}`}
          onClick={(e) => handleChartInterval(e, 'month')}
          style={btnStyle}
          disabled={['month'].indexOf(disabledInterval) > -1}
        >
            Monthly
        </button>
        <button
          className={`${chartInterval === 'year' ? 'btn btn-primary' : 'btn btn-secondary'}`}
          onClick={(e) => handleChartInterval(e, 'year')}
          style={btnStyle}
        >
            Yearly
        </button>
      </div>
      { !loading && <Suspense fallback={<div><Spinner /> </div>}><ReactHighcharts config={chartConfig} /></Suspense> }
      { loading && <div><Spinner /> </div>}
    </Fragment>
  )
}

MetricsChart.defaultProps = {
  chartType: 'column',
  intervalDependent: true
}

export default React.memo(MetricsChart)
