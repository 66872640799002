import React, { Component } from 'react'
import WizardButtons from '../WizardButtons'

class Finished extends Component {
  onNext = () => {
    window.location.href = '/'
  }

  render () {
    return (
      <div>
        <div className='py1'>
          You have successfully created an account. We will be notified to approve your role. Click "Finish" to return to the home page.
        </div>
        <WizardButtons
          previousHidden
          onNextClick={this.onNext}
          nextEnabled
          nextText='Finish'
        />
      </div>
    )
  }
}

export default Finished
