import React from 'react'

const PrettyJson = ({json}) => {
  const syntaxHighlight = (json) => {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var cls = 'text-warning';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'text-danger';
        } else {
          cls = 'text-success';
        }
      } else if (/true|false/.test(match)) {
        cls = 'text-primary';
      } else if (/null/.test(match)) {
        cls = 'text-secondary';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

  return <pre 
    style={{fontSize: '14px'}}
    dangerouslySetInnerHTML={{__html: syntaxHighlight(JSON.stringify(json, null, '  '))}} />
}

export default PrettyJson
