import React, { useState, Fragment, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button } from './../components/Button'
import Spinner from './../components/Spinner'
import Form from 'react-bootstrap/Form'
import { isValidPhoneNumber} from 'react-phone-number-input'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'
import PhoneField from '../components/PhoneField'

const OneClickTrialWithTrialLead = ({orgId, trialType, description, canCreate}) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [formLoaded, setFormLoaded] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [statesLoading, setStatesLoading] = useState(false)
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])

  const formEl = useRef()

  const initialFormData = {
    first_name: '',
    last_name: '',
    phone_number: '',
    country: '',
    state: '',
    company: ''
  }

  const btnStyle = {background: '#94438d', color: '#fff'}

  const formFieldSpinner = () => {
    return (
      <div className='form-control text-center'>
        <Spinner style={{marginTop: '0px', fontSize: '21px'}} />
      </div>
    )
  }

  const [formData, setFormData] = useState(initialFormData)

  const loadInfo = async (url, loadinFn, setFn, key) => {
    loadinFn(true)

    const response = await fetch(url).catch(e => {
      setError('Something went wrong')
      loadinFn(false)
    })

    if (response.status === 200) {
      setFormLoaded(true)
      const jsonData = await response.json()
      if (jsonData[key]) {
        setFn(jsonData[key])
      }
    } else {
      setError(response.statusText || 'something went wrong')
    }
    loadinFn(false)
  }

  const handleCloseModal = () => {
    setLoading(false)
    setError()
    setFormLoaded(false)
    setFormSubmitting(false)
    setStatesLoading(false)
    setStates([])
    setShowModal(false)
    setFormData(initialFormData)
  }

  const handleChange = (e, key) => {
    const changes = {[key]: e.target.value}
    if (key === 'country') {
      changes.state = ''
    }
    setFormData({...formData, ...changes})
  }

  const handlePhoneNumberChange = value => {
    setFormData({...formData, phone_number: value})
  }

  const postData = async() => {
    const response = await fetch(`/licenses/create_one_click_trial.json`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({trial_lead: {...formData, org_id: orgId, trial_type: trialType} })
    }).catch(e => {
      setError('Something went wrong')
      setFormSubmitting(false)
    })
    setFormSubmitting(false)
    if ([200, 201, 412, 422].indexOf(response.status) > -1) {
      const json = await response.json()
      if (json.license && json.license.id) {
        window.location.href = `/licenses/${json.license.id}?success=true`
      } else if (json.error) {
        if (json.error === 'Limit exceeded') {
          GLMToast(`You have reached your limit with ${description}s. Please contact A10 Sales for a full license.`,
            {className: 'alert alert-warning'})
          handleCloseModal()
        } else {
          GLMToast(json.error, {className: 'alert alert-danger'})
        }
      }
    } else {
      setError(response.statusText || 'something went wrong')
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (formEl.current.checkValidity() === true) {
      setError()
      setFormSubmitting(true)
      if (!isValidPhoneNumber(formData.phone_number)) {
        setError('Invalid phone number')
        setFormSubmitting(false)
      } else {
        postData()
      }
    }
  }

  useEffect(() => {
    if (showModal && !formLoaded) {
      loadInfo(`/users/latest_trial_lead_information`, setLoading, setFormData, 'trial_lead')
    }
    if (showModal && countries.length === 0) {
      loadInfo(`/api/ordered_countries`, setCountriesLoading, setCountries, 'countries')
    }
  }, [showModal])

  useEffect(() => {
    if (formData.country) {
      setStates([])
      loadInfo(`/api/countries/${formData.country}/subdivisions`, setStatesLoading, setStates, 'subdivisions')
    }
  }, [formData.country])

  const renderForm = () => {
    return (
      <Form className='row' ref={formEl} onSubmit={handleSubmit}>
        <Form.Group className='col-md-4'>
          <Form.Label size='sm' >Deployment Environment</Form.Label>
          <Form.Control size='sm' required as='select' value={formData.hypervisor} onChange={(e) => handleChange(e, 'hypervisor')}>
            <option value=''>Select</option>
            {[['ESXI_6_5_ABOVE', 'VMware ESXI 6.5 and above'], ['ESXI_6_4_BELOW', 'VMware ESXI 6.4 and below'], ['HYPER-V', 'Microsoft Hyper-V'], ['KVM', 'KVM'], ['OTHER','Other hypervisor']].map((key, i) =>
              <option key={i} value={key[0]}>
                {key[1]}
              </option>
            )}
          </Form.Control>
        </Form.Group>
        <div className='col-md-12'><hr style={{marginTop: '0', marginBottom: '8px'}} /></div>
        <Form.Group className='col-md-4'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder=''
            value={formData.first_name} onChange={(e) => handleChange(e, 'first_name')} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder=''
            value={formData.last_name} onChange={(e) => handleChange(e, 'last_name')} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder=''
            value={formData.company} onChange={(e) => handleChange(e, 'company')} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Telephone</Form.Label>
          <PhoneField
            required
            placeholder=''
            value={formData.phone_number}
            onChange={(handlePhoneNumberChange)} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Country</Form.Label>
          {countriesLoading && formFieldSpinner() }
          {countries.length > 0 && !countriesLoading &&
          <Form.Control required as='select' value={formData.country} onChange={(e) => handleChange(e, 'country')}>
            <option value=''>Select</option>
            {countries.map((country, i) =>
              <option key={i} value={country[1]}>
                {country[0]}
              </option>
            )}
          </Form.Control>
          }
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>State</Form.Label>
          {statesLoading && formFieldSpinner() }
          {!statesLoading && states.length === 0 && <Form.Control as='input' disabled placeholder='Not available' />}
          { states.length > 0 && !statesLoading &&
          <Form.Control required as='select' value={formData.state} onChange={(e) => handleChange(e, 'state')}>
            <option value=''>Select</option>
            {states.map((key, i) =>
              <option key={i} value={key.code}>
                {key.name}
              </option>
            )}
          </Form.Control>
          }
        </Form.Group>

        <Form.Group className='col-md-12 text-right'>
          <button
            className='btn btn-primary'
            style={btnStyle}
            disabled={loading || countriesLoading || statesLoading || formSubmitting}
            onSubmit={handleSubmit}>
            { formSubmitting && 'Submitting'}
            { !formSubmitting && 'Submit'}
          </button>
        </Form.Group>
      </Form>
    )
  }

  const renderModal = () => {
    return (
      <Modal show onHide={handleCloseModal} size='lg' animation={false} dialogClassName='adjust-header'>
        <Modal.Header closeButton>
          <Modal.Title>{description.toUpperCase()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Spinner /> }
          {error && <div className='alert alert-danger'> {error} </div>}
          {!loading && renderForm()}
        </Modal.Body>
      </Modal>
    )
  }

  const handleOpenModal = () => {
    if (canCreate) {
      setShowModal(true)
    }
  }

  return (
    <Fragment>
      <Button
        style={btnStyle}
        classNames={['center']}
        disabled={!canCreate}
        data-toggle={!canCreate && 'tooltip'}
        title={!canCreate && 'You have reached your max trial license limit of 5 for selected type.'}
        onClick={handleOpenModal}>
        START FREE TRIAL
      </Button>
      {showModal && renderModal()}
      <GLMToastContainer/>
    </Fragment>
  )
}

export default OneClickTrialWithTrialLead
