import React, { useContext } from 'react'

import ErrorAlert from './ErrorAlert'
import { FormContext } from '../containers/Form'

// export const FormErrors = props => {
//   const { errors = {} } = useContext(FormContext)
//   const baseErrors = errors.base || []
//
//   return baseErrors.length
//     ? <ErrorAlert {...props}>{baseErrors}</ErrorAlert>
//     : null
// }

export const FormErrors = props => {
  const { errors = {} } = useContext(FormContext)
  const fullMessages = errors.fullMessages || []

  if(fullMessages.length) {
    return <ErrorAlert {...props} children={fullMessages} />
  }
  return null
}

export default FormErrors
