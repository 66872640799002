import React, {useState} from "react";
import HarmonyJson from "./HarmonyJson";
import PrettyJson from "./PrettyJson";

const HarmonyJsonConstructor = ({schema}) => {
  const [formData, setFormData] = useState({})
  
  const handleChange = (e) => {
    setFormData(e.value)
  }

  return (
    <div className="row">
      <div className="col-md-5">
        <HarmonyJson schema={schema} onChange={handleChange} initialVal={{}} name={'jsonObj'}/>
      </div>
      <div className="col-md-7">
        <h4>JSON output</h4>
        <PrettyJson json={formData}/>
      </div>
    </div>
  )
}

export default HarmonyJsonConstructor
