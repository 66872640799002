import forEach from 'lodash/forEach'
import uniq from 'lodash/uniq'
import capitalize from 'lodash/capitalize'
import React from 'react'
import startsWith from 'lodash/startsWith'
import map from 'lodash/map'
import replace from 'lodash/replace'
import startCase from 'lodash/startCase'
import isNaN from 'lodash/isNaN'

const getFieldLabel = (el) => {
  const label = el.parent().find('label')
  if(label) {
    if (startsWith(label.prop('for'), 'root_')) {
      // Patch for getting label text for HC JSON entitlements fields
      const resolvedFor = label.prop('for').slice(5)
      return map(resolvedFor.split('_'), item => startCase(replace(item, '-', ' '))).join(' > ')
    } else {
      return label.text()
    }
  }
}

const requiredValidation = (el) => {
  const errors = []
  if (!el.val()) {
    const label = getFieldLabel(el)
    if (label) {
      errors.push(`${label} is required`)
    } else {
      errors.push(`Required field(s) missing`)
    }
  }
  return errors
}

const rangeValidation = (el) => {
  const errors = []
  const label = getFieldLabel(el)
  const intVal = parseFloat(el.val()||0)

  const valRanges = ['min', 'max']
  valRanges.forEach(r => {
    let elPropVal = el.prop(r) || 0

    // Patch for HC JSON max-version minimum value
    if (el.prop('id') == 'root_metadata_compatible-release_max-version' && r === 'min') {
      const customMinVal = parseFloat($("#root_metadata_compatible-release_min-version").val())
      if (!isNaN(customMinVal)) {
        elPropVal = customMinVal
      }
    }

    const rangeVal = parseFloat(elPropVal)
    if ((r === 'max' && intVal > rangeVal && rangeVal > 0) || (r === 'min' && intVal < rangeVal)) {
      if (label) {
        errors.push(`${capitalize(r)} value for ${label} is ${rangeVal}`)
      } else {
        errors.push(`Invalid range provided for certain field(s)`)
      }
    }
  })
  return errors
}

const emailValidation = (el) => {
  const errors = []
  const label = getFieldLabel(el)
  const val = el.val()
  let regex_string = el.prop("pattern") || "[^@]+@[^@]+\.[^@]+"
  const validEmail = new RegExp(regex_string)

  if (val && !validEmail.test(val)) {
    errors.push(`The email ${val} is invalid.`)
  }

  return errors
}

export const validateAllSections = (scope) => {
  const output = {}
  forEach(scope, (section) => {
    const errors = validateSection($(section))
    if (errors.length > 0) {
      output.errors = errors
      output.errorStep = $(section).data('step')
      return false
    }
  })
  return output
}

export const validateSection = (scope) => {
  let errors = []
  forEach(scope.find('[required]'), (e) => {
    errors = [...errors, ...requiredValidation($(e))]
  })

  forEach(scope.find('input[type="number"]'), (e) => {
    errors = [...errors, ...rangeValidation($(e))]
  })

  forEach(scope.find('input[type="email"]'), (e) => {
    errors = [...errors, ...emailValidation($(e))]
  })
  return uniq(errors)
}

export const renderErrors = (errors) => <ul>{errors.map((e) => <li>{e}</li>)}</ul>

export default validateSection
