import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'
import Tooltip from './Tooltip'
import { truncate } from '../../util/string'

export const Truncated = ({
  children,
  classNames = [],
  ...props
}) =>
  <Box
    as='span'
    classNames={['ellipsis', ...classNames]}
    data-original-title={children}
    children={truncate(children)}
    {...props}
  />

Truncated.propTypes = {
  classNames: PropTypes.array,
  children: PropTypes.string
}

export const Truncate = ({
  children = '',
  maxLength = 25,
  ...props
}) =>
  <Tooltip {...props}>
    {children.length >= maxLength
      ? <Truncated>{children}</Truncated>
      : children
    }
  </Tooltip>

Truncate.propTypes = {
  children: PropTypes.string,
  maxLength: PropTypes.number
}

export default Truncate
