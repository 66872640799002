import React, {
  createContext,
  useEffect,
  useState
} from 'react'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _set from 'lodash/set'

import log from 'loglevel';

import Box from '../components/Box'
import { FormInfo, FormTitle } from '../components/Form'
import FormErrors from '../components/FormErrors'
export { default as Field } from './FormField'
import { doFetch } from '../../util/fetch'
import { map } from '../../util/object'
import { clear, update } from '../../util/updaters'
import { lableize } from '../../util/string'
export const FormContext = createContext({})

// When we're ready to turn on per-attribute errors,
// call this instead of `legacyFormatErrors`
export const formatErrors = (errors = {}) => Object.entries(errors).reduce(
  (accum, [k, v]) => _set({ ...accum }, k, v),
  {}
)

// When we're ready to turn on per-attribute errors,
// nuke this
export const legacyFormatErrors = (errors = {}) => ({
  fullMessages: Object.entries(errors).reduce(
    (accum, [attr, errs]) => {
      let prefix = attr === 'base' ? '' : `${attr} `
      prefix = prefix.replace(".", "_")
      prefix = lableize(prefix)

      const isNumeric = Number.isInteger(Number(prefix))
      if (isNumeric) {
        return errs.map(e => `${e}`)
      }
      return errs.map(e => `${prefix}${e}`)
    }
    , [])
})

export const Form = ({
  attributes = {},
  children,
  initialData = {},
  initialErrors = {},
  inputProps = {},
  onSuccess,
  onError,
  prepareData,
  title,
  ...fetchProps
}) => {
  const [data, setData] = useState({ ...initialData })
  const [errors, setErrors] = useState({ ...initialErrors })
  const [submitted, setSubmit] = useState(false)

  useEffect(() => {
    log.debug(`state of data`, data)
  }, [data]);

  const handleChange = (e = {}) => {
    e && e.target && e.persist()
    const {
      name,
      value,
      checked,
      errors,
      type
    } = e.target ? e.target : e
    let newValue = type === 'checkbox' && checked === false ? false : checked || value
    if (newValue === '') {
      newValue = null
    }
    log.debug(`setData('${name}', '${newValue}')`)
    setData(update(name, newValue))
    setErrors(update(name, errors))
  }

  const handleUnmount = name => {
    if(_has(attributes, name) && !_has(attributes, "id")) {
      log.debug(`unmounting setting ${name}`, data)
      setData(update(name, _get(attributes, name)))
    } else {
      log.debug(`unmounting setting ${name}`, null)
      setData(update(name, null))
    }
    setErrors(clear(name))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const body = prepareData ? prepareData(data) : data
    const errorMsg = "Oops, the form couldn't submit."
    const {
      response,
      data: fetchData = {},
      error
    } = await doFetch({ errorMsg, ...fetchProps, body })
    log.debug("Form Submit", JSON.stringify(body))
    if (response.ok) {
      onSuccess(fetchData)
    } else {
      const newErrors = legacyFormatErrors(fetchData.errors || {})
      log.debug("Form Submit Errors", JSON.stringify(newErrors))
      setErrors(newErrors)
      onError()
    }
  }

  const context = {
    attributes,
    data,
    errors,
    handleChange,
    handleUnmount,
    inputProps,
    submitted
  }

  return (
    <FormContext.Provider value={context}>
      <Box as='form' onSubmit={handleSubmit}>
        <FormInfo>
          <FormTitle>{title}</FormTitle>
          {errors &&
            <FormErrors children={errors} />
          }
        </FormInfo>
        {typeof children === 'function'
          ? children(context)
          : children
        }
      </Box>
    </FormContext.Provider>
  )
}

export default Form
