import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { FormInput, FormLabel } from './Form'
import ToggleCard from './ToggleCard'

export { default as FlexPoolBandwidth } from '../containers/BandwidthInput'
export { AllocatedNGWAFBandwidths } from './AllocatedNGWAFBandwidths'
export { AllocatedBandwidths } from './AllocatedBandwidths'

export const CheckInFrequency = props =>
  <FormInput
    type='select'
    id='license_check_in_frequency'
    name='check_in_frequency'
    label='Check-in Frequency'
    required
    {...props}
  />

const containerLabel = <div className='pull-left'> Container Thunder Settings <br/><small className='form-text text-muted'>Optional for Thunder Containers</small></div>

export const CthunderAutoDeactivate = props =>
  <ToggleCard defaultOpen={false} label={containerLabel} togglePlusStyle={{lineHeight: '24px'}}>
    <FormLabel>Time Period (Since Check-In) *
      <div>
        <small className={'form-text', 'text-muted', 'font-weight-normal'}>until Container Thunder will return resources to the pool</small>
      </div>
    </FormLabel>
    <FormInput
      type='select'
      skipLabel={true}
      id='license_cthunder_auto_deactivate'
      name='cthunder_auto_deactivate'
      required
      {...props}
    />
  </ToggleCard>
