import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'
import { Label } from './Input'

export const Wrapper = ({
  classNames = [],
  ...props
}) =>
  <Box
    classNames={['radio', ...classNames]}
    {...props}
  />

Wrapper.propTypes = { classNames: PropTypes.array }

export const Radio = ({
  classNames = [],
  label,
  inline = false,
  ...props
}) => {
  const Outer = inline ? Wrapper : React.Fragment
  const htmlFor = props.name || props.id

  return (
    <Outer>
      <Label
        className='unit'
        {...(htmlFor && { htmlFor })}
      >
        <Box
          as='input'
          type='radio'
          classNames={['mr-4', ...classNames]}
          name={props.value}
          {...props}
        />
        <span className="radiolabel">{label || props.value}</span>
      </Label>
    </Outer>
  )
}

Radio.propTypes = {
  classNames: PropTypes.array,
  inline: PropTypes.bool
}

export default Radio
