import moment from 'moment'
import momenttz from 'moment-timezone'

export const formatDateTime = (dateTimeString) => {
  if (dateTimeString) {
    return moment(dateTimeString).format('DD-MMM-YY HH:mm')
  } else {
    return 'N/A'
  }
}

export const formatDateTimeUTC = (dateTimeString) => {
  if (dateTimeString) {
    momenttz.tz.setDefault('UTC')
    return `${momenttz(dateTimeString).format('DD-MMM-YY HH:mm')} UTC`
  } else {
    return 'N/A'
  }
}
