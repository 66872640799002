import React, {useState, Fragment, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import filter from 'lodash/filter'
import qs from 'query-string'
import { GLMToastContainer, GLMToast } from '../helpers/toastr'
import forEach from 'lodash/forEach'

const CustomEmailRecipientFormSection = ({onChange, intialEmails, showReciepientCount}) => {
  const [emails, setEmails] = useState(intialEmails)
  const [inputVal, setInputVal] = useState('')

  const validEmail = (v) => /\S+@\S+\.\S+/.test(v)
  const emailAlreadyExist = (item) => emails.indexOf(item) > -1

  const handleSubmit = (e) => {
    e.preventDefault()
    const emailItems = inputVal.split(',')
    const validEmails = []
   
    forEach(emailItems, (item)=> {
      const emailItem = item.trim()
      if (validEmail(emailItem)) {
        if (emailAlreadyExist(emailItem)) {
          GLMToast(`${emailItem} already added`, {className: 'alert alert-danger'})
        } else {
          validEmails.push(emailItem)
        }
      } else if (emailItem.replace(/\s/g,"") != '') {
        GLMToast(`${emailItem} is invalid`, {className: 'alert alert-danger'})
      }
    })

    if (validEmails.length > 0) {
      setEmails([...emails, ...validEmails])
      setInputVal('')
    }
  }

  useEffect(() => {
    const queryParams = qs.parse(window.location.search)
    if (queryParams.sendTo && typeof (queryParams.sendTo) === 'string') {
      setEmails([...emails, queryParams.sendTo])
    }
  }, [])

  useEffect(() => {
    onChange({target: {value: emails}})
  }, [emails])

  const removeEmail = (email) => setEmails(filter(emails, (e) => e !== email))

  const renderEmails = () => {
    return (
      <div className='mb1'>
        {emails.map((email, i) =>
          <span className='badge btn-primary' key={i} style={{marginRight: '4px'}}>
            {email}
            <i style={{cursor: 'pointer', paddingLeft: '5px'}}
              className='fa fa-times'
              onClick={() => removeEmail(email)}
            />
          </span>
        )}
      </div>
    )
  }

  return (
    <Fragment>
      <Form.Group>
        <Form.Label>Recipients {showReciepientCount && <>({emails.length})</>}</Form.Label>
        {emails.length > 0 && renderEmails()}
        <div className='row'>
          <div className='col-md-10'>
            <Form.Control
              type='text'
              placeholder='Recipient Emails'
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
            />
          </div>
          <div className='col-md-2'>
            <a href="#" className='btn btn-default col-md-12' onClick={handleSubmit}>Add</a>
          </div>
        </div>
      </Form.Group>
      <GLMToastContainer />
    </Fragment>
  )
}

CustomEmailRecipientFormSection.defaultProps = {
  intialEmails: [],
  showReciepientCount: false
}
export default CustomEmailRecipientFormSection
