import React from 'react'

import Box from './Box'

export const Tooltip = props =>
  <Box
    as='span'
    data-toggle='tooltip'
    {...props}
  />

export default Tooltip
