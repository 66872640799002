import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import momenttz from 'moment-timezone'

momenttz.tz.setDefault("UTC");

import 'react-datepicker/dist/react-datepicker.css'
import '../../../assets/stylesheets/components/ExpirationField.scss'
import { FormSection } from './Form'
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Label
} from './Input'

export const CalendarSelect = ({
  value,
  label,
  onChange,
  open,
  ...props
}) => {
  const [selected, setSelected] = useState(value)
  const customonchange = (v) => {
    var parsedv = getDate(v)
    setSelected(v)
    onChange(parsedv ? parsedv.toString() : "")
  }

  const getDate = (v) => {
    const DATE_FORMAT = 'DD/MM/YYYY'
    if (props.utcEnabled) {
      let utcDate = v
      if (v) {
        return momenttz(v, "UTC").format(DATE_FORMAT)
      }
    }
    return momenttz(v)
  }

  return (
    <FormSection className={`${open ? '' : 'hidden'}`}>
      <Label>{label}</Label>
      <InputGroup>
        <InputGroupAddon>
          <i className='fa fa-calendar' />
        </InputGroupAddon>
        <DatePicker
          className='form-control'
          id={props.datepickerId || 'datepicker'}
          name='expires_at_datepicker'
          selected={selected ? momenttz(selected) : ""}
          disabled={!open}
          onChange={customonchange}
        />
      </InputGroup>
    </FormSection>
  )
}

export default CalendarSelect
