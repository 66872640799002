import React, {useState} from 'react'
import Form from 'react-bootstrap/Form'
import CustomEmailRecipientFormSection from '../CustomEmailRecipientFormSection'
import Button from 'react-bootstrap/Button'

const RecipientListForm = ({initialFormData, form, customEmailsCount, emailListId}) => {
  const [formData, setFormData] = useState(initialFormData)

  const setName = (e) => {
    e.preventDefault()
    setFormData({...formData, name: e.target.value})
  }

  const handleEmails = (e) => setFormData({...formData, emails: e.target.value})

  const renderEmailsInputs = () => {
    return (
      <>
        {formData.emails.map((email, i) =>
          <input type="hidden" key={i} value={email} name="email_list[emails][]"/>
        )}
      </>
    )
  }

  const emptyFormData = () => formData.emails.length == 0 || formData.name.replace(/\s/g,"") == ""
  
  return (
    <Form action={form.action} method='POST' >
      {form.method != 'POST' && <input type="hidden" name="_method" value={form.method}/> }

      <div className='row'>
        <div className='col-md-8 col-md-offset-2'>
          <h3>{form.title}</h3>
          <hr/>
          {customEmailsCount > 0 && emailListId &&
            <Form.Group>
              <div className='alert alert-warning'>
                Recipient emails are not editable as the list is used in one or more email blasts, 
                create a <a href={`/email_lists/new?clone_list_id=${emailListId}`}>clone</a> instead
              </div>
            </Form.Group>
          }
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Name'
              name="email_list[name]"
              value={formData.name}
              onChange={setName}
            />
          </Form.Group>
          {customEmailsCount == 0 &&
            <Form.Group>
              <CustomEmailRecipientFormSection 
                onChange={handleEmails} 
                intialEmails={formData.emails}
                showReciepientCount={true}
              />
              {formData.emails.length > 0 && renderEmailsInputs()}
              {formData.emails.length == 0 &&
                <input type="hidden" name="email_list[emails][]"/>
              }
            </Form.Group>
          }
          <hr/>
          <Form.Group className='text-right'>
            <Button variant='primary' type='submit' disabled={emptyFormData()} data-disable-with={form.submitText}>
              {form.submitText}
            </Button>
          </Form.Group>
        </div>
      </div>
    </Form>
  )
}

RecipientListForm.defaultProps = {
  initialFormData: {
    name: '',
    emails: []
  },
  form: {
    action: '/email_lists',
    method: 'POST',
    title: 'Create Recipient List',
    submitText: 'Create Recipient List'
  },
  customEmailsCount: 0
}

export default RecipientListForm