import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'

export const Label = ({
  children,
  classNames,
  required,
  ...props
}) =>
  <Box
    as='label'
    classNames={[
      'control-label',
      { required, optional: !required },
      ...classNames
    ]}
    {...props}
  >
    {children}{required && ' *'}
  </Box>

Label.defaultProps = {
  classNames: [],
  required: false
}

Label.propTypes = {
  classNames: PropTypes.array,
  required: PropTypes.bool
}

export default Label
