import React, { Component } from 'react'
import Dropdown from '../Dropdown'
import WizardButtons from '../WizardButtons'
import RolesDataApi from '../../data/RolesDataApi'

class SelectRoles extends Component {
  state = {
    options: [['', '']],
    roleSelected: this.props.wizardState.roleSelected
  }

  componentDidMount = () => {
    this.loadRoles()
  }

  onNext = () => {
    this.props.onNext({
      roleSelected: this.state.roleSelected
    })
  }

  onPrevious = () => {
    this.props.onPrevious({
      roleSelected: this.state.roleSelected
    })
  }

  loadRoles = async () => {
    const roles = await new RolesDataApi().getInternalRoles()
    try {
      this.setState({
        options: roles
      })
    } catch (error) {
      this.props.handleError(error)
    }
  }

  updateInputValue = (e) => {
    this.setState({
      roleSelected: e.target.value
    })
  }

  render () {
    return (
      <div>
        <div className='py2'>
          <label className='control-label'>Select the role you are requesting.</label>
          <Dropdown options={this.state.options} value={this.state.roleSelected} onChange={this.updateInputValue} />
        </div>
        <WizardButtons
          onPreviousClick={this.onPrevious}
          onNextClick={this.onNext}
          nextEnabled
        />
      </div>
    )
  }
}

export default SelectRoles
