import React, { Component } from 'react'
import Downshift from 'downshift'
import Form from 'react-bootstrap/Form'
import Button from './Button'
import Label from './Form/Label'
import 'string.prototype.startswith'

class FilterWithClear extends Component {
  static defaultProps = {
    input: { id: 'typeahead-input' },
    item: { className: 'downshift-item' },
    items: [],
    label: { required: false, className: 'py1' },
    menu: { className: 'downshift-menu string required', style: {width: '100%', 'maxHeight': '10em', overflow: 'auto'} },
    root: { className: 'string required' }
  }

  state = { inputValue: '', items: [] }

  handleClearInput = values => {
    this.props.handleClearInput()
    this.setState({inputValue: ''})
  }

  handleInputChange = inputValue => {
    this.setState({inputValue})
  }

  handleOuterClick = changes => {
    this.setState({inputValue: changes.inputValue})
  }

  handleStateChange = changes => {
    const { onChange, submitField } = this.props
    const { inputValue, selectedItem, type } = changes
    const { clickItem, keyDownEnter, mouseUp } = Downshift.stateChangeTypes
    if (!onChange || (changes.hasOwnProperty('inputValue') && type === mouseUp)) return

    // if we got a value from the input field (not the select), set it, even if empty
    // otherwise, only set the value from select if it has a value.
    if (changes.hasOwnProperty('inputValue') && type !== clickItem && type !== keyDownEnter) {
      onChange({ [submitField]: inputValue })
    } else if(selectedItem) {
      onChange({ [submitField]: selectedItem })
    }
  }

  getItems = (items, inputValue) => (
    (this.itemIsNew(inputValue, items) ? [inputValue] : [])
      .concat(
        items.filter(i => i.startsWith(inputValue)))
  )

  itemIsNew = (item, items) => !!item && (items.indexOf(item) < 0)

  render = () => {
    const { input, item, label, menu, root, type } = this.props
    return (
      <div className='input-group'>
        <ControlledAutoComplete
          items={this.props.items}
          input={input}
          item={item}
          menu={menu}
          root={root}
          type={type}
          label={label}
          onStateChange={this.handleStateChange}
          inputValue={this.state.inputValue}
          onInputValueChange={this.handleInputChange}
          onOuterClick={this.handleOuterClick}
          getItems={this.getItems}
        />
        <span className='input-group-btn'>
          <Button
            variant='primary'
            onClick={this.handleClearInput}>
            <i className='fa fa-times' />
          </Button>
        </span>
      </div>
    )
  }
}

const ControlledAutoComplete = ({onInputChange, items, item, menu, root, type, input, label, getItems, ...rest}) => {
  return (
    <Downshift {...rest} >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu
      }) => (
        <div {...root}>
          <Form.Control as='input' {...getInputProps({ onFocus: openMenu, ...input })} />
          <Label {...getLabelProps({ htmlFor: input.id, ...label })} />
          <div {...getMenuProps({ ...menu, hidden: (!isOpen || inputValue.length < 3) })}>
            {isOpen &&
              getItems(items, inputValue).map((i, index) => (
                <div {...getItemProps({
                  ...item,
                  key: i,
                  item: i || inputValue,
                  index: index,
                  value: i,
                  ...(highlightedIndex === index && {
                    style: { background: 'rgba(0,0,0,.03)' }
                  })
                })}>
                  <strong>{i}</strong>
                </div>
              ))
            }
          </div>
        </div>
      )}
    </Downshift>
  )
}

export default FilterWithClear
