import React from 'react'
import PropTypes from 'prop-types'

import { FormInput } from './Form'

export const Quantity = ({ unlimited, ...props }) =>
  <FormInput
    type={unlimited ? 'text' : 'number'}
    id='license_number_of_appliances'
    name='number_of_appliances'
    label='Quantity'
    disabled={unlimited}
    value={unlimited ? 'UNLIMITED' : props.value}
    {...props}
  />

Quantity.propTypes = {
  unlimited: PropTypes.bool
}

export default Quantity
