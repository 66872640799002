import React, { Component } from 'react'

class WizardForm extends Component {
  onPrevious = () => {
    this.props.onPrevious(this.getWizardState ? this.getWizardState() : {})
  }

  onNext = () => {
    this.props.onNext(this.getWizardState ? this.getWizardState() : {})
  }

  skipForm = () => {
    if (this.props.currentIndex > this.props.lastIndex) {
      this.onNext()
    } else {
      this.onPrevious()
    }
  }

  render () {
    return (<div />)
  }
}

export default WizardForm
