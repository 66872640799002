import React from 'react'
import PropTypes from 'prop-types'

import Alert from './Alert'
import Box from './Box'

export const ErrorAlert = ({
  children: errors,
  model,
  ...props
}) =>
  <Alert>
    <Box as='h2'>
      {errors.length} error{errors.length > 1 && 's'}{' '}
      prohibited this {model ? `${model} ` : ''}from being saved
    </Box>
    <Box as='ul'>
      {errors.map((error, i) =>
        <Box
          as='li'
          key={`error_${i}`}
          children={error}
        />
      )}
    </Box>
  </Alert>

ErrorAlert.defaultProps = {
  errors: []
}

ErrorAlert.propTypes = {
  children: PropTypes.array,
  model: PropTypes.string
}

export default ErrorAlert
