import React, { useEffect, useState } from 'react'
import isUndefined from 'lodash/isUndefined'
import Spinner from '../Spinner'
import 'isomorphic-fetch'
import Modal from 'react-bootstrap/Modal'

const LogResponse = ({ logId, onClose }) => {
  const [logResponses, setLogResponses] = useState({})
  const [currentLogResponse, setCurrentLogResponse] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const loadLog = async () => {
    setLoading(true)

    const response = await fetch(`/threatstop_logs/${logId}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setLogResponses({...logResponses, [jsonData.id]: jsonData.response})
      setCurrentLogResponse(jsonData.response)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  useEffect(() => {
    setError()
    setCurrentLogResponse()
    if (logId && isUndefined(logResponses[logId])) {
      loadLog()
    } else if (logId && !isUndefined(logResponses[logId])) {
      setCurrentLogResponse(logResponses[logId])
    }
  }, [logId])

  return (
    <div className='modal-fade-backdrop'>
      {
        logId &&
        <Modal show onHide={() => onClose()} size='lg' animation={false} dialogClassName='adjust-header'>
          <Modal.Header closeButton>
            <Modal.Title>API Response</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{maxHeigh: '400px'}}>
            { currentLogResponse && <small><pre>{JSON.stringify(currentLogResponse, null, 2)}</pre></small> }
            { loading && <div className='text-center'><Spinner /></div> }
            { error && <div className='alert alert-danger'>{error}</div>}
          </Modal.Body>
        </Modal>
      }
    </div>
  )
}

export default LogResponse
