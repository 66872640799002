import _debounce from 'lodash/debounce'

export const encodeQuery = (params = {}) => (
  Object.entries(params)
    .map(pair => pair.map(encodeURIComponent))
    .map(pair => pair.join('='))
    .join('&')
)

export const decodeQuery = query => (
  query.split('&')
    .map(pair => pair.split('='))
    .map(pair => pair.map(decodeURIComponent))
    .reduce((accum, [k, v]) =>  ({ ...accum, [k]: v }), {})
)

export const uri = (path, query = null) => query ?
  `${path}?${encodeQuery(query)}`
  : path

export const getFetchOptions = ({
  authenticate = true,
  body = {},
  headers = {},
  query = {},
  ...rest
}) => ({
  ...(authenticate && { credentials: 'include' }),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers
  },
  ...(body && { body: JSON.stringify(body) }),
  ...rest
})

export const doFetch = async ({
  url,
  query,
  errorMsg,
  ...opts
}) => {
  const path = uri(url, query)
  const fetchOpts = getFetchOptions(opts)
  try {
    const response = await fetch(path, fetchOpts)
    const data = await response.json()
    const error = !response.ok && `Return status: ${response.status}`
    return { response, data, error }
  } catch (error) {
    const data = { errors: { base: errorMsg } }
    return { error, ...(errorMsg && data) }
  }
}

export const debouncedFetch = (wait = 200) => _debounce(doFetch, wait)
