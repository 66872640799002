import React from 'react';
import MetricsCard from './common/MetricsCard'

const CheckinMetricsChart = (props) => 
  <MetricsCard 
    {...{
      ...props, 
      chart: {
        url: '/internal/dashboard_charts/checkins.json', 
        label: 'Appliance Checkins',
        type: 'line'
      }
    }} 
  />

export default React.memo(CheckinMetricsChart)
