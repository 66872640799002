import React from 'react'
import Form from 'react-bootstrap/Form'
import { default as Base } from '../EmailInput'

const EmailInput = props =>
  <div className='col-sm-9 px0'>
    <Form.Group className='email optional license_authorized_users_email p0'>
      <Base label='false' type='id' {...props} />
    </Form.Group>
  </div>

export default EmailInput
