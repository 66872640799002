import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const ConfirmModal = ({ onConfirmClick, onClose, show, title, body }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    onConfirmClick()
  }

  return (
    <>
      <Modal show={show === true} onHide={onClose} className="react">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {body}<br /><br />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={onClose}>
              Close
            </Button>
            <Button type='submit'>Confirm</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default ConfirmModal
