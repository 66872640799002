import React, { useEffect, useState } from 'react'
import Spinner from '../Spinner'
import { formatDateTime } from '../../helpers/moment'
import ActionButtons from './ActionButtons'

const RecipientLists = () => {
  const [data, setData] = useState([])
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const loadData = async () => {
    setLoading(true)

    const response = await fetch(`/email_lists.json`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setData(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }
  useEffect(() => {
    loadData()
  }, [])

  const renderdata = () => {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Recipients count</th>
            <th>Created At</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) =>
            <tr key={i}>
              <td><a href={`/email_lists/${item.id}`}>{item.name}</a></td>
              <td>{item.recipients_count}</td>
              <td>{formatDateTime(item.created_at)}</td>
              <td><ActionButtons id={item.id}/></td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      {loading && <Spinner />}
      {error && <div className='alert alert-danger'> {error} </div>}
      {data.length === 0 && !loading && !error && <div className='alert alert-danger'> No Data available </div>}
      {data.length > 0 && renderdata()}
      {loading && paginationData && <Spinner />}
    </div>
  )
}

export default RecipientLists
