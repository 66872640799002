import React, { useEffect, useState } from 'react'
import Paginate from '../Paginate'
import Spinner from './Spinner'
import { formatDateTime } from '../helpers/moment'

const CustomEmailsIndex = ({currentPage}) => {
  const [customEmails, setCustomEmails] = useState([])
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const loadCustomEmails = async () => {
    setLoading(true)

    const response = await fetch(`/custom_emails.json?page=${currentPage}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setCustomEmails(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }
  useEffect(() => {
    loadCustomEmails()
  }, [])

  const gotTo = (page) => {
    window.location.href = `/custom_emails?page=${page}`
  }

  const renderCustomEmails = () => {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Subject</th>
            <th>License Type</th>
            <th>Recipients Count</th>
            <th>Recipients List</th>
            <th>Created By</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {customEmails.map((customEmail, i) =>
            <tr key={i}>
              <td><a href={`/custom_emails/${customEmail.id}`}>{customEmail.subject}</a></td>
              <td>
                <small>{customEmail.license_type}</small>
              </td>
              <td>{customEmail.recipient_emails_count || 'N/A'}</td>
              <td>
                {customEmail.email_list_id && customEmail.email_list_name && 
                  <a href={`/email_lists/${customEmail.email_list_id}`}>
                    {customEmail.email_list_name}
                  </a>
                }
              </td>
              <td>
                { customEmail.created_by_id &&
                  <a href={`/users/${customEmail.created_by_id}`}>{customEmail.created_by_display_name || 'N/A'}</a>
                }
                { !customEmail.created_by_id && <span>N/A</span>}
              </td>
              <td><small>{formatDateTime(customEmail.created_at)}</small></td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      {loading && <Spinner />}
      {error && <div className='alert alert-danger'> {error} </div>}
      {customEmails.length === 0 && !loading && !error && <div className='alert alert-danger'> No Data available </div>}
      {customEmails.length > 0 && renderCustomEmails()}
      { paginationData && paginationData.total_pages > 1 &&
      <Paginate
        pages={paginationData.total_pages}
        currentPage={paginationData.current_page}
        onPageNext={() => gotTo(currentPage + 1)}
        onPageBack={() => gotTo(currentPage - 1)}
        toPage={(pageNum) => gotTo(pageNum)} />
      }
      {loading && paginationData && <Spinner />}
    </div>
  )
}

export default CustomEmailsIndex
