import React, {useState, useRef, useEffect} from 'react'
import capitalize from 'lodash/capitalize'
import SkuSelect from '../SkuSelect'
import MetricsSkuSelect from './common/MetricsSkuSelect'

const LicenseMetricsOptions = ({onKeyChange, state, stateChangeHandler}) => {
  const ref = useRef(null);
  const [showSku, setShowSku] = useState(false)
  const [sku, setSku] = useState({name: state.sku_name, platform: state.sku_platform, product: state.sku_product })

  const btnStyle = {
    outline: 'none',
    padding: '0 4px',
    fontSize: '11px',
    fontWeight: 'bold'
  }

  const licenseOptions = {
    checkboxes: ['expired', 'trial', 'exported', 'activated', 'revoked']
  }

  const handleShowSku = (e) => {
    e.preventDefault()
    if (!showSku) {
      setShowSku(true)  
    }
  }
  useEffect(() => {
    if (sku) {
      const sku_platform = sku.platform
      const sku_product = sku.product
      const sku_name = sku.name
      stateChangeHandler({ sku_platform, sku_product, sku_name })
    }
  }, [sku])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setTimeout(()=>setShowSku(false))
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='btn-group'>
          {licenseOptions.checkboxes.map((item, i) =>
            <button
              key = {i}
              className={`${state[item] === true ? 'btn btn-primary' : 'btn btn-secondary'}`}
              onClick={(e) => onKeyChange(e, item)}
              style={btnStyle}
            >
              {capitalize(item)}
            </button>
          )}
        </div>
        <a className='btn btn-link metrics-more-options' onClick={handleShowSku}>
          <i className='fa fa-ellipsis-v '/>
        </a>
        {showSku &&
          <div className='metrics-chart-catalog-container card absolute' ref={ref}>
            <div className='card-body'>
              <MetricsSkuSelect onSkuChange={setSku} sku={sku}/>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default LicenseMetricsOptions
