import React from 'react'
import Button from '../components/Button'
import { Link } from '../components/Link'

const IdpRows = ({ idps, organizationId }) => (
  idps.map(idp =>
    <tr key={`idp_${idp.id}`}>
      <td>
        <Link href={`/idp_configurations/${idp.id}?organization_id=${organizationId}&current_organization=true`}>
          {idp.name}
        </Link>
      </td>
      <td>{idp.administrator_email &&
          <Link href={`mailto:${idp.administrator_email}`}>{idp.administrator_email}</Link>
      }</td>
      <td>
        {idp.domain_count}
      </td>
      <td>
        <div className='btn-group btn-group-sm pull-right'>
          <Button
            href={`/idp_configurations/${idp.id}/edit?organization_id=${organizationId}&current_organization=true`}>
            <i className='fa fa-edit' />
          </Button>
        </div>
      </td>
    </tr>
  )
)

export default IdpRows
