import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const mergeClasses = (className, classes) => (
  className || (classes && classes.length)
    ? { className: classNames(className, ...classes) }
    : {}
)

export const Box = ({
  as: Tag,
  className,
  classNames: classes,
  ...props
}) =>
  <Tag
    {...mergeClasses(className, classes)}
    {...props}
  />

Box.defaultProps = {
  as: 'div',
  classNames: []
}

Box.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.array
}

export default Box
