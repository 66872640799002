import React from 'react'

import {
  FormGroup,
  FormSection,
  FormText
} from './Form'
import { Checkbox } from './Input'

export const CThunderPartner = props =>
  <FormSection>
    <FormGroup>
      <Checkbox
        id='license_c_thunder_partner'
        name='c_thunder_partner'
        label='Container Thunder Partner Activation'
        hint='Allows use of Partner Key and Vendor Key to generate activation.'
        {...props}
      />
    </FormGroup>
  </FormSection>

export default CThunderPartner
