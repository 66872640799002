import React, { Component } from 'react'
import moment from 'moment'
import Report from './Report'

class ThroughputReport extends Component {
  state = {
    throughputType: this.props.throughputType,
    throughputId: this.props.throughputId,
    data: [],
    endTime: moment().utc().endOf('month'),
    startTime: moment().utc().startOf('month')
  }

  componentDidMount = async () => {
    await this.resetMonth()
  }

  loadThroughputs = async () => {
    const fixRespWindow = window => moment.unix(window).utc().toDate()
    const { throughputId, throughputType, endTime, startTime } = this.state
    const windowStart = startTime.unix()
    const windowEnd = endTime.unix()
    const response = await fetch(
      `/throughput/${throughputType}/${throughputId}.json?window_end=${windowEnd}&window_start=${windowStart}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        }
      }
    )
    if (response.ok) {
      const json = await response.json()
      const data = {throughputs: json.throughputs.map(d => ({ ...d, windowEnd: fixRespWindow(d.windowEnd) })),
        usage: json.usage,
        overage: json.overage}
      this.setState({ data })
    } else {
      this.setState({ data: [] })
    }
  }

  nextMonth = () => {
    const startTime = this.state.startTime.add(1, 'months').startOf('month')
    const endTime = this.state.endTime.add(1, 'months').endOf('month')
    this.setState({ startTime, endTime }, this.loadThroughputs)
  }

  previousMonth = () => {
    const startTime = this.state.startTime.subtract(1, 'months').startOf('month')
    const endTime = this.state.endTime.subtract(1, 'months').endOf('month')
    this.setState({ startTime, endTime }, this.loadThroughputs)
  }

  resetMonth = () => {
    const endTime = moment().utc().endOf('month')
    const startTime = moment().utc().startOf('month')
    this.setState({ startTime, endTime }, this.loadThroughputs)
  }

  render () {
    const { startTime, endTime, data } = this.state
    return (
      <Report
        previousMonth={this.previousMonth}
        resetMonth={this.resetMonth}
        nextMonth={this.nextMonth}
        startTime={startTime}
        endTime={endTime}
        throughputs={data.throughputs}
        usage={data.usage}
        overage={data.overage}
        {...this.props}
      />
    )
  }
}

export default ThroughputReport
