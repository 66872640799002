import React, { useEffect, useState, Fragment } from 'react'
import Spinner from './Spinner'
import { formatDateTime } from '../helpers/moment'

const LicenseNotesIndex = ({licenseId}) => {
  const [notes, setNotes] = useState([])
  const [pagination, setPagination] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [page, setPage] = useState(1)

  const loadData = async () => {
    setLoading(true)
    const fetchUrl = `/internal/licenses/${licenseId}/license_notes?page=${page}&per_page=5`

    const response = await fetch(fetchUrl).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setNotes([...notes, ...jsonData.data])
      setPagination(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }
  useEffect(() => {
    loadData(page)
  }, [page])

  const loadMoreNotes = (e) => {
    e.preventDefault()
    setPage(pagination.next_page)
  }

  const renderNotes = () => {
    return (
      <Fragment>
        {notes.map((note, i) =>
          <Fragment key={i}>
            <span className='text-muted small'>
              {note.updated_by_name &&
                <Fragment>{note.updated_by_name} | </Fragment>
              }
              {formatDateTime(note.updated_at)}
              { note.internal &&
                <Fragment>&nbsp;<i className='fa fa-lock text-primary' title='internal note' /></Fragment>
              }
            </span>
            <br />
            <div className='note-preview'>
              <small>{note.note}</small>
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  return (
    <div>
      { notes.length > 0 &&
        <h5 className='text-muted'>
          Notes {pagination && <Fragment>({pagination.total_count})</Fragment>}
        </h5>
      }
      {error && <div className='alert alert-danger'> {error} </div>}
      {notes.length > 0 && renderNotes()}
      { pagination && page < pagination.total_pages &&
        <small className='mt1'>
          <a href="#" onClick={loadMoreNotes}>Show More</a>
        </small>
      }
      {loading && <div><Spinner /></div>}
    </div>
  )
}

export default LicenseNotesIndex
