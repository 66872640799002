import React, { useState, Fragment, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button } from './../components/Button'
import Spinner from './../components/Spinner'
import Form from 'react-bootstrap/Form'
import omit from 'lodash/omit'
import isObject from 'lodash/isObject'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneField from '../components/PhoneField'

const HarmonyOneClickTrialButton = ({orgId, canCreate}) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [errors, setErrors] = useState([])
  const [formLoaded, setFormLoaded] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [successResponse, setSuccessResponse] = useState()
  const [statesLoading, setStatesLoading] = useState(false)
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])

  const formEl = useRef()

  const initialFormData = {
    first_name: '',
    last_name: '',
    phone_number: '',
    country: '',
    state: '',
    company: ''
  }

  const btnStyle = {background: '#94438d', color: '#fff'}

  const formFieldSpinner = () => {
    return (
      <div className='form-control text-center'>
        <Spinner style={{marginTop: '0px', fontSize: '21px'}} />
      </div>
    )
  }

  const [formData, setFormData] = useState(initialFormData)

  const loadInfo = async (url, loadinFn, setFn, key) => {
    loadinFn(true)

    const response = await fetch(url).catch(e => {
      setError('Something went wrong')
      loadinFn(false)
    })

    if (response.status === 200) {
      setFormLoaded(true)
      const jsonData = await response.json()
      if (jsonData[key]) {
        setFn(jsonData[key])
      }
    } else {
      setError(response.statusText || 'something went wrong')
    }
    loadinFn(false)
  }

  const handleCloseModal = () => {
    setLoading(false)
    setError()
    setErrors([])
    setFormLoaded(false)
    setFormSubmitting(false)
    setSuccessResponse()
    setStatesLoading(false)
    setStates([])
    setShowModal(false)
    setFormData(initialFormData)
  }

  const handleChange = (e, key) => {
    const changes = {[key]: e.target.value}
    if (key === 'country') {
      changes.state = ''
    }
    setFormData({...formData, ...changes})
  }

  const handleProviderNameChange = (e) => {
    const value = e.target.value
    if (/^[A-Za-z0-9-_]+$/.test(value) || value === '') {
      setFormData({...formData, harmony_provider_name: value})
    }
  }

  const handlePhoneNumberChange = value => {
    setFormData({...formData, phone_number: value})
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (formEl.current.checkValidity() === true) {
      setErrors([])
      setError()
      setFormSubmitting(true)
      let reqBody = {...formData, trial_type: 'harmony'}
      if (formData.cloudoption === 'Self-Managed') {
        reqBody = omit(reqBody, ['harmony_provider_name'])
      }
      if (!isValidPhoneNumber(formData.phone_number)) {
        setError('Invalid phone number')
        setFormSubmitting(false)
      }
      const response = await fetch(`/trial_leads.json`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({trial_lead: reqBody, organization_id: orgId})
      }).catch(e => {
        setError('Something went wrong')
        setFormSubmitting(false)
      })
      setFormSubmitting(false)
      if ([200, 201, 412, 422].indexOf(response.status) > -1) {
        const json = await response.json()
        if (json.success && isValidPhoneNumber(formData.phone_number)) {
          if (json.license_id) {
            window.location.href = `/licenses/${json.license_id}?success=true`
          } else {
            setSuccessResponse(json)
          }
        } else if ((json.errors && isObject(json.errors)) || !isValidPhoneNumber(formData.phone_number)) {
          setErrors(json.errors)
          if (!isValidPhoneNumber(formData.phone_number)) {
            setErrors(json.errors.push('Invalid phone number'))
          }
        }
      } else {
        setError(response.statusText || 'something went wrong')
      }
    }
  }

  useEffect(() => {
    if (showModal && !formLoaded) {
      loadInfo(`/users/latest_trial_lead_information`, setLoading, setFormData, 'trial_lead')
    }
    if (showModal && countries.length === 0) {
      loadInfo(`/api/ordered_countries`, setCountriesLoading, setCountries, 'countries')
    }
  }, [showModal])

  useEffect(() => {
    if (formData.country) {
      setStates([])
      loadInfo(`/api/countries/${formData.country}/subdivisions`, setStatesLoading, setStates, 'subdivisions')
    }
  }, [formData.country])

  const renderForm = () => {
    return (
      <Form className='row' ref={formEl} onSubmit={handleSubmit}>
        <Form.Group className='col-md-4'>
          <Form.Label size='sm' >Deployment Environment</Form.Label>
          <Form.Control size='sm' required as='select' value={formData.cloudoption} onChange={(e) => handleChange(e, 'cloudoption')}>
            <option value=''>Select</option>
            {['SaaS', 'Self-Managed'].map((key, i) =>
              <option key={i} value={key}>
                {key}
              </option>
            )}
          </Form.Control>
        </Form.Group>
        {formData.cloudoption === 'SaaS' &&
        <Form.Group className='col-md-4'>
          <Form.Label>Provider Name</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder=''
            value={formData.harmony_provider_name} onChange={handleProviderNameChange} />
          <small class='form-text text-muted'>This will be used for Harmony account creation</small>
        </Form.Group>
        }
        <div className='col-md-12'><hr style={{marginTop: '0', marginBottom: '8px'}} /></div>
        <Form.Group className='col-md-4'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder=''
            value={formData.first_name} onChange={(e) => handleChange(e, 'first_name')} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder=''
            value={formData.last_name} onChange={(e) => handleChange(e, 'last_name')} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder=''
            value={formData.company} onChange={(e) => handleChange(e, 'company')} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Telephone</Form.Label>
          <PhoneField
            required
            placeholder=''
            value={formData.phone_number}
            onChange={(handlePhoneNumberChange)} />
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>Country</Form.Label>
          {countriesLoading && formFieldSpinner() }
          {countries.length > 0 && !countriesLoading &&
          <Form.Control required as='select' value={formData.country} onChange={(e) => handleChange(e, 'country')}>
            <option value=''>Select</option>
            {countries.map((country, i) =>
              <option key={i} value={country[1]}>
                {country[0]}
              </option>
            )}
          </Form.Control>
          }
        </Form.Group>
        <Form.Group className='col-md-4'>
          <Form.Label>State</Form.Label>
          {statesLoading && formFieldSpinner() }
          {!statesLoading && states.length === 0 && <Form.Control as='input' disabled placeholder='Not available' />}
          { states.length > 0 && !statesLoading &&
          <Form.Control required as='select' value={formData.state} onChange={(e) => handleChange(e, 'state')}>
            <option value=''>Select</option>
            {states.map((key, i) =>
              <option key={i} value={key.code}>
                {key.name}
              </option>
            )}
          </Form.Control>
          }
        </Form.Group>

        <Form.Group className='col-md-12 text-right'>
          <button
            className='btn btn-primary'
            style={btnStyle}
            disabled={loading || countriesLoading || statesLoading || formSubmitting}
            onSubmit={handleSubmit}>
            { formSubmitting && 'Submitting'}
            { !formSubmitting && 'Submit'}
          </button>
        </Form.Group>
      </Form>
    )
  }

  const renderErrors = () => {
    return (
      <Fragment>
        {
          errors && Object.keys(errors).length > 0 &&
          <ul className='text-danger'>
            {Object.keys(errors).map((key, i) =>
              <li key={i}>
                {key !== 'base' && <strong>{key}:</strong>} {errors[key]}
              </li>
            )}
          </ul>
        }
      </Fragment>
    )
  }

  const renderSuccessResponse = () => {
    return (
      <Fragment>
        {
          formData.cloudoption === 'Self-Managed' &&
          <div className='alert alert-success'>
            Please visit <a href='/pages/harmony_self_managed_trial_setup'>Harmony Controller Self-Managed Trial Setup</a> for instructions
          </div>
        }
        {
          formData.cloudoption === 'SaaS' &&
          <div className='alert alert-success'>
            Trial license created successfully! Please find your trial license <a href={`/licenses/${successResponse.license_id}`}>here</a>.
          </div>
        }
      </Fragment>
    )
  }

  const renderModal = () => {
    return (
      <Modal show onHide={handleCloseModal} size='lg' animation={false} dialogClassName='adjust-header'>
        <Modal.Header closeButton>
          <Modal.Title>HARMONY CONTROLLER TRIAL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Spinner /> }
          {error && <div className='alert alert-danger'> {error} </div>}
          {renderErrors()}
          {!loading && !successResponse && renderForm()}
          {successResponse && renderSuccessResponse()}
        </Modal.Body>
      </Modal>
    )
  }

  const handleOpenModal = () => {
    if (canCreate) {
      setShowModal(true)
    }
  }

  return (
    <Fragment>
      <Button
        style={btnStyle}
        classNames={['center']}
        disabled={!canCreate}
        data-toggle={!canCreate && 'tooltip'}
        title={!canCreate && 'You have reached your max trial license limit of 5 for selected type.'}
        onClick={handleOpenModal}>
        START FREE TRIAL
      </Button>
      {showModal && renderModal()}
    </Fragment>
  )
}

export default HarmonyOneClickTrialButton
