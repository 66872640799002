import React, { PureComponent } from 'react'
import FilterWithClear from '../FilterWithClear'
import debounce from 'lodash/debounce'

class UserFilter extends PureComponent {
  static defaultProps = {user_filter: '', spinnerOn: false}

  debouncedHandleFilterChange = debounce(this.props.handleFilterChange, 300)

  handleClearInput = values => {
    this.props.handleFilterChange({
      user_filter: ''
    })
  }

  render () {
    return (
      <FilterWithClear
        input={{
          id: 'user_filter_id',
          name: 'user_filter_name',
          placeholder: 'Search Users',
          required: true
        }}
        submitField='user_filter'
        className='form-control'
        items={this.props.items}
        onChange={this.debouncedHandleFilterChange}
        handleClearInput={this.handleClearInput}
      />
    )
  }
}

export default UserFilter
