import React, {useState, useEffect, Fragment} from 'react'
import Modal from 'react-bootstrap/Modal'
import 'isomorphic-fetch'
import Paginate from '../../Paginate'
import qs from 'query-string'
import SortableTableHeader from '../SortableTableHeader'
import WithHelpText from './WithHelpText'

const TopTrialUsers = ({onClose}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [paginationData, setPaginationData] = useState()
  const [error, setError] = useState()
  const [page, setPage] = useState(1)
  const [sortVal, setSortVal] = useState({key: 'Total', direction: 'desc'})

  const loadData = async (selectedPage) => {
    const queryParams = qs.parse(window.location.search)
    const fetchParams = {...queryParams, page: selectedPage, activity_key: sortVal.key, direction: sortVal.direction}
    const fetchUrl = `/harmony_metrics/top_trial_lead_entries?${qs.stringify(fetchParams)}`
    setLoading(true)

    const response = await fetch(fetchUrl).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setData(jsonData.data)
      setPaginationData(jsonData.pagination)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  const handlePage = (page) => {
    setPage(page)
    loadData(page)
  }

  useEffect(() => {
    setPage(1)
    loadData(1)
  }, [sortVal])

  const renderData = () => {
    return (
      <table className='table small-table custom-hc-summary-table'>
        <thead>
          <tr>
            <th>Trial Lead Email</th>
            <SortableTableHeader
              title={'GLM Activations'}
              trackKey={'GLM Activations'}
              onChange={setSortVal}
              sortKey={sortVal.key}
              sortDirection={sortVal.direction} />
            {data.activity_keys.map((activity, i) =>
              <SortableTableHeader
                key={i}
                title={activity}
                trackKey={activity}
                onChange={setSortVal}
                sortKey={sortVal.key}
                sortDirection={sortVal.direction} />
            )}
            <SortableTableHeader
              title={'Total'}
              trackKey={'Total'}
              onChange={setSortVal}
              sortKey={sortVal.key}
              sortDirection={sortVal.direction} />
          </tr>
        </thead>
        <tbody>
          {data.providers.map((p, i) =>
            <tr key={i}>
              <td>{p.trial_lead_email}</td>
              <td style={{borderRight: '2px solid #dddddd'}}>{p.used_activations}</td>
              {data.activity_keys.map((activity, ai) =>
                <td key={ai}>{p.activities[activity]}</td>
              )}
              <td key={i}><b>{p.total_activities_count}</b></td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <Modal show onHide={() => onClose()} size='lg' animation={false} dialogClassName='adjust-header'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <WithHelpText infoKey='top_trial_users_list'>Top Trial Users</WithHelpText> {paginationData && <span>({paginationData.total_count})</span>}
            {loading && <i className='fa-spinner fa fa-pulse' style={{fontSize: '19px', marginLeft: '10px'}} /> }
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeigh: '400px'}}>
        {error && <div className='alert alert-danger'> {error} </div>}
        {data && renderData()}
        { paginationData && paginationData.total_pages > 1 &&
        <Fragment>
          <hr />
          <Paginate
            pages={paginationData.total_pages}
            currentPage={paginationData.current_page}
            onPageNext={() => handlePage(page + 1)}
            onPageBack={() => handlePage(page - 1)}
            toPage={(pageNum) => handlePage(pageNum)} />
        </Fragment>
        }
        {loading && paginationData && <Fragment>&nbsp;<i className='fa-spinner fa fa-pulse' /></Fragment>}
      </Modal.Body>
    </Modal>

  )
}

export default TopTrialUsers
