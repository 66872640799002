import React, {Fragment} from 'react'
import IdpRows from './IdpRows'
import IdpEmpty from './IdpEmpty'
import { Table, THead, TBody } from '../components/Table'
import Button from '../components/Button'

export const IdpIndex = ({ organizationId, organizationName, idpConfigurations }) => {
  const headers = ['Configuration Name',
    'Adminstrator Email',
    'Domain Count',
    <Button variant='primary'
      key='add_button'
      classNames={['pull-right btn-sm']}
      href={`/idp_configurations/new?organization_id=${organizationId}&current_organization=true`}>
      <span><i className='fa fa-plus' /> Configuration</span>
    </Button>]
  return (
    <Fragment>
      <div className='col-md-12'>
        <div className='row'>
          <h3>IdP Configurations</h3>
        </div>
        <div className='row'>
          {idpConfigurations.length ?
            (
              <Table>
                <THead>{headers}</THead>
                <TBody>
                  <IdpRows idps={idpConfigurations} organizationId={organizationId} />
                </TBody>
              </Table>
            )
            :
            (
              <IdpEmpty organizationId={organizationId} organizationName={organizationName} />
            )
          }
        </div>
      </div>
    </Fragment>
  )
}

export default IdpIndex
