import React from 'react'
import moment from 'moment'
import useVictory from './helpers/useVictory'
import MouseFollowTooltip from './MouseFollowTooltip'
import { formatBits, timeTickFormat } from './../util/dataFormatters'

const buildLabel = ({ datum }) => {
  return `${moment(datum.windowEnd).utc().format('llll')} (UTC)
          Usage: ${formatBits(datum.averageThroughput)}
          Allocated: ${formatBits(datum.allocatedBandwidth)}
          Allocation can change over time`
}

export const ThroughputAverageGraph = ({
  previous,
  resetWeek,
  next,
  startTime,
  endTime,
  data
}) => {
  const Victory = useVictory()
  return (
    <div className='border-bottom chart-wrapper'>
      <div className='text-center'>
        <h4 className='text-muted h4 mt1'>Throughput Average</h4>
        <h6 className='text-muted h6 mt1'>Per 1 Hour Window</h6>
      </div>
      {Victory &&
      <Victory.Chart
        height={230}
        width={450}
        scale={{x: 'time'}}
        domain={{x: [startTime.toDate(), endTime.toDate()]}}
        domainPadding={{y: 10}}
        padding={{bottom: 20, top: 16, left: 43, right: 9}}
        theme={Victory.Theme.material}
        containerComponent={
          <Victory.VoronoiContainer
            labels={(datum) => buildLabel(datum)}
            labelComponent={<MouseFollowTooltip orientation='bottom' cornerRadius={3} flyoutStyle={{fill: 'black', strokeWidth: 0}} />}
          />
        }
      >
        <Victory.Legend
          x={150}
          orientation="horizontal"
          style={{ labels: { fontSize: 7 }}}
          colorScale={[ '#008000', '#F59100' ]}
          data={[{ name: "Current Allocation" }, { name: "Actual Usage" }]}
        />
        <Victory.Axis dependentAxis
          tickFormat={(t) => (formatBits(t))}
          style={{tickLabels: {padding: 0, fontSize: 6}}}
        />
        <Victory.Axis
          scale={{x: 'time'}}
          style={{tickLabels: {padding: 0, fontSize: 6}}}
          tickFormat={timeTickFormat}
        />
        <Victory.Line
          data={data}
          style={{data: {stroke: '#F59100', strokeWidth: 1}, labels: {fontSize: 8, fill: 'white', padding: 0}}}
          x='windowEnd'
          y='averageThroughput'
        />
        <Victory.Line
          data={data}
          style={{data: {stroke: '#008000', strokeWidth: 1}, labels: {fontSize: 8, fill: 'white', padding: 0}}}
          x='windowEnd'
          y='allocatedBandwidth'
        />
      </Victory.Chart>
      }
      <div className='text-center'>
        <ul className='pagination'>
          <li><a onClick={(e) => previous(e, 1, 'month')} className='prev' href='#'><i className='fa fa-angle-double-left' /></a></li>
          <li><a onClick={(e) => previous(e, 5, 'days')} className='prev' href='#'><i className='fa fa-angle-left' /></a></li>
          <li><a onClick={(e) => resetWeek(e)} href='#'>Reset</a></li>
          <li><a onClick={(e) => next(e, 5, 'days')} className='next' href='#'><i className='fa fa-angle-right' /></a></li>
          <li><a onClick={(e) => next(e, 1, 'month')} className='next' href='#'><i className='fa fa-angle-double-right' /></a></li>
        </ul>
      </div>
    </div>
  )
}