import React, { Component } from 'react'

class Alert extends Component {
  static defaultProps = {
    closable: true,
    level: 'notice'
  }

  state = { visible: true }

  handleClose = () => {
    this.setState({ visible: false }) 
  }

  render () {
    const { children, closable, level, color, ...props } = this.props

    const alertLevel = color || `alert-${level}`
    // TODO fix toggle (see console when this is rendered)
    if (!this.state.visible) return null
    return (
      <div
        className={`alert ${alertLevel} alert-dismissable`}
        color='info'
        toggle={this.handleClose}
        {...props}
      >
        {closable && <button
          className='close'
          aria-label='Close'
          aria-hidden='true'
          data-dismiss='alert'
          onClick={this.handleClose}
        />}
        {children}
      </div>
    )
  }
}

export default Alert
