import React, { useState, useRef, useEffect, Fragment } from 'react'
import Stepper from './Stepper'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import {renderErrors, validateSection, validateAllSections} from '../helpers/stepFormValidator'

const LicenseStepper = (props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [steps, setSteps] = useState([])
  const containerRef = useRef()
  const stepsContainerRef = useRef()
  const submitRef = useRef()
  const [disabledSteps, setDiabledSteps] = useState([])
  const { formSubmitting, setFormSubmitting } = props

  const stepClasses = {
    visible: 'visible-step-section',
    all: 'step-section'
  }

  const setupStepper = () => {
    const newDisabledSteps = []
    const newSteps = []
    if (containerRef && containerRef.current) {
      forEach($(containerRef.current).children(), (el, i) => {
        const stepNode = $(el)
        const title = stepNode.attr('title')
        if (title && $.trim(stepNode.html()) !== '') {
          newSteps.push({title})
        } else {
          newDisabledSteps.push(i)
        }
      })
    }
    setSteps(newSteps)
    setDiabledSteps(newDisabledSteps)
  }
  useEffect(() => {
    setupStepper()
  }, [containerRef, props.controlField])

  const scrollToTop = () => {
    const offsetEl = $(stepsContainerRef.current)
    const offset = offsetEl.offset()
    if (offset) {
      $('html, body').animate({
        scrollTop: offset.top,
        scrollLeft: offset.left
      }, 200)
    }
  }

  const handleNext = (e) => {
    e.preventDefault()

    const errors = validateSection($(`.${stepClasses.visible}`))
    if (errors.length === 0) {
      setCurrentStep(currentStep + 1)
      scrollToTop()
    } else {
      props.alertFn(renderErrors(errors), {className: 'alert alert-danger'})
    }
  }

  const handlePrevious = (e) => {
    e.preventDefault()
    setCurrentStep(currentStep - 1)
    scrollToTop()
  }

  const validateFieldsAndSubmit = (e) => {
    e.preventDefault()
    const {errorStep, errors} = validateAllSections($(`.${stepClasses.all}`))
    if (errors) {
      setCurrentStep(errorStep)
      props.alertFn(renderErrors(errors), {className: 'alert alert-danger'})
      scrollToTop()
    } else {
      setFormSubmitting(true)
      $(submitRef.current).trigger('click')
    }
  }

  const renderFormAction = () => {
    return (
      <Fragment>
        <a href='#'
          disabled={formSubmitting}
          className='btn btn-primary'
          onClick={validateFieldsAndSubmit}>
          {props.action} License { formSubmitting && <i className='fa-spinner fa fa-pulse'/> }
        </a>
        <input ref={submitRef} className='hidden' type='submit' name='commit' />
      </Fragment>
    )
  }

  return (
    <div ref={stepsContainerRef}>
      <Stepper
        steps={steps}
        nonLinear={props.action === 'Update'}
        activeStep={currentStep}
        stepFn={setCurrentStep} />
      <div ref={containerRef} style={{marginTop: '12px'}} >
        {React.Children.map(props.children, (child, step) =>
          React.cloneElement(child, { ...child.props, currentStep, step, disabledSteps, stepClasses })
        )}
        <div className='row'>
          <div className='col-md-6'>
            {currentStep > 0 && <a href='#' className='btn btn-default' onClick={handlePrevious}>Previous</a> }
          </div>
          <div className='col-md-6 text-right'>
            {currentStep < steps.length - 1 && <a href='#' className='btn btn-primary' onClick={handleNext}>Next</a> }
            {props.action === 'Create' && currentStep >= steps.length - 1 && renderFormAction() }
          </div>
          {props.action === 'Update' &&
            <div className='col-md-12 text-right'>
              <hr />
              {renderFormAction()}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const LicenseStep = ({step, currentStep, title, disabledSteps, stepClasses, ...props}) => {
  let calculatedStep = step
  if (includes(disabledSteps, step)) {
    calculatedStep = 9999999
  } else {
    forEach(disabledSteps, (disabledStep) => {
      if (disabledStep < step) {
        calculatedStep -= 1
      }
    })
  }

  let classList = calculatedStep !== currentStep ? 'hidden' : `animate-fadeIn ${stepClasses.visible}`
  classList = `${classList} animated ${stepClasses.all}`
  return (
    <div
      data-step={calculatedStep}
      className={classList}
      title={title}>
      {props.children}
    </div>
  )
}

export { LicenseStepper, LicenseStep }
