import React from 'react'
import Form from 'react-bootstrap/Form'
import { classStr } from './helpers/form'

const EmailInput = ({ className, ...props }) =>
  <Form.Control
    as='input'
    className={classStr('string', className)}
    type='email'
    placeholder='email@example.com'
    title='email@example.com'
    pattern='[^@]+@[^@]+\.[^@]+'
    {...props}

  />

export default EmailInput
