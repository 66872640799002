import React from 'react'
import PropTypes from 'prop-types'

import Input from './Input'

const Email = ({
  classNames = [],
  ...props
}) =>
  <Input
    type='email'
    classNames={['string', ...classNames]}
    placeholder='email@example.com'
    title='email@example.com'
    pattern='[^@]+@[^@]+\.[^@]+'
    {...props}
  />

Email.propTypes = {
  classNames: PropTypes.array
}

export default Email
