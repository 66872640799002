import React, { useEffect, useState, Fragment } from 'react'
import Spinner from '../../Spinner'
import 'isomorphic-fetch'
import MetricsChart from './MetricsChart'
import WithHelpText from './WithHelpText'
import TrialLeadChartEntries from './TrialLeadChartEntries'
import TopTrialUsers from './TopTrialUsers'

const TrialsSummary = ({onClose}) => {
  const [summary, setSummary] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [chartItemRange, setChartItemRange] = useState()
  const [showChartItemTrials, setShowChartItemTrials] = useState(false)
  const [showTopTrialUsers, setShowTopTrialUsers] = useState(false)

  const handleChartItemClick = (range) => {
    setChartItemRange(range)
    setShowChartItemTrials(true)
  }

  const loadSummary = async () => {
    setLoading(true)

    const response = await fetch(`/harmony_metrics/trials_summary.json${window.location.search}`).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })

    if (response.status === 200) {
      const jsonData = await response.json()
      setSummary(jsonData)
    } else {
      setError(response.statusText || 'something went wrong')
    }
    setLoading(false)
  }

  useEffect(() => {
    loadSummary()
  }, [])

  const viewtopTrials = (e) => {
    e.preventDefault()
    setShowTopTrialUsers(true)
  }

  const renderSummary = () => {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <p>
            <WithHelpText infoKey='top_trial_user'>Top Trial User:</WithHelpText> <b>{summary.top_trial_user}</b>
            { summary.top_trial_user !== 'N/A' &&
              <Fragment>&nbsp;<a href='#' onClick={viewtopTrials}>(view all)</a></Fragment>
            }
          </p>
        </div>
        <div className='col-md-12'>
          <div className='card bg-light text-center'>
            <div className='card-body'>
              <p><WithHelpText infoKey='trials_in_range'>Total Trials in range:</WithHelpText> <b>{summary.new_trials}</b></p>
              {
                summary.new_trials > 0 &&
                <MetricsChart
                  onClick={handleChartItemClick}
                  yTitle='Number of trials'
                  fetchURL={`/internal/harmony_metrics_charts/new_trials_data.json${window.location.search}`}
                />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {loading && <div> Loading Trials Summary <Spinner /></div> }
      {error && <div className='alert alert-danger'> {error} </div>}
      {!summary && !loading && !error && <div className='alert alert-danger'> No Data available </div>}
      {summary && renderSummary()}
      {showChartItemTrials &&
        <TrialLeadChartEntries chartItemRange={chartItemRange} onClose={() => {
          setShowChartItemTrials(false) 
        }} />
      }
      {showTopTrialUsers && <TopTrialUsers onClose={() => setShowTopTrialUsers(false)} /> }
    </div>

  )
}

export default TrialsSummary
