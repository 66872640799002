import React, { forwardRef } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const InputComponent = forwardRef((props, ref) => {
  return <input ref={ref} {...{...props, className:"form-control PhoneInputInput"}} />
})

const PhoneField = (props) => <PhoneInput inputComponent={InputComponent} {...props}/>

export default PhoneField
