import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from '../Spinner'
import { formatDateTime } from '../components/HarmonyMetrics/helpers'

const ThreatstopUsers = ({licenseId}) => {
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const loadData = (fetchUrl) => {
    setLoading(true)
    setError()
    fetch(fetchUrl, { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        setData(data)
      })
      .catch(() => {
        setLoading(false)
        setError('Something went wrong')
      })
  }

  const handleClick = (e) => {
    e.preventDefault()
    setShowModal(true)
    const fetchUrl = `/threatstop/${licenseId}/users_with_access`
    loadData(fetchUrl)
  }

  const handleReload = (e) => {
    e.preventDefault()
    const fetchUrl = `/threatstop/${licenseId}/users_with_access?force_reload=true`
    loadData(fetchUrl)
  }

  const onClose = () => setShowModal(false)

  return (
    <>
      <i>
        &nbsp;<a href="#" onClick={handleClick}>View users</a> having access to ThreatStop account
      </i>
      { showModal && <Modal show={true} onHide={onClose} className="react">
        <Modal.Header closeButton>
          <Modal.Title>Users having access to ThreatStop account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <div className='text-center'> <Spinner /></div> }
          {error && !loading && <div className='alert alert-danger'> {error} </div>}
          {
            !loading && !error && data && 
              <div>
                {data.data.length > 0 && 
                  <ul>
                    {data.data.map((email, i) =>
                      <li key={i}>{email}</li>
                    )}
                  </ul>
                }
                {data.cached_at &&
                  <div className='mt2 small'>showing results cached at <i>{formatDateTime(data.cached_at)}</i>, 
                    <a href="#" onClick={handleReload}> click here</a> to force reload <br/>
                  </div>
                }
              </div>
          }
        </Modal.Body>
      </Modal>}
    </>
  )
}

export default ThreatstopUsers
