import React, { useEffect } from 'react'
import { FormInput } from './Form'

export const filterOrgs = orgs => orgs.map(({ label, value }) => ({ label, value }))

export const findOrg = (orgs, id) => orgs.find(o => o.value === id)

export const OrgSelect = ({
  children: orgs,
  onOrgChange,
  ...props
}) => {
  useEffect(() => {
    if (!(props.value && onOrgChange)) return
    const org = findOrg(orgs, props.value)
    onOrgChange(org)
  }, [props.value])

  return (
    <FormInput
      type='select'
      label='Organization'
      placeholder='Select an Organization'
      name='organization_id'
      required
      children={filterOrgs(orgs)}
      {...props}
    />

  )
}

export default OrgSelect
