import React, { useState } from 'react'
import CalendarSelect from './CalendarSelect'
import { getMoment, getMomentStr } from '../../util/time'
import { isUndefined } from 'lodash'

export const StartsAt = ({
  name,
  onChange,
  onSelect,
  value,
  ...props
}) => {
  const inputLabel = props.inputLabel || 'Starts At'
  const [calendarValue, setCalendarValue] = useState(value)
  const handleChange = v => {
    setCalendarValue(v)
    onChange({ name, value: v })
    if (!isUndefined(onSelect)) {
      onSelect({ name, value: v })
    }
  }

  return (
    <CalendarSelect
      label={inputLabel}
      open
      onChange={handleChange}
      value={value}
      utcEnabled={true}
      {...props}
    />
  )


}

export default StartsAt
