import React from 'react'

const ActionButtons = ({id}) =>
  <div className='btn-group btn-group-sm'>
    <a href={`/email_lists/new?clone_list_id=${id}`} className='btn btn-round btn-default' title="Clone">
      <i className='fa fa-copy'/>
    </a>
    <a href={`/email_lists/${id}/edit`} className='btn btn-round btn-default' title="Edit">
      <i className='fa fa-pencil'/>
    </a>
    <a className='btn btn-round btn-danger' href={`/email_lists/${id}`} title="Delete" data-method="delete" data-confirm="Are you sure?">
      <i className='fa fa-trash'/>
    </a>
  </div>

export default ActionButtons
