// Append classNames into a single string, removing empty values
export const classStr = (...names) => (
  names.filter(v => v).join(' ').trim()
)

// If an object contains only one key, return that item
// Else return return '' or a custom value given
export const getDefaultValue = (items) => {
  const keys = Object.keys(items || {})
  return keys.length === 1
    ? keys[0]
    : ''
}

// Based on a model type and attribute, get a string to be used with `className`
export const attributeClassName = (model, attr) => model && attr && `${model}_${attr}`

// Based on a model type and attribute, get a string to be used with `htmlFor` and `name`
export const attributeName = (model, attr) => model && attr && `${model}[${attr}]`

// Given a rails attr name like 'license_type', titleize it like 'License Type'
export const attributeLabel = attr => (
  attr.split('_')
    .map(a => `${a.charAt(0).toUpperCase()}${a.slice(1)}`)
    .join(' ')
)

// Convert an html input type into the equivalent ruby type
// The ruby types are used for classNames on elements wrapping inputs
export const classType = type => {
  if (type === 'text') return 'string'
  if (type === 'textarea') return 'text'
  if (type === 'number') return 'integer'
  if (type === 'checkbox') return 'boolean'
  return type
}

// Rails appends either 'required' or 'optional' to the className of input-wrapping elements
// This helpers converts a boolean to the className needed
export const requiredStr = (required = false) => required ? 'required' : 'optional'
