import React, { Component } from 'react'
import AcceptEULA from './AcceptEULA'
import Finished from './Finished'
import NewUserEmail from './NewUserEmail'
import SelectRoles from './SelectRoles'
import CreateOrg from './CreateOrg'
import StrongPassword from './StrongPassword'
import VerifyEmail from './VerifyEmail'
import { wizard } from '../Wizard'

const Wizard = wizard(NewUserEmail, VerifyEmail, StrongPassword,
  SelectRoles, AcceptEULA, CreateOrg, Finished)

class CreateUserWizard extends Component {
  state = {
    wizardState: {
      password: '',
      verifyPassword: '',
      verifyCode: '',
      email: '',
      orgName: '',
      score: 0,
      strengthMessage: 'Weak',
      roleSelected: 'employee',
      wizardTitle: 'Create New Account'
    }
  }

  render () {
    return (
      <div className='col-md-offset-2 col-md-8'>
        <Wizard wizardState={this.state.wizardState} />
      </div>
    )
  }
}

export default CreateUserWizard
