import React, { useState } from 'react'
import Button from '../components/Button'
import Label from '../components/Label'
import Input from '../components/Input'

export const IdpDomainListEditor = ({ idpDomains, onAdd, onDelete, onAdding }) => {
  const [newDomain, setNewDomain] = useState('')

  const domainValid = () => {
    return newDomain.length > 0
  }

  return (
    <>
      <div className='row py0'>
        <div className='col-md-6'>
          <Label classNames={['text-muted']}>{`Domain${idpDomains.length > 1?'s':''}`} <sup>*</sup></Label>
          <br />
        </div>
      </div>
      <div className='row py1'>
        <div className='col-md-11'>
          <Input value={newDomain}
            onChange={(e) => {
              setNewDomain(e.target.value.toLowerCase())
            }}
          />
        </div>
        <div className='col-md-1'>
          <Button variant='primary'
            disabled={!domainValid()}
            classNames={['btn-sm', 'pull-right', 'pt2', 'pb2']}
            onClick={() => {
              onAdd(newDomain)
              setNewDomain('')
            }}
          >
            <i className='fa fa-plus' />
          </Button>
        </div>
      </div>
      {idpDomains.map((domain, index) =>
        <div key={`row_${index}`} className='row py1'>
          <div className='col-md-11'>
            <Input key={`input_${index}`}
              value={domain}
              readOnly
            />
          </div>
          <div className='col-md-1'>
            <Button variant='danger'
              key={`minus_${index}`}
              classNames={['btn-sm', 'pull-right', 'pt2', 'pb2']}
              onClick={() => onDelete(index)}
            >
              <i className='fa fa-trash' />
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default IdpDomainListEditor
