import React from 'react'
import { FormInput } from './components/Form'

const PortSelect = ({ children, ...props }) => {
  const handleChange = (e) => {
    props.onChange({target: {id: e.target.value, value: 1}})
  }

  return (
    <FormInput
      type='select'
      label=' '
      placeholder='Add Available Port'
      onChange={handleChange}
      children={children.filter(c => !c.setting)}
    />
  )
}

export default PortSelect
