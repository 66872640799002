import React from 'react'
import { Button } from '../components/Button'

const IdpEmpty = ({ organizationName, organizationId }) => (
  <div className='center mt4'>
    <span className='fa-4x text-muted'>
      <i className='fa fa-hdd-o' />
    </span>
    <h4 className='p1'>
      {`${organizationName} doesn't have any IdP configurations yet.`}
    </h4>
    <div className='mt4'>
      <Button
        className='btn btn-md btn-primary'
        href={`/idp_configurations/new?organization_id=${organizationId}`}
      >
        Add Configuration
      </Button>
    </div>
  </div>
)

export default IdpEmpty
