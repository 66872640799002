import React from 'react'
import Alert from './Alert'

const ErrorAlert = ({ errors, model, ...props }) =>
  <Alert>
    <button type='button' className='close' aria-label='Close'>
      <span aria-hidden='true'>x</span>
    </button>
    <h2>
      {errors.length} error{errors.length > 1 && 's '}
      prohibited this {model} from being saved
    </h2>

    <ul>
      {errors.map((error, i) =>
        <li key={`error${i}`}>{error}</li>
      )}
    </ul>
  </Alert>

export default ErrorAlert
