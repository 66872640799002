import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const CloseButton = ({ closeToast }) => (
  <span
    onClick={closeToast}
    className='Toastify__close-button Toastify__close-button--default'>
    ×
  </span>
)

const defaultOpts = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true
}

export const GLMToastContainer = props => <ToastContainer closeButton={<CloseButton />} {...props} />
export const GLMToast = (message, opts = {}) => toast(typeof(message) === 'string' ? <div dangerouslySetInnerHTML={{__html: message}}></div> : message, {...defaultOpts, ...opts})
