import React from 'react'
import Box from './Box'
import { FormGroup } from './Form'
import BaseEmail from './Email'
import { Input, Label } from './Input'

// TODO fix styles
export const Checkbox = props =>
  <Box className='col-sm-1 col-sm-offset-1 text-center'>
    <Input type='checkbox' {...props} />
  </Box>

export const PartnerCheckbox = props =>
  <Box className='col-sm-1 text-right'>
    <Input type='checkbox' {...props} />
  </Box>

export const Delete = props =>
  <Box className='col-sm-1 delete-button'>
    <Input
      type='button'
      variant='link'
      className='pull-right'
      {...props}
    >
      <i className='fa fa-times' />
    </Input>
  </Box>

export const Email = props =>
  <Box className='col-sm-8 px0'>
    <FormGroup className='email optional license_authorized_users_email p0'>
      <BaseEmail {...props} />
    </FormGroup>
  </Box>

export const Heading = ({ canNotify, required }) =>
  <FormGroup className='row p0' required={required}>
    <Box classNames={[
      { 'col-md-8': canNotify, 'col-md-10': !canNotify },
      'p0'
    ]}
    >
      <Label required={required} type='string'>
        Users with Access
      </Label>
    </Box>
    {canNotify &&
      <Box className='col-sm-2 text-center'>
        <Label>Send Email</Label>
      </Box>
    }
    <Box className='col-sm-1 text-center'>
      <Label>Partner</Label>
    </Box>
  </FormGroup>

export const NewUserButton = props =>
  <Box className='col-sm-4 p0 px0'>
    <Input type='button' {...props}>
      Authorize New User
    </Input>
  </Box>

export const NotifyAll = props =>
  <>
    <Box className='col-sm-3 p0 text-right px0'>
      <Label>Select / Unselect All</Label>
    </Box>
    <Box className='col-sm-offset-1 text-center'>
      <Input type='checkbox' {...props} />
    </Box>
  </>

// TODO try Align as FormGroup
// TODO: Add FormError when required and email is blank / maybe invalid
export const UserRow = props =>
  <FormGroup
    className='container-fluid p0 postion-relative'
    {...props}
  />
