import React, { PureComponent } from 'react'
import Button from './Button'

const pageRange = (currentPage, pages) => {
  let start = 1
  let end = pages
  if (pages > 10) {
    end = 10
    if (currentPage >= 7) {
      start = currentPage - 5
      end = start + 9
      if (end > pages) {
        start = pages - 9
        end = pages
      }
    }
  }
  return [start, end]
}

class Paginate extends PureComponent {
  render () {
    const PaginatePageButtons = values => {
      let buttons = []
      let range = pageRange(values.currentPage, values.pages)
      for (var i = range[0]; i <= range[1]; i++) {
        buttons.push(
          <li className={i === values.currentPage ? 'active' : ''} key={'button_' + i}>
            <Button onClick={values.toPage.bind(values, i)} >
              {i}
            </Button>
          </li>)
      }
      return buttons
    }

    return (
      <ul className='pagination'>
        {this.props.currentPage !== 1 &&
          <li>
            <Button
              onClick={this.props.onPageBack}>
              Back
            </Button>
          </li>
        }
        <PaginatePageButtons
          pages={this.props.pages}
          currentPage={this.props.currentPage}
          pageRange={pageRange}
          toPage={this.props.toPage} />
        {this.props.pages !== this.props.currentPage &&
          <li>
            <Button
              onClick={this.props.onPageNext}>
            Next
            </Button>
          </li>
        }
      </ul>
    )
  }
}

export default Paginate
