import React, { useState } from 'react'
import moment from 'moment'

import AuthorizedUsers from '../containers/AuthorizedUsers'
import ExpiresAt from '../containers/ExpiresAt'
import { Form, Field } from '../containers/Form'
import {
  FormActions,
  FormInfo,
  FormSection,
  FormSubmit,
  FormTitle
} from '../components/Form'
import {
  CustomerPoNumber,
  InternalNotes,
  OraclePart,
  OracleLine,
  Reason,
  SfdcId,
  PartnerName
} from '../components/InternalFields'
import Notes from '../components/Notes'
import OrgSelect from '../components/OrgSelect'
import Quantity from '../components/Quantity'
import SalesOrderSelect from '../components/SalesOrderSelect'
import StartsAt from '../components/StartsAt'


export const ApprovePendingLicenseForm = ({ props, attributes }) => {
  const [org, setOrg] = useState()
  const [startsAt, setStartDate] = useState()

  const prepareFetchData = data => ({ license: {...data, license_pending_id: attributes.license_pending_id }})

  const handleSuccess = ({ data }) => {
    window.location = `/pending_licenses`
  }

  const handleError = () => {
    window.scrollTo(0,0)
  }

  const handleStartChange = values => {
    setStartDate(values.value)
  }
  const formatDate = date => moment(date, 'DD/MM/YYYY')
  const startsDate = startsAt === undefined ? formatDate(new Date()) : formatDate(startsAt)

  return (
    <Form
      title='Create License and Activation'
      url='/pending_licenses/create_license_and_activation'
      method='POST'
      attributes={attributes}
      prepareData={prepareFetchData}
      inputProps={props}
      onSuccess={handleSuccess}
      onError={handleError}
    >

      <Field name='name' />

      <Field
        as={OrgSelect}
        name='organization_id'
        onOrgChange={setOrg}
      />

      <Field as={Quantity} name='number_of_appliances' disabled={true} />

      <Field name='license_type' disabled={true} />

      <Field as={Reason} name='reason' />

      <FormSection>
        <Field as={SfdcId} name='sfdc_partner_id' />
        <Field as={PartnerName} name='partner_name' />
        <Field as={OraclePart} name='oracle_part' />
        <Field as={OracleLine} name='oracle_line' />
        <Field as={CustomerPoNumber} name='bill_to_purchase_order_id' />
        <Field
          as={SalesOrderSelect}
          isRequired={true}
          name='order_attributes.a10_sales_order_id'
        />
      </FormSection>

      <Field as={StartsAt} name='starts_at' onSelect={handleStartChange} />
      <Field as={ExpiresAt} name='expires_at' sku={{product: attributes.license_type}} startsAt={startsDate} date={startsAt} />

      <Field
        as={AuthorizedUsers}
        name='authorized_users'
        required={!(org && org.owner_id)}
      />

      <FormSection>
        <Field as={Notes} name='notes' />
        <Field as={InternalNotes} name='internal_notes' />
      </FormSection>

      <FormActions>
        <FormSubmit value='Create License and Activation' />
      </FormActions>
    </Form>
  )
}

export default ApprovePendingLicenseForm
