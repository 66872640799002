import React from 'react'
import capitalize from 'lodash/capitalize'

const UsersCreatedMetricsOptions = ({onKeyChange, state}) => {
  const btnStyle = {
    outline: 'none',
    padding: '0 4px',
    fontSize: '11px',
    fontWeight: 'bold'
  }

  const userOptions = {
    checkboxes: ['employee', 'customer']
  }

  return (
    <div className='btn-group'>
      {userOptions.checkboxes.map((item, i) =>
        <button
          key = {i}
          className={`${state[item] === true ? 'btn btn-primary' : 'btn btn-secondary'}`}
          onClick={(e) => onKeyChange(e, item)}
          style={btnStyle}
        >
          {capitalize(item)}
        </button>
      )}
    </div>
  )
}

export default UsersCreatedMetricsOptions
