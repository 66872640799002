import React, {Fragment} from 'react'
import ReactTooltip from 'react-tooltip'
import { randomString } from '../../helpers/util'

const infoText = {
  top_trial_user: 'GLM Trial lead email mapped to the provider having most number of activities in the given range',
  trials_in_range: 'Total Harmony SaaS trials created in provided range',
  top_trial_users: 'Providers mapped with trial leads with active license having most number of session management activities (register - deregister), device activities and activations in given range',
  activations: 'Number of activations used from GLM',
  devices: '(Register device activities - Deregister device activities) count for a given range',
  logins: 'Number of "Session Management" activities in given range',
  show_provider_summary_btn: 'Shows providers summary active in the specified range, count of activities are for the specified range only',
  active_providers_head: 'List of HC providers having activities triggered in the specified range',
  not_in_glm: 'Provider entry not mapped to GLM trial lead entry',
  view_activities_btn: 'Shows activity details in specified range for provider',
  search_providers_form: 'Search providers (with valid activities in range) by name, email, organization name, and contact',
  provider_region: 'Count of providers for the mentioned regions, the regions are populated from GLM trial leads data mapped to the providers',
  providers_activities: 'Shows activities count for providers that are triggered in the given range',
  top_trial_users_list: <span>HC Providers mapped with GLM trial lead entries in the given range,<br /> sortable by specific activity, activations or total activities count, by default sorted by total activities count</span>
}
const WithHelpText = (props) => {
  const tooltipId = randomString()

  if (props.hideHelp) {
    return <Fragment>{props.children}</Fragment>
  }

  return (
    <Fragment>
      <Fragment>
        {props.children} <i className='fa fa-question-circle' data-tip data-for={tooltipId} />
      </Fragment>
      <ReactTooltip id={tooltipId} place='top' data-html='true' effect='solid' wrapper='span'>
        {infoText[props.infoKey]}
      </ReactTooltip>
    </Fragment>
  )
}

export default WithHelpText
