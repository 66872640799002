import React, { useState } from 'react'
import CalendarSelect from '../CalendarSelect'
import momenttz from 'moment-timezone'
import { getMomentStr, getMoment } from '../../../util/time'
momenttz.tz.setDefault('UTC')

const RangeForm = ({start, end}) => {
  const [startDate, setStartDate] = useState(getMoment(start))
  const [endDate, setEndDate] = useState(getMoment(end))

  const onChange = (v, setFn) => {
    const utcDate = momenttz(v).format('DD/MM/YYYY')
    setFn(utcDate)
  }

  const handleSubmit = () => {
    const startDateStr = getMomentStr(startDate)
    const endDateStr = getMomentStr(endDate)
    window.location.href = `/harmony_metrics?page=1&starts_at=${startDateStr}&ends_at=${endDateStr}`
  }

  return (
    <div className='row horizontal-range-form'>
      <div className='col-md-4'>
        <CalendarSelect
          open
          value={startDate}
          onChange={(date) => onChange(date, setStartDate)}
          label={'Start Date'} />
      </div>
      <div className='col-md-4'>
        <CalendarSelect
          open
          value={endDate}
          onChange={(date) => onChange(date, setEndDate)}
          label={'End Date'} />
      </div>
      <div className='col-md-4 submit-btn'>
        <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  )
}

export default RangeForm
