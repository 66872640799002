import React, {useState, useEffect, Fragment, Suspense} from 'react'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import orderBy from 'lodash/orderBy'

import Spinner from '../../../Spinner'

const ReactHighmaps = React.lazy(() => import('react-highcharts/ReactHighmaps.src'));

const MapChart = ({fetchURL, label}) => {
  const [chartConfig, setChartConfig] = useState()
  const [topology, setTopology] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const getMinMax = (data) => {
    let values = map(data, (i) => i.value)
    const uniqOrderedValues = uniq((orderBy(values)))
    return {
      minVal: uniqOrderedValues[0],
      maxVal: uniqOrderedValues[uniqOrderedValues.length - 1]
    }
  }

  const prepareChartData = (data) => {
    const {minVal, maxVal} = getMinMax(data)
    const config = {
      chart: {
        map: topology
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },
      title: {
        text: ''
      },
      legend: {
        title: {
          text: label
        }
      },
      colorAxis: {
        minColor: '#dce0eb',
        maxColor: '#001E62',
        min: minVal,
        max: maxVal,
        type: 'logarithmic'
      },
      series: [{
        data: data,
        joinBy: ['iso-a2', 'code'],
        animation: true,
        name: label,
        states: {
          hover: {
            color: '#D62598'
          }
        },
        shadow: false
      }]
    }

    setChartConfig(config)
  }

  const loadTopology = async () => {
    const resp = await fetch('/internal/dashboard_charts/world_topology')
    const json = await resp.json()
    setTopology(json)
  }

  const loadChartData = () => {
    setLoading(true)

    fetch(fetchURL).catch(e => {
      setError('Something went wrong')
      setLoading(false)
    })
      .then((response) => {
        if (response.status === 200 || response.status === 422) {
          return response.json()
        } else {
          throw new Error(response.statusText || 'Something went wrong')
        }
      })
      .then(jsonData => {
        if (jsonData.error) {
          throw new Error(jsonData.error)
        } else {
          setLoading(false)
          prepareChartData(jsonData.data)
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message)
      })
  }

  useEffect(() => {
    loadTopology()
  }, [])

  useEffect(() => {
    if (topology) {
      loadChartData()
    }
  }, [fetchURL, topology])

  if (!loading && error) {
    return <div className='alert alert-danger'>{error}</div>
  }

  return (
    <Fragment>
      { !loading && chartConfig && 
        <Suspense  fallback={<div><Spinner/></div>}>
          <ReactHighmaps config={chartConfig} /> 
        </Suspense>
      }
      { loading && <div><Spinner /> </div>}
    </Fragment>
  )
}

export default React.memo(MapChart)
