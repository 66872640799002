import React, { useState, useEffect, Suspense, lazy } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import trim from 'lodash/trim'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import CustomEmailRecipientFormSection from './CustomEmailRecipientFormSection'
import MetricsSkuSelect from '../containers/DashboardMetrics/common/MetricsSkuSelect'
import CatalogContext from '../containers/DashboardMetrics/common/CatalogContext'

const Editor = lazy(() => import('react-draft-wysiwyg').then(module => ({default: module.Editor})))

const CustomEmailForm = ({formState, catalog, emailLists}) => {
  const initialSku = {
    platform: formState.sku_platform,
    product: formState.sku_product,
    name: formState.sku_name
  }
  
  const [sku, setSku] = useState({...initialSku})
  const [formData, setFormData] = useState(formState)
  const [djsConfig, setDjsConfig] = useState()
  const [error, setError] = useState()
  const [successMsg, setSuccessMsg] = useState()
  const [testRequired, setTestRequired] = useState(true)
  const [loading, setLoading] = useState(false)

  const [editorState, setEditorState] = useState()

  useEffect(() => {
    const loadResources = async () => {
      const draftToHtml = (await import('draftjs-to-html')).default
      const htmlToDraft = (await import('html-to-draftjs')).default
      const { EditorState, convertToRaw, ContentState } = await import('draft-js')

      setDjsConfig({draftToHtml, convertToRaw})

      const contentBlock = htmlToDraft(formState.body)
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      setEditorState(() => EditorState.createWithContent(contentState))
    }

    loadResources()
  }, [])

  useEffect(() => {
    if (!testRequired) {
      setTestRequired(true)
    }
  }, [formData.subject, formData.body])

  useEffect(() => {
    if (sku) {
      const sku_platform = sku.platform
      const sku_product = sku.product
      const sku_name = sku.name
      setFormData({ ...formData, sku_platform, sku_product, sku_name})
    }
  }, [sku])

  const handleChange = (e, key) => {
    if (error) {
      setError()
    }
    setFormData({ ...formData, [key]: e.target.value })
  }

  const handleActiveCheckbox = (key) => {
    setFormData({ ...formData, [key]: !formData[key] })
  }

  const customerEmployeeDisabled = () => {
    return formData.email_list_id || (formData.recipient_list && formData.recipient_list.length > 0)
  }

  const includeIframeOrImage = (htmlContent) => htmlContent.indexOf('img') > -1 || htmlContent.indexOf('iframe') > -1

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState)
    const content = newEditorState.getCurrentContent()
    const htmlContent = djsConfig.draftToHtml(djsConfig.convertToRaw(content))
    let body = ''
    if (trim(content.getPlainText()).length > 0 || includeIframeOrImage(htmlContent)) {
      body = htmlContent
    }
    setFormData({ ...formData, body })
  }

  const sendToServer = (fetchURL, callbackFn) => {
    setLoading(true)
    setError()
    fetch(fetchURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ custom_email: formData })
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText || 'Something went wrong')
        }
      })
      .then(json => {
        setLoading(false)
        if (json.errors) {
          setError(json.errors)
          setSuccessMsg()
        }
        if (json.success) {
          setError()
          callbackFn(json)
        }
      })
      .catch(() => {
        setLoading(false)
        setSuccessMsg()
        setError('Something went wrong.')
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (window.confirm('Are you sure you want to send the email blast?')) {
      sendToServer(`/custom_emails.json`, (json) => {
        window.location.href = `/custom_emails/${json.id}?created=true`
      })
    }
  }

  const handleTestSubmit = (e) => {
    e.preventDefault()
    sendToServer(`/custom_emails/test_email.json`, (json) => {
      setTestRequired(false)
      setSuccessMsg('Test email sent successfully!')
    })
  }
  if (!djsConfig) {
    return <div>Loading Resources...</div>
  }

  return (
    <div>
      <CatalogContext.Provider value={catalog}>
        <div className='row'>
          { successMsg && <div className='alert alert-success col-md-12'>{successMsg}</div>}
          { error && <div className='alert alert-danger col-md-12'>{error}</div>}
          <div className='col-md-12'>
            <Form>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Subject'
                      value={formData.subject}
                      onChange={(e) => handleChange(e, 'subject')}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group>
                    <Form.Label>Body</Form.Label>
                    <Suspense fallback={<div>Loading resources...</div>}>
                      <Editor
                        editorState={editorState}
                        toolbarStyle={{border: '1px solid #cccccc'}}
                        editorStyle={{border: '1px solid #cccccc', minHeight: '150px'}}
                        editorClassName='reset-this-root'
                        onEditorStateChange={(newEditorState) => handleEditorChange(newEditorState)}
                        toolbar={{
                          options: ['inline', 'blockType', 'fontSize', 'fontFamily',
                            'list', 'textAlign', 'colorPicker', 'link', 'embedded',
                            'emoji', 'image', 'remove', 'history'],
                          fontFamily: {
                            options: ['Arial', 'Times New Roman', 'Courier New']
                          }
                        }}
                      />
                    </Suspense>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Group>
                    <Form.Label>Recipient List</Form.Label>
                    <Form.Control as="select" 
                      value={formData.email_list_id}
                      onChange={(e) => handleChange(e, 'email_list_id')}>
                      <option value=''>None</option>
                      {emailLists.map((list, i) =>
                        <option key={i} value={list.id}>
                          {list.name} ({list.recipients_count})
                        </option>
                      )}
                    </Form.Control>
                    {
                      formData.email_list_id &&
                        <div className='text-right'>
                          <small>
                            <a href={`/email_lists/${formData.email_list_id}`} rel="noreferrer" target='_blank'>
                              View list
                            </a>
                          </small>
                        </div>   
                    }
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Group disabled={formData.email_list_id}>
                    <CustomEmailRecipientFormSection onChange={(e) => handleChange(e, 'recipient_list')} />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <Form.Group disabled={customerEmployeeDisabled()}>
                    <Form.Label>License Type</Form.Label>
                    <div className='card'>
                      <div className='card-body'>
                        <MetricsSkuSelect onSkuChange={setSku} sku={sku} />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group disabled={customerEmployeeDisabled()}>
                    <Form.Check
                      type='checkbox'
                      id='active-customers-checkbox'
                      label='&nbsp;Customers'
                      checked={formData.customers}
                      onChange={() => handleActiveCheckbox('customers')}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group disabled={customerEmployeeDisabled()}>
                    <Form.Check
                      type='checkbox'
                      id='internal-users-checkbox'
                      label='&nbsp;Internal employees'
                      checked={formData.internal_users}
                      onChange={() => handleActiveCheckbox('internal_users')}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Button variant='default' onClick={handleTestSubmit} disabled={loading}>
                  Test Email
                  </Button>
                &nbsp;
                  <Button variant='primary' onClick={handleSubmit} disabled={loading || testRequired}>
                  Send { testRequired && <small>(Test email required)</small> }
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </CatalogContext.Provider>
    </div>
  )
}

export default CustomEmailForm
