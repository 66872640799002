import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'
import Truncate from './Truncate'

export const Link = ({
  children,
  maxLength = 25,
  to,
  ...props
}) =>
  <Box as='a' href={to} {...props}>
    <Truncate maxLength={maxLength}>
      {children}
    </Truncate>
  </Box>

Link.propTypes = {
  maxLength: PropTypes.number,
  to: PropTypes.string
}

export default Link
