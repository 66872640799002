import React from 'react'
import { FormInput } from './Form'

export const LegacyPartitions = (props) =>
  <FormInput
    type='number'
    id='license_number_of_partitions'
    label='Number Of Partitions'
    min='1'
    {...props}
  />

export default LegacyPartitions
