import React, {Fragment} from 'react'
import useVictory from './helpers/useVictory'

const getParentSVG = (evt) => {
  if (evt.nativeEvent && evt.nativeEvent.identifier !== undefined) {
    return undefined
  }

  const getParent = (target) => {
    if (target.nodeName === 'svg') {
      return target
    } else {
      return target.parentNode ? getParent(target.parentNode) : target
    }
  }

  return getParent(evt.target)
}

const getSVGEventCoordinates = (evt, svg) => {
  if (evt.nativeEvent && evt.nativeEvent.identifier !== undefined) {
    return {
      x: evt.nativeEvent.locationX,
      y: evt.nativeEvent.locationY
    }
  }

  evt = evt.changedTouches && evt.changedTouches.length ? evt.changedTouches[0] : evt
  svg = svg || getParentSVG(evt)
  const matrix = svg.getScreenCTM().inverse()
  return {
    x: transformTarget(evt.clientX, matrix, 'x'),
    y: transformTarget(evt.clientY, matrix, 'y')
  }
}

const transformTarget = (target, matrix, dimension) => {
  const { a, d, e, f } = matrix

  return dimension === 'y' ? d * target + f : a * target + e
}

const MouseFollowTooltip = ({...props}) => {
  const Victory = useVictory()

  return (
    <Fragment>
      { Victory && <Victory.Tooltip {...props} pointerLength={12} /> }
    </Fragment>
  )
}

MouseFollowTooltip.defaultEvents = [
  {
    target: 'data',
    eventHandlers: {
      onMouseOver: evt => {
        const { x, y } = getSVGEventCoordinates(evt)
        return {
          target: 'labels',
          mutation: () => ({
            x,
            y,
            active: true
          })
        }
      },
      onMouseMove: evt => {
        const { x, y } = getSVGEventCoordinates(evt)
        return {
          target: 'labels',
          mutation: () => ({
            x,
            y,
            active: true
          })
        }
      },
      onMouseOut: () => {
        return { target: 'labels', mutation: () => ({ active: false }) }
      }
    }
  }
]

export default MouseFollowTooltip
