import React from 'react'
import Box from '../components/Box'

const IdpDomainList = ({ idpDomains }) => (
  idpDomains.map(domain =>
    <Box key={domain} tag='span'>
      {domain}
    </Box>
  )
)

export default IdpDomainList
