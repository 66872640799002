import React, { useState, useEffect } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const IncludeActivationsSwitch = () => {
  const [include_activations, setIncludeActivations] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setIncludeActivations(params.get('include_activations') === '1');
  }, []);

  const handleToggle = (checked) => {
    setIncludeActivations(checked);
    handleSubmit(checked);
  };

  const handleSubmit = (checked) => {
    const params = new URLSearchParams();
    params.set('include_activations', checked ? '1' : '0');
    
    window.location.search = params.toString();
  };

  return (
    <form id="filter_form">
      <BootstrapSwitchButton
        checked={include_activations}
        onlabel='Show Activations'
        offlabel='Show Activations'
        width={170}
        id='include_activations_switch'
        onChange={handleToggle}
      />
    </form>
  );
};

export default IncludeActivationsSwitch;
