import React from 'react'
import Button from 'react-bootstrap/Button'

const SortableTableHeader = ({title, trackKey, onChange, sortKey, sortDirection, renderPlainHeader, buttonOnly}) => {
  const asc = 'asc'
  const desc = 'desc'

  const requestSort = () => {
    if (sortKey === trackKey && sortDirection === asc) {
      onChange({ key: trackKey, direction: desc })
    } else if (sortKey === trackKey && sortDirection === desc) {
      onChange({})
    } else {
      onChange({ key: trackKey, direction: asc })
    }
  }

  const renderSortIcon = () => {
    if (sortKey === trackKey && sortDirection === asc) {
      return <i className='fa fa-caret-up' />
    } else if (sortKey === trackKey && sortDirection === desc) {
      return <i className='fa fa-caret-down' />
    }
  }

  const sortButton = () => 
    <Button 
      variant='link shadow-none' 
      onClick={requestSort} 
      style={{ padding: 0, whiteSpace: 'nowrap', outline: 'none' }}>
      {title} {renderSortIcon()}
    </Button>

  if (renderPlainHeader) {
    return (
      <th onClick={requestSort} style={{cursor: 'pointer'}}>
        <span className='text-primary'>{title} {renderSortIcon()}</span>
      </th>
    )
  }

  if (buttonOnly) {
    return sortButton()
  }

  return (
    <th>
      {sortButton()}
    </th>
  )
}

export default SortableTableHeader
