import React from 'react'
import SummaryRow from './SummaryRow'

const SummaryFlexpoolRows = (props) => {
  return (
    <div>
      {props.wizardState.isFlexpool &&
        <SummaryRow label='Unlimited Bandwidth:' value={props.wizardState.unlimitedBandwidth.toString()} />
      }
      {(!props.wizardState.unlimitedBandwidth) && props.wizardState.isFlexpool &&
        <SummaryRow label='Bandwidth:' value={props.wizardState.displayBandwidth} />
      }
      {(!props.wizardState.unlimitedBandwidth) && props.wizardState.isFlexpool &&
        <SummaryRow label='Bandwidth Units:' value={props.wizardState.bandwidthUnit} />
      }
    </div>
  )
}

export default SummaryFlexpoolRows
