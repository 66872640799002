import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const DeleteReasonModal = ({deleteURL, redirectURL}) => {
  const [showModal, setShowModal] = useState(false)
  const [reason, setReason] = useState("duplicate records")

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    fetch(deleteURL, {
      credentials: 'include',
      method: 'DELETE',
      body: JSON.stringify({ reason: reason }),
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json'
      }
    }).then(data => {
      window.location.href = redirectURL
    })
  }

  const handleLinkCLick = (e) => {
    e.preventDefault()
    setShowModal(true)
  }

  const onClose = () => setShowModal(false)

  return (
    <>
      <a className='btn btn-link' onClick={handleLinkCLick} href='#'>
        <i className='fa fa-trash text-danger' />
      </a>
      { showModal && <Modal show={true} onHide={onClose} className="react">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to delete this import?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Fill in the reason for deletion below.<br /><br />
            <Form.Group controlId='formBasicEmail'>
              <Form.Label>Delete Reason</Form.Label>
              <Form.Control type='text' placeholder='e.g., Acme Commons' value={reason} onChange={(e) => setReason(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={onClose}>
              Close
            </Button>
            <Button type='submit'>Delete Import</Button>
          </Modal.Footer>
        </Form>
      </Modal>}
    </>
  )
}

export default DeleteReasonModal
