import React, { useState } from 'react'
import ExpiresAt from './ExpiresAt'
import { Form, Field } from './Form'
import AutoRenew from '../components/AutoRenew'
import LicenseNotes from '../components/LicenseNotes'
import {
  FormActions,
  FormSubmit
} from '../components/Form'
import SalesOrderSelect from '../components/SalesOrderSelect'
import AuthorizedUsersCheckboxList from '../components/AuthorizedUsersCheckboxList'
import { getMoment, getMomentFmt } from '../helpers/moment'
import Alert from '../Alert'

export const EditAddonLicenseForm = ({ props, policies, attributes }) => {
  const [sku, setSku] = useState({})
  const [expiresAt, setExpiresAt] = useState(getMoment(props.initial_value.expires_at))

  const prepareFetchData = data => ({ license: data })

  const handleSuccess = ({ data }) => {
    window.location = `/licenses/${props.parent_id}/addons?success=true`
  }

  const getChange = (data) => {
    let date
    if (data.value) {
      date = getMomentFmt(data.value, 'DD/MM/YYYY')
    }

    if (typeof (date) === 'undefined' || (typeof (date) === 'object' && date.isValid())) {
      setExpiresAt(date)
    }
  }

  const handleError = () => {
    window.scrollTo(0,0)
  }

  return (
    <Form
      title='Update Addon License'
      url={`/licenses/${props.id}/update_addon.json`}
      method='PATCH'
      attributes={attributes}
      prepareData={prepareFetchData}
      inputProps={props}
      onSuccess={handleSuccess}
      onError={handleError}
    >
      <Field name='name' />
      <Field as={SalesOrderSelect} name='order_attributes.a10_sales_order_id' />
      <Field as={ExpiresAt} name='expires_at' sku={props.sku} getChange={getChange} initialValue={getMoment(props.initial_value.expires_at)} />
      {!props.parent_auto_renew && expiresAt && getMoment(props.parent_expires_at) < expiresAt &&
        <Alert closable='false' color='bg-orange-1 white'>
          <h5>
            <strong>Warning!</strong> This license expires past the base license expiration date.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You will lose that addon functionality when this base license expires.
          </h5>
        </Alert>}
      <Field as={AutoRenew} name='auto_renew' />

      { !!props.authorized_users.checkboxList.length &&
        <Field
          as={AuthorizedUsersCheckboxList}
          label={'Authorized Users'}
          name='authorized_users'
        />
      }
      <Field 
        as={LicenseNotes} 
        name='license_notes_attributes'
        initialVal={props.license_notes}
        allowInternal={policies.currentUser.writeInternal}  />

      <FormActions>
        <FormSubmit value='Update License' />
      </FormActions>
    </Form>
  )
}

export default EditAddonLicenseForm
