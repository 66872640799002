import React, { useState, useEffect, Fragment } from 'react'
import endsWith from 'lodash/endsWith'
import toLower from 'lodash/toLower'
import Button from '../Button'
import Alert from '../components/Alert'


export const LoginForm = ({ props, authenticity_token, support_email, pending }) => {
  const [showUsernameForm, setShowUsernameForm] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [useSSO, setUseSSO] = useState(false)
  const [SSOUrl, setSSOUrl] = useState(null)
  const [errors, setErrors] = useState(null)

  const emailform = React.createRef()
  const validateEmailForm = () => {
    return emailform.current.reportValidity()
  }
  const passwordform = React.createRef()
  const validatePasswordForm = () => {
    return passwordform.current.reportValidity()
  }
  const handleUsernameChange = ({ target: { value } }) => {
    setUsername(value)
  }
  const handleUsernameFormNext = async (e) => {
    const valid = validateEmailForm()
    if(valid) {
      setErrors(null)
      getIdpInfo().then(signinsso => {
        setShowUsernameForm(signinsso)
      })
    }
  }
  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value)
  }
  const handlePasswordFormNext = (e) => {
    const valid = validatePasswordForm()
    if(valid) {
      setErrors(null)
      passwordform.current.submit()
    }
  }
  const a10user = () => {
    if (username && typeof(username) === 'string') {
      return endsWith(toLower(username), '@a10networks.com')
    } else {
      return false
    }
  }
  const getIdpInfo = async () => {
    try {
      const response = await fetch('/users/idp_info.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: {
            email: username
          }
        })
      })
      const json = await response.json()
      if (response.ok) {
        setUseSSO(true)
        setSSOUrl(json['sso_target_url'] + '&login_hint=' + username)
        return true
      } else {
        setUseSSO(false)
      }
    } catch (error) {
      setUseSSO(false)
      setErrors('There was an error checking for single sign on availability')
    }
    return false
  }
  useEffect(() => {
    if(useSSO) {
      window.location.assign(SSOUrl)
    }
  }, [SSOUrl])

  return (
    <div>
      <div className="mt2">
        <Alert closable={true}>{errors}</Alert>
      </div>
      <div className='row'>
        <div className='col-md-7'>
          <div className='form-wrap'>
            <div className='form-info'>
              <h2>Sign In</h2>
            </div>
            {showUsernameForm &&
              <form onSubmit={(e) => e.preventDefault()} ref={emailform}>
                <label className='control-label email optional'>Email</label>
                <input name='user[email]'
                  type='email'
                  autoFocus={true}
                  onChange={e => handleUsernameChange(e)}
                  className={'form-control string email'}
                  required={true}
                  value={username} />
                <p className='help-block'>Must be a valid email: username@example.com</p>
                <input type='submit' value='Next' className='submit-username btn btn-primary' onClick={(e) => handleUsernameFormNext(e)} />
              </form>
            }
            {!showUsernameForm &&
              <form action='/users/sign_in' method='post' ref={passwordform}>
                <input name='authenticity_token'
                  hidden={true}
                  value={authenticity_token} />
                <input name='user[email]'
                  hidden={true}
                  value={username} />
                <label className='control-label password optional'>Password</label>
                <input name='user[password]'
                  onChange={e => handlePasswordChange(e)}
                  className={'form-control string password'}
                  type='password'
                  required='required'
                  autoFocus={true}
                  value={password} />
                <p className='help-block'>
                  Must be at least 8 characters
                  { a10user() &&
                   <Fragment>
                     &nbsp;| <span className='text-danger'>This is not corporate domain password</span>
                   </Fragment>
                  }
                </p>
                <input type='submit' name='commit' value='Sign In' className='submit-password btn btn-primary' onClick={(e) => handlePasswordFormNext(e)}/>
                <Button className={'previous btn btn-default'} onClick={(e) => setShowUsernameForm(true)}>
                  Previous
                </Button>
              </form>
            }
          </div>
        </div>
        <div className='col-md-5 p2'>
          <h2>A10 Global License Manager</h2>
          <p>The Global License Manager (GLM) is the master licensing
          and billing system for A10 vThunder. GLM issues licenses for the
          vThunder instances or A10 Enterprise License Manager (ELM) upon request,
          and collects information from the vThunders or ELM for customer
          visibility and license management.</p>
          <p>For more information please contact <a href={`mailto:${support_email}`}>acloud support</a>.</p>
          {pending && <a href="/pending_users/new">Don't have an account?</a> }
          {!pending && <a href="https://get.a10networks.com/trial-downloads/">Don't have an account?</a> }
          <br />
          <a href="/users/password/new">Forgot your password?</a><br />
          <a href="/users/confirmation/new">Didn't receive confirmation instructions?</a><br />
        </div>
      </div>
    </div>
  )
}

export default LoginForm
