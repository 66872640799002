import React, { Fragment } from 'react'
import WizardForm from '../WizardForm'
import Form from 'react-bootstrap/Form'
import WizardButtons from '../WizardButtons'
import SubscriptionCustomBandwidth from '../SubscriptionCustomBandwidth'

class Bandwidth extends WizardForm {
  state = {
    ...this.props.wizardState
  }

  componentDidMount = () => {
    if (!this.props.wizardState.isFlexpool) {
      this.skipForm()
    }
  }

  onBandwidthChange = (data) => {
    this.setState({
      selectedBandwidth: data.selectedBandwidth,
      displayBandwidth: data.displayBandwidth,
      bandwidthUnit: data.unit
    })
  }

  onUnlimitedChange = (e) => {
    this.setState({
      unlimitedBandwidth: e.target.checked
    })
  }

  getWizardState = () => {
    return {
      selectedBandwidth: this.state.selectedBandwidth,
      unlimitedBandwidth: this.state.unlimitedBandwidth,
      bandwidthUnit: this.state.bandwidthUnit,
      displayBandwidth: this.state.displayBandwidth
    }
  }

  render = () => (
    <Fragment>
      <div className='py1'>
        <Form.Check
          type='checkbox'
          id='sku_unlimited_bandwidth_checkbox'
          checked={this.state.unlimitedBandwidth}
          onChange={this.onUnlimitedChange}
          label='&nbsp;Unlimited Bandwidth'
        />
      </div>
      <div className='py1' disabled={this.state.unlimitedBandwidth}>
        <SubscriptionCustomBandwidth
          bandwidth={this.state.selectedBandwidth}
          onBandwidthChange={this.onBandwidthChange}
          limit={999999999}
        />
      </div>
      <WizardButtons
        onPreviousClick={this.onPrevious}
        onNextClick={this.onNext}
        nextEnabled
      />
    </Fragment>
  )
}

export default Bandwidth
