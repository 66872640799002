import React, {useState, useEffect } from 'react'

import {
  FormInput,
  FormSection
} from './Form'

export const Version = (props) => {
  useEffect(() => {
    let val = props.value || '';
    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];
    props.onChange && props.onChange({ name: props.name, value: value })
  }, [props.value])

  return (<div>
    <FormInput
      type='number'
      {...props}
    />
  </div>
  )
}

export default Version
