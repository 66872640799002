import React, { useState } from 'react'

import AuthorizedUsers from './AuthorizedUsers'
import { Form, Field } from './Form'
import { Checkbox } from '../components/Input'
import { reject, isUndefined } from 'lodash'
import SupportContractAuthUsers from '../components/SupportContractAuthUsers'
import { getMoment } from '../../util/time'

import {
  FormActions,
  FormSection,
  FormSubmit
} from '../components/Form'

import StartsAt from '../components/StartsAt'

export const SupportContractForm = props => {
  const [roles, setRoles] = useState(props.contract.roles)
  const [deviceSerialNumber, setDeviceSerialNumber] = useState(props.contract.device_serial_number)
  const prepareFetchData = data => ({ support_contract: { ...data, roles, device_serial_number: deviceSerialNumber} })

  const handleSuccess = ({ data }) => {
    const redirectPath = props.success_redirect || `/support_contracts/${data.id}?success=true`
    window.location = redirectPath
  }

  const handleError = () => {
    window.scrollTo(0, 0)
  }

  const handleRoles = role => {
    if (isRoleSelected(role)) {
      const updatedRoles = reject(roles, r => r === role.name)
      setRoles(updatedRoles)
    } else {
      setRoles([...roles, role.name])
    }
  }

  const handleDeviceSerialNumber = event => {
    setDeviceSerialNumber(event.target.value)
  }

  const isRoleSelected = role => {
    return roles.indexOf(role.name) > -1
  }

  const momentVal = (stringVal) => {
    if (stringVal) {
      return getMoment(stringVal)
    } else {
      return null
    }
  }

  return (
    <Form
      title={props.form.title}
      url={props.form.action}
      method={props.form.method}
      prepareData={prepareFetchData}
      inputProps={props}
      onSuccess={handleSuccess}
      onError={handleError}
    >
      <FormSection>
        <Field name='device_serial_number' required value={deviceSerialNumber} onChange={handleDeviceSerialNumber} />
        <div className='row no-section-borders'>
          <div className='col-md-6'>
            <Field as={StartsAt} name='starts_at' value={momentVal(props.contract.starts_at)} inputLabel='Starts At *' required />
          </div>
          <div className='col-md-6'>
            <Field as={StartsAt} name='ends_at' value={momentVal(props.contract.ends_at)} inputLabel='Ends At *' datepickerId='datepicker_ends_at' />
          </div>
        </div>
      </FormSection>
      <FormSection>
        <label className='control-label optional'>Roles</label>
        {
          props.roles.map((role, i) =>
            <div key={i} className='m0 py1'>
              <Checkbox
                id={i}
                value={role.display_name}
                checked={isRoleSelected(role)}
                onChange={() => handleRoles(role)}
              />
            </div>
          )
        }

      </FormSection>

      {isUndefined(props.contract.id) && <Field
        as={AuthorizedUsers}
        name='authorized_users'
        required
        canNotify={false}
      />
      }

      {!isUndefined(props.contract.id) && <FormSection>
        <label className='control-label optional'>Authorized Users</label>
        <SupportContractAuthUsers supportContractId={props.contract.id} canAuthorizeUser={props.canAuthorizeUser} />
      </FormSection>}

      <FormActions>
        <FormSubmit value={props.form.submitText} />
      </FormActions>
    </Form>
  )
}

SupportContractForm.defaultProps = {
  roles: [],
  contract: {
    starts_at: null,
    ends_at: null,
    device_serial_number: '',
    roles: ['drupal_ax_series_support']
  },
  form: {
    action: '/api/v1/support_contracts',
    method: 'POST',
    title: 'Create Support Contract',
    submitText: 'Create Support Contract'
  }
}

export default SupportContractForm
