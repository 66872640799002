import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const RangeSliderLimited = ({title, onChange, initialValue, name, unit, min, max, step}) => {
  const [rangeVal, setRangeVal] = useState(initialValue || min)
  const rangeStep = step || 1

  const handleChange = (e) => {
    let targetVal = e.target.value
    if (targetVal > max) {
      targetVal = max
    }else if (targetVal < min) {
      targetVal = min
    }
    setRangeVal(targetVal)
    onChange && onChange({ name, value: targetVal })
  }

  useEffect(()=> {
    setTimeout(() => onChange && onChange({ name, value: rangeVal }))
  }, [])

  return (
    <div>
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <input type='range' custom="true" onChange={handleChange} value={rangeVal} name={name} min={min} max={max} step={rangeStep}/>
      </Form.Group>
      <Form.Group className='ml2'>
        <Row>
          <Col md={3}>
            <Form.Control type='number' onChange={handleChange} value={rangeVal} min={min} max={max} step={rangeStep}/>
          </Col>
          <Col md={9} className='pt2'>
            {rangeVal} {unit}
          </Col>
        </Row>
      </Form.Group>
    </div>
  )
}

export default RangeSliderLimited