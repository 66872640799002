import React, { useEffect } from 'react'
import {
  FormInput,
  FormSection
} from './Form'

function SSLType ({ onSslChange, ...props }) {

  useEffect(() => {
    if (props.value) {
      onSslChange(true)
    } else {
      onSslChange(false)
    }
  }, [props.value])

  return (
    <FormSection>
      <FormInput
        type='select'
        label='SSL Chipset'
        id='license_ssl_chipset_type'
        name='ssl_chipset_type'
        placeholder='Software SSL Only'
        children={[]}
        {...props}
      />
    </FormSection>
  )
}

export default SSLType
