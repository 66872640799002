export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const titleize = str => str.split('_').map(capitalize).join(' ')

export const lableize = str => str.toLowerCase().split('_').map(capitalize).join(' ')

// Convert between camelCase and snakeCase
export const toCamel = str => str.replace(/_[a-z]/g, m => m.toUpperCase().replace('_', ''))
export const toSnake = str => str.replace(/[A-Z]/g, m => `_${m.toLowerCase()}`)

// truncate a string
export const truncate = (str, maxLength = 25) => (
  str.length >= maxLength
    ? str.slice(0, maxLength)
    : str
)
